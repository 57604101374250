import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { Condition, Visit } from "../../../evaluate-rule";
import { GetTradingPartnerMapping_tradingPartnerMapping as TradingPartnerMapping } from "../../../generated/GetTradingPartnerMapping";
import { GetTradingPartners } from "../../../generated/GetTradingPartners";
import { useUser } from "../../../user-context";
import { Layout } from "../../layout";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { GET_TRADING_PARTNERS } from "./edit";
import { TradingPartnerMappingForm } from "./trading-partner-mapping-form";

export const NewTradingPartnerRule: React.FC = () => {
  const user = useUser()!;
  const location = useLocation();
  const state: {
    tradingPartnerMapping?: TradingPartnerMapping | null;
  } | null = location.state;
  const { data, loading, error } =
    useQuery<GetTradingPartners>(GET_TRADING_PARTNERS);

  const tradingPartners = data?.tradingPartners ?? [];

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <TradingPartnerMappingForm
            tradingPartnerMapping={state?.tradingPartnerMapping ?? null}
            tradingPartners={tradingPartners}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </TradingPartnerMappingForm>
        )
      }
    />
  );
};
