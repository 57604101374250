import React, { useMemo, useState, useRef, useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../utils";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Badge } from "./badge";
import fuzzysort from "fuzzysort";
import { useVirtualizer } from "@tanstack/react-virtual";

interface TradingPartner {
  id: string;
  name: string;
  tradingPartnerId: string | null;
  eligibilityEnabled: boolean;
}

interface TradingPartnerComboboxProps {
  value: TradingPartner | null;
  tradingPartners: TradingPartner[];
  onSelect: (value: TradingPartner | null) => void;
  placeholder?: string;
  disabled?: boolean;
}

const normalizeScore = (score: number | null) => {
  const MIN_SCORE = -2500;
  if (score === null || score < MIN_SCORE) return 0;
  return (score - MIN_SCORE) / -MIN_SCORE;
};

export const TradingPartnerCombobox: React.FC<TradingPartnerComboboxProps> = ({
  value,
  tradingPartners,
  onSelect,
  placeholder = "Select trading partner...",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(value?.name ?? "");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const filteredItems = useMemo(() => {
    if (!search) return tradingPartners;

    const results = tradingPartners.map((partner) => {
      const nameMatch = fuzzysort.single(
        search.toLowerCase(),
        partner.name.toLowerCase()
      );
      const idMatch = fuzzysort.single(
        search.toLowerCase(),
        partner.tradingPartnerId?.toLowerCase() ?? ""
      );

      const score = Math.max(
        normalizeScore(nameMatch?.score ?? null),
        normalizeScore(idMatch?.score ?? null)
      );

      return {
        partner,
        score,
        nameMatch,
        idMatch,
      };
    });

    return results
      .filter((result) => result.score > 0)
      .sort((a, b) => b.score - a.score)
      .map((result) => result.partner);
  }, [tradingPartners, search]);

  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 56, // Height of each trading partner row
    overscan: 5,
  });

  // Trigger measurement when the popover opens
  useEffect(() => {
    if (open) {
      virtualizer.measure();
    }
  }, [open, virtualizer]);

  // Also measure when filtered items change
  useEffect(() => {
    virtualizer.measure();
  }, [filteredItems, virtualizer]);

  const renderHighlightedText = (
    text: string,
    match: Fuzzysort.Result | null
  ) => {
    if (!match || !search) return text;

    const highlighted = fuzzysort.highlight(match, (m, i) => (
      <span key={i} className="bg-yellow-100 text-yellow-900">
        {m}
      </span>
    ));

    return highlighted || text;
  };

  const renderTradingPartnerRow = (partner: TradingPartner) => {
    const nameMatch = search
      ? fuzzysort.single(search.toLowerCase(), partner.name.toLowerCase())
      : null;
    const idMatch = search
      ? fuzzysort.single(
          search.toLowerCase(),
          partner.tradingPartnerId?.toLowerCase() ?? ""
        )
      : null;

    return (
      <CommandItem
        key={partner.id}
        value={partner.id}
        onSelect={() => {
          onSelect(partner === value ? null : partner);
          setOpen(false);
        }}
        className="py-2"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col">
            <div className="font-medium">
              {renderHighlightedText(partner.name, nameMatch)}
            </div>
            <div className="text-sm text-muted-foreground font-mono">
              {renderHighlightedText(partner.tradingPartnerId ?? "", idMatch)}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {partner.eligibilityEnabled && (
              <Badge
                variant="outline"
                className="rounded-sm px-1 text-xs text-emerald-700 bg-emerald-50 border-emerald-200"
              >
                Eligibility
              </Badge>
            )}
            {value?.id === partner.id && (
              <Check className="h-4 w-4 text-primary" />
            )}
          </div>
        </div>
      </CommandItem>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={disabled}
          className={cn(
            "justify-between w-full",
            value && "text-muted-foreground"
          )}
        >
          {value ? (
            <div className="flex items-center gap-2 text-left">
              <span className="truncate">{value.name}</span>
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-mono text-xs"
              >
                {value.tradingPartnerId}
              </Badge>
              {value.eligibilityEnabled && (
                <Badge
                  variant="outline"
                  className="rounded-sm px-1 text-xs text-emerald-700 bg-emerald-50 border-emerald-200"
                >
                  Eligibility
                </Badge>
              )}
            </div>
          ) : (
            placeholder
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[400px] p-0" sideOffset={4}>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search by name or ID..."
            value={search}
            onValueChange={(newSearch) => {
              setSearch(newSearch);
              virtualizer.scrollToIndex(0);
            }}
          />
          <CommandEmpty>No trading partners found.</CommandEmpty>
          <CommandGroup>
            <div
              ref={scrollContainerRef}
              className="max-h-[300px] overflow-y-auto"
            >
              <div
                style={{
                  height: `${virtualizer.getTotalSize()}px`,
                  width: "100%",
                  position: "relative",
                }}
              >
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const partner = filteredItems[virtualRow.index];
                  return (
                    <div
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      {renderTradingPartnerRow(partner)}
                    </div>
                  );
                })}
              </div>
            </div>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
