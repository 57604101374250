import React, { useEffect, useState } from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";

import { constants } from "./constants";
import { Rings } from "./components/loading";
import {
  GetMe,
  GetMe_me as Me,
  GetMe_me_organization as Organization,
  GetMe_me_activeLocation as Location,
} from "./generated/GetMe";
import { useQueryClient } from "react-query";
import { Button } from "./components/ui/button";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./components/ui/alert-dialog";
import { differenceInMilliseconds, parseISO, set } from "date-fns";
import { mapNullable } from "./utils";

export type { Organization, Location };
export type User = Me & {
  externalLedgerName: string;
};

export interface Auth {
  login: () => void;
  logout: () => void;
  data: { user: User | null; demo: boolean };
  setDemo: (demo: boolean) => void;
  isSessionExpired: boolean;
  sessionExpiresAt: Date | null;
}

const login = () => {
  window.location.href = `${constants.VITE_API_URL}/login`;
};

const logout = () => {
  window.location.href = `${constants.VITE_API_URL}/logout`;
};

const AuthContext = React.createContext<Auth>({
  login,
  logout,
  data: { user: null, demo: false },
  setDemo: () => {},
  isSessionExpired: false,
  sessionExpiresAt: null,
});

const GET_ME = gql`
  query GetMe {
    currentSession {
      id
      expiresAt
    }
    me {
      id
      firstName
      lastName
      email
      isAdmin
      isPledgeUser
      organization {
        id
        name
        pendingRegistration
        stripeAccountId
        planTypes {
          id
          name
          insuranceType
        }
        locations {
          id
          name
          automatedEligibilityVerificationEnabled
          automatedEstimatesEnabled
          preVisitReminderDaysAhead
          adjudicatedAutopayEnabled
          balanceAutopayEnabled
          externalAutopayEnabled
          maxAutopayLimitEnabled
          automatedAccumulatorTrackingEnabled
          ledgerGranularityLevel
          preVisitCardOnFileInput
          preVisitFinancialPolicyInput
        }
        automatedRemindersEnabled
        estimatesEnabled
        benefitsProductEnabled
        billingProductEnabled
        appointmentsPageEnabled
        providerServiceConfiguration(where: { archivedAt: null }) {
          id
          name
          serviceType
          priorityOrder
          showInPdf
        }
        stripeConnectedAccounts {
          id
          default
          name
        }
        integrations {
          id
          name
        }
        reminderWorkflowTemplates {
          id
          fifteenDaysAfterReminder
          thirtyDaysAfterReminder
          fortyFiveDaysAfterReminder
          sixtyDaysAfterReminder
          ninetyDaysAfterReminder
        }
        accountTypes(take: 1) {
          id
        }
        appointmentLocations(take: 1) {
          id
        }
      }
      activeLocation {
        id
        name
        automatedEligibilityVerificationEnabled
        automatedEstimatesEnabled
        automatedTimeOfServiceChargingEnabled
        adjudicatedAutopayEnabled
        balanceAutopayEnabled
        externalAutopayEnabled
        maxAutopayLimitEnabled
        verificationWorkflowEnabled
        estimationWorkflowEnabled
        automatedAccumulatorTrackingEnabled
        ledgerGranularityLevel
        preVisitReminderEnabled
        preVisitReminderDaysAhead
        preVisitCardOnFileInput
        preVisitFinancialPolicyInput
        defaultEligibilityProvider {
          id
          displayName
        }
        defaultVerificationWorkflowStatus {
          id
          name
        }
        defaultEstimationWorkflowStatus {
          id
          name
        }
        estimateSetupMode
        integrations {
          id
          name
          type
          locationId
          supportsOnDemandSync
          supportsOnDemandNewPatientSync
          supportsPrepaymentPosting
          savingVisitCollectionRequestsEnabled
        }
        verificationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          name
          stage
          description
        }
        estimationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          name
          stage
          description
        }
      }
    }
  }
`;

// TODO: Figure out if we can pre-fetch this without rerendering the whole app
export const GET_ME_BOOTSTRAP = gql`
  query GetMeBootstrap {
    me {
      id
      organization {
        id
        appointmentLocations(orderBy: { name: asc }) {
          id
          name
        }
      }
      activeLocation {
        id
        accountTypes(orderBy: { name: asc }) {
          id
          name
        }
        patientLabels(orderBy: { name: asc }) {
          id
          name
        }
        appointmentLabels(orderBy: { name: asc }) {
          id
          name
        }
        chargeCodes
        providers {
          id
          displayName
        }
        payers {
          id
          name
        }
        chargemasterGroups {
          id
          code
          description
          modifier1
          modifier2
          modifier3
          modifier4
        }
      }
    }
    publicProviderTaxonomyCodes {
      id
      displayName
    }
  }
`;

export const WithExpiredSession: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isSessionExpired, logout } = useAuth();

  if (isSessionExpired) {
    return <ExpiredSession logout={logout} />;
  }
  return <>{children}</>;
};

const ExpiredSession: React.FC<{ logout: () => void }> = ({ logout }) => {
  const [timer, setTimer] = useState(15_000);

  // Call logout after 15 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer((prevTimer) => prevTimer - 1000);
    }, 1000);
    if (timer === 0) {
      logout();
    }
    return () => clearTimeout(timeout);
  }, [timer]);

  return (
    <div className="h-screen w-full flex justify-center align-middle">
      <Card className="my-auto mx-4">
        <CardHeader>
          <CardTitle>Session Expired</CardTitle>
          <CardDescription className="text-md">
            Your session has expired. You will be redirected to the login page
            in {Math.round(timer / 1000)} seconds.
          </CardDescription>
        </CardHeader>
        <CardFooter className="flex justify-end">
          <Button onClick={logout}>Login</Button>
        </CardFooter>
      </Card>
    </div>
  );
};

const EXTEND_SESSION = gql`
  mutation ExtendSession {
    extendSession {
      id
      expiresAt
    }
  }
`;

export default function SessionExpirationWarning() {
  const auth = useAuth();
  const [extendSession] = useMutation(EXTEND_SESSION);
  const { sessionExpiresAt } = auth;
  const [showWarning, setShowWarning] = useState(false);
  const [warningIgnored, setWarningIgnored] = useState(false);
  const [oneMinuteWarning, setOneMinuteWarning] = useState(false);
  const [oneMinuteWarningIgnored, setOneMinuteWarningIgnored] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    setWarningIgnored(false);
    const checkSessionExpiration = () => {
      const now = new Date();
      const timeUntilExpiration = differenceInMilliseconds(
        sessionExpiresAt!,
        now
      );
      const warningThreshold = 15 * 60 * 1000; // 15 minutes
      if (timeUntilExpiration <= warningThreshold && timeUntilExpiration > 0) {
        // 15 minutes
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
      if (timeUntilExpiration <= 60 * 1000 && timeUntilExpiration > 0) {
        setOneMinuteWarning(true);
      } else {
        setOneMinuteWarning(false);
      }
      if (timeUntilExpiration <= 0) {
        window.dispatchEvent(new CustomEvent("UNAUTHORIZED_ERROR"));
      }
    };

    if (sessionExpiresAt) {
      checkSessionExpiration();
      timer = setInterval(checkSessionExpiration, 5000); // Check every 5s
    }

    return () => clearInterval(timer);
  }, [sessionExpiresAt]);

  const handleExtendSession = async () => {
    await extendSession();
    setShowWarning(false);
  };

  const show =
    (showWarning && !warningIgnored) ||
    (oneMinuteWarning && !oneMinuteWarningIgnored);

  return (
    <AlertDialog open={show} onOpenChange={setShowWarning}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Session Expiring Soon</AlertDialogTitle>
          <AlertDialogDescription>
            Your session will expire in less than{" "}
            {oneMinuteWarning ? <>1 minute!</> : <>15 minutes.</>} Would you
            like to extend your session?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setShowWarning(false);
              setWarningIgnored(true);
              if (oneMinuteWarning) {
                setOneMinuteWarningIgnored(true);
              }
            }}
          >
            Dismiss
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleExtendSession}>
            Extend Session
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

const AuthProvider: React.FC<React.PropsWithChildren<any>> = (props) => {
  const client = useApolloClient();
  const queryClient = useQueryClient();
  const { loading, error, data } = useQuery<GetMe>(GET_ME);
  const [demo, setDemo] = useState(
    window.localStorage.getItem("demo") === "true" || false
  );
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [sessionExpiresAt, setSessionExpiresAt] = useState<Date | null>(null);

  React.useEffect(() => {
    const handleUnauthorized = () => {
      console.log("Session expired!");
      setIsSessionExpired(true);
    };
    window.addEventListener("UNAUTHORIZED_ERROR", handleUnauthorized);
    return () =>
      window.removeEventListener("UNAUTHORIZED_ERROR", handleUnauthorized);
  }, []);

  React.useEffect(() => {
    setSessionExpiresAt(mapNullable(parseISO)(data?.currentSession?.expiresAt));
  }, [data?.currentSession?.expiresAt]);

  if (loading)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        data: {
          user: data?.me,
          demo,
        },
        login,
        logout,
        isSessionExpired,
        sessionExpiresAt,
        setDemo: (demo) => {
          setDemo(demo);
          window.localStorage.setItem("demo", demo ? "true" : "false");
          client.refetchQueries({ include: "active" });
          queryClient.refetchQueries();
        },
      }}
      {...props}
    >
      <SessionExpirationWarning />
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
