import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  AdjustmentsIcon,
  CalculatorIcon,
  CashIcon,
  ClipboardCheckIcon,
  LibraryIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import {
  ArrowRight,
  Calendar,
  ClipboardListIcon,
  Clock,
  CreditCard,
  DollarSign,
  FileText,
  MailIcon,
  Search,
  TableIcon,
  User as UserIcon,
} from "lucide-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../auth-context";
import { useFeatureFlags } from "../hooks";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "./ui/command";
import {
  GetRecentPatients,
  GetRecentPatientsVariables,
  GetRecentPatients_patients as Patient,
} from "../generated/GetRecentPatients";
import {
  UnifiedSearchPatients,
  UnifiedSearchPatientsVariables,
} from "../generated/UnifiedSearchPatients";

// Unified GraphQL query for searching patients that handles all search types
const UNIFIED_SEARCH_PATIENTS_QUERY = gql`
  query UnifiedSearchPatients(
    $organizationId: String!
    $locationId: String
    $searchTerm: String!
    $firstName: String
    $lastName: String
  ) {
    patients(
      where: {
        organizationId: { equals: $organizationId }
        locationId: { equals: $locationId }
        OR: [
          { firstName: { contains: $searchTerm, mode: insensitive } }
          { lastName: { contains: $searchTerm, mode: insensitive } }
          { email: { contains: $searchTerm, mode: insensitive } }
          { accountNumber: { contains: $searchTerm, mode: insensitive } }
          {
            AND: [
              { firstName: { contains: $firstName, mode: insensitive } }
              { lastName: { contains: $lastName, mode: insensitive } }
            ]
          }
        ]
        deletedAt: { equals: null }
      }
      take: 5
      orderBy: [{ updatedAt: desc }]
    ) {
      id
      firstName
      lastName
      email
      accountNumber
      dateOfBirth
      displayName
    }
  }
`;

// GraphQL query for recently selected patients using IDs
const GET_RECENT_PATIENTS = gql`
  query GetRecentPatients(
    $organizationId: String!
    $locationId: String!
    $patientIds: [String!]
  ) {
    patients(
      where: {
        organizationId: { equals: $organizationId }
        locationId: { equals: $locationId }
        deletedAt: { equals: null }
        id: { in: $patientIds }
      }
    ) {
      id
      firstName
      lastName
      email
      accountNumber
      dateOfBirth
      displayName
    }
  }
`;

// Local storage key for recent patients, scoped to organization and location
const getRecentPatientsKey = (organizationId: string, locationId: string) =>
  `recent-patients-${organizationId}-${locationId}`;

// Function to get recent patient IDs from local storage
const getRecentPatientIds = (
  organizationId: string,
  locationId: string
): string[] => {
  try {
    const key = getRecentPatientsKey(organizationId, locationId);
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : [];
  } catch (error) {
    console.error(
      "Error retrieving recent patients from local storage:",
      error
    );
    return [];
  }
};

// Function to save a patient ID to recent patients in local storage
const addPatientToRecent = (
  patientId: string,
  organizationId: string,
  locationId: string
) => {
  try {
    const key = getRecentPatientsKey(organizationId, locationId);
    const currentIds = getRecentPatientIds(organizationId, locationId);

    // Remove the ID if it already exists to avoid duplicates
    const filteredIds = currentIds.filter((id) => id !== patientId);

    // Add the new ID to the beginning of the array
    // Store up to 20 IDs for tracking history, but we'll only fetch the 3 most recent
    const updatedIds = [patientId, ...filteredIds].slice(0, 20);

    // Save to local storage
    localStorage.setItem(key, JSON.stringify(updatedIds));
  } catch (error) {
    console.error("Error saving recent patient to local storage:", error);
  }
};

// Check if a patient is in the recent list (looking at all 20 stored IDs)
const isPatientInRecentHistory = (
  patientId: string,
  organizationId: string,
  locationId: string
): boolean => {
  try {
    const allRecentIds = getRecentPatientIds(organizationId, locationId);
    return allRecentIds.includes(patientId);
  } catch (error) {
    console.error("Error checking if patient is in recent history:", error);
    return false;
  }
};

// Navigation routes
const getNavigationRoutes = (flags: ReturnType<typeof useFeatureFlags>) => [
  {
    id: "patients",
    name: "Patients",
    icon: <UserGroupIcon className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => "/patients",
  },
  ...(flags.appointmentsPageEnabled
    ? [
        {
          id: "appointments",
          name: "Today's Appointments",
          icon: <Calendar className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/appointments",
        },
      ]
    : []),
  ...(flags.verificationWorkflowEnabled
    ? [
        {
          id: "verifications",
          name: "Verifications",
          icon: <ClipboardCheckIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/verifications",
        },
      ]
    : []),
  ...(flags.estimatesEnabled && flags.estimationWorkflowEnabled
    ? [
        {
          id: "estimations",
          name: "Estimations",
          icon: <CalculatorIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/estimations",
        },
      ]
    : []),
  ...(flags.postVisitBillingEnabled
    ? [
        {
          id: "billing",
          name: "Billing",
          icon: <CashIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/billing",
        },
      ]
    : []),
  ...(flags.stripeAccountActive
    ? [
        {
          id: "payments",
          name: "Payments",
          icon: <DollarSign className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/payments",
        },
      ]
    : []),
  ...(flags.showTasksTab
    ? [
        {
          id: "tasks",
          name: "Tasks",
          icon: <ClipboardListIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/tasks?status=Todo",
        },
      ]
    : []),
  {
    id: "providers",
    name: "Providers",
    icon: <UsersIcon className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => "/providers",
  },
  ...(flags.benefitsProductEnabled
    ? [
        {
          id: "payers",
          name: "Payers",
          icon: <LibraryIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/payers",
        },
      ]
    : []),
  ...(flags.estimatesEnabled
    ? [
        {
          id: "chargemaster",
          name: "Chargemaster",
          icon: <TableIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/chargemaster",
        },
      ]
    : []),
  ...(flags.verificationWorkflowEnabled || flags.estimatesEnabled
    ? [
        {
          id: "rules",
          name: "Rules",
          icon: <AdjustmentsIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/rules",
        },
      ]
    : []),
  ...(flags.showMessagesTab
    ? [
        {
          id: "messages",
          name: "Messages",
          icon: <MailIcon className="mr-2 h-4 w-4" />,
          shortcut: null,
          action: () => "/messages",
        },
      ]
    : []),
];

// Patient-specific actions
const getPatientActions = (patient: Patient) => [
  {
    id: "profile",
    name: "Open Profile",
    icon: <UserIcon className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => `/patients/${patient.id}`,
  },
  {
    id: "visits",
    name: "Go to Visits tab",
    icon: <Calendar className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => `/patients/${patient.id}?tab=visits`,
  },
  {
    id: "ledger",
    name: "Go to Ledger tab",
    icon: <FileText className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => `/patients/${patient.id}?tab=ledger`,
  },
  {
    id: "insurance",
    name: "Go to Insurance tab",
    icon: <CreditCard className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => `/patients/${patient.id}?tab=insurance`,
  },
  {
    id: "notes",
    name: "Go to Notes tab",
    icon: <FileText className="mr-2 h-4 w-4" />,
    shortcut: null,
    action: () => `/patients/${patient.id}?tab=notes`,
  },
];

// Format patient for display
const formatPatientName = (patient: Patient) => {
  if (patient.displayName) return patient.displayName;
  return `${patient.firstName || ""} ${patient.lastName || ""}`.trim();
};

interface UniversalCommandBarProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  user?: User;
  contextObject?: {
    type: string;
    data: any;
  };
}

// Helper to get the appropriate modifier key based on OS
const isMac =
  typeof navigator !== "undefined"
    ? /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    : false;
const modifierKey = isMac ? "⌘" : "Ctrl";

// Enhanced Command Item with direct navigation shortcut support
interface EnhancedCommandItemProps {
  children: React.ReactNode;
  value: string;
  onSelect: () => void;
  className?: string;
  directAction?: (() => string) | null;
  disabled?: boolean;
}

const EnhancedCommandItem: React.FC<EnhancedCommandItemProps> = ({
  children,
  value,
  onSelect,
  className = "",
  directAction = null,
  disabled = false,
}) => {
  return (
    <CommandItem
      value={value}
      onSelect={onSelect}
      className={`${className} transition-all duration-150 ease-in-out`}
      disabled={disabled}
      data-direct-action={directAction ? "true" : "false"}
      data-action-value={value}
    >
      {children}
      {directAction && !disabled && (
        // <div className="ml-2 flex items-center text-xs text-gray-500">
        <div className="ml-auto flex items-center text-xs text-gray-500">
          <span className="opacity-60">{modifierKey}+↵</span>
          <ArrowRight className="ml-1 h-3 w-3 opacity-60" />
        </div>
      )}
    </CommandItem>
  );
};

// Utility function to parse search term into name parts
const parseSearchTerm = (
  searchTerm: string
): { firstName: string; lastName: string } => {
  const parts = searchTerm.trim().split(/\s+/);
  if (parts.length === 1) {
    return { firstName: parts[0], lastName: "" };
  } else {
    const firstName = parts[0];
    const lastName = parts.slice(1).join(" ");
    return { firstName, lastName };
  }
};

// Helper function to get the relevant secondary field for display
const getPatientDisplayInfo = (
  patient: Patient,
  searchTerm: string
): {
  primaryInfo: string;
  secondaryInfo: string | null;
  matchType: "accountNumber" | "email" | "name" | "none";
} => {
  const primaryName = formatPatientName(patient);
  const searchLower = searchTerm.toLowerCase();

  // Format date of birth for display if available
  let dobString = "";
  if (patient.dateOfBirth) {
    const dobDate = new Date(patient.dateOfBirth);
    dobString = ` · ${dobDate.toLocaleDateString()}`;
  }

  // Primary info is always name + DOB
  const primaryInfo = `${primaryName}${dobString}`;

  // Determine secondary info based on search match
  if (
    searchTerm &&
    patient.accountNumber &&
    patient.accountNumber.toLowerCase().includes(searchLower)
  ) {
    return {
      primaryInfo,
      secondaryInfo: `Account #: ${patient.accountNumber}`,
      matchType: "accountNumber",
    };
  }

  // Show email as default secondary info if available
  if (patient.email) {
    return {
      primaryInfo,
      secondaryInfo: patient.email,
      matchType: "email",
    };
  }

  // If no email, show account number if available
  if (patient.accountNumber) {
    return {
      primaryInfo,
      secondaryInfo: `Account #: ${patient.accountNumber}`,
      matchType: "accountNumber",
    };
  }

  // No secondary info available
  return {
    primaryInfo,
    secondaryInfo: null,
    matchType: "none",
  };
};

// Helper to check if a string might be a date
const mightBeDate = (searchTerm: string): boolean => {
  // Common date formats: MM/DD/YYYY, MM-DD-YYYY, MM.DD.YYYY, or just numbers
  const datePattern = /^\d{1,2}[/\-.]?\d{1,2}[/\-.]?\d{2,4}$|^\d{4,8}$/;
  return datePattern.test(searchTerm);
};

export function UniversalCommandBar({
  open,
  onOpenChange,
  user,
  contextObject,
}: UniversalCommandBarProps) {
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const [search, setSearch] = React.useState("");
  const [selectedPatient, setSelectedPatient] = React.useState<Patient | null>(
    contextObject?.type === "patient" ? contextObject.data : null
  );
  // Track the currently selected command item value
  const [selectedCommandValue, setSelectedCommandValue] =
    React.useState<string>("");
  // Store the most recent patient data we have (either from search or recent)
  const [localPatientCache, setLocalPatientCache] = React.useState<Patient[]>(
    []
  );
  // State to track recent patient IDs
  const [recentPatientIds, setRecentPatientIds] = React.useState<string[]>([]);

  // Use feature flag-based navigation routes
  const navigationRoutes = React.useMemo(
    () => getNavigationRoutes(flags),
    [flags]
  );

  // Set up unified GraphQL query for patient search
  const [searchPatients, { data: searchData, loading: searchLoading }] =
    useLazyQuery<UnifiedSearchPatients, UnifiedSearchPatientsVariables>(
      UNIFIED_SEARCH_PATIENTS_QUERY,
      {
        fetchPolicy: "network-only",
      }
    );

  // Load recent patient IDs from local storage when user is available
  React.useEffect(() => {
    if (user && open) {
      const ids = getRecentPatientIds(
        user.organization.id,
        user.activeLocation.id
      );
      // Only use the 3 most recent IDs for fetching data
      setRecentPatientIds(ids.slice(0, 3));
    }
  }, [user, open]);

  // Query for recent patients using IDs from local storage
  const { data: recentData, loading: recentLoading } = useQuery<
    GetRecentPatients,
    GetRecentPatientsVariables
  >(GET_RECENT_PATIENTS, {
    skip: !user || !open || recentPatientIds.length === 0,
    variables:
      user && recentPatientIds.length > 0
        ? {
            organizationId: user.organization.id,
            locationId: user.activeLocation.id,
            patientIds: recentPatientIds,
          }
        : undefined,
    fetchPolicy: "network-only",
  });

  // Update local patient cache when we get data from either source
  React.useEffect(() => {
    const searchPatients = searchData?.patients || [];
    const recentPatients = recentData?.patients || [];

    // Combine both sources, removing duplicates by creating a map using patient id as key
    const patientMap = new Map<string, Patient>();

    // Add search results first (they have priority)
    searchPatients.forEach((patient) => {
      if (patient) patientMap.set(patient.id, patient);
    });

    // Add recent patients if not already in the map
    recentPatients.forEach((patient) => {
      if (patient && !patientMap.has(patient.id)) {
        patientMap.set(patient.id, patient);
      }
    });

    // Convert map back to array
    setLocalPatientCache(Array.from(patientMap.values()));
  }, [searchData, recentData]);

  // Save selected patient to recent patients when a patient action is selected
  const trackPatientSelection = (patientId: string) => {
    if (user) {
      // Save up to 20 recent patient IDs to local storage
      addPatientToRecent(
        patientId,
        user.organization.id,
        user.activeLocation.id
      );

      // But only update the state with the 3 most recent for fetching
      setRecentPatientIds((prev) => {
        const filtered = prev.filter((id) => id !== patientId);
        return [patientId, ...filtered].slice(0, 3);
      });
    }
  };

  // Handle command selection with tracking
  const handleSelect = (callback: () => string, patientId?: string) => {
    const path = callback();
    onOpenChange(false);

    // Track the patient if this is a patient-related action
    if (patientId) {
      trackPatientSelection(patientId);
    }

    navigate(path);
  };

  // Handle direct navigation via keyboard shortcut
  const handleCommandKeyDown = (e: React.KeyboardEvent) => {
    // Check for Command+Enter (Mac) or Ctrl+Enter (Windows/Linux)
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      // Find the currently focused command item
      const focusedElement = document.querySelector(
        "[cmdk-item][data-selected='true'][data-direct-action='true']"
      ) as HTMLElement;

      if (focusedElement) {
        e.preventDefault();

        // If in patient list, navigate directly to patient profile
        if (focusedElement.dataset.actionValue?.startsWith("patient-")) {
          const patientId = focusedElement.dataset.actionValue.replace(
            "patient-",
            ""
          );
          const matchingPatient = combinedResults.find(
            ({ patient }) => patient.id === patientId
          );

          if (matchingPatient) {
            const path = `/patients/${matchingPatient.patient.id}`;
            onOpenChange(false);
            setSearch(""); // Clear search input before navigating
            trackPatientSelection(matchingPatient.patient.id);
            navigate(path);
            return;
          }
        }

        // For navigation items
        if (focusedElement.dataset.actionValue?.startsWith("nav-")) {
          const routeId = focusedElement.dataset.actionValue.replace(
            "nav-",
            ""
          );
          const matchingRoute = navigationRoutes.find(
            (route) => route.id === routeId
          );

          if (matchingRoute) {
            handleSelect(matchingRoute.action);
            return;
          }
        }

        // For patient actions
        if (
          selectedPatient &&
          focusedElement.dataset.actionValue?.startsWith("action-")
        ) {
          const actionId = focusedElement.dataset.actionValue.replace(
            "action-",
            ""
          );
          const matchingAction = getPatientActions(selectedPatient).find(
            (action) => action.id === actionId
          );

          if (matchingAction) {
            handleSelect(matchingAction.action, selectedPatient.id);
            return;
          }
        }
      }
    }
  };

  // Filter search results locally for immediate feedback
  const filteredLocalPatients = React.useMemo(() => {
    if (!search || search.length < 2) {
      // If not actively searching, just return recent patients
      return recentData?.patients || [];
    }

    // Apply local filtering immediately
    const searchLower = search.toLowerCase();
    const { firstName: firstNameSearch, lastName: lastNameSearch } =
      parseSearchTerm(searchLower);
    const hasSpace = search.includes(" ");

    return localPatientCache.filter((patient) => {
      if (!patient) return false;

      const fullName = formatPatientName(patient).toLowerCase();
      const firstName = (patient.firstName || "").toLowerCase();
      const lastName = (patient.lastName || "").toLowerCase();
      const email = (patient.email || "").toLowerCase();
      const accountNumber = (patient.accountNumber || "").toLowerCase();

      // Check for matches in all searchable fields
      if (fullName.includes(searchLower)) return true;
      if (email.includes(searchLower)) return true;
      if (firstName.includes(searchLower)) return true;
      if (lastName.includes(searchLower)) return true;
      if (accountNumber.includes(searchLower)) return true;

      // Handle first+last name search
      if (
        hasSpace &&
        firstName.includes(firstNameSearch) &&
        lastName.includes(lastNameSearch)
      ) {
        return true;
      }

      return false;
    });
  }, [search, localPatientCache, recentData]);

  // The server-filtered results stay the same
  const filteredSearchResults = React.useMemo(() => {
    return searchData?.patients || [];
  }, [searchData]);

  // Filter navigation routes based on search term
  const filteredNavigationRoutes = React.useMemo(() => {
    if (!search) return navigationRoutes;

    const searchLower = search.toLowerCase();
    return navigationRoutes.filter(
      (route) =>
        route.name.toLowerCase().includes(searchLower) ||
        route.id.toLowerCase().includes(searchLower)
    );
  }, [search]);

  // Filter patient actions if a patient is selected
  const filteredPatientActions = React.useMemo(() => {
    if (!selectedPatient) return [];
    if (!search) return getPatientActions(selectedPatient);

    const searchLower = search.toLowerCase();
    return getPatientActions(selectedPatient).filter(
      (action) =>
        action.name.toLowerCase().includes(searchLower) ||
        action.id.toLowerCase().includes(searchLower)
    );
  }, [selectedPatient, search]);

  // Debounced search with longer delay (500ms) for patients to prioritize action navigation
  React.useEffect(() => {
    if (!search || search.length < 2 || !user) return;

    const timer = setTimeout(() => {
      const { firstName, lastName } = parseSearchTerm(search);
      const hasSpace = search.includes(" ");

      searchPatients({
        variables: {
          organizationId: user.organization.id,
          locationId: user.activeLocation.id,
          searchTerm: search,
          // Only include firstName & lastName when relevant
          ...(hasSpace ? { firstName, lastName } : {}),
        },
      });
    }, 500); // Kept at 500ms as requested

    return () => clearTimeout(timer);
  }, [search, user, searchPatients]);

  // Helper function to check if a patient is in the full recent history (all 20)
  const checkIsPatientRecent = React.useCallback(
    (patientId: string): boolean => {
      if (!user) return false;
      return isPatientInRecentHistory(
        patientId,
        user.organization.id,
        user.activeLocation.id
      );
    },
    [user]
  );

  // Combine and prioritize search results with recent patients and local filtering
  const combinedResults = React.useMemo(() => {
    // Use search results if available
    const searchResults = filteredSearchResults || [];
    const recentResults = recentData?.patients || [];
    const localResults = filteredLocalPatients;
    const isSearching = search.length >= 2;

    if (!isSearching) {
      // Just show recent patients when not actively searching
      return recentResults.map((patient) => ({
        patient,
        isRecent: true,
        displayInfo: getPatientDisplayInfo(patient, search),
      }));
    }

    // Create a Map to track unique patients by ID
    const patientMap = new Map<
      string,
      {
        patient: Patient;
        isRecent: boolean;
        score: number;
        displayInfo: ReturnType<typeof getPatientDisplayInfo>;
      }
    >();

    // Calculate search term for ranking
    const searchLower = search.toLowerCase();
    const { firstName: firstNameSearch, lastName: lastNameSearch } =
      parseSearchTerm(searchLower);

    // If we're searching and waiting for server results, use locally filtered results first
    if (isSearching && searchLoading && localResults.length > 0) {
      localResults.forEach((patient) => {
        if (patient) {
          // Calculate a relevance score
          const fullName = formatPatientName(patient).toLowerCase();
          const firstName = (patient.firstName || "").toLowerCase();
          const lastName = (patient.lastName || "").toLowerCase();
          const email = (patient.email || "").toLowerCase();
          const accountNumber = (patient.accountNumber || "").toLowerCase();

          let score = 0;

          // Exact matches score higher
          if (fullName === searchLower) score += 100;
          if (firstName === firstNameSearch && lastName === lastNameSearch)
            score += 90;
          if (firstName === searchLower || lastName === searchLower)
            score += 80;
          if (email === searchLower) score += 70;
          if (accountNumber === searchLower) score += 85;

          // Partial matches
          if (fullName.startsWith(searchLower)) score += 50;
          if (
            firstName.startsWith(searchLower) ||
            lastName.startsWith(searchLower)
          )
            score += 40;

          // Check if patient is in our recent list - use the full history check
          const isRecent = checkIsPatientRecent(patient.id);
          if (isRecent) score += 10;

          const displayInfo = getPatientDisplayInfo(patient, search);

          patientMap.set(patient.id, {
            patient,
            isRecent,
            score,
            displayInfo,
          });
        }
      });
    }

    // If we have search results from the server, use those with proper scoring
    if (searchResults.length > 0) {
      // Add search results first (with all the existing scoring logic)
      searchResults.forEach((patient) => {
        if (patient) {
          // Calculate a relevance score for better ranking
          const fullName = formatPatientName(patient).toLowerCase();
          const firstName = (patient.firstName || "").toLowerCase();
          const lastName = (patient.lastName || "").toLowerCase();
          const email = (patient.email || "").toLowerCase();
          const accountNumber = (patient.accountNumber || "").toLowerCase();

          let score = 0;
          let matchedSpecialField = false;

          // Exact matches score higher
          if (fullName === searchLower) score += 100;
          if (firstName === firstNameSearch && lastName === lastNameSearch)
            score += 90;
          if (firstName === searchLower || lastName === searchLower)
            score += 80;
          if (email === searchLower) {
            score += 70;
            matchedSpecialField = true;
          }
          if (accountNumber === searchLower) {
            score += 85;
            matchedSpecialField = true;
          }

          // Partial matches score too
          if (fullName.startsWith(searchLower)) score += 50;
          if (
            firstName.startsWith(firstNameSearch) &&
            lastNameSearch &&
            lastName.startsWith(lastNameSearch)
          )
            score += 45;
          if (
            firstName.startsWith(searchLower) ||
            lastName.startsWith(searchLower)
          )
            score += 40;
          if (email.startsWith(searchLower)) {
            score += 35;
            matchedSpecialField = true;
          }
          if (accountNumber.startsWith(searchLower)) {
            score += 45;
            matchedSpecialField = true;
          }

          // Contains matches score less
          if (fullName.includes(searchLower)) score += 30;
          if (
            firstName.includes(firstNameSearch) &&
            lastNameSearch &&
            lastName.includes(lastNameSearch)
          )
            score += 25;
          if (firstName.includes(searchLower) || lastName.includes(searchLower))
            score += 20;
          if (email.includes(searchLower)) {
            score += 15;
            matchedSpecialField = true;
          }
          if (accountNumber.includes(searchLower)) {
            score += 25;
            matchedSpecialField = true;
          }

          // Check if patient is in our full recent list - using the full history (20 patients)
          const isRecent = checkIsPatientRecent(patient.id);
          if (isRecent) score += 60; // Recent patients get a boost

          // Get display info (primary and secondary)
          const displayInfo = getPatientDisplayInfo(patient, search);

          patientMap.set(patient.id, {
            patient,
            isRecent,
            score,
            displayInfo,
          });
        }
      });

      // Add remaining recent patients if they match the search
      recentResults.forEach((patient) => {
        if (patient && !patientMap.has(patient.id)) {
          const fullName = formatPatientName(patient).toLowerCase();
          const firstName = (patient.firstName || "").toLowerCase();
          const lastName = (patient.lastName || "").toLowerCase();
          const email = (patient.email || "").toLowerCase();
          const accountNumber = (patient.accountNumber || "").toLowerCase();

          // Only include recent patients that match the search
          if (
            fullName.includes(searchLower) ||
            email.includes(searchLower) ||
            firstName.includes(searchLower) ||
            lastName.includes(searchLower) ||
            accountNumber.includes(searchLower) ||
            (firstName.includes(firstNameSearch) &&
              lastNameSearch &&
              lastName.includes(lastNameSearch))
          ) {
            // Calculate score for recent patients too
            let score = 60; // Base score for being recent

            if (fullName === searchLower) score += 100;
            if (firstName === firstNameSearch && lastName === lastNameSearch)
              score += 90;
            if (
              firstName.startsWith(firstNameSearch) &&
              lastNameSearch &&
              lastName.startsWith(lastNameSearch)
            )
              score += 45;

            // Get display info
            const displayInfo = getPatientDisplayInfo(patient, search);

            patientMap.set(patient.id, {
              patient,
              isRecent: true,
              score,
              displayInfo,
            });
          }
        }
      });
    }

    // Convert map back to array, sorting by score (high to low)
    return Array.from(patientMap.values()).sort((a, b) => {
      // First sort by score (higher is better)
      if (a.score !== b.score) {
        return b.score - a.score;
      }

      // Then by recency if scores are equal
      if (a.isRecent !== b.isRecent) {
        return a.isRecent ? -1 : 1;
      }

      // Finally by name
      return formatPatientName(a.patient).localeCompare(
        formatPatientName(b.patient)
      );
    });
  }, [
    filteredSearchResults,
    recentData,
    search,
    filteredLocalPatients,
    searchLoading,
    recentPatientIds,
    checkIsPatientRecent,
  ]);

  // Replace the effect for resetting selection with a state-based approach that prioritizes navigation matches
  React.useEffect(() => {
    // First priority: If we have patient results, select the first patient
    if (combinedResults.length > 0 && !searchLoading && !recentLoading) {
      const firstResult = combinedResults[0];
      if (firstResult && firstResult.patient) {
        setSelectedCommandValue(`patient-${firstResult.patient.id}`);
        return;
      }
    }

    // Second priority: If we have matching navigation routes, select the first one
    if (filteredNavigationRoutes.length > 0 && search.length > 0) {
      setSelectedCommandValue(`nav-${filteredNavigationRoutes[0].id}`);
      return;
    }

    // Third priority: If we have matching patient actions, select the first one
    if (filteredPatientActions.length > 0 && search.length > 0) {
      setSelectedCommandValue(`action-${filteredPatientActions[0].id}`);
      return;
    }

    // If nothing matches or we're still loading, clear selection
    setSelectedCommandValue("");
  }, [
    filteredNavigationRoutes,
    filteredPatientActions,
    combinedResults,
    search,
    searchLoading,
    recentLoading,
  ]);

  // Reset state when dialog closes
  React.useEffect(() => {
    if (!open) {
      setSearch("");
      setSelectedPatient(null);
      setSelectedCommandValue("");
    }
  }, [open]);

  // Set initial context if provided
  React.useEffect(() => {
    if (contextObject && contextObject.type === "patient") {
      setSelectedPatient(contextObject.data);
    }
  }, [contextObject]);

  return (
    <CommandDialog
      open={open}
      onOpenChange={onOpenChange}
      className="rounded-lg border border-gray-200 shadow-md"
    >
      <Command
        shouldFilter={false}
        onKeyDown={handleCommandKeyDown}
        loop={true}
        value={selectedCommandValue}
        onValueChange={setSelectedCommandValue}
      >
        <CommandInput
          placeholder={
            selectedPatient
              ? `Actions for ${formatPatientName(selectedPatient)}...`
              : "Search patients or type a command..."
          }
          value={search}
          onValueChange={setSearch}
          className="border-b border-gray-200"
        />
        <CommandList className="max-h-[50vh] overflow-y-auto">
          <style>
            {`
            [cmdk-list] {
              transition: height 150ms ease;
            }
            
            [cmdk-item] {
              transition: all 150ms ease;
              transform-origin: center;
              opacity: 1;
            }
            
            [cmdk-item][data-disabled="true"] {
              opacity: 0.5;
            }
            
            [cmdk-item][data-selected="true"] {
              transform: scale(1.01);
              background-color: rgb(249 250 251);
            }
            
            [cmdk-group] {
              transition: opacity 150ms ease;
            }
            
            [cmdk-group-heading] {
              transition: all 150ms ease;
            }
            
            [cmdk-separator] {
              transition: opacity 150ms ease;
            }
            
            .animate-in {
              animation: fadeIn 150ms ease-in forwards;
            }
            
            @keyframes fadeIn {
              from { opacity: 0; transform: translateY(2px); }
              to { opacity: 1; transform: translateY(0); }
            }
            `}
          </style>
          {!searchLoading &&
            !recentLoading &&
            combinedResults.length === 0 &&
            filteredNavigationRoutes.length === 0 &&
            filteredPatientActions.length === 0 &&
            search.length >= 2 &&
            !selectedPatient && <CommandEmpty>No results found.</CommandEmpty>}

          {!selectedPatient && (
            <>
              {/* Patient Results Group - Now shown first */}
              {(combinedResults.length > 0 ||
                searchLoading ||
                recentLoading) && (
                <CommandGroup
                  heading={
                    search.length < 2
                      ? "Recent Patients"
                      : combinedResults.length > 0
                      ? "Patients"
                      : "Search Results"
                  }
                >
                  {searchLoading || recentLoading ? (
                    <CommandItem disabled>Loading patients...</CommandItem>
                  ) : (
                    combinedResults.map(
                      ({ patient, isRecent, displayInfo }) => (
                        <EnhancedCommandItem
                          key={patient.id}
                          value={`patient-${patient.id}`}
                          onSelect={() => {
                            setSelectedPatient(patient);
                            setSearch(""); // Clear search input when selecting a patient
                            // Track patient selection when user selects a patient
                            trackPatientSelection(patient.id);
                          }}
                          className="flex items-center py-2 px-2 cursor-pointer hover:bg-gray-50"
                          directAction={() => `/patients/${patient.id}`}
                        >
                          {isRecent ? (
                            <Clock className="mr-2 h-4 w-4 text-gray-500" />
                          ) : (
                            <UserIcon className="mr-2 h-4 w-4 text-gray-500" />
                          )}
                          <div className="flex flex-col">
                            <span className="font-medium">
                              {displayInfo.primaryInfo}
                            </span>
                            {displayInfo.secondaryInfo && (
                              <span className="text-xs text-gray-500">
                                {displayInfo.secondaryInfo}
                              </span>
                            )}
                          </div>
                        </EnhancedCommandItem>
                      )
                    )
                  )}
                </CommandGroup>
              )}

              {/* Add separator if both patients and navigation are shown */}
              {(combinedResults.length > 0 || searchLoading || recentLoading) &&
                filteredNavigationRoutes.length > 0 && (
                  <CommandSeparator className="my-2" />
                )}

              {/* Navigation Group - now shown second */}
              {filteredNavigationRoutes.length > 0 && (
                <CommandGroup heading="Navigation">
                  {filteredNavigationRoutes.map((route) => (
                    <CommandItem
                      key={route.id}
                      value={`nav-${route.id}`}
                      onSelect={() => handleSelect(route.action)}
                      className="py-2 px-2 cursor-pointer hover:bg-gray-50 transition-all duration-150 ease-in-out"
                    >
                      {route.icon}
                      <span className="font-medium">{route.name}</span>
                      {route.shortcut && (
                        <CommandShortcut className="ml-auto text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded">
                          {route.shortcut}
                        </CommandShortcut>
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
            </>
          )}

          {/* Patient-specific actions */}
          {selectedPatient && (
            <>
              <CommandGroup
                heading={`Actions for ${formatPatientName(selectedPatient)}`}
              >
                {filteredPatientActions.map((action) => (
                  <CommandItem
                    key={action.id}
                    value={`action-${action.id}`}
                    onSelect={() =>
                      handleSelect(action.action, selectedPatient.id)
                    }
                    className="py-2 px-2 cursor-pointer hover:bg-gray-50 transition-all duration-150 ease-in-out"
                  >
                    {action.icon}
                    <span className="font-medium">{action.name}</span>
                    {action.shortcut && (
                      <CommandShortcut className="ml-auto text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded">
                        {action.shortcut}
                      </CommandShortcut>
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
              <CommandSeparator className="my-2" />
              <CommandGroup>
                <CommandItem
                  value="back-to-search"
                  onSelect={() => {
                    setSelectedPatient(null);
                    setSearch(""); // Clear search input when going back to search
                  }}
                  className="py-2 px-2 cursor-pointer hover:bg-gray-50 transition-all duration-150 ease-in-out"
                >
                  <Search className="mr-2 h-4 w-4 text-gray-500" />
                  Back to search
                </CommandItem>
              </CommandGroup>
            </>
          )}
        </CommandList>
      </Command>
    </CommandDialog>
  );
}
