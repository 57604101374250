import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetTradingPartnerMapping,
  GetTradingPartnerMappingVariables,
} from "../../../generated/GetTradingPartnerMapping";
import { GetTradingPartners } from "../../../generated/GetTradingPartners";
import { TRADING_PARTNER_MAPPING_FIELDS } from "../../../graphql";
import { useUser } from "../../../user-context";
import { Layout, LoadingLayout } from "../../layout";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { TradingPartnerMappingForm } from "./trading-partner-mapping-form";

export const GET_TRADING_PARTNERS = gql`
  query GetTradingPartners {
    tradingPartners {
      id
      name
      tradingPartnerId
      eligibilityEnabled
    }
  }
`;

const GET_TRADING_PARTNER_MAPPING = gql`
  ${TRADING_PARTNER_MAPPING_FIELDS}
  query GetTradingPartnerMapping($id: String!) {
    tradingPartnerMapping(where: { id: $id }) {
      ...TradingPartnerMappingFields
    }
  }
`;

export const EditTradingPartnerRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetTradingPartnerMapping,
    GetTradingPartnerMappingVariables
  >(GET_TRADING_PARTNER_MAPPING, {
    variables: {
      id: params.id!,
    },
  });
  const tradingPartnersResult =
    useQuery<GetTradingPartners>(GET_TRADING_PARTNERS);

  const tradingPartnerMapping = data?.tradingPartnerMapping;
  const tradingPartners = tradingPartnersResult.data?.tradingPartners ?? [];

  if (loading || !tradingPartnerMapping) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Trading Partner Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <TradingPartnerMappingForm
            tradingPartnerMapping={tradingPartnerMapping}
            tradingPartners={tradingPartners}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </TradingPartnerMappingForm>
        )
      }
    />
  );
};
