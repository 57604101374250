import React from "react";
import { useLocation } from "react-router-dom";
import { Condition, Visit } from "../../../evaluate-rule";
import { GetMaxAllowableRule_maxAllowableRule as MaxAllowableRule } from "../../../generated/GetMaxAllowableRule";
import { Layout } from "../../layout";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { MaxAllowableRuleForm } from "./max-allowable-rule-form";

export const NewMaxAllowableRule: React.FC = () => {
  const location = useLocation();
  const state: {
    maxAllowableRule?: MaxAllowableRule | null;
  } | null = location.state;

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        <MaxAllowableRuleForm
          maxAllowableRule={state?.maxAllowableRule ?? null}
        >
          {({ conditions }) => (
            <div>
              <BenefitAssignmentRuleSimulatorTable
                conditions={conditions as Condition<keyof Visit>[]}
              />
            </div>
          )}
        </MaxAllowableRuleForm>
      }
    />
  );
};
