import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Card, SubmitButton } from "../../../components";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  CreateBenefitMapping,
  CreateBenefitMappingVariables,
} from "../../../generated/CreateBenefitMapping";
import {
  UpdateBenefitMapping,
  UpdateBenefitMappingVariables,
} from "../../../generated/UpdateBenefitMapping";
import { GetBenefitMapping_benefitMapping as BenefitMapping } from "../../../generated/GetBenefitMapping";
import { useUser } from "../../../user-context";
import { Button } from "../../../components/ui/button";
import { isDefined } from "../../../utils";
import { Select } from "@tremor/react";
import { Textarea } from "../../../components/ui/textarea";
import { ServiceTypeCode } from "../../patients/eligibilities/types";
import { useAnalytics } from "../../../analytics-context";
import { BENEFIT_MAPPING_FIELDS } from "../../../graphql";
import { Visit } from "../../../evaluate-rule";
import { COMPARISONS } from "./show";
import { Comparison } from "../../../generated/globalTypes";
import { OvalSpinner } from "../../../components/loading";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select as UiSelect,
  SelectItem as UiSelectItem,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  GetLocationBenefitMappings,
  GetLocationBenefitMappingsVariables,
} from "../../../generated/GetLocationBenefitMappings";
import * as z from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../components/ui/input";
import { Form, FormField } from "../../../components/ui/form";
import { AttributeInput } from "../rule-inputs";
import { useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../../hooks";

const CREATE_BENEFIT_MAPPING = gql`
  ${BENEFIT_MAPPING_FIELDS}
  mutation CreateBenefitMapping($data: BenefitMappingCreateInput!) {
    createOneBenefitMapping(data: $data) {
      ...BenefitMappingFields
    }
  }
`;

const UPDATE_BENEFIT_MAPPING = gql`
  ${BENEFIT_MAPPING_FIELDS}
  mutation UpdateBenefitMapping(
    $id: String!
    $data: BenefitMappingUpdateInput!
  ) {
    updateOneBenefitMapping(where: { id: $id }, data: $data) {
      ...BenefitMappingFields
    }
  }
`;

const BENEFIT_MAPPING_ATTRIBUTES: {
  label: string;
  value: keyof Visit;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Appointment Type",
    value: "appointmentType",
  },
  {
    label: "Appointment Label",
    value: "appointmentLabelId",
  },
  {
    label: "Appointment Location",
    value: "appointmentLocationId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Provider",
    value: "providerId",
  },
  {
    label: "Provider Classification",
    value: "providerTaxonomyCodeId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
  {
    label: "In Network",
    value: "inNetwork",
  },
  // {
  //   label: "Plan",
  //   value: "planId",
  // },
  {
    label: "Charge Code",
    value: "chargemasterGroupIds",
  },
  {
    label: "Code Group",
    value: "codeGroups",
  },
  {
    label: "Any Visit Charge Code",
    value: "anyVisitChargemasterGroupIds",
  },
];

export type BenefitMappingConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (
    ["chargemasterGroupIds", "appointmentType", "appointmentLabelId"].includes(
      attributeType
    )
  ) {
    return COMPARISONS;
  }
  if (attributeType === "inNetwork") {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "not equals", value: Comparison.NotEquals },
    ];
  }
  // Don't allow contains comparison for ID attributes. Only for free text attributes.
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

export const LoadingSelect: React.FC<{ placeholder?: string }> = ({
  placeholder = "Loading Options...",
}) => (
  <Select
    disabled={true}
    placeholder={placeholder}
    icon={() => <OvalSpinner className="w-4 h-4" />}
  >
    <></>
  </Select>
);

const GET_LOCATION_BENEFIT_MAPPINGS = gql`
  query GetLocationBenefitMappings($locationId: String!) {
    benefitMappings(where: { locationId: { equals: $locationId } }) {
      id
      name
      notes
      providerServiceConfiguration {
        id
        name
      }
      benefitMappingConditions {
        id
        attributeType
        comparison
        attribute
      }
      benefitMappingFallbacks {
        id
        providerServiceConfigurationId
        providerServiceConfiguration {
          id
          name
        }
      }
    }
  }
`;

const DuplicateBenefitMappingDialogButton: React.FC<{
  onSelect: (benefitMapping: {
    id: string;
    name: string;
    benefitMappingConditions: {
      id: string;
      attributeType: string;
      comparison: string;
      attribute: string | string[];
    }[];
    providerServiceConfiguration: {
      id: string;
      name: string;
    };
    benefitMappingFallbacks: {
      id: string;
      providerServiceConfiguration: {
        id: string;
        name: string;
      };
    }[];
  }) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationBenefitMappings,
    GetLocationBenefitMappingsVariables
  >(GET_LOCATION_BENEFIT_MAPPINGS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [benefitMappingId, setBenefitMappingId] = useState<string>();
  const benefitMappings = data?.benefitMappings ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate an existing Charge Template Rule?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={benefitMappingId}
                  onValueChange={setBenefitMappingId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {benefitMappings.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!benefitMappingId}
                    onClick={() => {
                      const benefitMapping = benefitMappings.find(
                        (c) => c.id === benefitMappingId
                      );
                      if (benefitMapping) {
                        onSelect(benefitMapping);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const BENEFIT_MAPPING_FORM_OPTIONS = gql`
  query BenefitMappingFormOptions(
    $locationId: String!
    $organizationId: String!
  ) {
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      modifier1
      modifier2
      modifier3
      modifier4
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    patientLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    appointmentLocations(
      where: { organizationId: { equals: $organizationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
    codeCategories {
      name
      codeRange
      sections {
        name
        codeRange
        levelOne {
          name
          codeRange
          levelTwo {
            name
            codeRange
            levelThree {
              name
              codeRange
              levelFour {
                name
                codeRange
                levelFive {
                  name
                  codeRange
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BenefitMappingRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
    })
  ),
  providerServiceConfigurationId: z.string(),
  benefitMappingFallbacks: z.array(
    z.object({
      id: z.string().nullable(),
      providerServiceConfigurationId: z.string(),
    })
  ),
});

export const BenefitMappingForm: React.FC<{
  benefitMapping: BenefitMapping | null;
  children: (props: {
    conditions: BenefitMappingConditionInput[];
  }) => React.ReactElement;
}> = ({ benefitMapping, children }) => {
  const flags = useFeatureFlags();
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const optionsResult = useQuery(BENEFIT_MAPPING_FORM_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
      organizationId: user.organization.id,
    },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];
  const codeCategories = optionsResult.data?.codeCategories ?? [];
  const appointmentLocations = optionsResult.data?.appointmentLocations ?? [];
  const providerServiceConfigurations =
    user.organization.providerServiceConfiguration;

  const form = useForm<z.infer<typeof BenefitMappingRuleForm>>({
    resolver: zodResolver(BenefitMappingRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: benefitMapping?.id ?? null,
      name: benefitMapping?.name,
      notes: benefitMapping?.notes,
      conditions: benefitMapping?.benefitMappingConditions ?? [],
      // If benefit mapping providerConfig explicitly set to null, set to empty string so "Don't use Insurance" is selected
      // otherwise, default to undefined so empty select is shown
      providerServiceConfigurationId: benefitMapping
        ? benefitMapping.providerServiceConfiguration?.id ?? ""
        : undefined,
      benefitMappingFallbacks: benefitMapping?.benefitMappingFallbacks ?? [],
    },
  });
  const conditionsField = useFieldArray({
    name: "conditions",
    control: form.control,
  });
  const fallbacksField = useFieldArray({
    name: "benefitMappingFallbacks",
    control: form.control,
  });

  const ruleName = form.watch("name");
  const providerServiceConfigurationId = form.watch(
    "providerServiceConfigurationId"
  );
  const providerServiceConfiguration =
    providerServiceConfigurations.find(
      (c) => c.id === providerServiceConfigurationId
    ) ?? null;
  const conditions = form.watch("conditions");
  const [createBenefitMapping, createBenefitMappingResult] = useMutation<
    CreateBenefitMapping,
    CreateBenefitMappingVariables
  >(CREATE_BENEFIT_MAPPING);
  const [updateBenefitMapping, updateBenefitMappingResult] = useMutation<
    UpdateBenefitMapping,
    UpdateBenefitMappingVariables
  >(UPDATE_BENEFIT_MAPPING);

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison)
    ) &&
    isDefined(providerServiceConfigurationId) &&
    isDefined(ruleName);

  const fallbacks = form.watch("benefitMappingFallbacks");
  const benefitMappingFallbacks = providerServiceConfigurations.filter(
    (config) =>
      fallbacks.some((f) => f.providerServiceConfigurationId === config.id)
  );
  const selectedConfigs = [
    providerServiceConfiguration,
    ...benefitMappingFallbacks,
  ].filter(isDefined);
  const providerServiceConfigurationOptions = (
    currentConfigId: string | null
  ) =>
    providerServiceConfigurations.filter(
      (c) =>
        c.serviceType !== ServiceTypeCode.HealthBenefitPlanCoverage &&
        ((currentConfigId && currentConfigId === c.id) ||
          selectedConfigs.every((config) => config.id !== c.id))
    );
  const nextProviderServiceConfiguration = providerServiceConfigurations
    .filter((c) =>
      selectedConfigs.every(
        (config) =>
          c.serviceType !== ServiceTypeCode.HealthBenefitPlanCoverage &&
          config.id !== c.id
      )
    )
    .at(0);

  // Don't allow chargemasterGroupIds attribute if estimates are disabled
  const benefitMappingAttributeOptions = flags.estimatesEnabled
    ? BENEFIT_MAPPING_ATTRIBUTES
    : BENEFIT_MAPPING_ATTRIBUTES.filter(
        (a) => a.value !== "chargemasterGroupIds"
      );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          if (benefitMapping) {
            const toDelete = benefitMapping.benefitMappingConditions.filter(
              (c) => !data.conditions.some(({ id }) => id === c.id)
            );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            const fallbacksToDelete =
              benefitMapping.benefitMappingFallbacks.filter(
                (c) =>
                  !data.benefitMappingFallbacks.some(({ id }) => id === c.id)
              );
            const fallbacksToCreate = data.benefitMappingFallbacks.filter((c) =>
              benefitMapping.benefitMappingFallbacks.every(
                (bmf) => bmf.id !== c.id
              )
            );
            const fallbacksToUpdate = data.benefitMappingFallbacks.filter((c) =>
              benefitMapping.benefitMappingFallbacks.some(
                (bmf) => bmf.id === c.id
              )
            );

            updateBenefitMapping({
              variables: {
                id: benefitMapping.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  benefitMappingConditions: {
                    create: toCreate.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({ id, attributeType, comparison, attribute }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                        },
                      })
                    ),
                  },
                  providerServiceConfiguration:
                    data.providerServiceConfigurationId
                      ? {
                          connect: {
                            id: data.providerServiceConfigurationId,
                          },
                        }
                      : { disconnect: true },
                  benefitMappingFallbacks: {
                    create: fallbacksToCreate.map((fallback, i) => ({
                      priority: i,
                      providerServiceConfiguration: {
                        connect: {
                          id: fallback.providerServiceConfigurationId,
                        },
                      },
                    })),
                    delete: fallbacksToDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: fallbacksToUpdate.map((fallback, i) => ({
                      where: {
                        id: fallback.id!,
                      },
                      data: {
                        priority: { set: i },
                        providerServiceConfiguration: {
                          connect: {
                            id: fallback.providerServiceConfigurationId,
                          },
                        },
                      },
                    })),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Benefit Assignment Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createBenefitMapping({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  providerServiceConfiguration:
                    data.providerServiceConfigurationId
                      ? {
                          connect: {
                            id: data.providerServiceConfigurationId,
                          },
                        }
                      : undefined,
                  benefitMappingConditions: {
                    create: conditions.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping created");
                analytics?.track("Benefit Assignment Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create mapping");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicateBenefitMappingDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue(
                  "providerServiceConfigurationId",
                  c.providerServiceConfiguration.id
                );
                conditionsField.replace(
                  c.benefitMappingConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                  }))
                );
                fallbacksField.replace(
                  c.benefitMappingFallbacks.map((bmf) => ({
                    id: null,
                    providerServiceConfigurationId:
                      bmf.providerServiceConfiguration.id,
                  }))
                );
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.attributeType`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.attributeType`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select an attribute" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {benefitMappingAttributeOptions.map(
                                              (o) => (
                                                <UiSelectItem value={o.value}>
                                                  {o.label}
                                                </UiSelectItem>
                                              )
                                            )}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                    codeCategories={codeCategories}
                                    appointmentLocations={appointmentLocations}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="pt-4">
                      <div className="text-lg font-semibold">THEN</div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Assign benefit
                        </label>
                        <div className="mt-2 max-w-xl flex justify-between gap-2">
                          <FormField
                            control={form.control}
                            name="providerServiceConfigurationId"
                            render={({ field }) => (
                              <UiSelect
                                {...form.register(
                                  "providerServiceConfigurationId"
                                )}
                                defaultValue={field.value}
                                onValueChange={(value) => {
                                  field.onChange(value);
                                  // If the user selects "Don't use Insurance", clear the fallbacks
                                  if (value === "") {
                                    fallbacksField.replace([]);
                                  }
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a template" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <UiSelectItem value="">
                                      Don't use Insurance
                                    </UiSelectItem>
                                    {providerServiceConfigurationOptions(
                                      providerServiceConfiguration?.id ?? null
                                    ).map((config) => (
                                      <UiSelectItem
                                        key={config.id}
                                        value={config.id}
                                      >
                                        {config.name}
                                      </UiSelectItem>
                                    ))}
                                  </SelectGroup>
                                </SelectContent>
                              </UiSelect>
                            )}
                          />

                          {nextProviderServiceConfiguration && (
                            <Button
                              variant="default"
                              type="button"
                              className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              disabled={!providerServiceConfiguration}
                              onClick={() => {
                                fallbacksField.append({
                                  id: null,
                                  providerServiceConfigurationId:
                                    nextProviderServiceConfiguration.id,
                                });
                              }}
                            >
                              <PlusIcon className="h-5 w-5" />
                            </Button>
                          )}
                        </div>
                        {fallbacksField.fields.map((config, i) => {
                          const providerServiceConfigurationId = form.watch(
                            `benefitMappingFallbacks.${i}.providerServiceConfigurationId`
                          );
                          const options = providerServiceConfigurationOptions(
                            providerServiceConfigurationId
                          );
                          return (
                            <div
                              key={config.providerServiceConfigurationId}
                              className="mt-2 max-w-xl flex justify-between items-center gap-2"
                            >
                              Then
                              <FormField
                                control={form.control}
                                name={`benefitMappingFallbacks.${i}.providerServiceConfigurationId`}
                                render={({ field }) => (
                                  <UiSelect
                                    {...form.register(
                                      `benefitMappingFallbacks.${i}.providerServiceConfigurationId`
                                    )}
                                    defaultValue={field.value}
                                    onValueChange={field.onChange}
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select a service" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        {options.map((config) => (
                                          <UiSelectItem
                                            key={config.id}
                                            value={config.id}
                                          >
                                            {config.name}
                                          </UiSelectItem>
                                        ))}
                                      </SelectGroup>
                                    </SelectContent>
                                  </UiSelect>
                                )}
                              />
                              {nextProviderServiceConfiguration && (
                                <Button
                                  variant="default"
                                  type="button"
                                  className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  disabled={!nextProviderServiceConfiguration}
                                  onClick={() => {
                                    fallbacksField.append({
                                      id: null,
                                      providerServiceConfigurationId:
                                        nextProviderServiceConfiguration.id,
                                    });
                                  }}
                                >
                                  <PlusIcon className="h-5 w-5" />
                                </Button>
                              )}
                              <button
                                type="button"
                                className="relative rounded-full z-10 p-1 text-red-600 shadow-sm hover:bg-red-300 hover:text-white"
                                onClick={() => {
                                  fallbacksField.remove(i);
                                }}
                              >
                                <TrashIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        loading={
                          createBenefitMappingResult.loading ||
                          updateBenefitMappingResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
