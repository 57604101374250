import React from "react";

import { EligibilityRequestStatusFields } from "../../../generated/EligibilityRequestStatusFields";
import { Badge } from "../../../components";

export const getEligibilityStatus = (
  eligibility: Pick<
    EligibilityRequestStatusFields,
    "allRequestsErrored" | "eligible" | "status"
  >
): "Pending" | "Error" | "Active" | "Inactive" => {
  if (eligibility.status === "InProgress") return "Pending" as const;
  if (eligibility.status === "TimedOut") return "Error" as const;
  if (eligibility.allRequestsErrored) return "Error" as const;
  return eligibility.eligible ? "Active" : ("Inactive" as const);
};

export const EligibilityBadge: React.FC<
  React.PropsWithChildren<{
    eligibility: Pick<
      EligibilityRequestStatusFields,
      "allRequestsErrored" | "eligible" | "status"
    >;
    clickable?: boolean;
  }>
> = ({ eligibility, clickable }) => {
  const status = getEligibilityStatus(eligibility);
  switch (status) {
    case "Active":
      return <Badge text="Active" variant="success" clickable={clickable} />;
    case "Inactive":
      return <Badge text="Inactive" variant="warning" clickable={clickable} />;
    case "Error":
      return <Badge text="Error" variant="error" clickable={clickable} />;
    case "Pending":
      return <Badge text="Pending" variant="info" clickable={clickable} />;
  }
};
