import React from "react";
import { Layout, LoadingLayout } from "../../layout";
import { MaxAllowableRuleForm } from "./max-allowable-rule-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GetMaxAllowableRule,
  GetMaxAllowableRuleVariables,
} from "../../../generated/GetMaxAllowableRule";
import { MAX_ALLOWABLE_RULE_FIELDS } from "../../../graphql";

const GET_MAX_ALLOWABLE_RULE = gql`
  ${MAX_ALLOWABLE_RULE_FIELDS}
  query GetMaxAllowableRule($id: String!) {
    maxAllowableRule(where: { id: $id }) {
      ...MaxAllowableRuleFields
    }
  }
`;

export const EditMaxAllowableRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<
    GetMaxAllowableRule,
    GetMaxAllowableRuleVariables
  >(GET_MAX_ALLOWABLE_RULE, {
    variables: {
      id: params.id!,
    },
  });

  const maxAllowableRule = data?.maxAllowableRule;

  if (loading || !maxAllowableRule) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Max Visit Allowable Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <MaxAllowableRuleForm maxAllowableRule={maxAllowableRule}>
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </MaxAllowableRuleForm>
        )
      }
    />
  );
};
