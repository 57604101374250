import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Card, SubmitButton } from "../../../components";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  CreatePreVisitMapping,
  CreatePreVisitMappingVariables,
} from "../../../generated/CreatePreVisitMapping";
import {
  UpdatePreVisitMapping,
  UpdatePreVisitMappingVariables,
} from "../../../generated/UpdatePreVisitMapping";
import { GetPreVisitMapping_preVisitMapping as PreVisitMapping } from "../../../generated/GetPreVisitMapping";
import { useUser } from "../../../user-context";
import { Button } from "../../../components/ui/button";
import { isDefined } from "../../../utils";
import { Textarea } from "../../../components/ui/textarea";
import { useAnalytics } from "../../../analytics-context";
import { Visit } from "../../../evaluate-rule";
import { Comparison } from "../../../generated/globalTypes";
import { COMPARISONS } from "../benefit-mappings/show";
import { PRE_VISIT_MAPPING_FIELDS } from "../../../graphql";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select as UiSelect,
  SelectItem as UiSelectItem,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  GetLocationPreVisitMappings,
  GetLocationPreVisitMappingsVariables,
} from "../../../generated/GetLocationPreVisitMappings";
import * as z from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../components/ui/input";
import { Form, FormField } from "../../../components/ui/form";
import { AttributeInput } from "../rule-inputs";
import { useNavigate } from "react-router-dom";
import {
  PreVisitMappingFormOptions,
  PreVisitMappingFormOptionsVariables,
} from "../../../generated/PreVisitMappingFormOptions";

export const CREATE_PRE_VISIT_MAPPING = gql`
  ${PRE_VISIT_MAPPING_FIELDS}
  mutation CreatePreVisitMapping($data: PreVisitMappingCreateInput!) {
    createOnePreVisitMapping(data: $data) {
      ...PreVisitMappingFields
    }
  }
`;

export const UPDATE_PRE_VISIT_MAPPING = gql`
  ${PRE_VISIT_MAPPING_FIELDS}
  mutation UpdatePreVisitMapping(
    $id: String!
    $data: PreVisitMappingUpdateInput!
  ) {
    updateOnePreVisitMapping(where: { id: $id }, data: $data) {
      ...PreVisitMappingFields
    }
  }
`;

const PRE_VISIT_MAPPING_ATTRIBUTES: {
  label: string;
  value: AttributeTypes;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Appointment Type",
    value: "appointmentType",
  },
  {
    label: "Appointment Label",
    value: "appointmentLabelId",
  },
  {
    label: "Appointment Location",
    value: "appointmentLocationId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Provider",
    value: "providerId",
  },
  {
    label: "Provider Classification",
    value: "providerTaxonomyCodeId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
];

type AttributeTypes =
  | keyof Visit
  | "accountTypeId"
  | "appointmentType"
  | "appointmentLabelId"
  | "patientLabelId"
  | "providerId"
  | "providerTaxonomyCodeId"
  | "payerId";

export type PreVisitMappingConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (
    ["appointmentType", "appointmentLabelId", "patientLabelId"].includes(
      attributeType
    )
  ) {
    return COMPARISONS;
  }
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

const GET_LOCATION_PRE_VISIT_MAPPINGS = gql`
  query GetLocationPreVisitMappings($locationId: String!) {
    preVisitMappings(where: { locationId: { equals: $locationId } }) {
      id
      name
      notes
      preVisitMappingConditions {
        id
        attributeType
        comparison
        attribute
      }
    }
  }
`;

const DuplicatePreVisitMappingDialogButton: React.FC<{
  onSelect: (preVisitMapping: PreVisitMapping) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationPreVisitMappings,
    GetLocationPreVisitMappingsVariables
  >(GET_LOCATION_PRE_VISIT_MAPPINGS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [preVisitMappingId, setPreVisitMappingId] = useState<string>();
  const preVisitMappings = data?.preVisitMappings ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate an existing Pre-Visit Rule?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={preVisitMappingId}
                  onValueChange={setPreVisitMappingId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {preVisitMappings.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!preVisitMappingId}
                    onClick={() => {
                      const preVisitMapping = preVisitMappings.find(
                        (c) => c.id === preVisitMappingId
                      );
                      if (preVisitMapping) {
                        onSelect(preVisitMapping);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const PRE_VISIT_MAPPING_FORM_OPTIONS = gql`
  query PreVisitMappingFormOptions(
    $locationId: String!
    $organizationId: String!
  ) {
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    patientLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
    appointmentLocations(
      where: { organizationId: { equals: $organizationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

const PreVisitMappingRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
    })
  ),
});

export const PreVisitMappingForm: React.FC<{
  preVisitMapping: PreVisitMapping | null;
  children: (props: {
    conditions: PreVisitMappingConditionInput[];
  }) => React.ReactElement;
}> = ({ preVisitMapping, children }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const optionsResult = useQuery<
    PreVisitMappingFormOptions,
    PreVisitMappingFormOptionsVariables
  >(PRE_VISIT_MAPPING_FORM_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
      organizationId: user.organization.id,
    },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];
  const appointmentLocations = optionsResult.data?.appointmentLocations ?? [];
  const form = useForm<z.infer<typeof PreVisitMappingRuleForm>>({
    resolver: zodResolver(PreVisitMappingRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: preVisitMapping?.id ?? null,
      name: preVisitMapping?.name,
      notes: preVisitMapping?.notes,
      conditions: preVisitMapping?.preVisitMappingConditions ?? [],
    },
  });
  const conditionsField = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const ruleName = form.watch("name");
  const conditions = form.watch("conditions");
  const [createPreVisitMapping, createPreVisitMappingResult] = useMutation<
    CreatePreVisitMapping,
    CreatePreVisitMappingVariables
  >(CREATE_PRE_VISIT_MAPPING);
  const [updatePreVisitMapping, updatePreVisitMappingResult] = useMutation<
    UpdatePreVisitMapping,
    UpdatePreVisitMappingVariables
  >(UPDATE_PRE_VISIT_MAPPING);

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison)
    ) &&
    isDefined(ruleName);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          // Form is disabled if not defined
          if (preVisitMapping) {
            const toDelete = preVisitMapping.preVisitMappingConditions.filter(
              (c) => !data.conditions.some(({ id }) => id === c.id)
            );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            updatePreVisitMapping({
              variables: {
                id: preVisitMapping.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  preVisitMappingConditions: {
                    create: toCreate.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({ id, attributeType, comparison, attribute }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                        },
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Pre-Visit Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createPreVisitMapping({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  preVisitMappingConditions: {
                    create: data.conditions.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Rule created");
                analytics?.track("Pre-Visit Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create rule");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicatePreVisitMappingDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue("notes", c.notes);
                conditionsField.replace(
                  c.preVisitMappingConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                  }))
                );
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <div className="grid gap-2 grid-cols-1">
                                    <FormField
                                      control={form.control}
                                      name={`conditions.${index}.attributeType`}
                                      render={({ field }) => (
                                        <UiSelect
                                          onValueChange={field.onChange}
                                          defaultValue={field.value}
                                          {...form.register(
                                            `conditions.${index}.attributeType`
                                          )}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select an attribute" />
                                          </SelectTrigger>
                                          <SelectContent>
                                            <SelectGroup>
                                              {PRE_VISIT_MAPPING_ATTRIBUTES.map(
                                                (o) => (
                                                  <UiSelectItem value={o.value}>
                                                    {o.label}
                                                  </UiSelectItem>
                                                )
                                              )}
                                            </SelectGroup>
                                          </SelectContent>
                                        </UiSelect>
                                      )}
                                    />
                                  </div>

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                    appointmentLocations={appointmentLocations}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="mt-2 max-w-xl flex items-center gap-2">
                      <div className="text-lg font-semibold">THEN</div>
                      enable pre-visit reminders
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={() => {}}
                        loading={
                          createPreVisitMappingResult.loading ||
                          updatePreVisitMappingResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
