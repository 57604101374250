import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { DocumentNode, gql, useMutation } from "@apollo/client";
import { SubmitButton } from "./Button";
import { toast } from "react-toastify";

export const CloudCheck: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className ?? "h-6 w-6"}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m8.6,13.6l1.6,1.6l4,-4"
    />
  </svg>
);

export const CloudX: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="none"
    className={className ?? "h-6 w-6"}
  >
    <path
      id="svg_1"
      d="m3,15a4,4 0 0 0 4,4l9,0a5,5 0 1 0 -0.1,-9.999a5.002,5.002 0 1 0 -9.78,2.096a4.001,4.001 0 0 0 -3.12,3.903z"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      stroke="null"
      id="svg_2"
      d="m8.82353,16.11765l4.94118,-4.94118m-4.94118,0l4.94118,4.94118"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);

export const CloudSpinner: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className ?? "h-6 w-6"}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    {/* Cloud outline */}
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
    />
    {/* Circular spinner */}
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 12m-2 0a2 2 0 1 0 4 0"
      className="animate-spin origin-[12px_12px]"
      strokeDasharray="12"
      strokeDashoffset="8"
    />
  </svg>
);

export interface IntegrationLink {
  id: string;
  createdAt: string;
  lastCheckedAt?: string;
  integration: {
    id: string;
    name: string;
    supportsOnDemandPatientSync: boolean;
  };
  integrationLinkSyncs: {
    id: string;
    integrationLinkSyncErrors: {
      id: string;
      message: string;
    }[];
  }[];
}

const SYNC_PATIENT_FROM_INTEGRATION = gql`
  mutation SyncPatientFromIntegration(
    $integrationId: String!
    $patientId: String!
  ) {
    syncPatientFromIntegration(
      integrationId: $integrationId
      patientId: $patientId
    ) {
      patient {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const IntegrationStatus: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    integrationLinks: Pick<
      IntegrationLink,
      | "id"
      | "integrationLinkSyncs"
      | "integration"
      | "lastCheckedAt"
      | "createdAt"
    >[];
    refetchQueries?: DocumentNode[];
  }>
> = ({ patientId, integrationLinks, refetchQueries }) => {
  const [syncPatientFromIntegration, syncPatientFromIntegrationResult] =
    useMutation(SYNC_PATIENT_FROM_INTEGRATION);
  const anyErrors = integrationLinks.some(
    (link) => link.integrationLinkSyncs[0]?.integrationLinkSyncErrors?.length
  );
  return (
    <Popover className="relative align-middle">
      <Popover.Button className="p-1 hover:bg-gray-200 rounded-lg print:hidden">
        {anyErrors ? (
          <CloudX className="flex h-6 w-6 text-red-500" />
        ) : (
          <CloudCheck className="flex h-6 w-6 text-green-500" />
        )}
      </Popover.Button>
      <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="relative flex bg-white px-2 py-3 sm:p-6">
              <ul>
                {integrationLinks.map((link) => {
                  const errors =
                    link.integrationLinkSyncs[0]?.integrationLinkSyncErrors ??
                    [];
                  return (
                    <li
                      key={link.id}
                      className="flex flex-col gap-1 font-normal"
                    >
                      <div className="flex">
                        {errors.length > 0 ? (
                          <ExclamationCircleIcon className="h-8 w-8 pr-1 text-red-500" />
                        ) : (
                          <CheckCircleIcon className="h-8 w-8 pr-1 text-green-500" />
                        )}
                        <p>
                          Last synced with {link.integration.name}{" "}
                          {formatDistanceToNow(
                            parseISO(link.lastCheckedAt || link.createdAt),
                            {
                              addSuffix: true,
                            }
                          )}
                          {errors.length > 0 && " with errors"}.
                        </p>
                      </div>
                      {errors.length > 0 && (
                        <ul className="flex list-disc pl-8">
                          {errors.map((error) => (
                            <li className="text-red-500">{error.message}</li>
                          ))}
                        </ul>
                      )}

                      {link.integration.supportsOnDemandPatientSync && (
                        <SubmitButton
                          type="button"
                          className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
                          loading={syncPatientFromIntegrationResult.loading}
                          onClick={() =>
                            syncPatientFromIntegration({
                              variables: {
                                integrationId: link.integration.id,
                                patientId,
                              },
                              onCompleted: (data) => {
                                if (
                                  data.syncPatientFromIntegration.errors
                                    .length > 0
                                ) {
                                  for (const error of data
                                    .syncPatientFromIntegration.errors) {
                                    toast.error(error.message);
                                  }
                                } else {
                                  toast.success("Synced successfully");
                                }
                              },
                              onError: (error) => {
                                console.log(error);
                                toast.error("Error syncing");
                              },
                              refetchQueries,
                            })
                          }
                        >
                          Sync Now
                        </SubmitButton>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
