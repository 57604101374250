import { PlusCircledIcon, CheckIcon } from "@radix-ui/react-icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Layout, LoadingLayout } from "../layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/ui/card";
import { BarChart, List, ListItem, TextInput } from "@tremor/react";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { Switch } from "@headlessui/react";
import { useState, useMemo } from "react";
import { cn, formatUSD, isDefined, toCents } from "../../utils";
import { SubmitButton, Tooltip } from "../../components";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { useUser } from "../../user-context";
import { CurrencyInput, Input } from "../../components/ui/input";
import {
  GetChargemasterGroupDetails,
  GetChargemasterGroupDetailsVariables,
  GetChargemasterGroupDetails_chargemasterGroup as ChargemasterGroup,
} from "../../generated/GetChargemasterGroupDetails";
import { UPSERT_CHARGEMASTER_GROUP } from "./list";
import {
  UpsertChargemasterGroup,
  UpsertChargemasterGroupVariables,
} from "../../generated/UpsertChargemasterGroup";
import { ChargeTable, GET_CHARGEMASTER_CHARGES } from "./charge-table";
import { useAnalytics } from "../../analytics-context";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useFeatureFlags } from "../../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { CartesianGrid, Line, LineChart, XAxis, Legend, YAxis } from "recharts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { ChartLegend, ChartLegendContent } from "../../components/ui/chart";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../components/ui/command";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";

const GET_CHARGEMASTER_GROUP_DETAILS = gql`
  query GetChargemasterGroupDetails($id: String!) {
    chargemasterGroup(where: { id: $id }) {
      id
      updatedAt
      archivedAt
      lastUsedAt
      amount
      code
      modifier1
      modifier2
      modifier3
      modifier4
      cashPay
      description
      chargemasters {
        id
        lastUsedAt
        code
        modifier1
        modifier2
        modifier3
        modifier4
      }
      scheduledServiceFees {
        id
        allowedAmount
        feeSchedule {
          id
          name
          pending
        }
      }
      chargeTemplateCharges(orderBy: { priority: asc }) {
        id
        chargeTemplate {
          id
          name
        }
      }
    }
    chargemasterGroupUsageStats(chargemasterGroupId: $id) {
      month
      total
    }
  }
`;

// const UPSERT_CHARGEMASTER_GROUP = gql`
//   mutation UpsertChargemasterGroup(
//     $id: String!
//     $create: ChargemasterGroupCreateInput!
//     $update: ChargemasterGroupUpdateInput!
//   ) {
//     upsertOneChargemasterGroup(
//       where: { id: $id }
//       create: $create
//       update: $update
//     ) {
//       id
//       updatedAt
//       code
//       description
//     }
//   }
// `;

type ChargemasterGroupForm = {
  id: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description: string;
  cashPay: boolean;
  amount: string;
};

const ChargemasterGroupForm: React.FC<{
  chargemasterGroup: ChargemasterGroup | null;
}> = ({ chargemasterGroup }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [upsertChargemasterGroup, upsertChargemasterGroupResult] = useMutation<
    UpsertChargemasterGroup,
    UpsertChargemasterGroupVariables
  >(UPSERT_CHARGEMASTER_GROUP);

  const defaultAmount = formatUSD(chargemasterGroup?.amount ?? 0);
  const methods = useForm<ChargemasterGroupForm>({
    defaultValues: {
      id: chargemasterGroup?.id ?? uuidV4(),
      code: chargemasterGroup?.code,
      modifier1: chargemasterGroup?.modifier1,
      modifier2: chargemasterGroup?.modifier2,
      modifier3: chargemasterGroup?.modifier3,
      modifier4: chargemasterGroup?.modifier4,
      description: chargemasterGroup?.description,
      cashPay: chargemasterGroup?.cashPay ?? false,
      amount: defaultAmount,
    },
  });
  const [archived, setArchived] = useState(
    isDefined(chargemasterGroup?.archivedAt)
  );

  const amount = methods.watch("amount")?.replace("$", "");

  const onSubmit = (data: ChargemasterGroupForm) => {
    const archivedAt = chargemasterGroup?.archivedAt ?? new Date();
    const amount = toCents(parseFloat(data.amount.replace("$", "")));
    upsertChargemasterGroup({
      variables: {
        id: data.id,
        code: data.code.trim(),
        modifier1: data.modifier1.trim(),
        modifier2: data.modifier2.trim(),
        modifier3: data.modifier3.trim(),
        modifier4: data.modifier4.trim(),
        description: data.description ?? "",
        cashPay: data.cashPay,
        amount,
      },
      onCompleted: () => {
        toast.success("Chargemaster saved");
        analytics?.track("Chargemaster Group Saved", {
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
          chargemasterGroupId: data.id,
        });
        navigate("/chargemaster");
      },
      onError: () => {
        toast.error("Failed to save chargemaster");
      },
    });
  };

  const cashPay = methods.watch("cashPay");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8 p-2 w-full">
          <div>
            <div className="flex flex-col gap-4 max-w-xl">
              <div className="flex flex-wrap gap-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Code
                  </label>
                  <TextInput {...methods.register("code")} required />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M1
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier1")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M2
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier2")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M3
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier3")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M4
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier4")}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Cash Price
                  </label>
                  <CurrencyInput
                    {...methods.register("amount")}
                    value={amount}
                    onValueChange={(val) => {
                      methods.setValue("amount", val!);
                    }}
                    required
                  />
                </div>
                <div className="grow">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <Input {...methods.register("description")} required />
                </div>
              </div>
              <div>
                <label className="flex items-center gap-1 text-sm font-medium leading-6 text-gray-900 pb-1">
                  Cash Only
                  <Tooltip
                    content={<>Don't apply insurance towards this charge</>}
                    trigger={
                      <InformationCircleIcon className="w-4 h-4 text-gray-400" />
                    }
                  />
                </label>

                <Switch
                  {...methods.register("cashPay")}
                  onChange={(val) => {
                    methods.setValue("cashPay", val);
                  }}
                  className={cn(
                    cashPay ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={cn(
                      cashPay ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              {/* TODO: Uncomment when we implment archiving chargemaster groups */}
              {/* <div>
                <label className="block text-sm font-medium leading-6 text-gray-900 pb-1">
                  Archived
                </label>

                <Switch
                  checked={archived}
                  onChange={setArchived}
                  className={cn(
                    archived ? "bg-red-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={cn(
                      archived ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            <SubmitButton loading={upsertChargemasterGroupResult.loading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

type FeeScheduleTableProps = {
  fees: Array<{
    id: string;
    allowedAmount: number;
    feeSchedule: {
      id: string;
      name: string;
      pending: boolean;
    };
  }>;
};

const FeeScheduleTable: React.FC<FeeScheduleTableProps> = ({ fees }) => {
  const [sortField, setSortField] = useState<"name" | "amount">("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const sortedFees = [...fees].sort((a, b) => {
    const aValue = sortField === "name" ? a.feeSchedule.name : a.allowedAmount;
    const bValue = sortField === "name" ? b.feeSchedule.name : b.allowedAmount;

    if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
    if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const toggleSort = (field: "name" | "amount") => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead
            className="cursor-pointer"
            onClick={() => toggleSort("name")}
          >
            Fee Schedule{" "}
            {sortField === "name" && (sortDirection === "asc" ? "↑" : "↓")}
          </TableHead>
          <TableHead
            className="cursor-pointer"
            onClick={() => toggleSort("amount")}
          >
            Allowed Amount{" "}
            {sortField === "amount" && (sortDirection === "asc" ? "↑" : "↓")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedFees.length === 0 ? (
          <TableRow>
            <TableCell colSpan={2} className="text-center">
              Not present in any fee schedules
            </TableCell>
          </TableRow>
        ) : (
          sortedFees.map((fee) => (
            <TableRow key={fee.id}>
              <TableCell>
                <Link
                  to={`/fee-schedules/${fee.feeSchedule.id}/edit`}
                  className="hover:text-indigo-600"
                >
                  {fee.feeSchedule.name}
                </Link>
              </TableCell>
              <TableCell>{formatUSD(fee.allowedAmount)}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

("use client");

import { TrendingUp } from "lucide-react";

import {
  // Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";
import {
  GetChargemasterCharges,
  GetChargemasterChargesVariables,
} from "../../generated/GetChargemasterCharges";

type FilterDimension =
  | "payer"
  | "accountType"
  | "status"
  | "feeSchedule"
  | "billingProvider"
  | "modifier1";

const FILTER_OPTIONS: Record<FilterDimension, string> = {
  payer: "Payer",
  accountType: "Account Type",
  status: "Status",
  feeSchedule: "Fee Schedule",
  billingProvider: "Billing Provider",
  modifier1: "Modifier 1",
};

const CHART_COLORS = [
  "hsl(var(--chart-1))",
  "hsl(var(--chart-2))",
  "hsl(var(--chart-3))",
  "hsl(var(--chart-4))",
  "hsl(var(--chart-5))",
  // Add more colors if needed
  "hsl(var(--chart-1) / 0.4)",
  "hsl(var(--chart-2) / 0.4)",
  "hsl(var(--chart-3) / 0.4)",
  "hsl(var(--chart-4) / 0.4)",
  "hsl(var(--chart-5) / 0.4)",
];

// Maximum number of groups to show in the legend before grouping as "Others"
const MAX_LEGEND_GROUPS = 6;

export function AllowedAmountChart({
  chargemasterGroupId,
}: {
  chargemasterGroupId: string;
}) {
  const { data, loading } = useQuery<
    GetChargemasterCharges,
    GetChargemasterChargesVariables
  >(GET_CHARGEMASTER_CHARGES, {
    variables: { id: chargemasterGroupId },
  });

  // Update filter state to use arrays for multiple selections
  const [filters, setFilters] = useState<
    Partial<Record<FilterDimension, string[]>>
  >({
    status: ["Processed"], // Default filter
  });
  const [groupByDimension, setGroupByDimension] =
    useState<FilterDimension>("payer"); // Default grouping

  const charges = data?.charges ?? [];

  // Process and prepare chart data
  const processedChartData = useMemo(() => {
    // First filter the charges based on selected filters
    const filteredCharges = charges.filter((charge) => {
      // Skip filtering if no filter values are selected for a dimension
      if (filters.accountType && filters.accountType.length > 0) {
        const accountTypeName = charge.transaction.account.accountType?.name;
        if (
          !accountTypeName ||
          !filters.accountType.includes(accountTypeName)
        ) {
          return false;
        }
      }

      const ibc = charge.insuranceBillableCharges.at(0);

      if (filters.payer && filters.payer.length > 0) {
        const payerName = ibc?.accountCoverage?.insurancePolicy.payer.name;
        if (!payerName || !filters.payer.includes(payerName)) {
          return false;
        }
      }

      if (filters.status && filters.status.length > 0) {
        const status = ibc?.status;
        if (!status || !filters.status.includes(status)) {
          return false;
        }
      }

      if (filters.feeSchedule && filters.feeSchedule.length > 0) {
        const feeScheduleName =
          charge.bill?.appointment?.recommendedFeeSchedule?.name;
        if (
          !feeScheduleName ||
          !filters.feeSchedule.includes(feeScheduleName)
        ) {
          return false;
        }
      }

      if (filters.billingProvider && filters.billingProvider.length > 0) {
        const providerName = charge.bill?.billingProvider?.displayName;
        if (!providerName || !filters.billingProvider.includes(providerName)) {
          return false;
        }
      }

      if (filters.modifier1 && filters.modifier1.length > 0) {
        const modifier1 = charge.modifier1 || "None";
        if (!filters.modifier1.includes(modifier1)) {
          return false;
        }
      }

      return true;
    });

    // Map charges to chart data points
    const dataPoints = filteredCharges
      .map((charge) => {
        const ibc = charge.insuranceBillableCharges.at(0);
        const date = parseISO(charge.transaction.transactedAt);

        // Skip invalid dates
        if (isNaN(date.getTime())) {
          console.warn("Invalid date:", charge.transaction.transactedAt);
          return null;
        }

        let group: string;
        switch (groupByDimension) {
          case "payer":
            group =
              ibc?.accountCoverage?.insurancePolicy.payer.name || "Unknown";
            break;
          case "accountType":
            group = charge.transaction.account.accountType?.name || "Unknown";
            break;
          case "status":
            group = ibc?.status || "Unknown";
            break;
          case "feeSchedule":
            group =
              charge.bill?.appointment?.recommendedFeeSchedule?.name ||
              "Unknown";
            break;
          case "billingProvider":
            group = charge.bill?.billingProvider?.displayName || "Unknown";
            break;
          case "modifier1":
            group = charge.modifier1 || "None";
            break;
          default:
            group = "Unknown";
        }

        return {
          date: format(date, "yyyy-MM-dd"),
          rawDate: date, // Keep the raw date for sorting
          payer: ibc?.accountCoverage?.insurancePolicy.payer.name || "Unknown",
          accountType:
            charge.transaction.account.accountType?.name || "Unknown",
          status: ibc?.status || "Unknown",
          feeSchedule:
            charge.bill?.appointment?.recommendedFeeSchedule?.name || "Unknown",
          modifier1: charge.modifier1 || "None",
          allowedAmount: -charge.allowedAmount / 100,
          group,
        };
      })
      .filter((item): item is NonNullable<typeof item> => item !== null)
      // Sort by the raw date
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime());

    return dataPoints;
  }, [charges, filters, groupByDimension]);

  // Get all unique dates for the x-axis to ensure chronological order
  const allDates = useMemo(() => {
    const dateSet = new Set<string>();
    processedChartData.forEach((item) => {
      dateSet.add(item.date);
    });
    return Array.from(dateSet).sort(
      (a, b) => parseISO(a).getTime() - parseISO(b).getTime()
    );
  }, [processedChartData]);

  // Get unique values for filter dropdowns
  const filterOptions = useMemo(() => {
    const options: Record<FilterDimension, Set<string>> = {
      payer: new Set(),
      accountType: new Set(),
      status: new Set(),
      feeSchedule: new Set(),
      billingProvider: new Set(),
      modifier1: new Set(),
    };

    charges.forEach((charge) => {
      const ibc = charge.insuranceBillableCharges.at(0);
      if (ibc?.accountCoverage?.insurancePolicy.payer.name) {
        options.payer.add(ibc.accountCoverage.insurancePolicy.payer.name);
      }
      if (charge.transaction.account.accountType?.name) {
        options.accountType.add(charge.transaction.account.accountType.name);
      }
      if (ibc?.status) {
        options.status.add(ibc.status);
      }
      if (charge.bill?.appointment?.recommendedFeeSchedule?.name) {
        options.feeSchedule.add(
          charge.bill.appointment.recommendedFeeSchedule.name
        );
      }
      if (charge.bill?.billingProvider?.displayName) {
        options.billingProvider.add(charge.bill.billingProvider.displayName);
      }
      if (charge.modifier1) {
        options.modifier1.add(charge.modifier1);
      } else {
        options.modifier1.add("None");
      }
    });

    return options;
  }, [charges]);

  // Count occurrences of each group to determine which are most common
  const groupCounts = useMemo(() => {
    const counts = new Map<string, number>();
    processedChartData.forEach((item) => {
      const count = counts.get(item.group) || 0;
      counts.set(item.group, count + 1);
    });
    return counts;
  }, [processedChartData]);

  // Get top groups and create "Others" category for the rest
  const { topGroups, otherGroups } = useMemo(() => {
    // Convert to array and sort by count (descending)
    const sortedGroups = Array.from(groupCounts.entries()).sort(
      (a, b) => b[1] - a[1]
    );

    // Take top N groups
    const top = sortedGroups
      .slice(0, MAX_LEGEND_GROUPS)
      .map(([group]) => group);

    // Put the rest in "Others"
    const others = sortedGroups
      .slice(MAX_LEGEND_GROUPS)
      .map(([group]) => group);

    return { topGroups: top, otherGroups: others };
  }, [groupCounts]);

  // Create chart config with proper colors for each group
  const chartConfig = useMemo(() => {
    const config: ChartConfig = {};

    // Add top groups with distinct colors
    topGroups.forEach((group, index) => {
      config[group] = {
        label: group,
        color: CHART_COLORS[index % CHART_COLORS.length],
      };
    });

    // Add "Others" category if there are other groups
    if (otherGroups.length > 0) {
      config["Others"] = {
        label: `Others (${otherGroups.length})`,
        color: "hsl(var(--muted-foreground))",
      };
    }

    return config;
  }, [topGroups, otherGroups]);

  // Prepare data for the chart, mapping "Others" groups
  const chartData = useMemo(() => {
    // Create a map of all dates to ensure chronological order
    const dateMap = new Map<string, Record<string, any>>();

    // Initialize the date map with all dates
    allDates.forEach((date) => {
      const dateObj: Record<string, any> = { date };
      topGroups.forEach((group) => {
        dateObj[group] = null;
      });
      if (otherGroups.length > 0) {
        dateObj["Others"] = null;
      }
      dateMap.set(date, dateObj);
    });

    // Fill in the data points
    processedChartData.forEach((point) => {
      const dateObj = dateMap.get(point.date);
      if (dateObj) {
        const group = topGroups.includes(point.group) ? point.group : "Others";

        // For top groups, just set the value
        if (topGroups.includes(point.group)) {
          dateObj[group] = point.allowedAmount;
          dateObj[`${group}_data`] = point; // Store full data for tooltip
        }
        // For "Others", we need to handle multiple points per date
        else if (otherGroups.includes(point.group)) {
          // Store the original data in an array for tooltip access
          if (!dateObj["Others_points"]) {
            dateObj["Others_points"] = [];
          }
          dateObj["Others_points"].push(point);

          // Just mark that there's data for "Others" on this date
          dateObj["Others"] = point.allowedAmount;
        }
      }
    });

    // Convert the map back to an array
    return Array.from(dateMap.values());
  }, [processedChartData, allDates, topGroups, otherGroups]);

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Charge History</CardTitle>
        <CardDescription>
          <div className="grid grid-cols-4 gap-4 mt-2">
            {Object.entries(FILTER_OPTIONS).map(([key, label]) => (
              <div key={key} className="flex flex-col gap-1">
                <label className="text-sm font-medium">{label}</label>
                <MultiSelectFilter
                  title={label}
                  selectedValues={filters[key as FilterDimension] || []}
                  options={Array.from(
                    filterOptions[key as FilterDimension]
                  ).map((value) => ({
                    label: value,
                    value: value,
                  }))}
                  onSelectionChange={(values) =>
                    setFilters((prev) => ({
                      ...prev,
                      [key]: values.length > 0 ? values : undefined,
                    }))
                  }
                />
              </div>
            ))}
            <div className="flex flex-col gap-1 h-6">
              <label className="text-sm font-medium">Group By</label>
              <Select
                value={groupByDimension}
                onValueChange={(value: FilterDimension) =>
                  setGroupByDimension(value)
                }
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(FILTER_OPTIONS).map(([key, label]) => (
                    <SelectItem key={key} value={key}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardDescription>
      </CardHeader>
      <CardContent className="overflow-hidden pt-0">
        <div className="h-[500px] w-full">
          <ChartContainer config={chartConfig}>
            <LineChart
              accessibilityLayer
              data={chartData}
              margin={{
                left: 0, // Left margin for Y-axis
                right: 24, // Right margin
                bottom: 50, // Bottom margin for legend
                top: 16, // Top margin
              }}
              className="h-full w-full"
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
                className="stroke-muted"
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => format(parseISO(value), "MM/dd/yyyy")}
                className="text-sm fill-muted-foreground"
                angle={-45} // Angle the date labels to prevent overlap
                textAnchor="end" // Align the angled text
                height={60} // Increase height for angled labels
              />
              {/* Add Y-axis for better readability */}
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => `$${Math.abs(value).toFixed(0)}`}
                className="text-sm fill-muted-foreground"
              />
              <ChartTooltip
                content={({ active, payload, label }) => {
                  if (!active || !payload?.length) return null;

                  // Find the date object in our chart data
                  const dateObj = chartData.find((d) => d.date === label);
                  if (!dateObj) return null;

                  // Collect all points to show in the tooltip
                  const pointsToShow: Array<any> = [];

                  // Add points from top groups
                  payload.forEach((series) => {
                    const groupName = series.dataKey as string;
                    if (
                      topGroups.includes(groupName) &&
                      dateObj[`${groupName}_data`]
                    ) {
                      pointsToShow.push(dateObj[`${groupName}_data`]);
                    }
                  });

                  // Add points from "Others" group
                  if (dateObj["Others_points"]) {
                    pointsToShow.push(...dateObj["Others_points"]);
                  }

                  if (pointsToShow.length === 0) return null;

                  return (
                    <div className="rounded-lg bg-popover p-2 shadow-md border max-w-xs overflow-hidden">
                      <div className="grid gap-1">
                        <div className="flex items-center justify-between gap-2">
                          <span className="font-medium">Date:</span>
                          <span>{format(parseISO(label), "MM/dd/yyyy")}</span>
                        </div>
                        {pointsToShow
                          // Sort highest to lowest
                          .sort((a, b) => b.allowedAmount - a.allowedAmount)
                          // Limit to max 5 points to avoid overflow
                          .slice(0, 5)
                          .map((point) => (
                            <div
                              key={`${point.date}-${point.allowedAmount}-${point.group}`}
                              className="border-t first:border-t-0 pt-2 first:pt-0"
                            >
                              <div className="flex items-center gap-2 pb-1">
                                <div
                                  className="w-3 h-3 rounded-full"
                                  style={{
                                    backgroundColor: topGroups.includes(
                                      point.group
                                    )
                                      ? chartConfig[point.group]?.color
                                      : chartConfig["Others"]?.color || "#ccc",
                                    border: "2px solid white",
                                  }}
                                />
                                <span className="font-medium truncate">
                                  {point.group}:
                                </span>
                              </div>
                              <div className="grid gap-1 pl-5">
                                <div className="flex items-center justify-between gap-2">
                                  <span className="font-medium">Amount:</span>
                                  <span>
                                    ${Math.abs(point.allowedAmount).toFixed(2)}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between gap-2">
                                  <span className="font-medium">Status:</span>
                                  <span className="truncate">
                                    {point.status || "N/A"}
                                  </span>
                                </div>
                                {groupByDimension !== "accountType" && (
                                  <div className="flex items-center justify-between gap-2">
                                    <span className="font-medium">
                                      Account Type:
                                    </span>
                                    <span className="truncate">
                                      {point.accountType || "N/A"}
                                    </span>
                                  </div>
                                )}
                                {groupByDimension !== "payer" && (
                                  <div className="flex items-center justify-between gap-2">
                                    <span className="font-medium">Payer:</span>
                                    <span className="truncate">
                                      {point.payer || "N/A"}
                                    </span>
                                  </div>
                                )}
                                {groupByDimension !== "feeSchedule" && (
                                  <div className="flex items-center justify-between gap-2">
                                    <span className="font-medium">
                                      Fee Schedule:
                                    </span>
                                    <span className="truncate">
                                      {point.feeSchedule || "N/A"}
                                    </span>
                                  </div>
                                )}
                                {groupByDimension !== "modifier1" && (
                                  <div className="flex items-center justify-between gap-2">
                                    <span className="font-medium">
                                      Modifier 1:
                                    </span>
                                    <span className="truncate">
                                      {point.modifier1 || "N/A"}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                }}
                cursor={{ strokeDasharray: "3 3" }}
              />
              {/* Render lines for top groups */}
              {topGroups.map((group) => (
                <Line
                  key={group}
                  name={group}
                  dataKey={group}
                  type="monotone"
                  stroke={chartConfig[group]?.color || "#ccc"}
                  strokeWidth={0} // No line connecting the dots
                  dot={{
                    fill: chartConfig[group]?.color || "#ccc",
                    r: 4,
                    className: "stroke-background stroke-[0.5]",
                  }}
                  activeDot={{
                    r: 6,
                    className: "stroke-background stroke-2",
                  }}
                  isAnimationActive={false}
                />
              ))}

              {/* Render "Others" group if needed */}
              {otherGroups.length > 0 && (
                <Line
                  key="Others"
                  name="Others"
                  dataKey="Others"
                  type="monotone"
                  stroke={chartConfig["Others"]?.color || "#ccc"}
                  strokeWidth={0}
                  dot={{
                    fill: chartConfig["Others"]?.color || "#ccc",
                    r: 4,
                    className: "stroke-background stroke-[0.5]",
                  }}
                  activeDot={{
                    r: 6,
                    className: "stroke-background stroke-2",
                  }}
                  isAnimationActive={false}
                />
              )}

              <ChartLegend
                verticalAlign="bottom"
                height={50} // Adjusted legend height
                content={
                  <ChartLegendContent
                    className="flex flex-wrap justify-center gap-2 text-xs pt-2 overflow-hidden"
                    nameKey="name"
                  />
                }
              />
            </LineChart>
          </ChartContainer>
        </div>
      </CardContent>
    </Card>
  );
}

function MultiSelectFilter({
  title,
  options,
  selectedValues,
  onSelectionChange,
}: {
  title: string;
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<React.PropsWithChildren<{ className?: string }>>;
  }[];
  selectedValues: string[];
  onSelectionChange: (values: string[]) => void;
}) {
  const selectedValuesSet = new Set(selectedValues);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="h-8 border-dashed w-full justify-between"
        >
          <div className="flex items-center">{title}</div>
          {selectedValuesSet.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal"
              >
                {selectedValuesSet.size > 1
                  ? `${selectedValuesSet.size} selected`
                  : options.find((opt) => selectedValuesSet.has(opt.value))
                      ?.label}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValuesSet.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      const newSelectedValues = new Set(selectedValuesSet);
                      if (isSelected) {
                        newSelectedValues.delete(option.value);
                      } else {
                        newSelectedValues.add(option.value);
                      }
                      onSelectionChange(Array.from(newSelectedValues));
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected
                          ? "bg-primary text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValuesSet.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onSelectionChange([])}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export const ChargemasterGroupDetails: React.FC = () => {
  const flags = useFeatureFlags();
  const params = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<
    GetChargemasterGroupDetails,
    GetChargemasterGroupDetailsVariables
  >(GET_CHARGEMASTER_GROUP_DETAILS, {
    variables: { id: params.id! },
  });
  if (loading) return <LoadingLayout />;

  const chargemasterGroup = data?.chargemasterGroup!;
  const stats = data?.chargemasterGroupUsageStats ?? [];
  // const charges = data?.charges ?? [];

  const modifierDisplay = [
    chargemasterGroup.modifier1,
    chargemasterGroup.modifier2,
    chargemasterGroup.modifier3,
    chargemasterGroup.modifier4,
  ]
    .filter((x) => !!x)
    .join(" | ");

  const chartData = stats.map((stat) => ({
    date: stat.month,
    Count: stat.total,
  }));

  const nonPendingFees = chargemasterGroup.scheduledServiceFees.filter(
    (fee) => !fee.feeSchedule.pending
  );

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 flex gap-2 items-center">
            <Link to="/chargemaster" className="hover:text-gray-400">
              Chargemaster
            </Link>
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            {chargemasterGroup.code} {modifierDisplay && `(${modifierDisplay})`}
          </h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <div className="p-4">
                <ChargemasterGroupForm chargemasterGroup={chargemasterGroup} />
              </div>
            </Card>
            <Card>
              <AllowedAmountChart chargemasterGroupId={chargemasterGroup.id} />
              {/* <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Count of {chargemasterGroup.code}{" "}
                  {modifierDisplay && `(${modifierDisplay})`} charges
                </h3>
                <BarChart
                  data={chartData}
                  index="date"
                  categories={["Count"]}
                  colors={["blue"]}
                  yAxisWidth={45}
                  className="mt-6 hidden h-60 sm:block"
                />
              </div> */}
            </Card>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Used in Fee Schedules
                </h3>
                <div className="text-tremor-content dark:text-dark-tremor-content mt-2">
                  <FeeScheduleTable fees={nonPendingFees} />
                </div>
              </div>
            </Card>
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Used in Charge Templates
                </h3>
                <div className="text-tremor-content dark:text-dark-tremor-content">
                  <List className="mt-2">
                    <ListItem className="font-medium">
                      <span>Charge Template</span>
                      <span>Units</span>
                    </ListItem>
                    {chargemasterGroup.chargeTemplateCharges.length === 0 && (
                      <ListItem>Not present in any charge templates</ListItem>
                    )}
                    {chargemasterGroup.chargeTemplateCharges.map((ctc) => (
                      <ListItem key={ctc.id}>
                        <span>
                          <Link
                            to={`/charge-templates/${ctc.chargeTemplate.id}/edit`}
                            className="hover:text-indigo-600"
                          >
                            {chargemasterGroup.code}{" "}
                            {modifierDisplay && `(${modifierDisplay})`}
                          </Link>
                        </span>
                        <span>1</span>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </Card>
            {flags.chargesSupported && (
              <Card className="col-span-2">
                <div className="p-4">
                  <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Recent charges
                  </h3>
                  <ChargeTable chargemasterGroupId={chargemasterGroup.id} />
                </div>
              </Card>
            )}
          </div>
        </div>
      }
    />
  );
};
