import { useQuery } from "@apollo/client";
import {
  CheckEligibilityRequestStatus,
  CheckEligibilityRequestStatusVariables,
} from "../generated/CheckEligibilityRequestStatus";
import { EligibilityRequestStatus } from "../generated/globalTypes";
import { CHECK_ELIGIBILITY_REQUEST_STATUS } from "../pages/appointments";
import React from "react";

export type UseEligibilityStatusPollingOptions = {
  onComplete?: () => void;
  onTimeout?: () => void;
  pollInterval?: number;
  maxAttempts?: number;
};

/**
 * Returns the status of an eligibility request, and polls if it's in progress
 */
export const useEligibilityStatusPolling = (
  eligibilityRequest: {
    id: string;
    status: EligibilityRequestStatus;
  } | null,
  options: UseEligibilityStatusPollingOptions = {}
) => {
  const {
    onComplete,
    onTimeout,
    pollInterval = 2000,
    maxAttempts = 100, // 3.33 minute with 2s interval
  } = options;

  const [attempts, setAttempts] = React.useState(0);

  const result = useQuery<
    CheckEligibilityRequestStatus,
    CheckEligibilityRequestStatusVariables
  >(CHECK_ELIGIBILITY_REQUEST_STATUS, {
    skip:
      !eligibilityRequest ||
      eligibilityRequest.status !== "InProgress" ||
      attempts >= maxAttempts,
    pollInterval,
    variables: eligibilityRequest ? { id: eligibilityRequest.id } : undefined,
    onCompleted: (data) => {
      if (data.eligibilityRequest?.status === "Complete") {
        onComplete?.();
      } else if (data.eligibilityRequest?.status === "TimedOut") {
        onTimeout?.();
      } else {
        setAttempts((prev) => prev + 1);
        if (attempts >= maxAttempts) {
          onTimeout?.();
        }
      }
    },
  });

  return {
    ...result,
    hasTimedOut: attempts >= maxAttempts,
    attempts,
  };
};
