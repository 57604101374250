import { gql, useMutation, useQuery } from "@apollo/client";
import { InformationCircleIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Form, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import { z } from "zod";
import { Modal, SubmitButton, Tooltip } from "../../components";
import { OvalSpinner } from "../../components/loading";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { PlanTypeCombobox } from "../../components/ui/plan-type-combobox";
import { Switch } from "../../components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { TradingPartnerCombobox } from "../../components/ui/trading-partner-combobox";
import {
  ClearPayerPlanType,
  ClearPayerPlanTypeVariables,
} from "../../generated/ClearPayerPlanType";
import {
  ClearPayerTradingPartner,
  ClearPayerTradingPartnerVariables,
} from "../../generated/ClearPayerTradingPartner";
import {
  GetPayers,
  GetPayersVariables,
  GetPayers_payers as Payer,
} from "../../generated/GetPayers";
import {
  LoadAllTradingPartners,
  LoadAllTradingPartners_tradingPartners as TradingPartner,
} from "../../generated/LoadAllTradingPartners";
import {
  SetPayerInNetwork,
  SetPayerInNetworkVariables,
} from "../../generated/SetPayerInNetwork";
import {
  SetPayerOutNetwork,
  SetPayerOutNetworkVariables,
} from "../../generated/SetPayerOutNetwork";
import {
  SetPayerTradingPartner,
  SetPayerTradingPartnerVariables,
} from "../../generated/SetPayerTradingPartner";
import {
  TogglePayerExcludedFromBenefitsWorkflow,
  TogglePayerExcludedFromBenefitsWorkflowVariables,
} from "../../generated/TogglePayerExcludedFromBenefitsWorkflow";
import {
  UpdatePayerPlanType,
  UpdatePayerPlanTypeVariables,
} from "../../generated/UpdatePayerPlanType";
import { UpsertPayer, UpsertPayerVariables } from "../../generated/UpsertPayer";
import { useFeatureFlags } from "../../hooks";
import { useUser } from "../../user-context";
import { FullWidthLayout, HorizontalPadding } from "../layout";
import { ProviderCombobox } from "../../components/ui/provider-combobox";
import {
  SetPayerDefaultEligibilityProvider,
  SetPayerDefaultEligibilityProviderVariables,
} from "../../generated/SetPayerDefaultEligibilityProvider";
import {
  ClearPayerDefaultEligibilityProvider,
  ClearPayerDefaultEligibilityProviderVariables,
} from "../../generated/ClearPayerDefaultEligibilityProvider";

export const GET_PAYERS = gql`
  query GetPayers($organizationId: String!, $locationId: String!) {
    payers(
      where: {
        organizationId: { equals: $organizationId }
        locationId: { equals: $locationId }
      }
      orderBy: { name: asc }
    ) {
      id
      name
      excludedFromBenefitsWorkflow
      skipPlanTypeAutoDetection
      tradingPartner {
        id
        name
        tradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
      defaultPlanType {
        id
        name
      }
      defaultEligibilityProvider {
        id
        firstName
        lastName
        organizationName
        npi
      }
    }
    providers(where: { primaryLocationId: { equals: $locationId } }) {
      id
      firstName
      lastName
      npi
    }
  }
`;

export const LOAD_ALL_TRADING_PARTNERS = gql`
  query LoadAllTradingPartners {
    tradingPartners(orderBy: [{ name: asc }]) {
      id
      name
      tradingPartnerId
      eligibilityEnabled
    }
  }
`;

const SET_PAYER_TRADING_PARTNER = gql`
  mutation SetPayerTradingPartner(
    $payerId: String!
    $tradingPartnerId: String!
  ) {
    updateOnePayer(
      where: { id: $payerId }
      data: { tradingPartner: { connect: { id: $tradingPartnerId } } }
    ) {
      id
      name
      tradingPartner {
        id
        name
        tradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const CLEAR_PAYER_TRADING_PARTNER = gql`
  mutation ClearPayerTradingPartner($payerId: String!) {
    updateOnePayer(
      where: { id: $payerId }
      data: { tradingPartner: { disconnect: true } }
    ) {
      id
      name
      tradingPartner {
        id
        name
        tradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const ClearTradingPartnerMappingDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    payer: Payer;
    tradingPartner: TradingPartner;
  }>
> = ({ open, setOpen, payer, tradingPartner }) => {
  const [clearPayerTradingPartner, clearPayerTradingPartnerResult] =
    useMutation<ClearPayerTradingPartner, ClearPayerTradingPartnerVariables>(
      CLEAR_PAYER_TRADING_PARTNER
    );
  if (!payer || !tradingPartner) return null;
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="flex justify-center gap-2 items-center text-2xl pb-4">
            Clear mapping from {payer.name} to {tradingPartner.name}?
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
            <SubmitButton
              type="button"
              onClick={() => {
                clearPayerTradingPartner({
                  variables: {
                    payerId: payer.id,
                  },
                  onCompleted: () => {
                    setOpen(false);
                    toast.success("Payer trading partner cleared");
                  },
                  onError: (error) => {
                    setOpen(false);
                    toast.error("Failed to clear payer trading partner");
                  },
                });
              }}
              loading={clearPayerTradingPartnerResult.loading}
            >
              Clear
            </SubmitButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const PayerMappingButton: React.FC<
  React.PropsWithChildren<{
    payer: Payer;
    allTradingPartners: TradingPartner[];
  }>
> = ({ payer, allTradingPartners }) => {
  const [open, setOpen] = useState(false);
  const [setPayerTradingPartner, setPayerTradingPartnerResult] = useMutation<
    SetPayerTradingPartner,
    SetPayerTradingPartnerVariables
  >(SET_PAYER_TRADING_PARTNER);

  const [clearPayerTradingPartner, clearPayerTradingPartnerResult] =
    useMutation<ClearPayerTradingPartner, ClearPayerTradingPartnerVariables>(
      CLEAR_PAYER_TRADING_PARTNER
    );

  const onSelectTradingPartner = (tradingPartner: { id: string }) => {
    setPayerTradingPartner({
      variables: {
        payerId: payer.id,
        tradingPartnerId: tradingPartner.id,
      },
      onCompleted: () => {
        toast.success("Trading partner set successfully");
      },
      onError: (error) => {
        toast.error("Trading partner set failed");
      },
    });
  };

  return (
    <>
      <div className="flex gap-1 items-center justify-between">
        <TradingPartnerCombobox
          tradingPartners={allTradingPartners}
          value={payer.tradingPartner}
          onSelect={(tradingPartner) => {
            if (tradingPartner) {
              onSelectTradingPartner(tradingPartner);
            }
          }}
        />
        <div>
          <button
            className="inline-flex justify-center items-center rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25"
            onClick={() => setOpen(true)}
            disabled={!payer.tradingPartner}
          >
            Clear
          </button>
        </div>
      </div>
      {open && (
        <ClearTradingPartnerMappingDialog
          open={open}
          setOpen={setOpen}
          payer={payer}
          tradingPartner={payer.tradingPartner!}
        />
      )}
    </>
  );
};

const SET_PAYER_IN_NETWORK = gql`
  mutation SetPayerInNetwork($payerId: String!, $organizationId: String!) {
    updateOnePayer(
      where: { id: $payerId }
      data: {
        organizationInNetworkPayers: {
          create: { organization: { connect: { id: $organizationId } } }
        }
      }
    ) {
      id
      name
      tradingPartner {
        id
        name
        tradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const SET_PAYER_OUT_NETWORK = gql`
  mutation SetPayerOutNetwork($organizationInNetworkPayerId: String!) {
    deleteOneOrganizationInNetworkPayer(
      where: { id: $organizationInNetworkPayerId }
    ) {
      id
    }
  }
`;

const InNetworkToggleCell: React.FC<{
  payer: Payer;
}> = ({ payer }) => {
  const user = useUser()!;
  const organizationInNetworkPayerId = payer.organizationInNetworkPayers[0]?.id;
  const [enabled, setEnabled] = useState(
    payer.organizationInNetworkPayers.length > 0
  );
  const [setPayerInNetwork, setPayerInNetworkResult] = useMutation<
    SetPayerInNetwork,
    SetPayerInNetworkVariables
  >(SET_PAYER_IN_NETWORK);
  const [setPayerOutNetwork, setPayerOutNetworkResult] = useMutation<
    SetPayerOutNetwork,
    SetPayerOutNetworkVariables
  >(SET_PAYER_OUT_NETWORK);

  const handleChange = (checked: boolean) => {
    setEnabled(checked);
    if (checked) {
      setPayerInNetwork({
        variables: {
          payerId: payer.id,
          organizationId: user.organization.id,
        },
        onCompleted: () => {
          toast.success("Payer updated to in network");
        },
        onError: (error) => {
          toast.error("Failed to update payer to in network");
        },
      });
    }
    if (!checked) {
      setPayerOutNetwork({
        variables: {
          organizationInNetworkPayerId,
        },
        onCompleted: () => {
          toast.success("Payer updated to out of network");
        },
        onError: (error) => {
          toast.error("Failed to update payer to out of network");
        },
      });
    }
  };

  return (
    <TableCell>
      <div className="flex items-center gap-1 text-sm text-gray-900">
        <Switch checked={enabled} onCheckedChange={handleChange} />
        {setPayerInNetworkResult.loading && <OvalSpinner className="w-4 h-4" />}
      </div>
    </TableCell>
  );
};

const TOGGLE_PAYER_EXCLUDED_FROM_BENEFITS_WORKFLOW = gql`
  mutation TogglePayerExcludedFromBenefitsWorkflow(
    $id: String!
    $excluded: Boolean!
  ) {
    updateOnePayer(
      where: { id: $id }
      data: { excludedFromBenefitsWorkflow: { set: $excluded } }
    ) {
      id
      excludedFromBenefitsWorkflow
    }
  }
`;

const ExcludeFromBenefitsWorkflowCell: React.FC<{
  payer: Payer;
}> = ({ payer }) => {
  const [enabled, setEnabled] = useState(payer.excludedFromBenefitsWorkflow);
  const [togglePayerExcludedFromBenefitsWorkflow] = useMutation<
    TogglePayerExcludedFromBenefitsWorkflow,
    TogglePayerExcludedFromBenefitsWorkflowVariables
  >(TOGGLE_PAYER_EXCLUDED_FROM_BENEFITS_WORKFLOW);

  const handleChange = (checked: boolean) => {
    setEnabled(checked);
    togglePayerExcludedFromBenefitsWorkflow({
      variables: {
        id: payer.id,
        excluded: checked,
      },
      onCompleted: () => {
        if (checked) {
          toast.success("Payer excluded from benefits workflow");
        } else {
          toast.success("Payer marked as included from benefits workflow");
        }
      },
      onError: () => {
        toast.error(
          `Failed to update payer as ${
            checked ? "excluded" : "included"
          } from benefits workflow`
        );
      },
    });
  };

  return (
    <TableCell>
      <div className="flex items-center gap-1 text-sm text-gray-900">
        <Switch checked={enabled} onCheckedChange={handleChange} />
      </div>
    </TableCell>
  );
};

export const UPSERT_PAYER = gql`
  mutation UpsertPayer(
    $id: String!
    $create: PayerCreateInput!
    $update: PayerUpdateInput!
  ) {
    upsertOnePayer(where: { id: $id }, create: $create, update: $update) {
      id
      name
    }
  }
`;

const PayerSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const PayerForm: React.FC<{
  payer: Payer | null;
  onSuccess: () => void;
}> = ({ payer, onSuccess }) => {
  const user = useUser()!;

  const [upsertPayer, upsertPayerResult] = useMutation<
    UpsertPayer,
    UpsertPayerVariables
  >(UPSERT_PAYER);

  const form = useForm<z.infer<typeof PayerSchema>>({
    resolver: zodResolver(PayerSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: payer?.id ?? uuidV4(),
      name: payer?.name,
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          upsertPayer({
            variables: {
              id: data.id,
              create: {
                id: data.id,
                name: data.name,
                location: { connect: { id: user.activeLocation.id } },
                organization: { connect: { id: user.organization.id } },
              },
              update: {
                name: { set: data.name },
              },
            },
            onCompleted: () => {
              toast.success("Payer saved");
              onSuccess();
            },
            onError: () => {
              toast.error("Failed to save payer");
            },
            refetchQueries: [GET_PAYERS],
          });
        })}
      >
        <div className="flex flex-col gap-8 p-2 w-full">
          <div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-4">
                <div className="w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <Input {...form.register("name")} required />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            <SubmitButton loading={upsertPayerResult.loading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </form>
    </Form>
  );
};

export const PayerFormDialog: React.FC<{
  payer: Payer | null;
  setOpen: (open: boolean) => void;
}> = ({ payer, setOpen }) => {
  return (
    <Modal open={true} setOpen={setOpen}>
      <PayerForm payer={payer} onSuccess={() => setOpen(false)} />
    </Modal>
  );
};

const PayerNameCell: React.FC<{
  payer: Payer;
  onEdit: () => void;
}> = ({ payer, onEdit }) => (
  <TableCell className="py-3">
    <div className="flex items-center gap-3">
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <span className="font-medium truncate">{payer.name}</span>
        </div>
      </div>
      <Button
        variant="ghost"
        size="sm"
        className="opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={onEdit}
      >
        Edit
      </Button>
    </div>
  </TableCell>
);

const UPDATE_PAYER_PLAN_TYPE = gql`
  mutation UpdatePayerPlanType($id: String!, $planTypeId: String!) {
    updateOnePayer(
      where: { id: $id }
      data: { defaultPlanType: { connect: { id: $planTypeId } } }
    ) {
      id
      defaultPlanType {
        id
        name
      }
    }
  }
`;

const CLEAR_PAYER_PLAN_TYPE = gql`
  mutation ClearPayerPlanType($id: String!) {
    updateOnePayer(
      where: { id: $id }
      data: { defaultPlanType: { disconnect: true } }
    ) {
      id
      defaultPlanType {
        id
        name
      }
    }
  }
`;

const PlanTypeCell = React.memo<{ payer: Payer }>(({ payer }) => {
  const user = useUser()!;
  const planTypes = user.organization.planTypes;

  const [updatePlanType, updatePlanTypeResult] = useMutation<
    UpdatePayerPlanType,
    UpdatePayerPlanTypeVariables
  >(UPDATE_PAYER_PLAN_TYPE);
  const [clearPlanType, clearPlanTypeResult] = useMutation<
    ClearPayerPlanType,
    ClearPayerPlanTypeVariables
  >(CLEAR_PAYER_PLAN_TYPE);

  return (
    <div className="flex items-center gap-2">
      <PlanTypeCombobox
        insurancePolicy={{
          id: payer.id,
          planType: payer.defaultPlanType,
        }}
        planTypes={planTypes}
        onSelect={(planTypeId) => {
          updatePlanType({
            variables: {
              id: payer.id,
              planTypeId,
            },
            onCompleted: () => {
              toast.success("Plan type updated");
            },
            onError: () => {
              toast.error("Failed to update plan type");
            },
          });
        }}
      />
      <Button
        variant="ghost"
        onClick={() => {
          clearPlanType({
            variables: {
              id: payer.id,
            },
            onCompleted: () => {
              // Force clear the combobox by setting planType to null
              toast.success("Plan type cleared");
            },
            onError: () => {
              toast.error("Failed to clear plan type");
            },
          });
        }}
        className="inline-flex justify-center items-center h-7 rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-red-700 hover:text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25"
        disabled={!payer.defaultPlanType || clearPlanTypeResult.loading}
      >
        Clear
      </Button>
    </div>
  );
});

PlanTypeCell.displayName = "PlanTypeCell";

const DataTableToolbar: React.FC<{
  searchQuery: string;
  onSearchChange: (value: string) => void;
}> = ({ searchQuery, onSearchChange }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <div className="relative w-72">
          <SearchIcon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search payers..."
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full pl-8"
          />
        </div>
      </div>
    </div>
  );
};

// Memoized cell components
const MemoizedPayerNameCell = React.memo(PayerNameCell);
const MemoizedInNetworkToggleCell = React.memo(InNetworkToggleCell);
const MemoizedPayerMappingButton = React.memo(PayerMappingButton);
const MemoizedExcludeFromBenefitsWorkflowCell = React.memo(
  ExcludeFromBenefitsWorkflowCell
);
const MemoizedPlanTypeCell = React.memo(PlanTypeCell);

const SET_SKIP_PLAN_TYPE_AUTO_DETECTION = gql`
  mutation SetSkipPlanTypeAutoDetection($id: String!, $skip: Boolean!) {
    updateOnePayer(
      where: { id: $id }
      data: { skipPlanTypeAutoDetection: { set: $skip } }
    ) {
      id
      skipPlanTypeAutoDetection
    }
  }
`;

const SkipPlanTypeAutoDetectionCell = React.memo<{ payer: Payer }>(
  ({ payer }) => {
    const [enabled, setEnabled] = useState(payer.skipPlanTypeAutoDetection);
    const [setSkipPlanTypeAutoDetection] = useMutation(
      SET_SKIP_PLAN_TYPE_AUTO_DETECTION,
      {
        onError: (error) => {
          console.error(
            "Failed to update skip plan type auto detection:",
            error
          );
          toast.error("Failed to update auto detection setting");
        },
      }
    );

    const handleToggle = async (checked: boolean) => {
      setEnabled(checked);
      await setSkipPlanTypeAutoDetection({
        variables: {
          id: payer.id,
          skip: checked,
        },
        onCompleted: () => {
          toast.success("Auto detection setting updated");
        },
        onError: () => {
          toast.error("Failed to update auto detection setting");
        },
      });
    };

    return (
      <div className="flex items-center">
        <Switch
          checked={enabled}
          onCheckedChange={handleToggle}
          className="data-[state=checked]:bg-primary"
        />
      </div>
    );
  }
);

SkipPlanTypeAutoDetectionCell.displayName = "SkipPlanTypeAutoDetectionCell";

const MemoizedSkipPlanTypeAutoDetectionCell = React.memo(
  SkipPlanTypeAutoDetectionCell
);

const DefaultEligibilityProviderCell = React.memo<{ payer: Payer }>(
  ({ payer }) => {
    const user = useUser()!;
    const { data } = useQuery<GetPayers, GetPayersVariables>(GET_PAYERS, {
      variables: {
        organizationId: user.organization.id,
        locationId: user.activeLocation.id,
      },
    });

    const [setDefaultEligibilityProvider] = useMutation<
      SetPayerDefaultEligibilityProvider,
      SetPayerDefaultEligibilityProviderVariables
    >(SET_PAYER_DEFAULT_ELIGIBILITY_PROVIDER);

    const [clearDefaultEligibilityProvider] = useMutation<
      ClearPayerDefaultEligibilityProvider,
      ClearPayerDefaultEligibilityProviderVariables
    >(CLEAR_PAYER_DEFAULT_ELIGIBILITY_PROVIDER);

    const handleSelect = (provider: { id: string }) => {
      setDefaultEligibilityProvider({
        variables: {
          payerId: payer.id,
          providerId: provider.id,
        },
        onCompleted: () => {
          toast.success("Default eligibility provider set successfully");
        },
        onError: () => {
          toast.error("Failed to set default eligibility provider");
        },
      });
    };

    const handleClear = () => {
      clearDefaultEligibilityProvider({
        variables: {
          payerId: payer.id,
        },
        onCompleted: () => {
          toast.success("Default eligibility provider cleared");
        },
        onError: () => {
          toast.error("Failed to clear default eligibility provider");
        },
      });
    };

    return (
      <TableCell>
        <div className="flex items-center gap-2">
          <ProviderCombobox
            value={payer.defaultEligibilityProvider}
            onSelect={handleSelect}
            providers={data?.providers ?? []}
          />
          <Button
            variant="ghost"
            onClick={handleClear}
            className="inline-flex justify-center items-center h-7 rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-red-700 hover:text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25"
            disabled={!payer.defaultEligibilityProvider}
          >
            Clear
          </Button>
        </div>
      </TableCell>
    );
  }
);

DefaultEligibilityProviderCell.displayName = "DefaultEligibilityProviderCell";

const MemoizedDefaultEligibilityProviderCell = React.memo(
  DefaultEligibilityProviderCell
);

// Memoized table row component
const TableRowComponent = React.memo<{
  payer: Payer;
  onEdit: (payer: Payer) => void;
  allTradingPartners: TradingPartner[];
  showWorkflowColumn: boolean;
}>(({ payer, onEdit, allTradingPartners, showWorkflowColumn }) => (
  <TableRow className="group divide-x hover:bg-gray-50">
    <MemoizedPayerNameCell payer={payer} onEdit={() => onEdit(payer)} />
    <MemoizedInNetworkToggleCell payer={payer} />
    <TableCell className="py-3">
      <MemoizedPayerMappingButton
        payer={payer}
        allTradingPartners={allTradingPartners}
      />
    </TableCell>
    <TableCell className="py-3">
      <MemoizedPlanTypeCell
        key={payer.id + payer.defaultPlanType?.id}
        payer={payer}
      />
    </TableCell>
    <TableCell className="py-3">
      <MemoizedSkipPlanTypeAutoDetectionCell payer={payer} />
    </TableCell>
    <TableCell className="py-3">
      <MemoizedDefaultEligibilityProviderCell payer={payer} />
    </TableCell>
    {showWorkflowColumn && (
      <TableCell className="py-3">
        <MemoizedExcludeFromBenefitsWorkflowCell payer={payer} />
      </TableCell>
    )}
  </TableRow>
));

TableRowComponent.displayName = "TableRowComponent";

// Memoized toolbar component
const MemoizedDataTableToolbar = React.memo(DataTableToolbar);

export const PayerList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  const flags = useFeatureFlags();
  const [searchQuery, setSearchQuery] = useState("");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [editingPayer, setEditingPayer] = useState<Payer | null>(null);

  // Queries
  const { loading, error, data } = useQuery<GetPayers, GetPayersVariables>(
    GET_PAYERS,
    {
      variables: {
        organizationId: user!.organization.id,
        locationId: user!.activeLocation.id,
      },
    }
  );
  const { data: tradingPartnerData } = useQuery<LoadAllTradingPartners>(
    LOAD_ALL_TRADING_PARTNERS
  );

  // Memoized values
  const allTradingPartners = useMemo(
    () => tradingPartnerData?.tradingPartners ?? [],
    [tradingPartnerData?.tradingPartners]
  );

  const filteredPayers = useMemo(() => {
    if (!data?.payers) return [];
    const searchLower = searchQuery.toLowerCase();
    return searchLower
      ? data.payers.filter((payer) =>
          payer.name.toLowerCase().includes(searchLower)
        )
      : data.payers;
  }, [data?.payers, searchQuery]);

  // Virtualization
  const virtualizer = useVirtualizer({
    count: filteredPayers.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 56,
    overscan: 5,
  });

  // Callbacks
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
      virtualizer.scrollToIndex(0);
    },
    [virtualizer]
  );

  const handleEditPayer = useCallback((payer: Payer) => {
    setEditingPayer(payer);
  }, []);

  // Render helpers
  const renderLoadingState = () => (
    <TableRow>
      <TableCell
        colSpan={flags.verificationWorkflowEnabled ? 7 : 6}
        className="h-24 text-center"
      >
        <div className="flex justify-center">
          <OvalSpinner />
        </div>
      </TableCell>
    </TableRow>
  );

  const renderEmptyState = () => (
    <TableRow>
      <TableCell
        colSpan={flags.verificationWorkflowEnabled ? 7 : 6}
        className="h-24 text-center"
      >
        No payers found.
      </TableCell>
    </TableRow>
  );

  return (
    <div className="space-y-4">
      <HorizontalPadding>
        <MemoizedDataTableToolbar
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />
      </HorizontalPadding>
      <div className="border rounded-lg bg-white">
        <div
          ref={scrollContainerRef}
          className="max-h-[85vh] overflow-auto relative"
        >
          <Table>
            <TableHeader className="sticky top-0 bg-white z-50">
              <TableRow className="divide-x">
                <TableHead className="py-3 bg-white border-b">Name</TableHead>
                <TableHead className="py-3 bg-white border-b">
                  In Network
                </TableHead>
                <TableHead className="py-3 bg-white border-b">
                  Trading Partner
                </TableHead>
                <TableHead className="py-3 bg-white border-b">
                  Default Plan Type
                </TableHead>
                <TableHead className="py-3 bg-white border-b">
                  <div className="flex items-center gap-2">
                    Skip Auto Detection
                    <Tooltip
                      trigger={
                        <InformationCircleIcon className="h-4 w-4 text-muted-foreground" />
                      }
                      content={
                        <div className="max-w-sm">
                          When enabled, the system will not attempt to
                          automatically detect plan types for this payer from
                          eligibiilty responses. It will only use the default
                          plan type, if set.
                        </div>
                      }
                    />
                  </div>
                </TableHead>
                <TableHead className="py-3 bg-white border-b">
                  <div className="flex items-center gap-2">
                    Default Eligibility Provider
                    <Tooltip
                      trigger={
                        <InformationCircleIcon className="h-4 w-4 text-muted-foreground" />
                      }
                      content={
                        <div className="max-w-sm">
                          The default eligibility provider for this payer.
                        </div>
                      }
                    />
                  </div>
                </TableHead>
                {flags.verificationWorkflowEnabled && (
                  <TableHead className="py-3 bg-white border-b">
                    <div className="flex items-center gap-2">
                      Exclude from Workflow
                      <Tooltip
                        trigger={
                          <InformationCircleIcon className="h-4 w-4 text-muted-foreground" />
                        }
                        content={
                          <div className="max-w-sm">
                            Mark the payer as not needing benefits verification.
                            This will exclude patients with policies from this
                            payer from the verification worklists.
                          </div>
                        }
                      />
                    </div>
                  </TableHead>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                renderLoadingState()
              ) : filteredPayers.length === 0 ? (
                renderEmptyState()
              ) : (
                <>
                  {/* Top spacer row */}
                  <TableRow>
                    <TableCell
                      colSpan={flags.verificationWorkflowEnabled ? 7 : 6}
                      style={{
                        height: `${
                          virtualizer.getVirtualItems()[0]?.start ?? 0
                        }px`,
                        padding: 0,
                      }}
                    />
                  </TableRow>

                  {/* Virtualized rows */}
                  {virtualizer.getVirtualItems().map((virtualRow) => {
                    const payer = filteredPayers[virtualRow.index];
                    return (
                      <TableRowComponent
                        key={payer.id}
                        payer={payer}
                        onEdit={handleEditPayer}
                        allTradingPartners={allTradingPartners}
                        showWorkflowColumn={flags.verificationWorkflowEnabled}
                      />
                    );
                  })}

                  {/* Bottom spacer row */}
                  <TableRow>
                    <TableCell
                      colSpan={flags.verificationWorkflowEnabled ? 7 : 6}
                      style={{
                        height: `${
                          virtualizer.getTotalSize() -
                          (virtualizer.getVirtualItems()[
                            virtualizer.getVirtualItems().length - 1
                          ]?.end ?? 0)
                        }px`,
                        padding: 0,
                      }}
                    />
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {editingPayer && (
        <PayerFormDialog
          payer={editingPayer}
          setOpen={(open) => {
            if (!open) setEditingPayer(null);
          }}
        />
      )}
    </div>
  );
};

export const Payers: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [open, setOpen] = useState(false);
  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex justify-between w-full">
            <h1 className="text-2xl font-semibold text-gray-900">Payers</h1>
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              New Payer
            </Button>
            {open && <PayerFormDialog payer={null} setOpen={setOpen} />}
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="py-4">
          <PayerList />
        </div>
      }
    />
  );
};

const SET_PAYER_DEFAULT_ELIGIBILITY_PROVIDER = gql`
  mutation SetPayerDefaultEligibilityProvider(
    $payerId: String!
    $providerId: String!
  ) {
    updateOnePayer(
      where: { id: $payerId }
      data: { defaultEligibilityProvider: { connect: { id: $providerId } } }
    ) {
      id
      name
      defaultEligibilityProvider {
        id
        firstName
        lastName
        organizationName
        npi
      }
    }
  }
`;

const CLEAR_PAYER_DEFAULT_ELIGIBILITY_PROVIDER = gql`
  mutation ClearPayerDefaultEligibilityProvider($payerId: String!) {
    updateOnePayer(
      where: { id: $payerId }
      data: { defaultEligibilityProvider: { disconnect: true } }
    ) {
      id
      name
      defaultEligibilityProvider {
        id
        firstName
        lastName
        organizationName
        npi
      }
    }
  }
`;
