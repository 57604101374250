import { DocumentTextIcon, PlusIcon } from "@heroicons/react/outline";
import { formatDistanceToNow, parseISO } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "../../../components";
import { Button } from "../../../components/ui/button";
import { Visit } from "../../../evaluate-rule";
import { GetMaxAllowableRule_maxAllowableRule as MaxAllowableRule } from "../../../generated/GetMaxAllowableRule";
import { formatUSD } from "../../../utils";
import { BenefitMappingBadge } from "../benefit-mappings";
import {
  AttributeDisplay,
  attributeTypeDisplay,
  comparisonDisplay,
} from "../benefit-mappings/show";

export const MaxAllowableRulesList: React.FC<{
  maxAllowableRules: MaxAllowableRule[];
}> = ({ maxAllowableRules }) => {
  return (
    <div className="flex flex-col gap-4" id="max-allowables">
      <div className="flex justify-between w-full">
        <div className="text-lg font-medium pb-2">Max Visit Allowables</div>
        <Button asChild className="bg-indigo-500 hover:bg-indigo-500">
          <Link to="/rules/max-allowables/new">New Rule</Link>
        </Button>
      </div>
      {maxAllowableRules.length === 0 ? (
        <Card>
          <div className="text-center w-full py-2">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No rules
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new rule.
            </p>
            <div className="mt-6">
              <Button asChild className="bg-indigo-500 hover:bg-indigo-500">
                <div>
                  <PlusIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  <Link to="/rules/max-allowables/new">New Rule</Link>
                </div>
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="px-0 py-0">
          <div className="flex flex-col divide-y divide-gray-200 w-full">
            {maxAllowableRules.map((ctm) => {
              return (
                <div className="w-full flex justify-between gap-1 pt-3 p-4">
                  <div className="flex flex-col gap-1 truncate">
                    <div className="text-lg font-medium">{ctm.name}</div>
                    {ctm.notes && (
                      <div className="flex items-center gap-2">
                        <DocumentTextIcon className="w-5 h-5 text-gray-500" />{" "}
                        <div className="italic truncate">{ctm.notes}</div>
                      </div>
                    )}
                    <div>
                      <span className="font-medium">
                        {[
                          ctm.lastUpdatedBy.firstName,
                          ctm.lastUpdatedBy.lastName,
                        ].join(" ")}
                      </span>{" "}
                      modified this rule{" "}
                      {formatDistanceToNow(parseISO(ctm.updatedAt), {
                        addSuffix: true,
                      })}
                    </div>
                    <div className="flex flex-col gap-1">
                      {ctm.maxAllowableRuleConditions.map((condition, j) => (
                        <div className="flex gap-1">
                          <span className="font-medium">
                            {j === 0 ? "If" : "And"}
                          </span>
                          <div
                            key={j}
                            className="flex flex-wrap items-baseline gap-2"
                          >
                            <BenefitMappingBadge>
                              {attributeTypeDisplay(condition.attributeType)}
                            </BenefitMappingBadge>
                            {comparisonDisplay(condition.comparison)}
                            <AttributeDisplay
                              attributeType={
                                condition.attributeType as keyof Visit
                              }
                              attribute={condition.attribute}
                              comparison={condition.comparison}
                            />
                          </div>
                        </div>
                      ))}
                      <div>
                        <span className="font-medium">Then</span> set max
                        allowable to{" "}
                        <BenefitMappingBadge>
                          <>{formatUSD(ctm.maxAllowable)}</>
                        </BenefitMappingBadge>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Link
                      key={ctm.id}
                      to={`/rules/max-allowables/${ctm.id}/edit`}
                      className="bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-indigo-500 hover:bg-indigo-400 cursor-pointer inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      )}
    </div>
  );
};
