import React, { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { useAuth, User } from "./auth-context";
import { Header, Sidebar } from "./components";
import { Settings } from "./pages/settings";
import { Patients } from "./pages/patients";
import { Patient as Profile } from "./pages/patients/profile";
import { NewPatient } from "./pages/patients/new";
import { EditPatient } from "./pages/patients/edit";
import { Providers } from "./pages/providers";
import { NewProvider } from "./pages/providers/new";
// import { FeeSchedules } from "./pages/feeSchedules";
// import { NewFeeSchedule } from "./pages/feeSchedules/new";
import { EditProvider } from "./pages/providers/edit";
import { Eligibility } from "./pages/patients/eligibilities/show";
import { NewEligibility } from "./pages/patients/eligibilities/new";
import { Integrations } from "./pages/integrations";
import { useIntercom } from "react-use-intercom";
import { Payments } from "./pages/payments";
import { BillingPage, Bills, OldBillingPage } from "./pages/billing";
import { BillingGroups } from "./pages/billing-groups";
import { Payers } from "./pages/payers";
import { Messages } from "./pages/messages";
// import { FeeScheduleDisplay } from "./pages/feeSchedules/show";
import { useFeatureFlags } from "./hooks";
import { BillingBatches } from "./pages/billing/batches";
import { PaymentRequestBatch } from "./pages/billing/batches/show";
import { Tasks } from "./pages/tasks";
// import { Worklists } from "./pages/worklists";
import { PatientInsurance } from "./pages/patients/insurances/show";
import { RulesPage } from "./pages/rules";
import { BenefitAssignmentRule } from "./pages/rules/benefit-mappings/show";
import { NewBenefitAssignmentRule } from "./pages/rules/benefit-mappings/new";
import { AppointmentsList } from "./pages/appointments/list";
import { ChargeTemplatesPage } from "./pages/chargemaster/templates";
import { NewChargeTemplateRule } from "./pages/rules/charge-templates/new";
import { EditChargeTemplateRule } from "./pages/rules/charge-templates/edit";
import { FeeSchedules as NewFeeSchedules } from "./pages/fee-schedules";
import { NewFeeSchedule as NewNewFeeSchedule } from "./pages/fee-schedules/new";
import { EditFeeSchedule } from "./pages/fee-schedules/edit";
import { ChargeMaster } from "./pages/chargemaster";
import { VerificationWorkflow } from "./pages/verifications";
import { EstimationWorkflow } from "./pages/estimations";
import { ImperativePanelHandle } from "react-resizable-panels";
import { DashboardResizable } from "./dashboard-resizable";
import { ChargemasterGroupDetails } from "./pages/chargemaster/show";
import { NewFeeScheduleRule } from "./pages/rules/fee-schedules/new";
import { EditFeeScheduleRule } from "./pages/rules/fee-schedules/edit";
import { ChargeTemplateDetails } from "./pages/chargemaster/templates/show";
import { RuleExplorerPage } from "./pages/rules/explorer";
import { NewDepositRule } from "./pages/rules/deposits/new";
import { EditDepositRule } from "./pages/rules/deposits/edit";
import { NewPreVisitRule } from "./pages/rules/pre-visits/new";
import { EditPreVisitRule } from "./pages/rules/pre-visits/edit";
import { TradingPartnerRulesList } from "./pages/rules/trading-partners";
import { NewTradingPartnerRule } from "./pages/rules/trading-partners/new";
import { EditTradingPartnerRule } from "./pages/rules/trading-partners/edit";
import { NewMaxAllowableRule } from "./pages/rules/max-allowables/new";
import { EditMaxAllowableRule } from "./pages/rules/max-allowables/edit";
import {
  CommandBarProvider,
  useCommandBar,
} from "./components/SidebarCommandBar";
import { UniversalCommandBar } from "./components/UniversalCommandBar";

export let routes = [
  // NOTE: temporarily map the / route to the patients page
  // { path: "/", name: "Home", Component: Home },
  { path: "/", name: "Patients", Component: Patients },
  {
    path: "/appointments",
    name: "Appointments",
    Component: AppointmentsList,
  },
  { path: "/billing", name: "Bills", Component: Bills },
  { path: "/billing/new", name: "Bills", Component: BillingPage },
  { path: "/billing/old", name: "Bills", Component: OldBillingPage },
  { path: "/billing/batches", name: "Batches", Component: BillingBatches },
  {
    path: "/billing/batches/:id",
    name: "Batch",
    Component: PaymentRequestBatch,
  },
  { path: "/payments", name: "Payments", Component: Payments },
  { path: "/fee-schedules", name: "Fee Schedules", Component: NewFeeSchedules },
  {
    path: "/fee-schedules/new",
    name: "New Fee Schedules",
    Component: NewNewFeeSchedule,
  },
  {
    path: "/fee-schedules/:id/edit",
    name: "Edit Fee Schedules",
    Component: EditFeeSchedule,
  },
  // { path: "/feeSchedules", name: "Fee Schedules", Component: FeeSchedules },
  // {
  //   path: "/feeSchedules/new",
  //   name: "New Fee Schedule",
  //   Component: NewFeeSchedule,
  // },
  // {
  //   path: "/feeSchedules/:id",
  //   name: "Fee Schedule",
  //   Component: FeeScheduleDisplay,
  // },
  {
    path: "/chargemaster",
    name: "Chargemaster",
    Component: ChargeMaster,
  },
  {
    path: "/chargemaster/:id",
    name: "Chargemaster Details",
    Component: ChargemasterGroupDetails,
  },
  {
    path: "/chargemaster/templates",
    name: "Charge Templates",
    Component: ChargeTemplatesPage,
  },
  {
    path: "/chargemaster/templates/:id",
    name: "Charge Template Details",
    Component: ChargeTemplateDetails,
  },
  {
    path: "/patients/:patientId/eligibilities/new",
    name: "New Eligibility",
    Component: NewEligibility,
  },
  {
    path: "/patients/:patientId/eligibilities/:id",
    name: "Eligibility",
    Component: Eligibility,
  },
  {
    path: "/patients/:patientId/insurances/:insurancePolicyId",
    name: "Insurance",
    Component: PatientInsurance,
  },
  { path: "/patients", name: "Patients", Component: Patients },
  {
    path: "/patients/new",
    name: "New Patient",
    Component: NewPatient,
  },
  // { path: "/patients/:patientId", name: "Patient", Component: Patient },
  { path: "/patients/:patientId", name: "Patient", Component: Profile },
  {
    path: "/patients/:patientId/edit",
    name: "Edit Patient",
    Component: EditPatient,
  },
  { path: "/providers", name: "Providers", Component: Providers },
  {
    path: "/providers/new",
    name: "New Provider",
    Component: NewProvider,
  },
  {
    path: "/providers/:providerId/edit",
    name: "Edit Provider",
    Component: EditProvider,
  },
  { path: "/billing-groups", name: "Billing Groups", Component: BillingGroups },
  { path: "/integrations", name: "Integrations", Component: Integrations },
  { path: "/payers", name: "Payers", Component: Payers },
  { path: "/messages", name: "Messages", Component: Messages },
  { path: "/tasks", name: "Tasks", Component: Tasks },
  {
    path: "/verifications",
    name: "Verifications",
    Component: VerificationWorkflow,
  },
  {
    path: "/estimations",
    name: "Estimations",
    Component: EstimationWorkflow,
  },
  // {
  //   path: "/worklists",
  //   name: "Worklists",
  //   Component: Worklists,
  // },
  { path: "/rules", name: "Rules", Component: RulesPage },
  {
    path: "/rules/explorer",
    name: "Rule Explorer",
    Component: RuleExplorerPage,
  },
  {
    path: "/rules/benefit-mappings/new",
    name: "New Rule",
    Component: NewBenefitAssignmentRule,
  },
  {
    path: "/rules/benefit-mappings/:id/edit",
    name: "Rule",
    Component: BenefitAssignmentRule,
  },
  {
    path: "/rules/charge-templates",
    name: "Charge Templates",
    Component: ChargeTemplatesPage,
  },
  {
    path: "/rules/charge-templates/new",
    name: "New Charge Template Rule",
    Component: NewChargeTemplateRule,
  },
  {
    path: "/rules/charge-templates/:id/edit",
    name: "Edit Charge Template Rule",
    Component: EditChargeTemplateRule,
  },
  {
    path: "/rules/fee-schedules/new",
    name: "New Fee Schedule Rule",
    Component: NewFeeScheduleRule,
  },
  {
    path: "/rules/fee-schedules/:id/edit",
    name: "Edit Fee Schedule Rule",
    Component: EditFeeScheduleRule,
  },
  {
    path: "/rules/deposits/new",
    name: "New Deposit Rule",
    Component: NewDepositRule,
  },
  {
    path: "/rules/deposits/:id/edit",
    name: "Edit Deposit Rule",
    Component: EditDepositRule,
  },
  {
    path: "/rules/pre-visits/new",
    name: "New Pre-Visit Rule",
    Component: NewPreVisitRule,
  },
  {
    path: "/rules/pre-visits/:id/edit",
    name: "Edit Pre-Visit Rule",
    Component: EditPreVisitRule,
  },
  {
    path: "/rules/trading-partners",
    name: "Trading Partner Rules",
    Component: TradingPartnerRulesList,
  },
  {
    path: "/rules/trading-partners/new",
    name: "New Trading Partner Rule",
    Component: NewTradingPartnerRule,
  },
  {
    path: "/rules/trading-partners/:id/edit",
    name: "Edit Trading Partner Rule",
    Component: EditTradingPartnerRule,
  },
  {
    path: "/rules/max-allowables/new",
    name: "New Max Allowable Rule",
    Component: NewMaxAllowableRule,
  },
  {
    path: "/rules/max-allowables/:id/edit",
    name: "Edit Max Allowable Rule",
    Component: EditMaxAllowableRule,
  },
];

export const Dashboard: React.FC<React.PropsWithChildren<{ user: User }>> = ({
  user,
}) => {
  const flags = useFeatureFlags();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = useAuth();
  const { boot } = useIntercom();
  useEffect(() => {
    boot({
      name: user.firstName ?? "there",
      email: user.email ?? undefined,
    });
  }, [user]);

  if (user.isAdmin && !routes.some((route) => route.path === "/settings")) {
    routes.push({
      path: "/settings",
      name: "Settings",
      Component: Settings,
    });
  }

  const ref = useRef<ImperativePanelHandle>(null);

  return (
    <CommandBarProvider>
      <CommandBarInstance user={user} />
      <div
        id="dashboard"
        className="h-screen flex overflow-hidden bg-gray-50 print:h-max print:overflow-visible print:bg-transparent"
      >
        <Sidebar
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          logout={logout}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden print:overflow-visible">
          <Header user={user} logout={logout} setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative overflow-y-auto focus:outline-none print:overflow-visible">
            <DashboardResizable>
              <Routes>
                {routes.map(({ path, Component }, key) => (
                  <Route path={path} key={key} element={<Component />} />
                ))}
              </Routes>
            </DashboardResizable>
          </main>
        </div>
      </div>
    </CommandBarProvider>
  );
};

// Component to render the command bar
const CommandBarInstance: React.FC<{ user: User }> = ({ user }) => {
  const { open, setOpen } = useCommandBar();
  const flags = useFeatureFlags();

  // Don't render command bar if feature flag is disabled
  if (!flags.commandBarEnabled) {
    return null;
  }

  return <UniversalCommandBar open={open} onOpenChange={setOpen} user={user} />;
};
