import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Card, SubmitButton } from "../../../components";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import {
  CreateDepositMapping,
  CreateDepositMappingVariables,
} from "../../../generated/CreateDepositMapping";
import {
  UpdateDepositMapping,
  UpdateDepositMappingVariables,
} from "../../../generated/UpdateDepositMapping";
import { GetDepositMapping_depositMapping as DepositMapping } from "../../../generated/GetDepositMapping";
import { useUser } from "../../../user-context";
import { Button } from "../../../components/ui/button";
import {
  cn,
  isDefined,
  mapNullable,
  toCents,
  toDollarStr,
} from "../../../utils";
import { Textarea } from "../../../components/ui/textarea";
import { useAnalytics } from "../../../analytics-context";
import { Visit } from "../../../evaluate-rule";
import { Comparison } from "../../../generated/globalTypes";
import { COMPARISONS } from "../benefit-mappings/show";
import { DEPOSIT_MAPPING_FIELDS } from "../../../graphql";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select as UiSelect,
  SelectItem as UiSelectItem,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  GetLocationDepositMappings,
  GetLocationDepositMappingsVariables,
} from "../../../generated/GetLocationDepositMappings";
import * as z from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyInput, Input } from "../../../components/ui/input";
import { Form, FormField } from "../../../components/ui/form";
import { AttributeInput } from "../rule-inputs";
import { useNavigate } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";

export const CREATE_DEPOSIT_MAPPING = gql`
  ${DEPOSIT_MAPPING_FIELDS}
  mutation CreateDepositMapping($data: DepositMappingCreateInput!) {
    createOneDepositMapping(data: $data) {
      ...DepositMappingFields
    }
  }
`;

export const UPDATE_DEPOSIT_MAPPING = gql`
  ${DEPOSIT_MAPPING_FIELDS}
  mutation UpdateDepositMapping(
    $id: String!
    $data: DepositMappingUpdateInput!
  ) {
    updateOneDepositMapping(where: { id: $id }, data: $data) {
      ...DepositMappingFields
    }
  }
`;

const DEPOSIT_MAPPING_ATTRIBUTES: {
  label: string;
  value: AttributeTypes;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Appointment Type",
    value: "appointmentType",
  },
  {
    label: "Appointment Label",
    value: "appointmentLabelId",
  },
  {
    label: "Appointment Location",
    value: "appointmentLocationId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Provider",
    value: "providerId",
  },
  {
    label: "Provider Classification",
    value: "providerTaxonomyCodeId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
  {
    label: "Any Policy Payer",
    value: "anyPayerIds",
  },
  {
    label: "Deductible",
    value: "remainingDeductible",
  },
  {
    label: "Out of Pocket",
    value: "remainingOutOfPocket",
  },
  {
    label: "Remaining Visits",
    value: "remainingVisits",
  },
];

const BENEFIT_ATTRIBUTE_TYPES = [
  "remainingDeductible",
  "remainingOutOfPocket",
  "remainingVisits",
];
type AttributeTypes =
  | keyof Visit
  | "remainingDeductible"
  | "remainingOutOfPocket"
  | "remainingVisits";

export type DepositMappingConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (
    ["appointmentType", "appointmentLabelId", "patientLabelId"].includes(
      attributeType
    )
  ) {
    return COMPARISONS;
  }
  if (
    ["remainingDeductible", "remainingOutOfPocket", "remainingVisits"].includes(
      attributeType
    )
  ) {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "does not equal", value: Comparison.NotEquals },
      { label: "is greater than", value: Comparison.GreaterThan },
      {
        label: "is greater than or equal",
        value: Comparison.GreaterThanOrEqual,
      },
      { label: "is less than", value: Comparison.LessThan },
      { label: "is less than or equal", value: Comparison.LessThanOrEqual },
      { label: "is met", value: Comparison.IsMet },
    ];
  }
  // Don't allow contains comparison for ID attributes. Only for free text attributes.
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

const GET_LOCATION_DEPOSIT_MAPPINGS = gql`
  query GetLocationDepositMappings($locationId: String!) {
    depositMappings(where: { locationId: { equals: $locationId } }) {
      id
      name
      notes
      amount
      useCopay
      providerServiceConfiguration {
        id
        name
      }
      depositMappingConditions {
        id
        attributeType
        comparison
        attribute
        providerServiceConfigurationId
      }
    }
  }
`;

const DuplicateDepositMappingDialogButton: React.FC<{
  onSelect: (depositMapping: DepositMapping) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationDepositMappings,
    GetLocationDepositMappingsVariables
  >(GET_LOCATION_DEPOSIT_MAPPINGS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [depositMappingId, setDepositMappingId] = useState<string>();
  const depositMappings = data?.depositMappings ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate an existing Deposit Rule?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={depositMappingId}
                  onValueChange={setDepositMappingId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {depositMappings.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!depositMappingId}
                    onClick={() => {
                      const depositMapping = depositMappings.find(
                        (c) => c.id === depositMappingId
                      );
                      if (depositMapping) {
                        onSelect(depositMapping);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const DEPOSIT_MAPPING_FORM_OPTIONS = gql`
  query DepositMappingFormOptions(
    $locationId: String!
    $organizationId: String!
  ) {
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    patientLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
    appointmentLocations(
      where: { organizationId: { equals: $organizationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

const DepositMappingRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
      providerServiceConfigurationId: z.string().nullable().optional(),
    })
  ),
  amount: z.string().nullable(),
  providerServiceConfigurationId: z.string().nullable().optional(),
});

export const DepositMappingForm: React.FC<{
  depositMapping: DepositMapping | null;
  children: (props: {
    conditions: DepositMappingConditionInput[];
  }) => React.ReactElement;
}> = ({ depositMapping, children }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const [option, setOption] = useState<"fixed" | "copay">("fixed");

  const optionsResult = useQuery(DEPOSIT_MAPPING_FORM_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
      organizationId: user.organization.id,
    },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];
  const appointmentLocations = optionsResult.data?.appointmentLocations ?? [];
  const providerServiceConfigurations =
    user.organization.providerServiceConfiguration;

  const form = useForm<z.infer<typeof DepositMappingRuleForm>>({
    resolver: zodResolver(DepositMappingRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: depositMapping?.id ?? null,
      name: depositMapping?.name,
      notes: depositMapping?.notes,
      conditions: depositMapping?.depositMappingConditions ?? [],
      amount: mapNullable(toDollarStr)(depositMapping?.amount),
      providerServiceConfigurationId:
        depositMapping?.providerServiceConfiguration?.id,
    },
  });
  const conditionsField = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const ruleName = form.watch("name");
  const providerServiceConfigurationId = form.watch(
    "providerServiceConfigurationId"
  );
  const amount = form.watch("amount");
  const conditions = form.watch("conditions");
  const [createDepositMapping, createDepositMappingResult] = useMutation<
    CreateDepositMapping,
    CreateDepositMappingVariables
  >(CREATE_DEPOSIT_MAPPING);
  const [updateDepositMapping, updateDepositMappingResult] = useMutation<
    UpdateDepositMapping,
    UpdateDepositMappingVariables
  >(UPDATE_DEPOSIT_MAPPING);

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison) &&
        // If the condition is a benefit attribute, it must have a provider service configuration
        (!BENEFIT_ATTRIBUTE_TYPES.includes(c.attributeType) ||
          isDefined(c.providerServiceConfigurationId))
    ) &&
    (option === "fixed"
      ? isDefined(amount)
      : isDefined(providerServiceConfigurationId)) &&
    isDefined(ruleName);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          const cents = mapNullable((val: string) =>
            toCents(Number.parseInt(val))
          )(data.amount);

          // Form is disabled if not defined
          if (depositMapping) {
            const toDelete = depositMapping.depositMappingConditions.filter(
              (c) => !data.conditions.some(({ id }) => id === c.id)
            );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            updateDepositMapping({
              variables: {
                id: depositMapping.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  depositMappingConditions: {
                    create: toCreate.map(
                      ({
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                        providerServiceConfiguration:
                          providerServiceConfigurationId
                            ? {
                                connect: {
                                  id: providerServiceConfigurationId,
                                },
                              }
                            : undefined,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({
                        id,
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                          providerServiceConfiguration:
                            providerServiceConfigurationId
                              ? {
                                  connect: {
                                    id: providerServiceConfigurationId,
                                  },
                                }
                              : { disconnect: true },
                        },
                      })
                    ),
                  },
                  ...(option === "fixed"
                    ? {
                        amount: { set: cents },
                        useCopay: { set: false },
                      }
                    : {
                        providerServiceConfiguration: {
                          connect: {
                            id: providerServiceConfigurationId,
                          },
                        },
                        useCopay: { set: true },
                      }),
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Deposit Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createDepositMapping({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  ...(option === "fixed"
                    ? {
                        amount: cents,
                        useCopay: false,
                      }
                    : {
                        providerServiceConfiguration: {
                          connect: {
                            id: providerServiceConfigurationId,
                          },
                        },
                        useCopay: true,
                      }),
                  depositMappingConditions: {
                    create: data.conditions.map(
                      ({
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                        providerServiceConfiguration:
                          providerServiceConfigurationId
                            ? {
                                connect: {
                                  id: providerServiceConfigurationId,
                                },
                              }
                            : undefined,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Rule created");
                analytics?.track("Deposit Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create rule");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicateDepositMappingDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue("amount", mapNullable(toDollarStr)(c.amount));
                form.setValue(
                  "providerServiceConfigurationId",
                  c.providerServiceConfiguration?.id ?? null
                );
                conditionsField.replace(
                  c.depositMappingConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                    providerServiceConfigurationId:
                      condition.providerServiceConfigurationId,
                  }))
                );
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          const isBenefitAttributeType =
                            BENEFIT_ATTRIBUTE_TYPES.includes(attributeType);
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <div
                                    className={cn(
                                      "grid gap-2",
                                      isBenefitAttributeType
                                        ? "grid-cols-2"
                                        : "grid-cols-1"
                                    )}
                                  >
                                    <FormField
                                      control={form.control}
                                      name={`conditions.${index}.attributeType`}
                                      render={({ field }) => (
                                        <UiSelect
                                          onValueChange={field.onChange}
                                          defaultValue={field.value}
                                          {...form.register(
                                            `conditions.${index}.attributeType`
                                          )}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select an attribute" />
                                          </SelectTrigger>
                                          <SelectContent>
                                            <SelectGroup>
                                              {DEPOSIT_MAPPING_ATTRIBUTES.map(
                                                (o) => (
                                                  <UiSelectItem value={o.value}>
                                                    {o.label}
                                                  </UiSelectItem>
                                                )
                                              )}
                                            </SelectGroup>
                                          </SelectContent>
                                        </UiSelect>
                                      )}
                                    />
                                    {isBenefitAttributeType && (
                                      <FormField
                                        control={form.control}
                                        name={`conditions.${index}.providerServiceConfigurationId`}
                                        render={({ field }) => (
                                          <UiSelect
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            {...form.register(
                                              `conditions.${index}.providerServiceConfigurationId`
                                            )}
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Select a benefit" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {providerServiceConfigurations.map(
                                                  (c) => (
                                                    <UiSelectItem value={c.id}>
                                                      {c.name}
                                                    </UiSelectItem>
                                                  )
                                                )}
                                              </SelectGroup>
                                            </SelectContent>
                                          </UiSelect>
                                        )}
                                      />
                                    )}
                                  </div>

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                    appointmentLocations={appointmentLocations}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="pt-4">
                      <div className="text-lg font-semibold">THEN</div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Set Deposit
                        </label>
                        <div className="mt-2 max-w-xl flex flex-col gap-2">
                          <RadioGroup value={option} onChange={setOption}>
                            <RadioGroup.Option value="fixed" itemType="radio">
                              {({ checked }) => (
                                <div className="flex flex-col">
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <span>Fixed Amount</span>
                                  </div>
                                  {checked && (
                                    <div className="py-2 max-w-xl flex justify-between gap-2">
                                      <FormField
                                        control={form.control}
                                        name="amount"
                                        render={({ field }) => (
                                          <CurrencyInput
                                            placeholder="Select a deposit amount"
                                            decimalsLimit={2}
                                            onValueChange={(val) => {
                                              field.onChange(val ?? "");
                                            }}
                                            defaultValue={field.value}
                                            value={field.value}
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </RadioGroup.Option>
                            <RadioGroup.Option value="copay">
                              {({ checked }) => (
                                <div className="flex flex-col">
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <span>Copay</span>
                                  </div>
                                  {checked && (
                                    <div className="py-2 max-w-xl flex justify-between gap-2">
                                      <FormField
                                        control={form.control}
                                        name="providerServiceConfigurationId"
                                        render={({ field }) => (
                                          <UiSelect
                                            {...form.register(
                                              "providerServiceConfigurationId"
                                            )}
                                            defaultValue={field.value}
                                            onValueChange={(value) => {
                                              field.onChange(value);
                                            }}
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Select a benefit" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {providerServiceConfigurations.map(
                                                  (config) => (
                                                    <UiSelectItem
                                                      key={config.id}
                                                      value={config.id}
                                                    >
                                                      {config.name}
                                                    </UiSelectItem>
                                                  )
                                                )}
                                              </SelectGroup>
                                            </SelectContent>
                                          </UiSelect>
                                        )}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </RadioGroup.Option>
                          </RadioGroup>
                        </div>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={() => {}}
                        loading={
                          createDepositMappingResult.loading ||
                          updateDepositMappingResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
