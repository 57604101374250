import React from "react";
import { gql, useApolloClient } from "@apollo/client";
import { InsurancePolicyRegistrationStage } from "../split-pane";
import { CalendarIcon, UserIcon } from "lucide-react";
import {
  differenceInDays,
  format,
  formatDistanceToNow,
  parseISO,
} from "date-fns";
import { Link } from "react-router-dom";
import { Tooltip, Badge } from "../../../components";
import {
  INSURANCE_POLICY_SUMMARY_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  COVERAGE_BENEFIT_FIELDS,
} from "../../../graphql";
import { isDefined, toDateMMDDYYYY } from "../../../utils";
import { BriefcaseIcon, IdentificationIcon } from "@heroicons/react/outline";
import {
  CoveragePriority,
  InsurancePolicyBenefitStatus,
  InsurancePolicyVerificationStatus,
} from "../../../generated/globalTypes";
import {
  RequestEligibility_requestEligibility_eligibilityRequest_insurancePolicy as InsurancePolicy,
  RequestEligibility_requestEligibility_eligibilityRequest_insurancePolicy_eligibilityRequests as EligibilityRequest,
} from "../../../generated/RequestEligibility";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { EligibilityStatusBadge } from "../../appointments";
import { CheckEligibilityRequestStatus } from "../../../generated/CheckEligibilityRequestStatus";

const PolicyStatusCellRow: React.FC<{
  insurancePolicy: InsurancePolicyRowProps;
  patientId: string;
  eligibilityRequest: EligibilityRequest | null;
  onEligibilityRequestCompleted?: (data: CheckEligibilityRequestStatus) => void;
}> = ({
  insurancePolicy,
  patientId,
  eligibilityRequest,
  onEligibilityRequestCompleted,
}) => {
  if (!eligibilityRequest) {
    return (
      <div>
        <Badge text="Unverified" variant="info" />
      </div>
    );
  }

  return (
    <div className="flex gap-2 items-center">
      <EligibilityStatusBadge
        eligibilityRequest={eligibilityRequest}
        patientId={patientId}
        insurancePolicyId={insurancePolicy.id}
        onCompleted={onEligibilityRequestCompleted}
      />
      {eligibilityRequest && (
        <Link
          to={`/patients/${patientId}/eligibilities/${eligibilityRequest.id}`}
          className="text-gray-400 hover:text-gray-300"
          target="_blank"
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </Link>
      )}
    </div>
  );
};

export const REQUEST_ELIGIBILITY = gql`
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  mutation RequestEligibility(
    $insurancePolicyId: String!
    $providerId: String!
    $synchronous: Boolean
  ) {
    requestEligibility(
      insurancePolicyId: $insurancePolicyId
      providerId: $providerId
      synchronous: $synchronous
    ) {
      eligibilityRequest {
        id
        createdAt
        inNetwork
        insurancePolicy {
          id
          ...InsurancePolicySummaryFields
          insurancePolicyVerificationStatus
          insurancePolicyBenefitState {
            status
            needsVerificationReason
          }
          verificationWorkflowStatus {
            id
            name
            stage
          }
          mostRecentCoverageBenefits {
            ...CoverageBenefitFields
          }
          acceptedAt
          acceptedBy {
            id
            firstName
            lastName
          }
          mostRecentEligibilityRequest {
            id
            createdAt
            automated
            rawEligibilityRequestResponses {
              id
              requestedServiceTypeCode
              requestedServiceTypeDisplay
            }
            requestedBy {
              id
              firstName
              lastName
            }
            insurancePolicy {
              id
              payer {
                id
                name
              }
              plan {
                id
                name
              }
              patient {
                id
              }
            }
            ...EligibilityRequestStatusFields
          }
        }
        status
        eligible
        accountCoverage {
          id
          plan {
            id
            name
          }
          payer {
            id
          }
        }
        requestedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const PRIORITY_MAPPING = {
  [CoveragePriority.PRIMARY]: 1,
  [CoveragePriority.SECONDARY]: 2,
  [CoveragePriority.TERTIARY]: 3,
};

export const InsurancePolicyPriorityIcon: React.FC<{
  priority: CoveragePriority;
}> = ({ priority }) => (
  <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md border bg-gray-100 border-gray-50 text-[0.625rem] font-medium text-gray-400">
    {PRIORITY_MAPPING[priority]}
  </span>
);

export type InsurancePolicyRowProps = Pick<
  InsurancePolicy,
  | "id"
  | "createdAt"
  | "effectiveDate"
  | "renewalDate"
  | "terminationDate"
  | "active"
  | "payer"
  | "memberId"
  | "mostRecentEligibilityRequest"
>;

export const InsurancePolicyRow: React.FC<{
  priority: CoveragePriority | null;
  insurancePolicy: InsurancePolicyRowProps;
  patientId: string;
  onEligibilityRequestCompleted?: (data: CheckEligibilityRequestStatus) => void;
}> = ({
  priority,
  insurancePolicy,
  patientId,
  onEligibilityRequestCompleted,
}) => {
  const hasPlanDates =
    isDefined(insurancePolicy.effectiveDate) ||
    isDefined(insurancePolicy.renewalDate) ||
    isDefined(insurancePolicy.terminationDate);
  const planDates = [
    insurancePolicy.effectiveDate
      ? toDateMMDDYYYY(insurancePolicy.effectiveDate)
      : "NA",
    insurancePolicy.active
      ? insurancePolicy.renewalDate
        ? toDateMMDDYYYY(insurancePolicy.renewalDate)
        : "NA"
      : insurancePolicy.terminationDate
      ? toDateMMDDYYYY(insurancePolicy.terminationDate)
      : "NA",
  ].join("-");
  const daysSinceCreation = differenceInDays(
    new Date(),
    parseISO(insurancePolicy.createdAt)
  );
  const mostRecentEligibilityRequest =
    insurancePolicy.mostRecentEligibilityRequest;
  return (
    <div>
      <div className="flex justify-between gap-2">
        <div className="flex flex-col items-start w-full">
          <div className="flex justify-between w-full">
            <div className="flex gap-1 items-center w-full">
              {priority && <InsurancePolicyPriorityIcon priority={priority} />}
              <div className="font-medium truncate">
                <Link
                  to={`/patients/${patientId}/insurances/${insurancePolicy.id}`}
                  className="hover:text-gray-700"
                >
                  {insurancePolicy.payer.name}
                </Link>
              </div>
            </div>

            <div className="flex gap-1">
              {daysSinceCreation < 30 && (
                <Tooltip
                  trigger={
                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      New
                    </span>
                  }
                  content={
                    <>
                      Created{" "}
                      {formatDistanceToNow(
                        parseISO(insurancePolicy.createdAt),
                        {
                          addSuffix: true,
                        }
                      )}
                    </>
                  }
                />
              )}
              <PolicyStatusCellRow
                eligibilityRequest={mostRecentEligibilityRequest ?? null}
                insurancePolicy={insurancePolicy}
                patientId={patientId}
                onEligibilityRequestCompleted={onEligibilityRequestCompleted}
              />
            </div>
          </div>

          <div className="flex items-center flex-wrap gap-1 text-gray-700 text-sm">
            <div className="flex items-center gap-1">
              <Tooltip
                trigger={<IdentificationIcon className="h-4" />}
                content={<>Member ID</>}
              />
              {insurancePolicy.memberId ? (
                insurancePolicy.memberId
              ) : (
                <div className="text-gray-500 italic">Empty</div>
              )}
            </div>
            {hasPlanDates && (
              <div className="flex items-center gap-1">
                <Tooltip
                  trigger={<CalendarIcon className="h-4" />}
                  content={<>Plan Dates</>}
                />
                {planDates}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AppointmentDisplay: React.FC<{
  appointment: {
    id: string;
    start: string;
    end?: string;
    provider?: { displayName: string };
    account: {
      accountType: { name: string };
    };
    appointmentLabelings: {
      id: string;
      appointmentLabel: {
        id: string;
        name: string;
      };
    }[];
  };
  Adornment?: React.ReactNode;
}> = ({ appointment, Adornment }) => {
  return (
    <div key={appointment.id}>
      <div className="flex flex-col text-base text-gray-900 truncate">
        <div className="flex items-center gap-2">
          <div>
            {format(parseISO(appointment.start), "MMM do h:mm aa")}
            {isDefined(appointment.end) &&
              `- ${format(parseISO(appointment.end), "h:mm aa")}`}
          </div>
          {Adornment}
        </div>
        <div className="flex flex-wrap items-center gap-1">
          {appointment.provider && (
            <div className="flex items-center gap-1 text-xs">
              <UserIcon className="h-4" />
              <span className="text-ellipsis truncate">
                {appointment.provider?.displayName}
              </span>
            </div>
          )}
          {appointment.account.accountType && (
            <div className="flex items-center gap-1 text-xs">
              <BriefcaseIcon className="h-4" />
              <span className="text-ellipsis truncate">
                {appointment.account.accountType.name}
              </span>
            </div>
          )}
          {appointment.appointmentLabelings.length > 0 && (
            <div className="flex items-center gap-1 text-xs">
              <CalendarIcon className="h-4" />
              <span className="text-ellipsis truncate">
                {appointment.appointmentLabelings
                  .map((labeling) => labeling.appointmentLabel.name)
                  .join(", ")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
