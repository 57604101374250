import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { DataTableFacetedFilter } from "../../components/ui/table-helpers/data-table-faceted-filter";
import { DataTableViewOptions } from "../../components/ui/table-helpers/data-table-view-options";
import { HorizontalPadding } from "../layout";
import {
  ChargemasterRow,
  ImportChargemasterDialog,
  NewChargemasterDialog,
} from "./list";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const ARCHIVED_OPTIONS = [
  { label: "Active", value: "Active" },
  { label: "Stale", value: "Stale" },
  { label: "Archived", value: "Archived" },
];

const CASH_PAY_OPTIOONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<ChargemasterRow>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const chargemasterGroups = table
    .getFilteredRowModel()
    .rows.map((r) => r.original.chargemasterGroup);

  return (
    <HorizontalPadding>
      <div className="flex items-start justify-between gap-2 w-full">
        <div className="flex flex-1 items-center flex-wrap gap-2">
          <Input
            placeholder="Search charges"
            value={table.getState().globalFilter ?? ""}
            onChange={(event) => table.setGlobalFilter(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
          {table.getColumn("archived") && (
            <DataTableFacetedFilter
              column={table.getColumn("archived")}
              title="Archived"
              options={ARCHIVED_OPTIONS}
            />
          )}
          {table.getColumn("cashPay") && (
            <DataTableFacetedFilter
              column={table.getColumn("cashPay")}
              title="Cash Only"
              options={CASH_PAY_OPTIOONS}
            />
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <div className="flex gap-2 items-center">
          {/* <ImportChargemasterDialog chargemasterGroups={chargemasterGroups} /> */}
          <NewChargemasterDialog />
          <DataTableViewOptions table={table} />
        </div>
      </div>
    </HorizontalPadding>
  );
}
