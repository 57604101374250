import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  AdjustmentsIcon,
  BanIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronRightIcon,
  CogIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  DownloadIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  ExternalLinkIcon,
  EyeOffIcon,
  FastForwardIcon,
  InformationCircleIcon,
  LinkIcon,
  LockClosedIcon,
  MailIcon,
  PencilAltIcon,
  PlusIcon,
  PrinterIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import {
  AlertCircle,
  HistoryIcon,
  LayoutPanelTop,
  Loader2,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";

import { Disclosure, RadioGroup, Transition } from "@headlessui/react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import * as HoverCard from "@radix-ui/react-hover-card";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BadgeDelta, NumberInput, TextInput } from "@tremor/react";
import { compareDesc, format, formatDistanceToNow, parseISO } from "date-fns";
import fuzzysort from "fuzzysort";
import { CheckCircle, CircleIcon } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { toast } from "react-toastify";
import { useAnalytics } from "../../../analytics-context";
import {
  Card,
  Modal,
  ProgressBar,
  SubmitButton,
  Tooltip,
} from "../../../components";
import { Circle } from "../../../components/icons";
import { OvalSpinner } from "../../../components/loading";
import { MultiProgressBar } from "../../../components/ProgressBar";
import { Button, ButtonVariant } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../../components/ui/dropdown-menu";
import { Input } from "../../../components/ui/input";
import {
  AcceptInsurancePolicy,
  AcceptInsurancePolicyVariables,
} from "../../../generated/AcceptInsurancePolicy";
import {
  AddInsurancePolicyCustomPatientNote,
  AddInsurancePolicyCustomPatientNoteVariables,
} from "../../../generated/AddInsurancePolicyCustomPatientNote";
import {
  CreateNewCoverageBenefit,
  CreateNewCoverageBenefitVariables,
} from "../../../generated/CreateNewCoverageBenefit";
import { EligibilityRequestStatusFields } from "../../../generated/EligibilityRequestStatusFields";
import {
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_CoinsuranceBenefit as CoinsuranceBenefit,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_CopayBenefit as CopayBenefit,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_DeductibleBenefit as DeductibleBenefit,
  GetBenefitsForProviderServiceConfiguration,
  GetBenefitsForProviderServiceConfigurationVariables,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_LimitationBenefit as LimitationBenefit,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_OtherBenefit as OtherBenefit,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_OutOfPocketBenefit as OutOfPocketBenefit,
  GetBenefitsForProviderServiceConfiguration_getBenefitsForProviderServiceConfiguration_benefit_LimitationBenefit as VisitsBenefit,
} from "../../../generated/GetBenefitsForProviderServiceConfiguration";
import {
  GetCoverageBenefitHistory,
  GetCoverageBenefitHistoryVariables,
  GetCoverageBenefitHistory_coverageBenefits as HistoryCoverageBenefit,
} from "../../../generated/GetCoverageBenefitHistory";
import {
  GetEligibilityRequestDetails_eligibilityRequest as EligibilityRequestDetails,
  GetEligibilityRequestDetails,
  GetEligibilityRequestDetailsVariables,
  GetEligibilityRequestDetails_eligibilityRequest_rawEligibilityRequestResponses as RawEligibilityRequestResponse,
} from "../../../generated/GetEligibilityRequestDetails";
import {
  GetInsurancePolicy_insurancePolicy_inNetworkBenefits_appliedBenefitAccumulatorAdjustments as BenefitAccumulatorAdjustment,
  GetInsurancePolicy_insurancePolicy_mostRecentCoverageBenefits as CoverageBenefit,
  GetInsurancePolicy_insurancePolicy_eligibilityRequests as EligibilityRequest,
  GetInsurancePolicy,
  GetInsurancePolicyVariables,
  GetInsurancePolicy_insurancePolicy as InsurancePolicy,
  GetInsurancePolicy_insurancePolicy_patient_organization_providerServiceConfiguration as ProviderServiceConfiguration,
  GetInsurancePolicy_insurancePolicy_renewalResets as RenewalReset,
} from "../../../generated/GetInsurancePolicy";
import {
  GetMostRecentEligibilityForInsurancePolicy,
  GetMostRecentEligibilityForInsurancePolicyVariables,
} from "../../../generated/GetMostRecentEligibilityForInsurancePolicy";
import {
  CoverageBenefitCreateInput,
  NetworkStatus,
} from "../../../generated/globalTypes";
import {
  RestorePreviousCoverageBenefits,
  RestorePreviousCoverageBenefitsVariables,
} from "../../../generated/RestorePreviousCoverageBenefits";
import {
  SendBenefits,
  SendBenefitsVariables,
} from "../../../generated/SendBenefits";
import {
  SendBenefitsToLoggedInUser,
  SendBenefitsToLoggedInUserVariables,
} from "../../../generated/SendBenefitsToLoggedInUser";
import {
  StartTrackingCoverageBenefits,
  StartTrackingCoverageBenefitsVariables,
} from "../../../generated/StartTrackingCoverageBenefits";
import {
  UnacceptInsurancePolicy,
  UnacceptInsurancePolicyVariables,
} from "../../../generated/UnacceptInsurancePolicy";
import {
  COVERAGE_BENEFIT_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  INSURANCE_POLICY_SUMMARY_FIELDS,
  PATIENT_HEADER_FIELDS,
} from "../../../graphql";
import { useFeatureFlags, useQueryParams } from "../../../hooks";
import { useEligibilityStatusPolling } from "../../../hooks/use-eligibility-status-polling";
import { useUser } from "../../../user-context";
import {
  classNames,
  cn,
  decimalToPercentage,
  formatDateMMDDYYYY,
  formatPercentage,
  formatUSD,
  isDefined,
  mapNullable,
  pascalCaseToReadable,
  percentageToDecimal,
  toCents,
  toDollars,
} from "../../../utils";
import { Layout, LoadingLayout } from "../../layout";
import { InsurancePolicyWorkflowButton } from "../../shared/insurance-policy-workflow-button";
import { GET_PATIENT_INSURANCE_DETAILS } from "../coverage-benefit-display";
import { BenefitsPDF } from "../eligibilities/benefits-pdf";
import { EligibilityBadge } from "../eligibilities/eligibilityBadge";
import {
  SEND_BENEFITS,
  SEND_BENEFITS_TO_LOGGED_IN_USER,
  isAuthRequired,
} from "../eligibilities/show";
import {
  BenefitCode,
  CoverageLevel,
  PlaceOfService,
  ServiceTypeCode,
  placeOfServiceCodeToName,
  serviceTypeCodeToName,
} from "../eligibilities/types";
import { InsurancePolicySummary } from "../insurance-policy-summary";
import { PatientHeader } from "../patient-header";
import { LookupUHCBenefitsDialog } from "./uhc-portal-side-panel";

export enum CoverageBenefitType {
  Coinsurance = "Coinsurance",
  Copay = "Copay",
  RemainingDeductible = "RemainingDeductible",
  MaxDeductible = "MaxDeductible",
  RemainingOutOfPocket = "RemainingOutOfPocket",
  MaxOutOfPocket = "MaxOutOfPocket",
  RemainingVisits = "RemainingVisits",
  MaxVisits = "MaxVisits",
  NonCovered = "NonCovered",
  ActiveCoverage = "ActiveCoverage",
}

export const START_TRACKING_COVERAGE_BENEFITS = gql`
  ${COVERAGE_BENEFIT_FIELDS}
  mutation StartTrackingCoverageBenefits($insurancePolicyId: String!) {
    startTrackingCoverageBenefits(id: $insurancePolicyId) {
      id
      insurancePolicyBenefitState {
        status
        needsVerificationReason
      }
      insurancePolicyVerificationStatus
      mostRecentCoverageBenefits {
        ...CoverageBenefitFields
      }
    }
  }
`;

export const CoverageBenefitsDisplay: React.FC<
  React.PropsWithChildren<{
    providerServiceConfigurations: ProviderServiceConfiguration[];
    insurancePolicy: InsurancePolicy;
    inNetwork: boolean;
  }>
> = ({ providerServiceConfigurations, insurancePolicy, inNetwork }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [startTrackingCoverageBenefits, startTrackingCoverageBenefitsResult] =
    useMutation<
      StartTrackingCoverageBenefits,
      StartTrackingCoverageBenefitsVariables
    >(START_TRACKING_COVERAGE_BENEFITS);
  const eligibilityEnabled = !!insurancePolicy.payer?.eligibilityEnabled;

  const healthPlanCoverageBenefits = inNetwork
    ? {
        individual:
          insurancePolicy.planCoverageBenefit.inNetworkIndividualBenefit,
        family: insurancePolicy.planCoverageBenefit.inNetworkFamilyBenefit,
      }
    : {
        individual:
          insurancePolicy.planCoverageBenefit.outOfNetworkIndividualBenefit,
        family: insurancePolicy.planCoverageBenefit.outOfNetworkFamilyBenefit,
      };

  const mostRecentCoverageBenefits = inNetwork
    ? insurancePolicy.inNetworkBenefits
    : insurancePolicy.outOfNetworkBenefits;

  const noBenefitsTracked =
    mostRecentCoverageBenefits.length === 0 &&
    healthPlanCoverageBenefits.individual == null &&
    healthPlanCoverageBenefits.family == null;

  // If no benefits trcked
  if (noBenefitsTracked) {
    return (
      <div className="text-center pb-4">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          No benefits tracked
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by running an eligibilty check or start tracking manually.
        </p>
        <div className="flex flex-col items-center gap-2 mt-4">
          {eligibilityEnabled && (
            <>
              <Link
                to={`/patients/${insurancePolicy.patient.id}/eligibilities/new`}
                state={{
                  policy: insurancePolicy.id,
                }}
                className="flex flex-shrink-0 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Check Eligibility
              </Link>
              <div className="text-gray-500 text-sm">OR</div>
            </>
          )}
          <SubmitButton
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              startTrackingCoverageBenefits({
                variables: {
                  insurancePolicyId: insurancePolicy.id,
                },
                onCompleted: () => {
                  toast.success("Started tracking benefits");
                  analytics?.track("Benefits Manually Tracked", {
                    organizationId: user.organization.id,
                    organizationName: user.organization.name,
                    locationId: user.activeLocation.id,
                    locationName: user.activeLocation.name,
                  });
                },
                onError: () => {
                  toast.error("Failed to start tracking benefits");
                },
                refetchQueries: [
                  GET_INSURANCE_POLICY,
                  GET_PATIENT_INSURANCE_DETAILS,
                  GET_COVERAGE_BENEFIT_HISTORY,
                ],
              });
            }}
            loading={startTrackingCoverageBenefitsResult.loading}
          >
            Start tracking benefits manually
          </SubmitButton>
        </div>
      </div>
    );
  }

  const servicesWithBenefits = providerServiceConfigurations.map((config) => {
    const coverageBenefit = mostRecentCoverageBenefits.find(
      (cb) => cb.providerServiceConfiguration.id === config.id
    );
    return {
      ...config,
      coverageBenefit,
    };
  });

  const healthPlanService = servicesWithBenefits.find(
    (config) => config.serviceType === ServiceTypeCode.HealthBenefitPlanCoverage
  );
  const nonHealthPlanServices = servicesWithBenefits.filter(
    (config) => config.serviceType !== ServiceTypeCode.HealthBenefitPlanCoverage
  );
  return (
    <div className="flex flex-col w-full">
      <div className="divide-y divide-gray-200 gap-1">
        {healthPlanService && (
          <HealthPlanBenefitRow
            config={{
              ...healthPlanService,
              individual: healthPlanCoverageBenefits.individual,
              family: healthPlanCoverageBenefits.family,
            }}
            insurancePolicy={insurancePolicy}
            inNetwork={inNetwork}
          />
        )}
        {nonHealthPlanServices
          .sort(
            (a, b) =>
              (a.priorityOrder ?? Infinity) - (b.priorityOrder ?? Infinity)
          )
          .map((service) => (
            <ServiceBenefitRow
              config={service as any}
              insurancePolicy={insurancePolicy}
              inNetwork={inNetwork}
            />
          ))}
      </div>
    </div>
  );
};

export const ACCEPT_INSURANCE_POLICY = gql`
  mutation AcceptInsurancePolicy($insurancePolicyId: String!) {
    acceptInsurancePolicy(insurancePolicyId: $insurancePolicyId) {
      id
      verificationWorkflowStatusId
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt
      insurancePolicyBenefitState {
        status
        needsVerificationReason
      }
      insurancePolicyVerificationStatus
      acceptedAt
      acceptedBy {
        id
        firstName
        lastName
      }
      verificationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

export const UNACCEPT_INSURANCE_POLICY = gql`
  mutation UnacceptInsurancePolicy($insurancePolicyId: String!) {
    unacceptInsurancePolicy(insurancePolicyId: $insurancePolicyId) {
      id
      verificationWorkflowStatusId
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt
      insurancePolicyBenefitState {
        status
        needsVerificationReason
      }
      insurancePolicyVerificationStatus
      acceptedAt
      acceptedBy {
        id
        firstName
        lastName
      }
      verificationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const CREATE_NEW_COVERAGE_BENEFIT = gql`
  mutation CreateNewCoverageBenefit($data: CoverageBenefitCreateInput!) {
    createNewCoverageBenefit(data: $data) {
      id
    }
  }
`;

export const GET_BENEFITS_FOR_PROVIDER_SERVICE_CONFIGURATION = gql`
  query GetBenefitsForProviderServiceConfiguration(
    $insurancePolicyId: String!
    $benefitType: String!
    $providerServiceConfigurationId: String!
  ) {
    getBenefitsForProviderServiceConfiguration(
      insurancePolicyId: $insurancePolicyId
      benefitType: $benefitType
      providerServiceConfigurationId: $providerServiceConfigurationId
    ) {
      match
      benefit {
        ... on DeductibleBenefit {
          id
          max
          remaining
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
        ... on OutOfPocketBenefit {
          id
          code
          max
          remaining
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
        ... on CoinsuranceBenefit {
          id
          code
          percentage
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
        ... on CopayBenefit {
          id
          code
          amount
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
        ... on LimitationBenefit {
          id
          code
          max
          remaining
          timePeriodQualifier
          quantityQualifier
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
        ... on OtherBenefit {
          id
          code
          timePeriodQualifier
          quantityQualifier
          coverageLevel
          serviceType
          serviceTypeDisplay
          inNetwork
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          eligibilityRequestId
        }
      }
    }
  }
`;

export type CommonBenefit =
  | CopayBenefit
  | CoinsuranceBenefit
  | DeductibleBenefit
  | OutOfPocketBenefit
  | LimitationBenefit
  | OtherBenefit;

type Form = {
  copay: number | null;
  coinsurance: string | null;
  remainingDeductible: number | null;
  maxDeductible: number | null;
  remainingOutOfPocket: number | null;
  maxOutOfPocket: number | null;
  remainingVisits: number | null;
  maxVisits: number | null;
};

// mapNullable, but also map empty string to null
const mapNullableInput =
  <T, U>(fn: (inner: T) => U) =>
  (inner: T | null | undefined) =>
    inner === "" ? null : mapNullable(fn)(inner);

type ServiceBenefitEditInsurancePolicy = Pick<InsurancePolicy, "id">;

type CostSharingType = "copay" | "coinsurance" | "both" | "unknown";

/**
 * Determines if the CoverageBenefit is a copay plan, coinsurance plan, or unknown
 */
export const getCostSharingType = (
  coverageBenefit: Pick<CoverageBenefit, "copay" | "coinsurance">
): CostSharingType => {
  if (
    isDefined(coverageBenefit.copay) &&
    isDefined(coverageBenefit.coinsurance) &&
    coverageBenefit.copay !== 0 &&
    coverageBenefit.coinsurance !== 0
  ) {
    return "both";
  }
  if (isDefined(coverageBenefit.copay)) {
    // If copay is non-zero, then it is a copay plan
    if (coverageBenefit.copay !== 0) return "copay";
    // If copay is zero, then it is a coinsurance plan if coinsurance is non-zero
    if (
      isDefined(coverageBenefit.coinsurance) &&
      coverageBenefit.coinsurance !== 0
    )
      return "coinsurance";
    // If copay is zero and coinsurance is zero, then consider it a copay plan
    return "copay";
  }
  if (isDefined(coverageBenefit.coinsurance)) {
    // If coinsurance is non-zero, then it is a coinsurance plan
    if (coverageBenefit.coinsurance !== 0) return "coinsurance";
    // If coinsurance is zero, then it is a copay plan if copay is non-zero
    if (isDefined(coverageBenefit?.copay) && coverageBenefit.copay !== 0)
      return "copay";
    return "coinsurance";
  }
  return "unknown";
};

type MostRecentEligibility = Pick<
  EligibilityRequest,
  "id" | "createdAt" | "requestedBy" | "automated"
> &
  Omit<EligibilityRequestStatusFields, "deduplicatedErrors">;

const GET_MOST_RECENT_ELIGIBILITY_FOR_INSURANCE_POLICY = gql`
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  query GetMostRecentEligibilityForInsurancePolicy(
    $insurancePolicyId: String!
  ) {
    insurancePolicy(where: { id: $insurancePolicyId }) {
      id
      eligibilityRequests(orderBy: { createdAt: desc }, take: 1) {
        id
        createdAt
        requestedBy {
          id
          firstName
          lastName
        }
        automated
        ...EligibilityRequestStatusFields
      }
    }
  }
`;

const ServiceBenefitEditModal: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    name: string;
    coverageBenefit: CoverageBenefit | null;
    patientId: string;
    insurancePolicy: ServiceBenefitEditInsurancePolicy;
    providerServiceConfigurationId: string;
    inNetwork: boolean;
    // Whether this is editing the health plan benfits
    isHealthPlan: boolean;
    // The coverage level the benefit is at, e.g. "IND" or "FAM"
    coverageLevel?: CoverageLevel;
    benefitAdjustments?: BenefitAdjustments;
  }>
> = ({
  open,
  setOpen,
  name,
  coverageBenefit,
  patientId,
  insurancePolicy,
  providerServiceConfigurationId,
  inNetwork,
  isHealthPlan,
  coverageLevel,
  benefitAdjustments,
}) => {
  const user = useUser()!;
  const [createCoverageBenefit, createCoverageBenefitResult] = useMutation<
    CreateNewCoverageBenefit,
    CreateNewCoverageBenefitVariables
  >(CREATE_NEW_COVERAGE_BENEFIT);
  const { data, loading } = useQuery<
    GetMostRecentEligibilityForInsurancePolicy,
    GetMostRecentEligibilityForInsurancePolicyVariables
  >(GET_MOST_RECENT_ELIGIBILITY_FOR_INSURANCE_POLICY, {
    variables: {
      insurancePolicyId: insurancePolicy.id,
    },
  });
  const mostRecentEligibility =
    data?.insurancePolicy?.eligibilityRequests?.at(0) ?? null;

  const methods = useForm<Form>();

  const defaultCopay = mapNullable(toDollars)(coverageBenefit?.copay);
  const defaultCoinsurance = mapNullable(decimalToPercentage)(
    coverageBenefit?.coinsurance
  );
  const defaultDeductibleApplies = coverageBenefit?.deductibleApplies;
  const defaultRemainingDeductible = mapNullable((val: number) =>
    toDollars(Math.max(0, val - (benefitAdjustments?.appliedDeductible ?? 0)))
  )(coverageBenefit?.remainingDeductible);
  const defaultMaxDeductible = mapNullable(toDollars)(
    coverageBenefit?.maxDeductible
  );
  const defaultRemainingOutOfPocket = mapNullable((val: number) =>
    toDollars(Math.max(0, val - (benefitAdjustments?.appliedOutOfPocket ?? 0)))
  )(coverageBenefit?.remainingOutOfPocket);
  const defaultMaxOutOfPocket = mapNullable(toDollars)(
    coverageBenefit?.maxOutOfPocket
  );
  const defaultRemainingVisits = isDefined(coverageBenefit?.remainingVisits)
    ? coverageBenefit.remainingVisits - (benefitAdjustments?.appliedVisits ?? 0)
    : coverageBenefit?.remainingVisits;
  const defaultMaxVisits = coverageBenefit?.maxVisits;

  const [copayBenefitId, setCopayBenefitId] = useState(
    coverageBenefit?.copayBenefit?.id ?? null
  );
  const [coinsuranceBenefitId, setCoinsuranceBenefitId] = useState(
    coverageBenefit?.coinsuranceBenefit?.id ?? null
  );
  const [remainingDeductibleBenefitId, setRemainingDeductibleBenefitId] =
    useState(coverageBenefit?.remainingDeductibleBenefit?.id ?? null);
  const [maxDeductibleBenefitId, setMaxDeductibleBenefitId] = useState(
    coverageBenefit?.maxDeductibleBenefit?.id ?? null
  );
  const [remainingOutOfPocketBenefitId, setRemainingOutOfPocketBenefitId] =
    useState(coverageBenefit?.remainingOutOfPocketBenefit?.id ?? null);
  const [maxOutOfPocketBenefitId, setMaxOutOfPocketBenefitId] = useState(
    coverageBenefit?.maxOutOfPocketBenefit?.id ?? null
  );
  const [remainingVisitsBenefitId, setRemainingVisitsBenefitId] = useState(
    coverageBenefit?.remainingVisitsBenefit?.id ?? null
  );
  const [maxVisitsBenefitId, setMaxVisitsBenefitId] = useState(
    coverageBenefit?.maxVisitsBenefit?.id ?? null
  );
  const [nonCoveredBenefitId, setNonCoveredBenefitId] = useState(
    coverageBenefit?.nonCoveredBenefit?.id ?? null
  );
  const [activeCoverageBenefitId, setActiveCoverageBenefitId] = useState(
    coverageBenefit?.activeCoverageBenefit?.id ?? null
  );
  const [query, setQuery] = useState("");

  const [hidden, setHidden] = useState(coverageBenefit?.hidden);
  const [costSharingStatus, setCostSharingStatus] = useState<
    "copay" | "coinsurance" | "both" | "none" | "unknown"
  >(
    isDefined(coverageBenefit) ? getCostSharingType(coverageBenefit) : "unknown"
  );
  const [coveredStatus, setCoveredStatus] = useState<"yes" | "no" | "unknown">(
    isDefined(coverageBenefit?.nonCovered)
      ? coverageBenefit?.nonCovered
        ? "no"
        : "yes"
      : "unknown"
  );
  const [deductibleAppliesStatus, setDeductibleAppliesStatus] = useState<
    "yes" | "no" | "unknown"
  >(
    isHealthPlan
      ? "yes"
      : isDefined(coverageBenefit?.deductibleApplies)
      ? coverageBenefit?.deductibleApplies
        ? "yes"
        : "no"
      : "unknown"
  );
  // If editing Health Plan, consider it the "service" level
  const [deductibleLevel, setDeductibleLevel] = useState<
    "plan" | "service" | null
  >(
    deductibleAppliesStatus === "yes"
      ? isHealthPlan ||
        coverageBenefit?.remainingDeductible ||
        coverageBenefit?.maxDeductible
        ? "service"
        : "plan"
      : null
  );
  // If editing Health Plan, consider it the "service" level
  const [oopLevel, setOopLevel] = useState<"plan" | "service" | null>(
    isHealthPlan ||
      coverageBenefit?.remainingOutOfPocket ||
      coverageBenefit?.maxOutOfPocket
      ? "service"
      : "plan"
  );
  const [authRequiredStatus, setAuthRequiredStatus] = useState<
    "yes" | "no" | "unknown"
  >(
    isDefined(coverageBenefit?.authRequired)
      ? coverageBenefit?.authRequired
        ? "yes"
        : "no"
      : "unknown"
  );

  const [locked, setLocked] = useState(coverageBenefit?.locked ?? false);

  const onSubmit = (data: Form) => {
    const deductibleApplies = deductibleAppliesStatus !== "no";

    let params: CoverageBenefitCreateInput = {
      createdBy: {
        connect: {
          id: user.id,
        },
      },
      providerServiceConfiguration: {
        connect: {
          id: providerServiceConfigurationId,
        },
      },
      insurancePolicy: {
        connect: {
          id: insurancePolicy.id,
        },
      },
      empty: false,
      nonCovered: false,
      networkStatus: inNetwork,
      hidden,
      coverageLevel,
    };

    if (coveredStatus === "no") {
      params = {
        ...params,
        nonCovered: true,
      };
    } else {
      params = {
        ...params,
        ...(costSharingStatus === "copay" || costSharingStatus === "both"
          ? {
              copay: mapNullableInput(toCents)(data.copay),
              copayBenefit:
                isDefined(data.copay) && isDefined(copayBenefitId)
                  ? {
                      connect: { id: copayBenefitId },
                    }
                  : undefined,
            }
          : {}),
        ...(costSharingStatus === "coinsurance" || costSharingStatus === "both"
          ? {
              coinsurance: mapNullableInput(percentageToDecimal)(
                data.coinsurance
              ),
              coinsuranceBenefit:
                isDefined(data.coinsurance) && isDefined(coinsuranceBenefitId)
                  ? {
                      connect: { id: coinsuranceBenefitId },
                    }
                  : undefined,
            }
          : {}),
        ...(deductibleApplies
          ? {
              deductibleApplies:
                deductibleAppliesStatus === "yes" ? true : undefined,
              remainingDeductible:
                deductibleLevel === "plan"
                  ? null
                  : mapNullableInput(toCents)(data.remainingDeductible),
              remainingDeductibleBenefit:
                deductibleLevel === "plan"
                  ? undefined
                  : isDefined(data.remainingDeductible) &&
                    isDefined(remainingDeductibleBenefitId)
                  ? {
                      connect: { id: remainingDeductibleBenefitId },
                    }
                  : undefined,
              maxDeductible:
                deductibleLevel === "plan"
                  ? null
                  : mapNullableInput(toCents)(data.maxDeductible),
              maxDeductibleBenefit:
                deductibleLevel === "plan"
                  ? undefined
                  : isDefined(data.maxDeductible) &&
                    isDefined(maxDeductibleBenefitId)
                  ? {
                      connect: { id: maxDeductibleBenefitId },
                    }
                  : undefined,
            }
          : {
              deductibleApplies: false,
            }),
        remainingOutOfPocket:
          oopLevel === "plan"
            ? null
            : mapNullableInput(toCents)(data.remainingOutOfPocket),
        remainingOutOfPocketBenefit:
          oopLevel === "plan"
            ? undefined
            : isDefined(data.remainingOutOfPocket) &&
              isDefined(remainingOutOfPocketBenefitId)
            ? {
                connect: { id: remainingOutOfPocketBenefitId },
              }
            : undefined,
        maxOutOfPocket:
          oopLevel === "plan"
            ? null
            : mapNullableInput(toCents)(data.maxOutOfPocket),
        maxOutOfPocketBenefit:
          oopLevel === "plan"
            ? undefined
            : isDefined(data.maxOutOfPocket) &&
              isDefined(maxOutOfPocketBenefitId)
            ? {
                connect: { id: maxOutOfPocketBenefitId },
              }
            : undefined,
        remainingVisits: mapNullableInput(parseInt)(
          data.remainingVisits as any
        ),
        remainingVisitsBenefit:
          isDefined(data.remainingVisits) && isDefined(remainingVisitsBenefitId)
            ? {
                connect: { id: remainingVisitsBenefitId },
              }
            : undefined,
        maxVisits: mapNullableInput(parseInt)(data.maxVisits as any),
        maxVisitsBenefit:
          isDefined(data.maxVisits) && isDefined(maxVisitsBenefitId)
            ? {
                connect: { id: maxVisitsBenefitId },
              }
            : undefined,
        authRequired:
          authRequiredStatus === "yes"
            ? true
            : authRequiredStatus === "no"
            ? false
            : undefined,
        locked,
        // If the data for any remaining values have been updated for a template benefit, make sure to connect the template benefit
        ...(coverageBenefit?.planBenefitTemplate?.id &&
        ((data.remainingOutOfPocket &&
          data.remainingOutOfPocket !==
            coverageBenefit?.remainingOutOfPocket) ||
          (data.remainingDeductible &&
            data.remainingDeductible !==
              coverageBenefit?.remainingDeductible) ||
          (data.remainingVisits &&
            data.remainingVisits !== coverageBenefit?.remainingVisits))
          ? {
              planBenefitTemplate: {
                connect: {
                  id: coverageBenefit.planBenefitTemplate.id,
                },
              },
            }
          : {}),
      };
    }

    createCoverageBenefit({
      variables: {
        data: params,
      },
      onCompleted: () => {
        toast.success(`${name} benefits updated`);
        setOpen(false);
      },
      onError: () => {
        toast.error(`Failed to update ${name} benefits`);
      },
      refetchQueries: [GET_INSURANCE_POLICY],
    });
  };

  const errors = methods.formState.errors;

  return (
    <Modal open={open} setOpen={setOpen} size="xl">
      <div className="grid grid-cols-2 gap-4 divide-x">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col"
          >
            <div className="max-h-[80vh] overflow-auto">
              <div className="mt-3 sm:mt-5 text-2xl font-medium">
                Edit {inNetwork ? "In Network" : "Out of Network"} {name}{" "}
                Benefits
              </div>
              <div className="border-b border-gray-900/10 py-4 flex flex-col gap-4 divide-y grow">
                <RadioGroup value={coveredStatus} onChange={setCoveredStatus}>
                  <RadioGroup.Label className="flex items-center gap-2 text-base font-semibold text-gray-900">
                    Service is covered
                    {coveredStatus === "no" && nonCoveredBenefitId && (
                      <button
                        type="button"
                        onClick={() => {
                          const benefitListTypeButton = document.getElementById(
                            CoverageBenefitType.NonCovered +
                              "benefit-type-list-button"
                          );
                          const isOpen =
                            benefitListTypeButton?.getAttribute(
                              "data-headlessui-state"
                            ) === "open";
                          if (!isOpen) {
                            benefitListTypeButton?.click();
                          }
                          benefitListTypeButton?.focus();
                        }}
                      >
                        <SearchIcon className="h-3.5 text-slate-500" />
                      </button>
                    )}
                    {coveredStatus === "yes" && activeCoverageBenefitId && (
                      <button
                        type="button"
                        onClick={() => {
                          const benefitListTypeButton = document.getElementById(
                            CoverageBenefitType.ActiveCoverage +
                              "benefit-type-list-button"
                          );
                          const isOpen =
                            benefitListTypeButton?.getAttribute(
                              "data-headlessui-state"
                            ) === "open";
                          if (!isOpen) {
                            benefitListTypeButton?.click();
                          }
                          benefitListTypeButton?.focus();
                        }}
                      >
                        <SearchIcon className="h-3.5 text-slate-500" />
                      </button>
                    )}
                  </RadioGroup.Label>
                  <p className="text-sm text-gray-500">
                    Whether the service is covered under this insurance policy?
                  </p>
                  <fieldset className="mt-2">
                    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                      <RadioGroup.Option value="yes">
                        {({ checked }) => (
                          <div className="flex items-center">
                            <input
                              type="radio"
                              checked={checked}
                              readOnly
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Yes, Covered
                            </label>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option value="no">
                        {({ checked }) => (
                          <div className="flex items-center">
                            <input
                              type="radio"
                              checked={checked}
                              readOnly
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              No, Not Covered
                            </label>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option value="unknown">
                        {({ checked }) => (
                          <div className="flex items-center">
                            <input
                              type="radio"
                              checked={checked}
                              readOnly
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Unknown
                            </label>
                          </div>
                        )}
                      </RadioGroup.Option>
                    </div>
                  </fieldset>
                </RadioGroup>

                <Transition
                  show={coveredStatus !== "no"}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="flex flex-col gap-4 divide-y"
                >
                  <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900 pt-4">
                      Cost Sharing
                    </h2>

                    <div className="flex gap-4">
                      <div className="w-48">
                        <RadioGroup
                          value={costSharingStatus}
                          onChange={setCostSharingStatus}
                        >
                          <fieldset className="mt-2">
                            <div className="space-y-2 flex flex-col">
                              <RadioGroup.Option value="copay" className="w-1">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block whitespace-nowrap text-sm font-medium leading-6 text-gray-900">
                                      Copay
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option
                                value="coinsurance"
                                className="w-1"
                              >
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block whitespace-nowrap text-sm font-medium leading-6 text-gray-900">
                                      Coinsurance
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="both" className="w-1">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block whitespace-nowrap text-sm font-medium leading-6 text-gray-900">
                                      Copay & Coinsurance
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="none" className="w-1">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block whitespace-nowrap text-sm font-medium leading-6 text-gray-900">
                                      No cost sharing
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option
                                value="unknown"
                                className="w-1"
                              >
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block whitespace-nowrap text-sm font-medium leading-6 text-gray-900">
                                      Unknown
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </fieldset>
                        </RadioGroup>
                      </div>

                      <div>
                        {costSharingStatus === "none" && (
                          <div>This service has no cost sharing.</div>
                        )}
                        {(costSharingStatus === "copay" ||
                          costSharingStatus === "both") && (
                          <div>
                            <BenefitLabel
                              benefitType={CoverageBenefitType.Copay}
                              title="Copay"
                              selectedBenefitId={copayBenefitId}
                            />
                            <div className="max-w-[12em]">
                              <NumberInput
                                icon={CurrencyDollarIcon}
                                step="0.01"
                                defaultValue={defaultCopay ?? undefined}
                                enableStepper={false}
                                error={!!errors.copay}
                                placeholder={""}
                                {...methods.register("copay")}
                                onValueChange={() => {
                                  // Clear the linked benefit on user input
                                  setCopayBenefitId(null);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {(costSharingStatus === "coinsurance" ||
                          costSharingStatus === "both") && (
                          <div>
                            <BenefitLabel
                              benefitType={CoverageBenefitType.Coinsurance}
                              title="Coinsurance"
                              selectedBenefitId={coinsuranceBenefitId}
                            />
                            <div className="max-w-[12em]">
                              <NumberInput
                                className="flex-row-reverse"
                                icon={() => <div className="mx-2">%</div>}
                                // @ts-expect-error
                                iconPosition="right"
                                step="1"
                                defaultValue={defaultCoinsurance ?? undefined}
                                enableStepper={false}
                                error={!!errors.coinsurance}
                                placeholder={""}
                                {...methods.register("coinsurance")}
                                onValueChange={() => {
                                  // Clear the linked benefit on user input
                                  setCoinsuranceBenefitId(null);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="pt-4">
                      <h2 className="text-lg font-semibold leading-7 text-gray-900">
                        Deductible
                      </h2>
                    </div>

                    {/* Don't ask this question for Health Plan */}
                    {!isHealthPlan && (
                      <div className="flex flex-col gap-4">
                        <RadioGroup
                          value={deductibleAppliesStatus}
                          onChange={setDeductibleAppliesStatus}
                        >
                          <RadioGroup.Label className="text-sm text-gray-500">
                            Is cost sharing subject to a deductible?
                          </RadioGroup.Label>
                          <fieldset className="mt-2">
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                              <RadioGroup.Option value="yes">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      Yes
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="no">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      No
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="unknown">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      Unknown
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </fieldset>
                        </RadioGroup>
                      </div>
                    )}

                    {/* For Health Plan, just show the deductible inputs and don't as this question */}
                    {!isHealthPlan && (
                      <Transition
                        show={deductibleAppliesStatus !== "no"}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <div className="flex flex-col gap-4">
                          <RadioGroup
                            value={deductibleLevel}
                            onChange={setDeductibleLevel}
                          >
                            <RadioGroup.Label className="text-sm text-gray-500">
                              Uses plan level deductible?
                            </RadioGroup.Label>
                            <fieldset className="mt-2">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                <RadioGroup.Option value="plan">
                                  {({ checked }) => (
                                    <div className="flex items-center">
                                      <input
                                        type="radio"
                                        checked={checked}
                                        readOnly
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        Yes
                                      </label>
                                    </div>
                                  )}
                                </RadioGroup.Option>
                                <RadioGroup.Option value="service">
                                  {({ checked }) => (
                                    <div className="flex items-center">
                                      <input
                                        type="radio"
                                        checked={checked}
                                        readOnly
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        No, service level deductible
                                      </label>
                                    </div>
                                  )}
                                </RadioGroup.Option>
                              </div>
                            </fieldset>
                          </RadioGroup>
                        </div>
                      </Transition>
                    )}

                    <Transition
                      show={deductibleLevel === "service"}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <div className="flex gap-4">
                        <div className="col-span-1">
                          <BenefitLabel
                            benefitType={
                              CoverageBenefitType.RemainingDeductible
                            }
                            title="Remaining"
                            selectedBenefitId={remainingDeductibleBenefitId}
                          />
                          <div className="max-w-[12em]">
                            <NumberInput
                              icon={CurrencyDollarIcon}
                              step="0.01"
                              defaultValue={defaultRemainingDeductible?.toString()}
                              enableStepper={false}
                              error={!!errors.remainingDeductible}
                              placeholder={""}
                              {...methods.register("remainingDeductible")}
                              onValueChange={() => {
                                // Clear the linked benefit on user input
                                setRemainingDeductibleBenefitId(null);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-span-1">
                          <BenefitLabel
                            benefitType={CoverageBenefitType.MaxDeductible}
                            title="Max"
                            selectedBenefitId={maxDeductibleBenefitId}
                          />
                          <div className="max-w-[12em]">
                            <NumberInput
                              icon={CurrencyDollarIcon}
                              step="0.01"
                              defaultValue={defaultMaxDeductible?.toString()}
                              enableStepper={false}
                              error={!!errors.maxDeductible}
                              placeholder={""}
                              {...methods.register("maxDeductible")}
                              onValueChange={() => {
                                // Clear the linked benefit on user input
                                setMaxDeductibleBenefitId(null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900 pt-4">
                      Out of Pocket
                    </h2>
                    {/* For Health Plan, just show the OOP inputs and don't as this question */}
                    {!isHealthPlan && (
                      <div className="flex flex-col gap-4">
                        <RadioGroup value={oopLevel} onChange={setOopLevel}>
                          <RadioGroup.Label className="text-sm text-gray-500">
                            Uses plan level Out of Pocket maximum?
                          </RadioGroup.Label>
                          <fieldset className="mt-2">
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                              <RadioGroup.Option value="plan">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      Yes
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="service">
                                {({ checked }) => (
                                  <div className="flex items-center">
                                    <input
                                      type="radio"
                                      checked={checked}
                                      readOnly
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                      No, service level Out of Pocket maximum
                                    </label>
                                  </div>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </fieldset>
                        </RadioGroup>
                      </div>
                    )}

                    <Transition
                      show={oopLevel === "service"}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <div className="flex gap-4">
                        <div className="col-span-1">
                          <BenefitLabel
                            benefitType={
                              CoverageBenefitType.RemainingOutOfPocket
                            }
                            title="Remaining"
                            selectedBenefitId={remainingOutOfPocketBenefitId}
                          />
                          <div className="max-w-[12em]">
                            <NumberInput
                              icon={CurrencyDollarIcon}
                              step="0.01"
                              defaultValue={defaultRemainingOutOfPocket?.toString()}
                              enableStepper={false}
                              error={!!errors.remainingOutOfPocket}
                              placeholder={""}
                              {...methods.register("remainingOutOfPocket")}
                              onValueChange={() => {
                                // Clear the linked benefit on user input
                                setRemainingOutOfPocketBenefitId(null);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-span-1">
                          <BenefitLabel
                            benefitType={CoverageBenefitType.MaxOutOfPocket}
                            title="Max"
                            selectedBenefitId={maxOutOfPocketBenefitId}
                          />
                          <div className="max-w-[12em]">
                            <NumberInput
                              icon={CurrencyDollarIcon}
                              step="0.01"
                              defaultValue={defaultMaxOutOfPocket?.toString()}
                              enableStepper={false}
                              error={!!errors.maxOutOfPocket}
                              placeholder={""}
                              {...methods.register("maxOutOfPocket")}
                              onValueChange={() => {
                                // Clear the linked benefit on user input
                                setMaxOutOfPocketBenefitId(null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold leading-7 text-gray-900 pt-4">
                      Visit Limits
                    </h2>
                    <div className="flex gap-4">
                      <div className="col-span-1">
                        <BenefitLabel
                          benefitType={CoverageBenefitType.RemainingVisits}
                          title="Remaining"
                          selectedBenefitId={remainingVisitsBenefitId}
                        />
                        <div className="max-w-[12em]">
                          <NumberInput
                            step="1"
                            defaultValue={defaultRemainingVisits?.toString()}
                            error={!!errors.remainingVisits}
                            placeholder={""}
                            {...methods.register("remainingVisits")}
                            onValueChange={() => {
                              // Clear the linked benefit on user input
                              setRemainingVisitsBenefitId(null);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <BenefitLabel
                          benefitType={CoverageBenefitType.MaxVisits}
                          title="Max"
                          selectedBenefitId={maxVisitsBenefitId}
                        />
                        <div className="max-w-[12em]">
                          <NumberInput
                            step="1"
                            defaultValue={defaultMaxVisits?.toString()}
                            error={!!errors.maxVisits}
                            placeholder={""}
                            {...methods.register("maxVisits")}
                            onValueChange={() => {
                              // Clear the linked benefit on user input
                              setMaxVisitsBenefitId(null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <RadioGroup
                    value={authRequiredStatus}
                    onChange={setAuthRequiredStatus}
                    className="pt-4"
                  >
                    <RadioGroup.Label className="text-lg font-medium text-gray-900">
                      Authorization Required
                    </RadioGroup.Label>
                    <p className="text-sm text-gray-500">
                      Is this service subject to authorization requirements?
                    </p>
                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <RadioGroup.Option value="yes">
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Yes
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value="no">
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                No
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value="unknown">
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Unknown
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                      </div>
                    </fieldset>
                  </RadioGroup>

                  <RadioGroup
                    value={hidden}
                    onChange={setHidden}
                    className="pt-4"
                  >
                    <RadioGroup.Label className="flex items-center gap-2 text-base font-semibold text-gray-900">
                      Hide from patient portal
                    </RadioGroup.Label>
                    <p className="text-sm text-gray-500">
                      Show this service in the patient benefit portal and in the
                      benefits PDF when downloaded?
                    </p>
                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <RadioGroup.Option value={false}>
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Show
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value={true}>
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Hide
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                      </div>
                    </fieldset>
                  </RadioGroup>
                  <RadioGroup value={locked} onChange={setLocked}>
                    <RadioGroup.Label className="flex items-center gap-2 text-base font-semibold text-gray-900 pt-4">
                      Lock Benefit
                    </RadioGroup.Label>
                    <p className="text-sm text-gray-500">
                      Do you want to lock this benefit? If locked, you will need
                      to make any changes manually.
                    </p>
                    <fieldset className="mt-2">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <RadioGroup.Option value={true}>
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Yes
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value={false}>
                          {({ checked }) => (
                            <div className="flex items-center">
                              <input
                                type="radio"
                                checked={checked}
                                readOnly
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                No
                              </label>
                            </div>
                          )}
                        </RadioGroup.Option>
                      </div>
                    </fieldset>
                  </RadioGroup>
                </Transition>
              </div>
            </div>

            <div className="pt-6 flex items-center justify-end gap-x-6 border-t">
              <button className="" type="button" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <div>
                <SubmitButton
                  loading={createCoverageBenefitResult.loading}
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                >
                  Save
                </SubmitButton>
              </div>
            </div>
          </form>
        </FormProvider>
        {/* Eligibility Context */}
        <div className="flex flex-col gap-2 p-4 max-h-[80vh] overflow-auto">
          {mostRecentEligibility && (
            <div>
              <Link
                to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
                className="flex group"
              >
                <div className="flex items-center gap-1 text-gray-900 group-hover:text-gray-700">
                  <div className="group-hover:underline">
                    From{" "}
                    <EligibilityBadge eligibility={mostRecentEligibility} />{" "}
                    verification{" "}
                    {formatDistanceToNow(
                      parseISO(mostRecentEligibility.createdAt),
                      {
                        addSuffix: true,
                      }
                    )}
                  </div>
                  {mostRecentEligibility.automated ? (
                    <div className="flex gap-1">
                      <CogIcon className="w-4" />
                      <span>Auto-Verified</span>
                    </div>
                  ) : mostRecentEligibility.requestedBy ? (
                    <div className="group-hover:underline">
                      verified by{" "}
                      {[
                        mostRecentEligibility.requestedBy?.firstName,
                        mostRecentEligibility.requestedBy?.lastName,
                      ]
                        .filter(isDefined)
                        .join(" ")}
                    </div>
                  ) : null}
                  <div className="whitespace-nowrap transition ease-in-out duration-300 group-hover:translate-x-1">
                    <span aria-hidden="true"> &rarr;</span>
                  </div>
                </div>
              </Link>
            </div>
          )}

          <TextInput
            icon={SearchIcon}
            placeholder={`Search for e.g. "${name}"`}
            onChange={(value) => {
              setQuery(value.target.value);
            }}
          />

          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.ActiveCoverage}
            selectedBenefitId={
              coveredStatus === "yes" ? activeCoverageBenefitId : null
            }
            onSelect={(benefit) => {
              setCoveredStatus("yes");
              setActiveCoverageBenefitId(benefit.id);
              setNonCoveredBenefitId(null);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.NonCovered}
            selectedBenefitId={
              coveredStatus === "no" ? nonCoveredBenefitId : null
            }
            onSelect={(benefit) => {
              setCoveredStatus("no");
              setNonCoveredBenefitId(benefit.id);
              setActiveCoverageBenefitId(null);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.Copay}
            selectedBenefitId={
              costSharingStatus === "copay" ? copayBenefitId : null
            }
            // @ts-expect-error
            onSelect={(benefit: CopayBenefit) => {
              methods.setValue("copay", mapNullable(toDollars)(benefit.amount));
              // If the user selects a copay benefit, set the cost sharing status to copay
              if (
                costSharingStatus !== "copay" &&
                costSharingStatus !== "both"
              ) {
                setCostSharingStatus("copay");
              }
              // Delay setting the focus to allow the radio select to change
              setTimeout(() => {
                methods.setFocus("copay");
              }, 250);
              setCopayBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.Coinsurance}
            selectedBenefitId={
              costSharingStatus === "coinsurance" ? coinsuranceBenefitId : null
            }
            // @ts-expect-error
            onSelect={(benefit: CoinsuranceBenefit) => {
              methods.setValue(
                "coinsurance",
                mapNullable(decimalToPercentage)(benefit.percentage)
              );
              // If the user selects a coinsurance benefit, set the cost sharing status to coinsurance
              if (
                costSharingStatus !== "coinsurance" &&
                costSharingStatus !== "both"
              ) {
                setCostSharingStatus("coinsurance");
              }
              // Delay setting the focus to allow the radio select to change
              setTimeout(() => {
                methods.setFocus("coinsurance");
              }, 250);
              setCoinsuranceBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.RemainingDeductible}
            selectedBenefitId={
              deductibleAppliesStatus === "no"
                ? null
                : remainingDeductibleBenefitId
            }
            // @ts-expect-error
            onSelect={(benefit: DeductibleBenefit) => {
              methods.setValue(
                "remainingDeductible",
                mapNullable(toDollars)(benefit.remaining)
              );
              methods.setFocus("remainingDeductible");
              setRemainingDeductibleBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.MaxDeductible}
            selectedBenefitId={
              deductibleAppliesStatus === "no" ? null : maxDeductibleBenefitId
            }
            // @ts-expect-error
            onSelect={(benefit: DeductibleBenefit) => {
              methods.setValue(
                "maxDeductible",
                mapNullable(toDollars)(benefit.max)
              );
              methods.setFocus("maxDeductible");
              setMaxDeductibleBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.RemainingOutOfPocket}
            selectedBenefitId={remainingOutOfPocketBenefitId}
            // @ts-expect-error
            onSelect={(benefit: OutOfPocketBenefit) => {
              methods.setValue(
                "remainingOutOfPocket",
                mapNullable(toDollars)(benefit.remaining)
              );
              methods.setFocus("remainingOutOfPocket");
              setRemainingOutOfPocketBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.MaxOutOfPocket}
            selectedBenefitId={maxOutOfPocketBenefitId}
            // @ts-expect-error
            onSelect={(benefit: OutOfPocketBenefit) => {
              methods.setValue(
                "maxOutOfPocket",
                mapNullable(toDollars)(benefit.max)
              );
              methods.setFocus("maxOutOfPocket");
              setMaxOutOfPocketBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.RemainingVisits}
            selectedBenefitId={remainingVisitsBenefitId}
            // @ts-expect-error
            onSelect={(benefit: VisitsBenefit) => {
              methods.setValue("remainingVisits", benefit.remaining);
              methods.setFocus("remainingVisits");
              setRemainingVisitsBenefitId(benefit.id);
            }}
            query={query}
          />
          <BenefitTypeList
            insurancePolicyId={insurancePolicy.id}
            providerServiceConfigurationId={providerServiceConfigurationId}
            benefitType={CoverageBenefitType.MaxVisits}
            selectedBenefitId={maxVisitsBenefitId}
            // @ts-expect-error
            onSelect={(benefit: VisitsBenefit) => {
              methods.setValue("maxVisits", benefit.max);
              methods.setFocus("maxVisits");
              setMaxVisitsBenefitId(benefit.id);
            }}
            query={query}
          />
        </div>
      </div>
    </Modal>
  );
};

const BenefitLabel: React.FC<
  React.PropsWithChildren<{
    benefitType: CoverageBenefitType;
    title: string;
    selectedBenefitId: string | null;
  }>
> = ({ benefitType, title, selectedBenefitId }) => {
  return (
    <label className="flex items-center justify-between gap-1 text-sm font-medium leading-6 text-gray-900">
      <div>{title}</div>
      <div>
        {selectedBenefitId && (
          <button
            type="button"
            onClick={() => {
              const benefitListTypeButton = document.getElementById(
                benefitType + "benefit-type-list-button"
              );
              const isOpen =
                benefitListTypeButton?.getAttribute("data-headlessui-state") ===
                "open";
              if (!isOpen) {
                benefitListTypeButton?.click();
              }
              benefitListTypeButton?.focus();
            }}
          >
            <SearchIcon className="h-3.5 text-slate-500" />
          </button>
        )}
      </div>
    </label>
  );
};

const BenefitTypeList: React.FC<
  React.PropsWithChildren<{
    insurancePolicyId: string;
    benefitType: CoverageBenefitType;
    providerServiceConfigurationId: string;
    selectedBenefitId: string | null;
    onSelect?: (benefit: CommonBenefit) => void;
    query?: string;
  }>
> = ({
  insurancePolicyId,
  providerServiceConfigurationId,
  benefitType,
  selectedBenefitId,
  onSelect,
  query,
}) => {
  const { data, loading } = useQuery<
    GetBenefitsForProviderServiceConfiguration,
    GetBenefitsForProviderServiceConfigurationVariables
  >(GET_BENEFITS_FOR_PROVIDER_SERVICE_CONFIGURATION, {
    variables: {
      insurancePolicyId,
      benefitType,
      providerServiceConfigurationId,
    },
  });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const disclosureRef = useRef<HTMLDivElement>(null);

  const benefits = data?.getBenefitsForProviderServiceConfiguration ?? [];

  // If the query passed in changes, open the disclosure if it's not already open
  useEffect(() => {
    if (query) {
      const openAttribute = disclosureRef.current?.getAttribute(
        "data-headlessui-state"
      );
      const isOpen = openAttribute === "open";
      if (!isOpen) {
        buttonRef.current?.click();
      } else {
      }
    }
  }, [buttonRef, disclosureRef, query]);

  let sorted = benefits.map((b) => ({
    ...b,
    benefit: {
      ...b.benefit,
      flattenedNotes: b.benefit.notes?.join(" ") ?? "",
    },
  }));
  if (query) {
    sorted = fuzzysort
      .go(query, sorted, {
        keys: [
          "benefit.serviceTypeDisplay",
          "benefit.flattenedNotes",
          "benefit.placeOfServiceDisplay",
          "benefit.coverageLevel",
          "benefit.networkStatusDisplay",
          "benefit.amount",
          "benefit.percentage",
          "benefit.remaining",
          "benefit.max",
        ],
        threshold: -2500,
        limit: 50,
      })
      .map((result) => result.obj);
  }

  return (
    <Card>
      <Disclosure as="div" ref={disclosureRef} className="flex flex-col w-full">
        <Disclosure.Button
          id={benefitType + "benefit-type-list-button"}
          ref={buttonRef}
          className="flex justify-between items-center gap-2 py-2 text-lg font-medium hover:text-gray-500 group"
        >
          {({ open }) => (
            <>
              <div className="flex items-center gap-2">
                {pascalCaseToReadable(benefitType)}
                <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                  {sorted.length}
                </span>
              </div>

              <PlusIcon
                className={classNames(
                  "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                  open ? "rotate-45" : ""
                )}
              />
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500 divide-y py-1">
          {sorted.map(({ benefit, match }) => (
            <BenefitTypeListRow
              key={benefit.id}
              benefit={{ ...benefit, match }}
              selected={selectedBenefitId === benefit.id}
              onSelect={onSelect}
            />
          ))}
        </Disclosure.Panel>
      </Disclosure>
    </Card>
  );
};

const BenefitTypeListRow: React.FC<
  React.PropsWithChildren<{
    benefit: CommonBenefit & { match: boolean };
    selected: boolean;
    onSelect?: (benefit: CommonBenefit) => void;
  }>
> = ({ benefit, selected, onSelect }) => {
  let display: string | null = "Other";
  switch (benefit.__typename) {
    case "CopayBenefit":
      display = mapNullable(formatUSD)(benefit.amount);
      break;
    case "CoinsuranceBenefit":
      display = mapNullable(formatPercentage)(benefit.percentage);
      break;
    case "DeductibleBenefit":
      display = mapNullable(formatUSD)(benefit.remaining ?? benefit.max);
      break;
    case "OutOfPocketBenefit":
      display = mapNullable(formatUSD)(benefit.remaining ?? benefit.max);
      break;
    case "LimitationBenefit":
      display =
        benefit.remaining?.toString() ?? benefit.max?.toString() ?? null;
      break;
    case "OtherBenefit":
      if ("code" in benefit && benefit.code === BenefitCode.NonCovered) {
        display = "Not Covered";
      }
      if ("code" in benefit && benefit.code === BenefitCode.ActiveCoverage) {
        display = "Active";
      }
      break;
  }

  return (
    <div
      onClick={() => onSelect?.(benefit)}
      className={classNames(
        "flex gap-2 cursor-pointer select-none p-3 hover:rounded-md hover:ring-2 ring-inset ring-indigo-500 group",
        selected && "bg-indigo-500 rounded-md"
      )}
    >
      <>
        <div
          className={classNames(
            "flex flex-none items-center justify-center",
            selected && "text-white"
          )}
        >
          {display}
        </div>
        <div className="ml-4 flex-auto flex-wrap">
          <div className="flex justify-between items-center gap-1">
            <div>
              <p
                className={classNames(
                  "text-sm font-medium",
                  selected ? "text-white" : "text-gray-700"
                )}
              >
                {benefit.serviceTypeDisplay}
              </p>
              {isAuthRequired(benefit.authRequired) && (
                <Tooltip
                  trigger={
                    <ExclamationIcon
                      className="h-4 w-4 text-red-400"
                      aria-hidden="true"
                    />
                  }
                  content={<span>Authorization required</span>}
                />
              )}
            </div>
            {benefit.match && (
              <div>
                <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                  Best Match
                </span>
              </div>
            )}
          </div>
          <div
            className={classNames(
              "flex flex-wrap justify-between items-center text-xs",
              selected ? "text-white" : "text-gray-800"
            )}
          >
            <div className="flex gap-1">
              <span
                className={classNames(
                  selected ? "text-white" : "text-gray-500"
                )}
              >
                Coverage Level
              </span>
              {benefit.coverageLevel ?? (
                <span
                  className={classNames(
                    selected ? "text-white" : "text-gray-400"
                  )}
                >
                  NA
                </span>
              )}
            </div>
            <div className="flex gap-1">
              <span
                className={classNames(
                  selected ? "text-white" : "text-gray-500"
                )}
              >
                Network Status
              </span>
              <span
                className={classNames(
                  selected ? "text-white" : "",
                  benefit.inNetwork === NetworkStatus.YES
                    ? "text-green-500"
                    : benefit.inNetwork === NetworkStatus.NO
                    ? "text-red-500"
                    : "text-gray-400"
                )}
              >
                {benefit.networkStatusDisplay}
              </span>
            </div>
            <div className="flex gap-1">
              <span
                className={classNames(
                  selected ? "text-white" : "text-gray-500"
                )}
              >
                Place of Service
              </span>
              {benefit.placeOfServiceDisplay ?? (
                <span
                  className={classNames(
                    selected ? "text-white" : "text-gray-400"
                  )}
                >
                  NA
                </span>
              )}
            </div>
          </div>
          {benefit.notes.length > 0 && (
            <div>
              <div
                className={classNames(
                  "flex flex-col flex-wrap text-xs bg-gray-100 p-1 rounded-md",
                  selected ? "text-white bg-indigo-400" : "text-gray-500"
                )}
              >
                <div className="flex items-center gap-1 font-medium text-sm">
                  <DocumentTextIcon className="h-4 w-4" />
                  Notes
                </div>
                <ul className="list-disc list-inside">
                  {benefit.notes.map((note, index) => (
                    <li className="break-all">{note}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

type BenefitAdjustments = {
  appliedDeductible: number;
  appliedOutOfPocket: number;
  appliedVisits: number;
};

const EditCoverageBenefitButton: React.FC<{
  name: string;
  coverageBenefit: CoverageBenefit | null;
  insurancePolicy: ServiceBenefitEditInsurancePolicy;
  patientId: string;
  inNetwork: boolean;
  providerServiceConfigurationId: string;
  isHealthPlan: boolean;
  coverageLevel?: CoverageLevel;
  benefitAdjustments?: BenefitAdjustments;
}> = ({
  name,
  coverageBenefit,
  insurancePolicy,
  patientId,
  inNetwork,
  providerServiceConfigurationId,
  isHealthPlan,
  coverageLevel,
  benefitAdjustments,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        className="p-1.5 rounded-md hover:bg-gray-50"
        onClick={() => {
          setOpen(true);
        }}
      >
        <PencilAltIcon className="h-5 w-5 text-gray-600 print:hidden" />
      </button>
      {open && (
        <ServiceBenefitEditModal
          open={open}
          setOpen={setOpen}
          name={name}
          coverageBenefit={coverageBenefit ?? null}
          insurancePolicy={insurancePolicy}
          patientId={patientId}
          providerServiceConfigurationId={providerServiceConfigurationId}
          inNetwork={inNetwork}
          isHealthPlan={isHealthPlan}
          coverageLevel={coverageLevel}
          benefitAdjustments={benefitAdjustments}
        />
      )}
    </>
  );
};

const HealthPlanBenefitRow: React.FC<
  React.PropsWithChildren<{
    config: ProviderServiceConfiguration & {
      individual: CoverageBenefit | null;
      family: CoverageBenefit | null;
    };
    insurancePolicy: InsurancePolicy;
    inNetwork: boolean;
  }>
> = ({ config, insurancePolicy, inNetwork }) => {
  const indvBenefitAccumulatorAdjustments =
    config.individual?.appliedBenefitAccumulatorAdjustments ?? [];
  const famBenefitAccumulatorAdjustments =
    config.family?.appliedBenefitAccumulatorAdjustments ?? [];

  const indvBenefitAdjustments = {
    appliedDeductible: indvBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedDeductible,
      0
    ),
    appliedOutOfPocket: indvBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedOutOfPocket,
      0
    ),
    appliedVisits: indvBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedVisits,
      0
    ),
  };
  const famBenefitAdjustments = {
    appliedDeductible: famBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedDeductible,
      0
    ),
    appliedOutOfPocket: famBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedOutOfPocket,
      0
    ),
    appliedVisits: famBenefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedVisits,
      0
    ),
  };

  return (
    <div className="pt-1 pb-2 flex flex-col">
      <div className="pr-2">
        <div className="truncate text-lg font-medium">{config.name}</div>
      </div>
      <div className="flex flex-wrap justify-start gap-2">
        <div className="flex flex-col">
          <div className="flex items-center">
            Individual
            <EditCoverageBenefitButton
              name={`Individual ${config.name}`}
              coverageBenefit={config.individual}
              insurancePolicy={insurancePolicy}
              patientId={insurancePolicy.patient.id}
              inNetwork={inNetwork}
              providerServiceConfigurationId={config.id}
              isHealthPlan={true}
              coverageLevel={CoverageLevel.Individual}
              benefitAdjustments={indvBenefitAdjustments}
            />
            {config.individual?.hidden === true && (
              <Tooltip
                trigger={
                  <EyeOffIcon className="h-5 w-5 text-gray-500" aria-hidden />
                }
                content={<span>Hidden from patient</span>}
              />
            )}
            {config.individual?.locked === true && (
              <Tooltip
                trigger={
                  <LockClosedIcon
                    className="h-5 w-5 text-gray-500 ml-1.5"
                    aria-hidden
                  />
                }
                content={
                  <span>Benefit locked and can only be manually edited</span>
                }
              />
            )}
            {config.individual?.planBenefitTemplate?.planType && (
              <Tooltip
                trigger={<LayoutPanelTop className="h-4 w-4" />}
                content={
                  <div>
                    Benefit set by{" "}
                    {config.individual?.planBenefitTemplate?.planType?.name}{" "}
                    template
                  </div>
                }
              />
            )}
          </div>
          <ServiceBenefitCell
            coverageBenefit={config.individual}
            patientId={insurancePolicy.patient.id}
            providerServiceConfiguration={config}
            inNetwork={inNetwork}
            insurancePolicy={insurancePolicy}
            benefitAccumulatorAdjustments={indvBenefitAccumulatorAdjustments}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center">
            Family
            <EditCoverageBenefitButton
              name={`Family ${config.name}`}
              coverageBenefit={config.family}
              insurancePolicy={insurancePolicy}
              patientId={insurancePolicy.patient.id}
              inNetwork={inNetwork}
              providerServiceConfigurationId={config.id}
              isHealthPlan={true}
              coverageLevel={CoverageLevel.Family}
              benefitAdjustments={famBenefitAdjustments}
            />
            {config.family?.hidden === true && (
              <Tooltip
                trigger={
                  <EyeOffIcon className="h-5 w-5 text-gray-500" aria-hidden />
                }
                content={<span>Hidden from patient</span>}
              />
            )}
            {config.family?.locked === true && (
              <Tooltip
                trigger={
                  <LockClosedIcon
                    className="h-5 w-5 text-gray-500 ml-1.5"
                    aria-hidden
                  />
                }
                content={
                  <span>Benefit locked and can only be manually edited</span>
                }
              />
            )}
            {config.family?.planBenefitTemplate?.planType && (
              <Tooltip
                trigger={<LayoutPanelTop className="h-4 w-4" />}
                content={
                  <div>
                    Benefit set by{" "}
                    {config.family?.planBenefitTemplate?.planType?.name}{" "}
                    template
                  </div>
                }
              />
            )}
          </div>
          <ServiceBenefitCell
            coverageBenefit={config.family}
            patientId={insurancePolicy.patient.id}
            providerServiceConfiguration={config}
            inNetwork={inNetwork}
            insurancePolicy={insurancePolicy}
            benefitAccumulatorAdjustments={famBenefitAccumulatorAdjustments}
          />
        </div>
      </div>
    </div>
  );
};

const ServiceBenefitRow: React.FC<
  React.PropsWithChildren<{
    config: ProviderServiceConfiguration & {
      coverageBenefit: CoverageBenefit | null;
    };
    insurancePolicy: InsurancePolicy;
    inNetwork: boolean;
  }>
> = ({ config, insurancePolicy, inNetwork }) => {
  const benefitAccumulatorAdjustments =
    config.coverageBenefit?.appliedBenefitAccumulatorAdjustments ?? [];
  const benefitAdjustments = {
    appliedDeductible: benefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedDeductible,
      0
    ),
    appliedOutOfPocket: benefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedOutOfPocket,
      0
    ),
    appliedVisits: benefitAccumulatorAdjustments.reduce(
      (acc, curr) => acc + curr.appliedVisits,
      0
    ),
  };

  const override = config.providerServiceConfigurationOverrides?.at(0);
  const payerOverride =
    override?.payerProviderServiceConfigurationOverrides?.find(
      (p) => p.payer.id === insurancePolicy.payer.id
    );
  const fallbackServiceTypes = (
    override?.fallbackServiceTypes ??
    config.fallbackServiceTypes ??
    []
  ).map((stc) => serviceTypeCodeToName(stc as any as ServiceTypeCode));

  const skipEdiExtraction =
    config.skipEdiExtraction || override?.skipEdiExtraction || false;

  return (
    <div key={config.id} className="pt-1 pb-2 flex flex-col gap-1">
      <div className="pr-2">
        <div className="h-full flex items-center gap-2 ">
          <div>
            <HoverCard.Root openDelay={100} closeDelay={100}>
              <HoverCard.Trigger asChild className="cursor-pointer">
                <div className="truncate text-lg font-medium flex items-center gap-2">
                  {config.name}
                </div>
              </HoverCard.Trigger>
              <HoverCard.Content className="z-10" sideOffset={5} side="top">
                <Card>
                  <div className="flex flex-col gap-2 p-2 w-full">
                    <dl className="space-y-6 pb-2 border-b border-gray-200 text-sm font-medium text-gray-900 lg:block">
                      <div className="flex items-center justify-between gap-4">
                        <dd className="font-semibold">{config.name}</dd>
                      </div>
                    </dl>
                    <dl className="space-y-1 border-gray-200 text-sm font-medium text-gray-900 lg:block">
                      {payerOverride && (
                        <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
                          <div className="flex items-center justify-between gap-4">
                            <dd className="flex items-center gap-2">
                              Using benefit configuration override for{" "}
                              {payerOverride.payer.name}
                            </dd>
                          </div>
                        </dl>
                      )}
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Primary Service Type
                        </dt>
                        <dd>
                          {serviceTypeCodeToName(
                            (override?.serviceType as ServiceTypeCode) ??
                              config.serviceType
                          )}
                        </dd>
                      </div>
                      <div className="flex justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Fallback Service Types
                        </dt>
                        <dd>
                          {fallbackServiceTypes.length ? (
                            <div className="flex flex-col">
                              {fallbackServiceTypes.map((stc) => (
                                <div>{stc}</div>
                              ))}
                            </div>
                          ) : (
                            <span className="text-gray-500 italic">None</span>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Claim Type
                        </dt>
                        <dd>
                          {config.claimType ?? (
                            <span className="text-gray-500 italic">None</span>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Place of Service
                        </dt>
                        <dd>
                          {config.placeOfService ? (
                            placeOfServiceCodeToName(
                              config.placeOfService as PlaceOfService
                            )
                          ) : (
                            <span className="text-gray-500 italic">None</span>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Specialist
                        </dt>
                        <dd>
                          {config.specialist === true ? (
                            "Yes"
                          ) : config.specialist === false ? (
                            "No"
                          ) : (
                            <span className="text-gray-500 italic">None</span>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">Virtual</dt>
                        <dd>
                          {config.virtual === true ? (
                            "Yes"
                          ) : config.virtual === false ? (
                            "No"
                          ) : (
                            <span className="text-gray-500 italic">None</span>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <dt className="text-gray-600 font-normal">
                          Skip auto-benefit extraction
                        </dt>
                        <dd>{skipEdiExtraction ? "Yes" : "No"}</dd>
                      </div>
                    </dl>
                  </div>
                </Card>
                <HoverCard.Arrow fill="white" />
              </HoverCard.Content>
            </HoverCard.Root>
          </div>
          <EditCoverageBenefitButton
            name={config.name}
            coverageBenefit={config.coverageBenefit}
            insurancePolicy={insurancePolicy}
            patientId={insurancePolicy.patient.id}
            inNetwork={inNetwork}
            providerServiceConfigurationId={config.id}
            isHealthPlan={false}
            benefitAdjustments={benefitAdjustments}
          />
          {config.coverageBenefit?.hidden === true && (
            <Tooltip
              trigger={
                <EyeOffIcon className="h-5 w-5 text-gray-500" aria-hidden />
              }
              content={<span>Hidden from patient</span>}
            />
          )}
          {config.coverageBenefit?.locked === true && (
            <Tooltip
              trigger={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-500 ml-1.5"
                  aria-hidden
                />
              }
              content={
                <span>Benefit locked and can only be manually edited</span>
              }
            />
          )}
          {config.coverageBenefit?.planBenefitTemplate?.planType && (
            <Tooltip
              trigger={<LayoutPanelTop className="h-4 w-4" />}
              content={
                <div>
                  Benefit set by{" "}
                  {config.coverageBenefit?.planBenefitTemplate?.planType?.name}{" "}
                  template
                </div>
              }
            />
          )}
          {skipEdiExtraction && (
            <Tooltip
              trigger={
                <FastForwardIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden
                />
              }
              content={
                <span>
                  Automatic benefit extraction from eligibility requests is
                  disabled for this benefit.
                </span>
              }
            />
          )}
        </div>
      </div>
      <div className="py-0">
        <ServiceBenefitCell
          coverageBenefit={config.coverageBenefit}
          patientId={insurancePolicy.patient.id}
          providerServiceConfiguration={config}
          inNetwork={inNetwork}
          insurancePolicy={insurancePolicy}
          benefitAccumulatorAdjustments={benefitAccumulatorAdjustments}
        />
      </div>
    </div>
  );
};

// HoverCard for displaying the benefit details
export const ServiceBenefitHoverCard: React.FC<
  React.PropsWithChildren<{
    coverageBenefit: CoverageBenefit | null;
    providerServiceConfiguration: Pick<
      ProviderServiceConfiguration,
      "id" | "name" | "serviceType"
    >;
    insurancePolicy: ServiceBenefitEditInsurancePolicy;
    inNetwork: boolean;
    patientId: string;
    trigger: React.ReactNode;
    hoverEnabled?: boolean;
  }>
> = ({
  coverageBenefit,
  patientId,
  providerServiceConfiguration,
  insurancePolicy,
  inNetwork,
  trigger,
  hoverEnabled = true,
}) => {
  const benefitAccumulatorAdjustments =
    coverageBenefit?.appliedBenefitAccumulatorAdjustments ?? [];

  return (
    <HoverCard.Root
      openDelay={100}
      closeDelay={100}
      open={hoverEnabled === false ? false : undefined}
    >
      <HoverCard.Trigger asChild className="cursor-pointer">
        {trigger}
      </HoverCard.Trigger>
      <HoverCard.Content className="z-10" sideOffset={5} side="top">
        <div className="flex p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
          {coverageBenefit ? (
            <ServiceBenefitCell
              patientId={patientId}
              coverageBenefit={coverageBenefit}
              providerServiceConfiguration={providerServiceConfiguration}
              inNetwork={inNetwork}
              insurancePolicy={insurancePolicy}
              benefitAccumulatorAdjustments={benefitAccumulatorAdjustments}
            />
          ) : (
            <>No benefits tracked</>
          )}
        </div>

        <HoverCard.Arrow fill="white" />
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

const NoBenefitsTrackedCard: React.FC<{
  coverageBenefit: CoverageBenefit | null;
  inNetwork: boolean;
  insurancePolicy: ServiceBenefitEditInsurancePolicy;
  patientId: string;
  providerServiceConfigurationId: string;
  name: string;
  isHealthPlan: boolean;
  coverageLevel?: CoverageLevel;
  benefitAdjustments?: BenefitAdjustments;
}> = ({
  coverageBenefit,
  inNetwork,
  insurancePolicy,
  patientId,
  name,
  providerServiceConfigurationId,
  isHealthPlan,
  coverageLevel,
  benefitAdjustments,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card
        className="hover:bg-gray-50 cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="flex items-center gap-2">
          <svg
            className="mx-auto h-6 w-6 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          No benefits tracked
        </div>
      </Card>
      {open && (
        <ServiceBenefitEditModal
          open={open}
          setOpen={setOpen}
          name={name}
          coverageBenefit={coverageBenefit ?? null}
          insurancePolicy={insurancePolicy}
          patientId={patientId}
          providerServiceConfigurationId={providerServiceConfigurationId}
          inNetwork={inNetwork}
          isHealthPlan={isHealthPlan}
          coverageLevel={coverageLevel}
          benefitAdjustments={benefitAdjustments}
        />
      )}
    </>
  );
};

type HoverCardBenefitProps<
  T extends
    | Omit<CopayBenefit, "__typename">
    | Omit<CoinsuranceBenefit, "__typename">
    | Omit<DeductibleBenefit, "__typename">
    | Omit<OutOfPocketBenefit, "__typename">
    | Omit<VisitsBenefit, "__typename" | "quantityQualifier">
> = Pick<
  T,
  | "serviceType"
  | "eligibilityRequestId"
  | "serviceTypeDisplay"
  | "networkStatusDisplay"
  | "placeOfServiceDisplay"
  | "coverageLevel"
  | "notes"
  | "authRequired"
>;

type Benefit = HoverCardBenefitProps<
  Omit<CopayBenefit, "__typename"> &
    Omit<CoinsuranceBenefit, "__typename"> &
    Omit<DeductibleBenefit, "__typename"> &
    Omit<OutOfPocketBenefit, "__typename"> &
    Omit<VisitsBenefit, "__typename" | "quantityQualifier">
>;

const BenefitHoverCard: React.FC<{
  patientId: string;
  benefits: (Benefit & { type: string; value: React.ReactNode })[];
}> = ({ patientId, benefits }) => {
  const flags = useFeatureFlags();
  const [benefit] = benefits;
  if (!benefit) return null;
  const authRequired = isAuthRequired(benefit.authRequired);
  return (
    <HoverCard.Root
      openDelay={100}
      // TODO: Enable this for all users
      open={flags.showBenefitsSettingsPage ? undefined : false}
    >
      <HoverCard.Trigger asChild>
        <div className="flex gap-1">
          <HashLink
            to={{
              pathname: `/patients/${patientId}/eligibilities/${benefit.eligibilityRequestId}`,
              hash: `#${benefit.serviceType}`,
            }}
            className="p-0.5 rounded-md hover:bg-gray-50"
          >
            <LinkIcon className="h-3.5 text-slate-500" />
          </HashLink>
          {authRequired && (
            <Tooltip
              trigger={
                <ExclamationIcon
                  className="h-4 w-4 text-red-400"
                  aria-hidden="true"
                />
              }
              content={<span>Authorization required</span>}
            />
          )}
        </div>
      </HoverCard.Trigger>
      <HoverCard.Content side="top" sideOffset={5}>
        <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
          {benefits.map((benefit) => (
            <div className="flex flex-col gap-2 flex-wrap">
              <Card className=" whitespace-normal max-w-xl">
                <dl className="flex flex-col divide-y divide-gray-100">
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Type
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.type}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Value
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.value}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Service Type
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.serviceTypeDisplay ?? (
                        <span className="text-gray-500">N/A</span>
                      )}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Network Status
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.networkStatusDisplay}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Place of Service
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.placeOfServiceDisplay ?? (
                        <span className="text-gray-500">N/A</span>
                      )}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Coverage Level
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {benefit.coverageLevel ?? (
                        <span className="text-gray-500">N/A</span>
                      )}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Notes
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-wrap">
                      {benefit.notes.length > 0 ? (
                        <ul className="list-disc list-inside">
                          {benefit.notes.map((note, index) => (
                            <li key={index} className="break-words">
                              {note}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span className="text-gray-500">N/A</span>
                      )}
                    </dd>
                  </div>
                </dl>
              </Card>
            </div>
          ))}
        </div>
        <HoverCard.Arrow fill="white" />
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export const ServiceBenefitCell: React.FC<
  React.PropsWithChildren<{
    coverageBenefit: CoverageBenefit | null;
    patientId: string;
    inNetwork: boolean;
    insurancePolicy: ServiceBenefitEditInsurancePolicy;
    providerServiceConfiguration: Pick<
      ProviderServiceConfiguration,
      "id" | "name" | "serviceType"
    >;
    isHealthPlan?: boolean;
    benefitAccumulatorAdjustments: BenefitAccumulatorAdjustment[];
  }>
> = ({
  coverageBenefit,
  patientId,
  insurancePolicy,
  inNetwork,
  providerServiceConfiguration,
  isHealthPlan,
  benefitAccumulatorAdjustments,
}) => {
  const isPlanLevel =
    isHealthPlan ??
    providerServiceConfiguration.serviceType ===
      ServiceTypeCode.HealthBenefitPlanCoverage;

  const benefitAdjustments = {
    appliedDeductible: benefitAccumulatorAdjustments.reduce(
      (acc, adj) => acc + adj.appliedDeductible,
      0
    ),
    appliedOutOfPocket: benefitAccumulatorAdjustments.reduce(
      (acc, adj) => acc + adj.appliedOutOfPocket,
      0
    ),
    appliedVisits: benefitAccumulatorAdjustments.reduce(
      (acc, adj) => acc + adj.appliedVisits,
      0
    ),
  };
  if (coverageBenefit?.empty || !coverageBenefit) {
    return (
      <div className="flex gap-2 flex-wrap">
        <NoBenefitsTrackedCard
          coverageBenefit={coverageBenefit}
          inNetwork={inNetwork}
          insurancePolicy={insurancePolicy}
          patientId={patientId}
          providerServiceConfigurationId={providerServiceConfiguration.id}
          name={providerServiceConfiguration.name}
          isHealthPlan={false}
          benefitAdjustments={benefitAdjustments}
        />
      </div>
    );
  }
  if (coverageBenefit?.nonCovered) {
    return (
      <div className="flex gap-2 flex-wrap">
        <Card>
          <div className="flex items-center gap-2">
            <BanIcon className="h-6 w-6 stroke-1 text-gray-500" />
            Service not covered by this plan
          </div>
        </Card>
      </div>
    );
  }

  // Display the benefits in a different order depending on if it's for the Health Plan or a Service Type
  const costSharingType = coverageBenefit
    ? getCostSharingType(coverageBenefit)
    : null;
  const costSharingKey =
    costSharingType === "both"
      ? ["copay", "coinsurance"]
      : costSharingType === "copay"
      ? ["copay"]
      : costSharingType === "coinsurance"
      ? ["coinsurance"]
      : [];
  const benefitCardOrder =
    providerServiceConfiguration.serviceType ===
    ServiceTypeCode.HealthBenefitPlanCoverage
      ? ["deductible", "outOfPocket", ...costSharingKey, "visits", "auth"]
      : [...costSharingKey, "deductible", "outOfPocket", "visits", "auth"];

  return (
    <div className="flex gap-2 flex-wrap">
      {benefitCardOrder.map((benefitType) => {
        return (
          <>
            {benefitType === "copay" && isDefined(coverageBenefit?.copay) && (
              <Card className="min-w-[8em]">
                <div className="flex flex-col">
                  <label className="text-sm text-gray-700 flex gap-1">
                    Copay
                    {coverageBenefit?.copayBenefit && (
                      <BenefitHoverCard
                        patientId={patientId}
                        benefits={[
                          {
                            ...coverageBenefit.copayBenefit,
                            type: "Copay",
                            value: mapNullable(formatUSD)(
                              coverageBenefit?.copay
                            ) ?? <span className="text-gray-500">-</span>,
                          },
                        ]}
                      />
                    )}
                  </label>
                  <div className="text-lg font-medium">
                    {mapNullable(formatUSD)(coverageBenefit?.copay) ?? (
                      <span className="text-gray-500">N/A</span>
                    )}
                  </div>
                </div>
              </Card>
            )}
            {benefitType === "coinsurance" &&
              isDefined(coverageBenefit?.coinsurance) && (
                <Card className="min-w-[8em]">
                  <div className="flex flex-col">
                    <label className="text-sm text-gray-700 flex gap-1">
                      Coinsurance
                      {coverageBenefit?.coinsuranceBenefit && (
                        <BenefitHoverCard
                          patientId={patientId}
                          benefits={[
                            {
                              ...coverageBenefit.coinsuranceBenefit,
                              type: "Coinsurance",
                              value: mapNullable(formatPercentage)(
                                coverageBenefit?.coinsurance
                              ) ?? <span className="text-gray-500">N/A</span>,
                            },
                          ]}
                        />
                      )}
                    </label>
                    <div className="text-lg font-medium">
                      {mapNullable(formatPercentage)(
                        coverageBenefit?.coinsurance
                      ) ?? <span className="text-gray-500">-</span>}
                    </div>
                  </div>
                </Card>
              )}
            {benefitType === "deductible" &&
              (isDefined(coverageBenefit?.remainingDeductible) ||
                isDefined(coverageBenefit?.maxDeductible) ||
                isDefined(coverageBenefit?.deductibleApplies)) && (
                <DeductibleBenefitProgressDisplay
                  title="Deductible"
                  remaining={coverageBenefit?.remainingDeductible ?? null}
                  max={coverageBenefit?.maxDeductible ?? null}
                  adjustment={benefitAdjustments.appliedDeductible}
                  patientId={patientId}
                  remainingBenefit={
                    coverageBenefit?.remainingDeductibleBenefit ?? null
                  }
                  maxBenefit={coverageBenefit?.maxDeductibleBenefit ?? null}
                  deductibleApplies={coverageBenefit?.deductibleApplies ?? null}
                  benefitAccumulatorAdjustments={benefitAccumulatorAdjustments.filter(
                    (a) => a.appliedDeductible > 0
                  )}
                  formatter={formatUSD}
                  isHealthPlan={isPlanLevel}
                  benefitCreatedAt={coverageBenefit.createdAt}
                />
              )}
            {benefitType === "outOfPocket" &&
              (isDefined(coverageBenefit?.remainingOutOfPocket) ||
                isDefined(coverageBenefit?.maxOutOfPocket)) && (
                <BenefitProgressDisplay
                  title="Out of Pocket"
                  remaining={coverageBenefit?.remainingOutOfPocket ?? null}
                  max={coverageBenefit?.maxOutOfPocket ?? null}
                  adjustment={benefitAdjustments.appliedOutOfPocket}
                  patientId={patientId}
                  remainingBenefit={
                    coverageBenefit?.remainingOutOfPocketBenefit ?? null
                  }
                  maxBenefit={coverageBenefit?.maxOutOfPocketBenefit ?? null}
                  benefitAccumulatorAdjustments={benefitAccumulatorAdjustments.filter(
                    (a) => a.appliedOutOfPocket > 0
                  )}
                  formatter={formatUSD}
                  isHealthPlan={isPlanLevel}
                  benefitCreatedAt={coverageBenefit.createdAt}
                />
              )}
            {benefitType === "visits" &&
              (isDefined(coverageBenefit?.remainingVisits) ||
                isDefined(coverageBenefit?.maxVisits)) && (
                <LimitationBenefitProgressDisplay
                  title="Visits"
                  remaining={coverageBenefit?.remainingVisits ?? null}
                  max={coverageBenefit?.maxVisits ?? null}
                  adjustment={benefitAdjustments.appliedVisits}
                  patientId={patientId}
                  remainingBenefit={
                    coverageBenefit?.remainingVisitsBenefit ?? null
                  }
                  maxBenefit={coverageBenefit?.maxVisitsBenefit ?? null}
                  benefitAccumulatorAdjustments={benefitAccumulatorAdjustments.filter(
                    (a) => a.appliedVisits > 0
                  )}
                  benefitCreatedAt={coverageBenefit.createdAt}
                />
              )}
            {benefitType === "auth" &&
              isDefined(coverageBenefit?.authRequired) && (
                <Card className="min-w-[8em]">
                  <div className="flex flex-col w-full">
                    <label className="text-sm text-gray-700 flex gap-1">
                      Authorization
                    </label>
                    <div className="text-lg font-medium">
                      {coverageBenefit?.authRequired
                        ? "Required"
                        : "Not Required"}
                    </div>
                  </div>
                </Card>
              )}
          </>
        );
      })}
    </div>
  );
};

const DeductibleBenefitProgressDisplay: React.FC<
  React.PropsWithChildren<{
    title: string;
    remaining: number | null;
    max: number | null;
    adjustment: number;
    patientId: string;
    formatter?: (value: number) => string;
    remainingBenefit: HoverCardBenefitProps<DeductibleBenefit> | null;
    maxBenefit: HoverCardBenefitProps<DeductibleBenefit> | null;
    deductibleApplies: boolean | null;
    benefitAccumulatorAdjustments: BenefitAccumulatorAdjustment[];
    isHealthPlan: boolean;
    benefitCreatedAt: string;
  }>
> = ({
  title,
  remaining: unadjustedRemaining,
  max,
  adjustment,
  patientId,
  remainingBenefit,
  maxBenefit,
  benefitAccumulatorAdjustments,
  formatter = (x: number) => x?.toString(),
  deductibleApplies,
  isHealthPlan,
  benefitCreatedAt,
}) => {
  const flags = useFeatureFlags();
  const appliedAdjustment = Math.min(unadjustedRemaining ?? 0, adjustment ?? 0);
  const remaining = isDefined(unadjustedRemaining)
    ? unadjustedRemaining - appliedAdjustment
    : unadjustedRemaining;
  const met = remaining === 0;

  if (deductibleApplies === false) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">{title}</label>
          <div className="italic">Deductible does not apply</div>
        </div>
      </Card>
    );
  }
  if (deductibleApplies == true && max === 0) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">{title}</label>
          <div className="italic">
            {isHealthPlan ? (
              <>Not applicable</>
            ) : (
              <>Plan level deductible applies</>
            )}
          </div>
        </div>
      </Card>
    );
  }
  // If the deductibleApplies but there is no service level set
  if (deductibleApplies === true && remaining === null && max === null) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">{title}</label>
          <div className="italic">
            {isHealthPlan ? (
              <>No information</>
            ) : (
              <>Plan level deductible applies</>
            )}
          </div>
        </div>
      </Card>
    );
  }
  if (remaining === null || max === null) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">
            {title}
            {met && <CheckIcon className="text-green-600 h-4 w-4" />}
            {(remainingBenefit || maxBenefit) && (
              <BenefitHoverCard
                patientId={patientId}
                benefits={[
                  ...(remainingBenefit
                    ? [
                        {
                          ...remainingBenefit,
                          type: "Remaining Deductible",
                          value: mapNullable(formatUSD)(remaining) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                  ...(maxBenefit
                    ? [
                        {
                          ...maxBenefit,
                          type: "Max Deductible",
                          value: mapNullable(formatUSD)(max) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            )}
            {flags.automatedAccumulatorTrackingEnabled &&
              benefitAccumulatorAdjustments.length > 0 && (
                <HoverCard.Root openDelay={100}>
                  <HoverCard.Trigger asChild>
                    <div className="p-0.5 rounded-md hover:bg-gray-50">
                      <AdjustmentsIcon className="h-3.5 text-slate-500" />
                    </div>
                  </HoverCard.Trigger>
                  <HoverCard.Content side="top" sideOffset={5}>
                    <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                      <div className="flex flex-col">
                        <div className="font-medium">
                          Activity towards benefit
                        </div>
                        {benefitAccumulatorAdjustments.map((adj) => {
                          return (
                            <div className="flex items-center justify-between gap-2">
                              <div>
                                {formatDistanceToNow(
                                  parseISO(
                                    adj.accumulatorAdjustment.appointment.start
                                  ),
                                  {
                                    addSuffix: true,
                                  }
                                )}{" "}
                                with{" "}
                                {
                                  adj.accumulatorAdjustment.appointment.provider
                                    ?.displayName
                                }
                              </div>
                              <div className="text-sm">
                                <BadgeDelta
                                  deltaType="decrease"
                                  isIncreasePositive={false}
                                >
                                  Deductible: {formatUSD(adj.appliedDeductible)}
                                </BadgeDelta>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </HoverCard.Content>
                </HoverCard.Root>
              )}
          </label>
          <div className="text-lg font-medium">
            {mapNullable(formatter)(remaining ?? max)}
            {isDefined(remaining) ? " Left" : " Max"}
          </div>
        </div>
      </Card>
    );
  }
  return (
    <Card className="min-w-[8em]">
      <div className="flex flex-col w-full">
        <label className="text-sm text-gray-700 flex gap-1">
          {title}
          {met && <CheckIcon className="text-green-600 h-4 w-4" />}
          {(remainingBenefit || maxBenefit) && (
            <BenefitHoverCard
              patientId={patientId}
              benefits={[
                ...(remainingBenefit
                  ? [
                      {
                        ...remainingBenefit,
                        type: "Remaining Deductible",
                        value: mapNullable(formatUSD)(remaining) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
                ...(maxBenefit
                  ? [
                      {
                        ...maxBenefit,
                        type: "Max Deductible",
                        value: mapNullable(formatUSD)(max) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {flags.automatedAccumulatorTrackingEnabled &&
            benefitAccumulatorAdjustments.length > 0 && (
              <HoverCard.Root openDelay={100}>
                <HoverCard.Trigger asChild>
                  <div className="p-0.5 rounded-md hover:bg-gray-50">
                    <AdjustmentsIcon className="h-3.5 text-slate-500" />
                  </div>
                </HoverCard.Trigger>
                <HoverCard.Content side="top" sideOffset={5}>
                  <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                    <div className="flex flex-col">
                      <div className="font-medium">
                        Activity towards benefit
                      </div>
                      {benefitAccumulatorAdjustments.map((adj) => {
                        return (
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              {formatDistanceToNow(
                                parseISO(
                                  adj.accumulatorAdjustment.appointment.start
                                ),
                                { addSuffix: true }
                              )}{" "}
                              with{" "}
                              {
                                adj.accumulatorAdjustment.appointment.provider
                                  ?.displayName
                              }
                            </div>
                            <div className="text-sm">
                              <BadgeDelta
                                deltaType="decrease"
                                isIncreasePositive={false}
                              >
                                Deductible: {formatUSD(adj.appliedDeductible)}
                              </BadgeDelta>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </HoverCard.Content>
              </HoverCard.Root>
            )}
        </label>
        <div className="text-lg font-medium flex items-center justify-between gap-2">
          {mapNullable(formatter)(remaining)} left
          <div className="text-sm">
            {isDefined(max) && <> of </>}
            {mapNullable(formatter)(max)}
          </div>
        </div>

        <div>
          {flags.automatedAccumulatorTrackingEnabled ? (
            <HoverCard.Root openDelay={100}>
              <HoverCard.Trigger asChild>
                <div>
                  <MultiProgressBar
                    className="h-2"
                    bars={[
                      {
                        className: "bg-green-600",
                        percentage: (max - (unadjustedRemaining ?? 0)) / max,
                      },
                      {
                        className: "bg-green-600 group-hover:bg-yellow-500",
                        percentage: (appliedAdjustment ?? 0) / max,
                      },
                    ]}
                  />
                </div>
              </HoverCard.Trigger>
              <HoverCard.Content side="top" sideOffset={5}>
                <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                  <div className="flex flex-col">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            Deductible as of{" "}
                            {formatDateMMDDYYYY(benefitCreatedAt)}
                          </td>
                          <td className="text-right pl-2">
                            {formatUSD(unadjustedRemaining ?? 0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="flex items-center gap-1">
                            Activity towards Deductible
                            <Tooltip
                              content={
                                <>
                                  Only includes activity tracked for your
                                  practice in Pledge
                                </>
                              }
                              trigger={
                                <InformationCircleIcon className="h-4 w-4 text-gray-500" />
                              }
                            />
                          </td>
                          <td className="text-right pl-2">
                            {formatUSD(appliedAdjustment)}
                          </td>
                        </tr>
                        <tr className="border-t border-gray-100 font-semibold">
                          <td>Remaining Deductible</td>
                          <td className="text-right pl-2">
                            {formatUSD(
                              (unadjustedRemaining ?? 0) -
                                (appliedAdjustment ?? 0)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </HoverCard.Content>
            </HoverCard.Root>
          ) : (
            <ProgressBar className="h-2" percentage={(max - remaining) / max} />
          )}
        </div>
      </div>
    </Card>
  );
};

const BenefitProgressDisplay: React.FC<
  React.PropsWithChildren<{
    title: string;
    remaining: number | null;
    max: number | null;
    adjustment: number | null;
    patientId: string;
    formatter?: (value: number) => string;
    remainingBenefit: HoverCardBenefitProps<
      DeductibleBenefit | OutOfPocketBenefit | VisitsBenefit
    > | null;
    maxBenefit: HoverCardBenefitProps<
      DeductibleBenefit | OutOfPocketBenefit | VisitsBenefit
    > | null;
    benefitAccumulatorAdjustments: BenefitAccumulatorAdjustment[];
    isHealthPlan?: boolean;
    benefitCreatedAt: string;
  }>
> = ({
  title,
  remaining: unadjustedRemaining,
  max,
  adjustment,
  patientId,
  remainingBenefit,
  maxBenefit,
  benefitAccumulatorAdjustments,
  formatter = (x: number) => x?.toString(),
  isHealthPlan,
  benefitCreatedAt,
}) => {
  const flags = useFeatureFlags();
  const appliedAdjustment = Math.min(unadjustedRemaining ?? 0, adjustment ?? 0);
  const remaining = isDefined(unadjustedRemaining)
    ? unadjustedRemaining - appliedAdjustment
    : unadjustedRemaining;

  const met = remaining === 0;
  const benefit = remainingBenefit ?? maxBenefit;

  if (isHealthPlan && max === 0) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">{title}</label>
          <div className="italic">Not applicable</div>
        </div>
      </Card>
    );
  }

  if (remaining === null || max === null) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">
            {title}
            {met && <CheckIcon className="text-green-600 h-4 w-4" />}
            {(remainingBenefit || maxBenefit) && (
              <BenefitHoverCard
                patientId={patientId}
                benefits={[
                  ...(remainingBenefit
                    ? [
                        {
                          ...remainingBenefit,
                          type: `Remaining ${title}`,
                          value: mapNullable(formatUSD)(remaining) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                  ...(maxBenefit
                    ? [
                        {
                          ...maxBenefit,
                          type: `Max ${title}`,
                          value: mapNullable(formatUSD)(max) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            )}
            {flags.automatedAccumulatorTrackingEnabled &&
              benefitAccumulatorAdjustments.length > 0 && (
                <HoverCard.Root openDelay={100}>
                  <HoverCard.Trigger asChild>
                    <div className="p-0.5 rounded-md hover:bg-gray-50">
                      <AdjustmentsIcon className="h-3.5 text-slate-500" />
                    </div>
                  </HoverCard.Trigger>
                  <HoverCard.Content side="top" sideOffset={5}>
                    <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                      <div className="flex flex-col">
                        <div className="font-medium">
                          Activity towards benefit
                        </div>
                        {benefitAccumulatorAdjustments.map((adj) => {
                          return (
                            <div className="flex items-center justify-between gap-2">
                              <div>
                                {formatDistanceToNow(
                                  parseISO(
                                    adj.accumulatorAdjustment.appointment.start
                                  ),
                                  {
                                    addSuffix: true,
                                  }
                                )}{" "}
                                with{" "}
                                {
                                  adj.accumulatorAdjustment.appointment.provider
                                    ?.displayName
                                }
                              </div>
                              <div className="text-sm">
                                <BadgeDelta
                                  deltaType="decrease"
                                  isIncreasePositive={false}
                                >
                                  Out of Pocket:{" "}
                                  {formatUSD(adj.appliedOutOfPocket)}
                                </BadgeDelta>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </HoverCard.Content>
                </HoverCard.Root>
              )}
          </label>
          <div className="text-lg font-medium">
            {mapNullable(formatter)(remaining ?? max)}
            {isDefined(remaining) ? " Left" : " Max"}
          </div>
        </div>
      </Card>
    );
  }
  return (
    <Card className="min-w-[8em]">
      <div className="flex flex-col w-full">
        <label className="text-sm text-gray-700 flex gap-1">
          {title}
          {met && <CheckIcon className="text-green-600 h-4 w-4" />}
          {(remainingBenefit || maxBenefit) && (
            <BenefitHoverCard
              patientId={patientId}
              benefits={[
                ...(remainingBenefit
                  ? [
                      {
                        ...remainingBenefit,
                        type: `Remaining ${title}`,
                        value: mapNullable(formatUSD)(remaining) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
                ...(maxBenefit
                  ? [
                      {
                        ...maxBenefit,
                        type: `Max ${title}`,
                        value: mapNullable(formatUSD)(max) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {flags.automatedAccumulatorTrackingEnabled &&
            benefitAccumulatorAdjustments.length > 0 && (
              <HoverCard.Root openDelay={100}>
                <HoverCard.Trigger asChild>
                  <div className="p-0.5 rounded-md hover:bg-gray-50">
                    <AdjustmentsIcon className="h-3.5 text-slate-500" />
                  </div>
                </HoverCard.Trigger>
                <HoverCard.Content side="top" sideOffset={5}>
                  <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                    <div className="flex flex-col">
                      <div className="font-medium">
                        Activity towards benefit
                      </div>
                      {benefitAccumulatorAdjustments.map((adj) => {
                        return (
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              {formatDistanceToNow(
                                parseISO(
                                  adj.accumulatorAdjustment.appointment.start
                                ),
                                { addSuffix: true }
                              )}{" "}
                              with{" "}
                              {
                                adj.accumulatorAdjustment.appointment.provider
                                  ?.displayName
                              }
                            </div>
                            <div className="text-sm">
                              <BadgeDelta
                                deltaType="decrease"
                                isIncreasePositive={false}
                              >
                                Out of Pocket:{" "}
                                {formatUSD(adj.appliedOutOfPocket)}
                              </BadgeDelta>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </HoverCard.Content>
              </HoverCard.Root>
            )}
        </label>
        <div className="text-lg font-medium flex items-center justify-between gap-2">
          {mapNullable(formatter)(remaining)} left
          <div className="text-sm">
            {isDefined(max) && <> of </>}
            {mapNullable(formatter)(max)}
          </div>
        </div>

        <div>
          {flags.automatedAccumulatorTrackingEnabled ? (
            <HoverCard.Root openDelay={100}>
              <HoverCard.Trigger asChild>
                <div>
                  <MultiProgressBar
                    className="h-2"
                    bars={[
                      {
                        className: "bg-green-600",
                        percentage: (max - (unadjustedRemaining ?? 0)) / max,
                      },
                      {
                        className: "bg-green-600 group-hover:bg-yellow-500",
                        percentage: (appliedAdjustment ?? 0) / max,
                      },
                    ]}
                  />
                </div>
              </HoverCard.Trigger>
              <HoverCard.Content side="top" sideOffset={5}>
                <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                  <div className="flex flex-col">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            Out of Pocket as of{" "}
                            {formatDateMMDDYYYY(benefitCreatedAt)}
                          </td>
                          <td className="text-right pl-2">
                            {formatUSD(unadjustedRemaining ?? 0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="flex items-center gap-1">
                            Activity towards Out of Pocket
                            <Tooltip
                              content={
                                <>
                                  Only includes activity tracked for your
                                  practice in Pledge
                                </>
                              }
                              trigger={
                                <InformationCircleIcon className="h-4 w-4 text-gray-500" />
                              }
                            />
                          </td>
                          <td className="text-right pl-2">
                            {formatUSD(appliedAdjustment)}
                          </td>
                        </tr>
                        <tr className="border-t border-gray-100 font-semibold">
                          <td>Remaining Out of Pocket</td>
                          <td className="text-right pl-2">
                            {formatUSD(
                              (unadjustedRemaining ?? 0) -
                                (appliedAdjustment ?? 0)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </HoverCard.Content>
            </HoverCard.Root>
          ) : (
            <ProgressBar className="h-2" percentage={(max - remaining) / max} />
          )}
        </div>
      </div>
    </Card>
  );
};

const LimitationBenefitProgressDisplay: React.FC<
  React.PropsWithChildren<{
    title: string;
    remaining: number | null;
    max: number | null;
    adjustment: number | null;
    patientId: string;
    remainingBenefit: HoverCardBenefitProps<VisitsBenefit> | null;
    maxBenefit: HoverCardBenefitProps<VisitsBenefit> | null;
    benefitAccumulatorAdjustments: BenefitAccumulatorAdjustment[];
    formatter?: (value: number) => string;
    benefitCreatedAt: string;
  }>
> = ({
  title,
  remaining: unadjustedRemaining,
  max,
  adjustment,
  patientId,
  remainingBenefit,
  maxBenefit,
  benefitAccumulatorAdjustments,
  formatter = (x: number) => x?.toString(),
  benefitCreatedAt,
}) => {
  const flags = useFeatureFlags();
  const appliedAdjustment = Math.min(unadjustedRemaining ?? 0, adjustment ?? 0);
  const remaining = isDefined(unadjustedRemaining)
    ? unadjustedRemaining - appliedAdjustment
    : unadjustedRemaining;

  const met = remaining === 0;
  const benefit = remainingBenefit ?? maxBenefit;
  if (remaining === null || max === null) {
    return (
      <Card className="min-w-[8em]">
        <div className="flex flex-col w-full">
          <label className="text-sm text-gray-700 flex gap-1">
            {title}
            {(remainingBenefit || maxBenefit) && (
              <BenefitHoverCard
                patientId={patientId}
                benefits={[
                  ...(remainingBenefit
                    ? [
                        {
                          ...remainingBenefit,
                          type: `Remaining ${title}`,
                          value: mapNullable(formatter)(remaining) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                  ...(maxBenefit
                    ? [
                        {
                          ...maxBenefit,
                          type: `Max ${title}`,
                          value: mapNullable(formatter)(max) ?? (
                            <span className="text-gray-500">N/A</span>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </label>
          <div
            className={classNames(
              "text-lg font-medium",
              met ? "text-red-600" : ""
            )}
          >
            {mapNullable(formatter)(remaining ?? max)}
            {isDefined(remaining) ? " Left" : " Max"}
          </div>
        </div>
      </Card>
    );
  }

  const percentage = remaining / max;
  let innerBarClassName = "bg-green-600";
  if (percentage < 0.3) innerBarClassName = "bg-yellow-500";
  if (percentage === 0) innerBarClassName = "bg-red-500";
  return (
    <Card className="min-w-[8em]">
      <div className="flex flex-col w-full">
        <label className="text-sm text-gray-700 flex gap-1">
          {title}
          {(remainingBenefit || maxBenefit) && (
            <BenefitHoverCard
              patientId={patientId}
              benefits={[
                ...(remainingBenefit
                  ? [
                      {
                        ...remainingBenefit,
                        type: `Remaining ${title}`,
                        value: mapNullable(formatter)(remaining) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
                ...(maxBenefit
                  ? [
                      {
                        ...maxBenefit,
                        type: `Max ${title}`,
                        value: mapNullable(formatter)(max) ?? (
                          <span className="text-gray-500">N/A</span>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {flags.automatedAccumulatorTrackingEnabled &&
            benefitAccumulatorAdjustments.length > 0 && (
              <HoverCard.Root openDelay={100}>
                <HoverCard.Trigger asChild>
                  <div className="p-0.5 rounded-md hover:bg-gray-50">
                    <AdjustmentsIcon className="h-3.5 text-slate-500" />
                  </div>
                </HoverCard.Trigger>
                <HoverCard.Content side="top" sideOffset={5}>
                  <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                    <div className="flex flex-col">
                      <div className="font-medium">
                        Activity towards benefit
                      </div>
                      <div>
                        {benefitAccumulatorAdjustments.map((adj) => {
                          return (
                            <div key={adj.id}>
                              <div className="flex items-center justify-between gap-2">
                                <div>
                                  {formatDistanceToNow(
                                    parseISO(
                                      adj.accumulatorAdjustment.appointment
                                        .start
                                    ),
                                    { addSuffix: true }
                                  )}{" "}
                                  with{" "}
                                  {
                                    adj.accumulatorAdjustment.appointment
                                      .provider?.displayName
                                  }
                                </div>
                                <div className="text-sm">
                                  <BadgeDelta
                                    deltaType="decrease"
                                    isIncreasePositive={true}
                                  >
                                    Visits: {adj.appliedVisits}
                                  </BadgeDelta>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </HoverCard.Content>
              </HoverCard.Root>
            )}
        </label>
        <div className="flex items-center justify-between gap-2">
          <div
            className={classNames(
              "text-lg font-medium",
              met ? "text-red-600" : ""
            )}
          >
            {mapNullable(formatter)(remaining)} left
          </div>
          <div className="text-sm">
            {isDefined(max) && <> of </>}
            {mapNullable(formatter)(max)}
          </div>
        </div>

        <div>
          {flags.automatedAccumulatorTrackingEnabled ? (
            <HoverCard.Root openDelay={100}>
              <HoverCard.Trigger asChild>
                <div>
                  <MultiProgressBar
                    className="h-2"
                    bars={[
                      {
                        className: "bg-green-600",
                        percentage: (max - (unadjustedRemaining ?? 0)) / max,
                      },
                      {
                        className: "bg-green-600 group-hover:bg-yellow-500",
                        percentage: (appliedAdjustment ?? 0) / max,
                      },
                    ]}
                  />
                </div>
              </HoverCard.Trigger>
              <HoverCard.Content side="top" sideOffset={5}>
                <div className="flex gap-4 p-4 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
                  <div className="flex flex-col">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            Remaining Visits as of{" "}
                            {formatDateMMDDYYYY(benefitCreatedAt)}
                          </td>
                          <td className="text-right pl-2">
                            {unadjustedRemaining ?? 0}
                          </td>
                        </tr>
                        <tr>
                          <td className="flex items-center gap-1">
                            Activity towards Visit Limit
                            <Tooltip
                              content={
                                <>
                                  Only includes activity tracked for your
                                  practice in Pledge
                                </>
                              }
                              trigger={
                                <InformationCircleIcon className="h-4 w-4 text-gray-500" />
                              }
                            />
                          </td>
                          <td className="text-right pl-2">
                            {appliedAdjustment}
                          </td>
                        </tr>
                        <tr className="border-t border-gray-100 font-semibold">
                          <td>Remaining Visits</td>
                          <td className="text-right pl-2">
                            {(unadjustedRemaining ?? 0) -
                              (appliedAdjustment ?? 0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </HoverCard.Content>
            </HoverCard.Root>
          ) : (
            <ProgressBar className="h-2" percentage={(max - remaining) / max} />
          )}
        </div>
      </div>
    </Card>
  );
};

const GET_COVERAGE_BENEFIT_HISTORY = gql`
  ${COVERAGE_BENEFIT_FIELDS}
  query GetCoverageBenefitHistory($insurancePolicyId: String!) {
    coverageBenefits(
      where: { insurancePolicyId: { equals: $insurancePolicyId } }
      orderBy: { createdAt: asc }
    ) {
      id
      createdAt
      ...CoverageBenefitFields
      createdBy {
        id
        firstName
        lastName
      }
      eligibilityCoverageBenefitSnapshot {
        id
        eligibilityBenefitExtraction {
          id
          eligibilityRequestId
        }
      }
    }
  }
`;

type AttributeDiff = {
  key: string;
  originalValue: any;
  updatedValue: any;
  isChanged: boolean;
};

type CoverageBenefitDiff = {
  type: "created" | "updated";
  diffs: AttributeDiff[];
};

const COVERAGE_BENFIT_DIFF_ATTRIBUTES = [
  "copay" as const,
  "coinsurance" as const,
  "remainingDeductible" as const,
  "maxDeductible" as const,
  "remainingOutOfPocket" as const,
  "maxOutOfPocket" as const,
  "remainingVisits" as const,
  "maxVisits" as const,
  "nonCovered" as const,
  "authRequired" as const,
];

/**
 * A function that takes two coverage benefits and returns a list of
 * differences between the two.
 */
const coverageBenefitDiff = (
  original: CoverageBenefit | null,
  updated: CoverageBenefit
): CoverageBenefitDiff => {
  const differences = [];

  // If the benefit is starting to be tracked and is just marked as empty, then it's empty
  if (original === null && updated.empty) {
    return {
      type: "created",
      diffs: [],
    };
  }

  // Iterate over the properties of the updated object
  for (const key of COVERAGE_BENFIT_DIFF_ATTRIBUTES) {
    const originalValue = original ? original[key] : null;
    const updatedValue = updated[key];
    // TODO: better comparison for objects
    const isChanged =
      JSON.stringify(originalValue) !== JSON.stringify(updatedValue);
    // Add the difference to the list
    differences.push({
      key,
      originalValue,
      updatedValue,
      isChanged,
    });
  }

  return {
    type: original ? "updated" : "created",
    diffs: differences,
  };
};

const Empty = () => <span className="text-gray-600 italic">Empty</span>;
const boolToDisplay = (value: boolean | null) => {
  if (value === null) {
    return <Empty />;
  }
  return value ? "Yes" : "No";
};

const diffToDirection = (diff: AttributeDiff) => {
  if (diff.isChanged) {
    if (diff.originalValue === null || diff.updatedValue === null) {
      return "updated";
    }
    const originalNumber = Number(diff.originalValue);
    const updatedNumber = Number(diff.updatedValue);
    if (!Number.isNaN(originalNumber) && !Number.isNaN(updatedNumber)) {
      if (originalNumber > updatedNumber) {
        return "decreased";
      } else {
        return "increased";
      }
    }
    return "updated";
  }
  return "unchanged";
};

const DiffDisplay: React.FC<
  React.PropsWithChildren<{
    diff: AttributeDiff;
  }>
> = ({ diff }) => {
  if (diff.key === "copay") {
    return (
      <>
        Copay was {diffToDirection(diff)} from{" "}
        {mapNullable(formatUSD)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatUSD)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "coinsurance") {
    return (
      <>
        Coinsurance was {diffToDirection(diff)} from{" "}
        {mapNullable(formatPercentage)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatPercentage)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "remainingDeductible") {
    return (
      <>
        Remaining Deductible was {diffToDirection(diff)} from{" "}
        {mapNullable(formatUSD)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatUSD)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "maxDeductible") {
    return (
      <>
        Max Deductible was {diffToDirection(diff)} from{" "}
        {mapNullable(formatUSD)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatUSD)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "remainingOutOfPocket") {
    return (
      <>
        Remaining Out of Pocket was {diffToDirection(diff)} from{" "}
        {mapNullable(formatUSD)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatUSD)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "maxOutOfPocket") {
    return (
      <>
        Max Out of Pocket was {diffToDirection(diff)} from{" "}
        {mapNullable(formatUSD)(diff.originalValue) ?? <Empty />} to{" "}
        {mapNullable(formatUSD)(diff.updatedValue) ?? <Empty />}
      </>
    );
  }
  if (diff.key === "remainingVisits") {
    return (
      <>
        Remaining Visits was {diffToDirection(diff)} from{" "}
        {diff.originalValue ?? <Empty />} to {diff.updatedValue ?? <Empty />}
      </>
    );
  }
  if (diff.key === "maxVisits") {
    return (
      <>
        Max Visits was {diffToDirection(diff)} from{" "}
        {diff.originalValue ?? <Empty />} to {diff.updatedValue ?? <Empty />}
      </>
    );
  }
  if (diff.key === "nonCovered") {
    return (
      <>
        Non Covered was updated from {boolToDisplay(diff.originalValue)} to{" "}
        {boolToDisplay(diff.updatedValue)}
      </>
    );
  }
  if (diff.key === "authRequired") {
    return (
      <>
        Auth Required was updated from {boolToDisplay(diff.originalValue)} to{" "}
        {boolToDisplay(diff.updatedValue)}
      </>
    );
  }
  return (
    <>
      {diff.key} was updated from {JSON.stringify(diff.originalValue)} to{" "}
      {JSON.stringify(diff.updatedValue)}
    </>
  );
};

type HistoryGroupedByChange = {
  renewalResetId: string | null;
  restoreId: string | null;
  eligibilityRequestId: string | null;
  planTypeId: string | null;
  timestamp: Date;
  versions: {
    [benefitName: string]: {
      network: string;
      diff: CoverageBenefitDiff;
      version: HistoryCoverageBenefit;
    }[];
  };
};

const CoverageBenefitDiffCard: React.FC<{
  patientId: string;
  changeGroup: HistoryGroupedByChange;
}> = ({ patientId, changeGroup }) => {
  const {
    eligibilityRequestId,
    timestamp,
    versions,
    renewalResetId,
    planTypeId,
    restoreId,
  } = changeGroup;
  const changes = Object.values(versions)
    .filter((version) =>
      Object.values(version).some((v) => v.diff.diffs.some((d) => d.isChanged))
    )
    .flat();
  const [firstChange] = changes;
  const createdBy = [
    firstChange?.version?.createdBy?.firstName,
    firstChange?.version?.createdBy?.lastName,
  ]
    .filter(isDefined)
    .join(" ");
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="flex flex-wrap">
            <span className="font-medium pr-1">
              {createdBy ? (
                createdBy
              ) : (
                <div className="flex items-center gap-1">
                  <CogIcon className="h-4 w-4 text-gray-500" />
                  Automation
                </div>
              )}
            </span>{" "}
            {restoreId ? "restored" : "updated"} benefits{"  "}
            {eligibilityRequestId ? (
              <Link
                to={`/patients/${patientId}/eligibilities/${eligibilityRequestId}`}
                key={eligibilityRequestId}
                className="hover:text-gray-600 ml-1"
              >
                via Eligibility Request
              </Link>
            ) : renewalResetId ? (
              "due to policy renewal"
            ) : planTypeId ? (
              "due to plan type template"
            ) : (
              "manually"
            )}
          </div>
        </div>
        <Tooltip
          trigger={
            <time
              dateTime={timestamp.toISOString()}
              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
            >
              {formatDistanceToNow(timestamp, {
                addSuffix: true,
              })}
            </time>
          }
          content={<>{formatDateMMDDYYYY(timestamp.toISOString())}</>}
        />
      </div>
      {changes
        .filter(({ diff }) => diff.diffs.some((d) => d.isChanged))
        .map(({ network, diff, version }) => (
          <div className="text-sm">
            <div className="text-gray-500 font-medium">
              {version.networkStatus ? "In Network" : "Out of Network"} -{" "}
              {version.providerServiceConfiguration.name}
              {version.coverageLevel ? ` (${version.coverageLevel})` : null}
            </div>
            <ul className="text-sm pl-2 list-inside list-disc">
              {diff.diffs
                .filter((diff) => diff.isChanged)
                .map((diff) => (
                  <li>
                    <DiffDisplay key={diff.key} diff={diff} />
                  </li>
                ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

const RESTORE_PREVIOUS_COVERAGE_BENEFITS = gql`
  mutation RestorePreviousCoverageBenefits(
    $mostRecentTimestamp: DateTime!
    $insurancePolicyId: String!
    $renewalDate: DateTime!
    $effectiveDate: DateTime!
  ) {
    restorePreviousCoverageBenefits(
      mostRecentTimestamp: $mostRecentTimestamp
      insurancePolicyId: $insurancePolicyId
      renewalDate: $renewalDate
      effectiveDate: $effectiveDate
    ) {
      id
    }
  }
`;

const FullCoverageBenefitVersionHistoryDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  patientId: string;
  insurancePolicyId: string;
  changeGroups: HistoryGroupedByChange[];
}> = ({ open, setOpen, patientId, changeGroups, insurancePolicyId }) => {
  const [
    restorePreviousCoverageBenefits,
    restorePreviousCoverageBenefitsResult,
  ] = useMutation<
    RestorePreviousCoverageBenefits,
    RestorePreviousCoverageBenefitsVariables
  >(RESTORE_PREVIOUS_COVERAGE_BENEFITS);
  const [isRestoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedChangeGroup, setSelectedChangeGroup] =
    useState<HistoryGroupedByChange | null>(null);
  const [renewalDate, setRenewalDate] = useState<string>("");
  const [effectiveDate, setEffectiveDate] = useState<string>("");

  const isInRenewalState = isDefined(changeGroups[0]?.renewalResetId);
  const restoreSnapshot = (changeGroup: HistoryGroupedByChange | null) => {
    if (changeGroup) {
      const mostRecentTimestamp = Object.values(changeGroup.versions)
        .flatMap((version) => version.map((entry) => entry.version.createdAt))
        .reduce((latest, current) =>
          new Date(current) > new Date(latest) ? current : latest
        );

      restorePreviousCoverageBenefits({
        variables: {
          mostRecentTimestamp: new Date(mostRecentTimestamp),
          insurancePolicyId,
          renewalDate: new Date(renewalDate),
          effectiveDate: new Date(effectiveDate),
        },
        onCompleted: () => {
          toast.success("Benefits restored to previous snapshot");
          setOpen(false);
        },
        onError: () => {
          toast.error("Failed to restore benefits to previous snapshot");
        },
        refetchQueries: [GET_INSURANCE_POLICY],
      });
    } else {
      toast.error("Failed to restore benefits to previous snapshot");
    }
  };

  const isValidDate = (dateString: string): boolean => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const isEffectiveDateBeforeRenewal = (
    effectiveDate: string,
    renewalDate: string
  ): boolean => {
    return new Date(effectiveDate) < new Date(renewalDate);
  };

  const isRenewalDateInFuture = (renewalDate: string): boolean => {
    return new Date(renewalDate) > new Date();
  };

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      {isRestoreDialogOpen ? (
        <>
          <div className="mt-3 text-center text-lg sm:mt-5">
            Confirm Restore
          </div>
          <div className="mt-3">
            <p>
              Please confirm the renewal and effective date for the restored
              policy
            </p>
          </div>
          <div className="mt-3 flex flex-col space-y-4">
            <label className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                Renewal Date
              </span>
              <Input
                type="date"
                className={cn(
                  "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                  renewalDate &&
                    !isRenewalDateInFuture(renewalDate) &&
                    "border-red-300"
                )}
                onChange={(e) => setRenewalDate(e.target.value)}
              />
              {renewalDate && !isRenewalDateInFuture(renewalDate) && (
                <span className="text-sm text-red-500 mt-1">
                  Renewal date must be in the future
                </span>
              )}
            </label>
            <label className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                Effective Date
              </span>
              <Input
                type="date"
                className={cn(
                  "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                  effectiveDate &&
                    renewalDate &&
                    !isEffectiveDateBeforeRenewal(effectiveDate, renewalDate) &&
                    "border-red-300"
                )}
                onChange={(e) => setEffectiveDate(e.target.value)}
              />
              {effectiveDate &&
                renewalDate &&
                !isEffectiveDateBeforeRenewal(effectiveDate, renewalDate) && (
                  <span className="text-sm text-red-500 mt-1">
                    Effective date must be before renewal date
                  </span>
                )}
            </label>
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setRestoreDialogOpen(false)}
            >
              Cancel
            </button>
            <SubmitButton
              type="button"
              onClick={() => {
                restoreSnapshot(selectedChangeGroup);
                setRestoreDialogOpen(false);
              }}
              disabled={
                !renewalDate ||
                !effectiveDate ||
                !isValidDate(renewalDate) ||
                !isValidDate(effectiveDate) ||
                !isRenewalDateInFuture(renewalDate) ||
                !isEffectiveDateBeforeRenewal(effectiveDate, renewalDate)
              }
            >
              Restore
            </SubmitButton>
          </div>
        </>
      ) : (
        <>
          <div className="mt-3 text-center text-lg sm:mt-5">
            Benefits Version History
          </div>
          <div className="flex flex-col max-h-[80vh] overflow-auto py-2 pr-2">
            <ul role="list" className="space-y-4 w-full">
              {changeGroups.map((changeGroup, i) => (
                <li key={i} className="relative flex sm:gap-x-4">
                  <div
                    className={classNames(
                      i === changeGroups.length - 1 ? "h-6" : "-bottom-6",
                      "absolute left-0 top-0 flex w-6 justify-center"
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    {isInRenewalState && !changeGroup.renewalResetId ? (
                      <Tooltip
                        trigger={
                          <HistoryIcon
                            onClick={() => {
                              setSelectedChangeGroup(changeGroup);
                              setRestoreDialogOpen(true);
                            }}
                            className="h-4 w-4 cursor-pointer hover:text-indigo-700"
                          />
                        }
                        content={<>Restore previous snapshot</>}
                      />
                    ) : (
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    )}
                  </div>
                  <Card className="w-full">
                    <CoverageBenefitDiffCard
                      patientId={patientId}
                      changeGroup={changeGroup}
                    />
                  </Card>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const CoverageVersionHistory: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ insurancePolicy, open, setOpen }) => {
  const { data, loading } = useQuery<
    GetCoverageBenefitHistory,
    GetCoverageBenefitHistoryVariables
  >(GET_COVERAGE_BENEFIT_HISTORY, {
    variables: {
      insurancePolicyId: insurancePolicy.id,
    },
  });

  const versions = data?.coverageBenefits ?? [];

  const versionsByNetworkService = versions.reduce(
    (
      acc: {
        [inNetwork: string]: {
          [serviceCoverageLevelKey: string]: HistoryCoverageBenefit[];
        };
      },
      version
    ) => {
      const serviceId = version.providerServiceConfiguration.id;
      const coverageLevel = version.coverageLevel;
      const serviceCoverageLevelKey = serviceId + coverageLevel;
      const networkKey = String(version.networkStatus);
      let network = acc[networkKey] ?? {};
      if (!network[serviceCoverageLevelKey]) {
        network[serviceCoverageLevelKey] = [];
      }
      network[serviceCoverageLevelKey].push(version);
      acc[networkKey] = network;
      return acc;
    },
    {}
  );

  const versionHistoriesByService = Object.entries(
    versionsByNetworkService
  ).map(([network, networks]) => {
    return Object.values(networks).map((versions) => {
      return versions.map((version, i, arr) => {
        const previous = arr[i - 1] ?? null;
        return {
          network,
          version,
          diff: coverageBenefitDiff(previous, version),
        };
      });
    });
  });

  const versionHistories = versionHistoriesByService
    .flat()
    .flat()
    .sort((a, b) => {
      return compareDesc(
        parseISO(a.version.createdAt),
        parseISO(b.version.createdAt)
      );
    });

  const versionsGroupedByChange = versionHistories.reduce(
    (
      acc: {
        [changeId: string]: {
          id: string;
          eligibilityRequestId: string | null;
          renewalResetId: string | null;
          restoreId: string | null;
          planTypeId: string | null;
          timestamp: Date;
          versions: {
            [benefitName: string]: {
              network: string;
              diff: CoverageBenefitDiff;
              version: HistoryCoverageBenefit;
            }[];
          };
        };
      },
      row
    ) => {
      const eligibilityRequestId =
        row.version.eligibilityCoverageBenefitSnapshot
          ?.eligibilityBenefitExtraction.eligibilityRequestId ?? null;
      const renewalResetId = row.version.renewalReset?.id ?? null;
      const restoreId = row.version.restoreId ?? null;
      const planTypeId = row.version.planBenefitTemplate?.planType?.id ?? null;
      const changeId =
        (eligibilityRequestId || restoreId || renewalResetId) ?? v4();
      const benefitName =
        row.network + row.version.providerServiceConfiguration.id;
      const changeGroup = acc[changeId] ?? {
        id: changeId,
        eligibilityRequestId,
        renewalResetId,
        restoreId,
        planTypeId,
        timestamp: parseISO(row.version.createdAt),
        versions: {},
      };
      const benefitGroup = changeGroup.versions[benefitName] ?? [];
      benefitGroup.push(row);
      return {
        ...acc,
        [changeId]: {
          ...changeGroup,
          versions: {
            ...changeGroup.versions,
            [benefitName]: benefitGroup,
          },
        },
      };
    },
    {}
  );

  const versionChangeHistory = Object.values(versionsGroupedByChange).filter(
    ({ versions }) =>
      Object.values(versions).some((version) =>
        Object.values(version).some((v) =>
          v.diff.diffs.some((d) => d.isChanged)
        )
      )
  );

  const [first] = versionChangeHistory;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center">
        <div className="font-medium">Latest Benefits Update</div>

        <Button
          variant="ghost"
          size="sm"
          className="gap-x-1.5 print:hidden text-sm flex items-center text-indigo-600 hover:text-indigo-700"
          onClick={() => setOpen(true)}
        >
          View Full History
        </Button>
      </div>

      <div className="flex flex-col max-h-[80vh] overflow-auto p-2 border rounded-md">
        {loading ? (
          <div className="text-gray-500 italic">Loading history...</div>
        ) : (
          <>
            {first ? (
              <CoverageBenefitDiffCard
                patientId={insurancePolicy.patient.id}
                changeGroup={first}
              />
            ) : (
              <div className="text-gray-500 italic">No benefits history</div>
            )}
          </>
        )}
      </div>
      {open && (
        <FullCoverageBenefitVersionHistoryDialog
          open={open}
          setOpen={setOpen}
          patientId={insurancePolicy.patient.id}
          insurancePolicyId={insurancePolicy.id}
          changeGroups={versionChangeHistory}
        />
      )}
    </div>
  );
};

export const GET_INSURANCE_POLICY = gql`
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${COVERAGE_BENEFIT_FIELDS}
  ${PATIENT_HEADER_FIELDS}
  query GetInsurancePolicy($id: String!) {
    insurancePolicy(where: { id: $id }) {
      id
      ...InsurancePolicySummaryFields
      payer {
        id
        uhcBenefitsSearchEnabled
      }
      customPatientNote
      planCoverageBenefit {
        inNetworkIndividualBenefit {
          ...CoverageBenefitFields
        }
        outOfNetworkIndividualBenefit {
          ...CoverageBenefitFields
        }
        inNetworkFamilyBenefit {
          ...CoverageBenefitFields
        }
        outOfNetworkFamilyBenefit {
          ...CoverageBenefitFields
        }
      }
      inNetworkBenefits: mostRecentCoverageBenefits(inNetwork: true) {
        ...CoverageBenefitFields
      }
      outOfNetworkBenefits: mostRecentCoverageBenefits(inNetwork: false) {
        ...CoverageBenefitFields
      }
      mostRecentCoverageBenefits {
        ...CoverageBenefitFields
      }
      acceptedAt
      acceptedBy {
        id
        firstName
        lastName
      }
      verificationWorkflowStatusId
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt
      renewalResets {
        id
        createdAt
        endedAt
      }
      patient {
        id
        ...PatientHeaderFields
        firstName
        lastName
        email
        displayName
        dateOfBirth
        location {
          id
          verificationWorkflowStatuses(
            orderBy: [{ stage: asc }, { position: asc }]
          ) {
            id
            name
            stage
            description
            createdAt
          }
        }
        organization {
          id
          providerServiceConfiguration(where: { archivedAt: null }) {
            id
            name
            serviceType
            fallbackServiceTypes
            priorityOrder
            showInPdf
            claimType
            placeOfService
            specialist
            virtual
            skipEdiExtraction
            # Only look at overrides for the policy's payer
            providerServiceConfigurationOverrides(
              where: {
                payerProviderServiceConfigurationOverrides: {
                  some: {
                    payer: {
                      is: {
                        insurancePolicies: { some: { id: { equals: $id } } }
                      }
                    }
                  }
                }
              }
            ) {
              id
              payerProviderServiceConfigurationOverrides {
                id
                payer {
                  id
                  name
                }
              }
              skipEdiExtraction
              serviceType
              fallbackServiceTypes
            }
          }
        }
      }
      communications(orderBy: { createdAt: desc }, take: 1) {
        id
        createdAt
        sentAt
      }
      eligibilityRequests(orderBy: { createdAt: desc }) {
        id
        createdAt
        automated
        status
        clearinghouse
        rawEligibilityRequestResponses {
          id
          requestedServiceTypeCode
          requestedServiceTypeDisplay
        }
        requestedBy {
          id
          firstName
          lastName
        }
        insurancePolicy {
          id
          payer {
            id
            name
          }
          plan {
            id
            name
          }
          patient {
            id
          }
        }
        ...EligibilityRequestStatusFields
      }
    }
  }
`;

export const CompletenessScore: React.FC<{
  insurancePolicy: Pick<
    InsurancePolicy,
    "mostRecentCoverageBenefits" | "inNetwork"
  >;
  providerServiceConfigurations: Pick<ProviderServiceConfiguration, "id">[];
}> = ({ insurancePolicy, providerServiceConfigurations }) => {
  const nonEmpty = insurancePolicy.mostRecentCoverageBenefits
    .filter((b) => b.networkStatus === insurancePolicy.inNetwork)
    .filter((b) => !b.empty).length;
  const requested = providerServiceConfigurations.length;
  const complete = requested == nonEmpty;

  return (
    <div className="text-xs text-gray-900 flex items-center gap-2 print:hidden">
      {complete && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
      {!complete && (
        <div className="h-5 w-5 flex items-center justify-center">
          <Circle
            percentage={100 * (nonEmpty / requested)}
            stroke={"#eab308"}
          />
        </div>
      )}
      <div>
        {nonEmpty} of {requested}
      </div>
    </div>
  );
};

const SendBenefitsToLoggedInUserConfirmationDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  insurancePolicy: InsurancePolicy;
}> = ({ open, setOpen, insurancePolicy }) => {
  const user = useUser()!;
  const [sendBenefitsToLoggedInUser, sendBenefitsToLoggedInUserResult] =
    useMutation<
      SendBenefitsToLoggedInUser,
      SendBenefitsToLoggedInUserVariables
    >(SEND_BENEFITS_TO_LOGGED_IN_USER);

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="mt-3 text-center text-lg sm:mt-5">
        Send Benefits Email to {user.email}?
      </div>
      <div className="mt-3">
        <p>Send an email to yourself to view the insurance benefits.</p>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
        <SubmitButton
          type="button"
          onClick={() => {
            sendBenefitsToLoggedInUser({
              variables: {
                insurancePolicyId: insurancePolicy.id,
              },
              onCompleted: () => {
                toast.success("Benefits email sent");
                setOpen(false);
              },
              onError: () => {
                toast.error("Failed to send benefits email");
              },
            });
          }}
          loading={sendBenefitsToLoggedInUserResult.loading}
        >
          Send
        </SubmitButton>
      </div>
    </Modal>
  );
};

const SendBenefitsConfirmationDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  patientEmail: string;
  insurancePolicy: Pick<InsurancePolicy, "id" | "communications">;
}> = ({ open, setOpen, patientEmail, insurancePolicy }) => {
  const [sendBenefits, sendBenefitsResult] = useMutation<
    SendBenefits,
    SendBenefitsVariables
  >(SEND_BENEFITS);

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="mt-3 text-center text-lg sm:mt-5">
        Send Benefits Email?
      </div>
      <div className="mt-3">
        <p>
          Send an email to <span className="font-medium">{patientEmail}</span>{" "}
          patient to view their insurance benefits.
        </p>
        {insurancePolicy.communications.length > 0 && (
          <ul className="list-disc list-inside">
            <li>
              Benefits email sent for this insurance policy last on{" "}
              {formatDateMMDDYYYY(
                insurancePolicy.communications[0].sentAt ??
                  insurancePolicy.communications[0].createdAt
              )}
              .
            </li>
          </ul>
        )}
      </div>
      <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
        <SubmitButton
          type="button"
          onClick={() => {
            sendBenefits({
              variables: {
                insurancePolicyId: insurancePolicy.id,
              },
              onCompleted: () => {
                toast.success("Benefits email sent");
                setOpen(false);
              },
              onError: () => {
                toast.error("Failed to send benefits email");
              },
            });
          }}
          loading={sendBenefitsResult.loading}
        >
          Send
        </SubmitButton>
      </div>
    </Modal>
  );
};

export const SendBenefitsEmailButton: React.FC<{
  insurancePolicy: Pick<
    InsurancePolicy,
    "id" | "mostRecentCoverageBenefits" | "communications" | "inNetwork"
  >;
  patientEmail: string | null;
  buttonClassName?: string;
  variant?: ButtonVariant;
}> = ({ insurancePolicy, patientEmail, buttonClassName, variant }) => {
  const [open, setOpen] = useState(false);
  const benefitsTracked =
    insurancePolicy.mostRecentCoverageBenefits.filter(
      (b) => b.networkStatus === insurancePolicy.inNetwork
    ).length > 0;
  const hasEmail = !!patientEmail;
  return (
    <>
      <Tooltip
        trigger={
          <Button
            type="button"
            variant={variant ?? "outline"}
            size="sm"
            className={cn("gap-x-1.5 print:hidden", buttonClassName)}
            disabled={!hasEmail || !benefitsTracked}
            onClick={() => {
              setOpen(true);
            }}
          >
            <MailIcon className="h-5 w-5" />
            <div className="hidden lg:flex">Send Benefits Email</div>
          </Button>
        }
        content={
          !benefitsTracked
            ? "Start tracking benefits to send email"
            : !hasEmail
            ? "Patient has no email on file"
            : "Send an email the patient to view their benefits"
        }
      />
      {open && (
        <SendBenefitsConfirmationDialog
          open={open}
          setOpen={setOpen}
          patientEmail={patientEmail!}
          insurancePolicy={insurancePolicy}
        />
      )}
    </>
  );
};

export const BenefitsConfirmationButton: React.FC<{
  insurancePolicy: Pick<
    InsurancePolicy,
    "id" | "acceptedAt" | "acceptedBy" | "mostRecentCoverageBenefits"
  >;
}> = ({ insurancePolicy }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [acceptInsurancePolicy, acceptInsurancePolicyResult] = useMutation<
    AcceptInsurancePolicy,
    AcceptInsurancePolicyVariables
  >(ACCEPT_INSURANCE_POLICY);
  const [unacceptInsurancePolicy, unacceptInsurancePolicyResult] = useMutation<
    UnacceptInsurancePolicy,
    UnacceptInsurancePolicyVariables
  >(UNACCEPT_INSURANCE_POLICY);

  if (insurancePolicy.acceptedAt) {
    return (
      <Tooltip
        content={
          <div className="flex flex-col gap-1">
            <div>Change status to unconfirmed</div>
            <div className="text-xs">
              Benefits confirmed on{" "}
              {formatDateMMDDYYYY(insurancePolicy.acceptedAt)}
              {insurancePolicy.acceptedBy &&
                " by " +
                  [
                    insurancePolicy.acceptedBy.firstName,
                    insurancePolicy.acceptedBy.lastName,
                  ].join(" ")}
            </div>
          </div>
        }
        trigger={
          <button
            type="button"
            disabled={unacceptInsurancePolicyResult.loading}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 print:hidden"
            onClick={() => {
              unacceptInsurancePolicy({
                variables: {
                  insurancePolicyId: insurancePolicy.id,
                },
                onCompleted: () => {
                  toast.success("Benefits changed to unconfirmed");
                  analytics?.track("Insurance Policy Uncofirmed", {
                    organizationId: user.organization.id,
                    organizationName: user.organization.name,
                    locationId: user.activeLocation.id,
                    locationName: user.activeLocation.name,
                  });
                },
                onError: () => {
                  toast.error("Failed to change benefits to unconfirmed");
                },
                // Update the apollo cache to set the acceptedAt and acceptedBy fields to null
                update: (cache, { data }) => {
                  if (data?.unacceptInsurancePolicy) {
                    cache.writeQuery({
                      query: GET_INSURANCE_POLICY,
                      data: {
                        insurancePolicy: {
                          ...insurancePolicy,
                          acceptedAt: null,
                          acceptedBy: null,
                          verificationWorkflowStatusId:
                            data.unacceptInsurancePolicy
                              .verificationWorkflowStatusId,
                          verificationWorkflowActiveAt:
                            data.unacceptInsurancePolicy
                              .verificationWorkflowActiveAt,
                          verificationWorkflowArchivedAt:
                            data.unacceptInsurancePolicy
                              .verificationWorkflowArchivedAt,
                        },
                      },
                    });
                  }
                },
              });
            }}
          >
            {unacceptInsurancePolicyResult.loading ? (
              <OvalSpinner className="h-5 w-5" />
            ) : (
              <CheckCircle className="h-5 w-5" aria-hidden="true" />
            )}
            <div className="hidden lg:flex">Confirmed</div>
          </button>
        }
      />
    );
  }
  return (
    <Tooltip
      trigger={
        <button
          type="button"
          disabled={acceptInsurancePolicyResult.loading}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 print:hidden disabled:opacity-50 disabled:pointer-events-none"
          onClick={() => {
            acceptInsurancePolicy({
              variables: {
                insurancePolicyId: insurancePolicy.id,
              },
              onCompleted: () => {
                toast.success("Benefits confirmed");
                analytics?.track("Insurance Policy Cofirmed", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
              },
              onError: () => {
                toast.error("Failed to confirm benefits");
              },
            });
          }}
        >
          {acceptInsurancePolicyResult.loading ? (
            <OvalSpinner className="h-5 w-5" />
          ) : (
            <CircleIcon className="h-5 w-5" aria-hidden="true" />
          )}
          <div className="hidden lg:flex">Confirm</div>
        </button>
      }
      content={<>Confirm benefits</>}
    />
  );
};

const ADD_INSURANCE_POLICY_CUSTOM_PATIENT_NOTE = gql`
  mutation AddInsurancePolicyCustomPatientNote(
    $insurancePolicyId: String!
    $note: String!
  ) {
    updateOneInsurancePolicy(
      where: { id: $insurancePolicyId }
      data: { customPatientNote: { set: $note } }
    ) {
      id
      customPatientNote
    }
  }
`;

const PatientInsurancePolicyNoteDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  policy: InsurancePolicy;
}> = ({ open, setOpen, policy }) => {
  const [
    addInsurancePolicyCustomPatientNote,
    addInsurancePolicyCustomPatientNoteResult,
  ] = useMutation<
    AddInsurancePolicyCustomPatientNote,
    AddInsurancePolicyCustomPatientNoteVariables
  >(ADD_INSURANCE_POLICY_CUSTOM_PATIENT_NOTE);
  const [note, setNote] = useState(policy.customPatientNote ?? "");
  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="mt-3 text-center text-lg sm:mt-5">
        Add a patient note to the policy.
      </div>
      <div className="mt-3 text-center text-gray-600 sm:mt-5">
        This note will be visible to the patient in their benefits portal and
        will be added to the benefits PDF when downloaded.
      </div>
      <div className="flex flex-col max-h-[80vh] overflow-auto py-2 pr-2">
        <textarea
          rows={4}
          onChange={(e) => {
            setNote(e.currentTarget.value ?? "");
          }}
          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={note}
        />
      </div>
      <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
        <SubmitButton
          type="button"
          onClick={() => {
            addInsurancePolicyCustomPatientNote({
              variables: {
                insurancePolicyId: policy.id,
                note,
              },
              onCompleted: () => {
                toast.success("Note added");
                setOpen(false);
              },
              onError: () => {
                toast.error("Failed to add note");
              },
            });
          }}
          loading={addInsurancePolicyCustomPatientNoteResult.loading}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

const PatientInsurancePolicyNoteDialogButton: React.FC<{
  policy: InsurancePolicy;
}> = ({ policy }) => {
  const [open, setOpen] = useState(false);
  const existingNote = !!policy.customPatientNote;
  return (
    <>
      <Tooltip
        trigger={
          <button
            className="p-1.5 rounded-md hover:bg-gray-50"
            onClick={() => {
              setOpen(true);
            }}
          >
            <DocumentTextIcon
              className={cn(
                "h-5 w-5 print:hidden",
                existingNote ? "text-amber-500" : "text-gray-600"
              )}
            />
          </button>
        }
        content={
          existingNote ? (
            <>Update the patient note on the policy.</>
          ) : (
            <>Add a patient note to the policy.</>
          )
        }
      />
      {open && (
        <PatientInsurancePolicyNoteDialog
          open={open}
          setOpen={setOpen}
          policy={policy}
        />
      )}
    </>
  );
};

const EligibilityHistoryRow: React.FC<{
  patientId: string;
  eligibilityRequest: EligibilityRequest;
}> = ({ patientId, eligibilityRequest }) => {
  const { data } = useEligibilityStatusPolling(eligibilityRequest);

  const currentEligibility = data?.eligibilityRequest || eligibilityRequest;

  return (
    <Link
      to={`/patients/${patientId}/eligibilities/${currentEligibility.id}`}
      className="flex justify-between pt-1 pb-2 hover:bg-gray-50 cursor-pointer"
    >
      <div className="flex flex-col px-1">
        <div className="flex items-center gap-1 flex-wrap text-sm">
          Requested:
          <Tooltip
            trigger={
              <div className="font-semibold">
                {eligibilityRequest.rawEligibilityRequestResponses
                  .flatMap((resp) => resp.requestedServiceTypeCode)
                  .filter(isDefined)
                  .join(", ")}
              </div>
            }
            content={eligibilityRequest.rawEligibilityRequestResponses
              .flatMap((resp) => resp.requestedServiceTypeDisplay)
              .filter(isDefined)
              .join(", ")}
          />
        </div>

        <div className="flex flex-wrap items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          <div className="truncate">
            {eligibilityRequest.automated ? (
              <div className="flex gap-1">
                <CogIcon className="w-4" />
                <span>Auto-Verified</span>
              </div>
            ) : (
              <p>
                Verified by:{" "}
                {[
                  eligibilityRequest.requestedBy?.firstName,
                  eligibilityRequest.requestedBy?.lastName,
                ]
                  .filter(isDefined)
                  .join(" ")}
              </p>
            )}
          </div>
          <svg
            viewBox="0 0 2 2"
            className="h-0.5 w-0.5 flex-none fill-gray-300"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="whitespace-nowrap">
            <Tooltip
              trigger={
                <>
                  {formatDistanceToNow(parseISO(eligibilityRequest.createdAt), {
                    addSuffix: true,
                  })}
                </>
              }
              content={format(
                parseISO(eligibilityRequest.createdAt),
                "MM/dd/yyyy"
              )}
            />
          </p>
        </div>
      </div>

      <div className="flex items-center gap-1">
        <div className="text-sm text-gray-900">
          <EligibilityBadge eligibility={eligibilityRequest} />
        </div>
        <ChevronRightIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </div>
    </Link>
  );
};

const RenewalResetBanner: React.FC<{
  patientId: string;
  insurancePolicy: InsurancePolicy;
  renewalReset: RenewalReset;
  setOpenCoverageVersionHistory: (open: boolean) => void;
}> = ({
  patientId,
  insurancePolicy,
  renewalReset,
  setOpenCoverageVersionHistory,
}) => {
  const openCoverageVersionHistory = () => {
    setOpenCoverageVersionHistory(true);
  };

  return (
    <Alert
      variant="default"
      className="mx-auto my-2 bg-amber-50 border-amber-200 flex items-center"
    >
      <div className="flex items-center">
        <AlertCircle className="h-4 w-4 mr-2" />
      </div>
      <div className="flex flex-col flex-grow">
        <AlertTitle className="sr-only">Information</AlertTitle>
        <AlertDescription className="flex items-center justify-between flex-wrap gap-4 text-amber-900">
          <span>
            This policy's benefits were reset on{" "}
            {formatDateMMDDYYYY(renewalReset.createdAt)} when the policy was
            renewed.
          </span>
          <div className="flex gap-2 flex-wrap">
            <Button
              variant="outline"
              size="sm"
              asChild
              className="border-amber-500 text-amber-700 hover:bg-amber-100 hover:text-amber-900"
            >
              <Link
                to={`/patients/${patientId}/eligibilities/new`}
                state={{
                  policy: insurancePolicy.id,
                }}
              >
                Run New Eligibility Request
              </Link>
            </Button>
            <Button
              variant="outline"
              size="sm"
              asChild
              className="border-amber-500 text-amber-700 hover:bg-amber-100 hover:text-amber-900"
              onClick={openCoverageVersionHistory}
            >
              <span className="cursor-pointer">Restore Old Benefits</span>
            </Button>
          </div>
        </AlertDescription>
      </div>
    </Alert>
  );
};

// Add query for eligibility request details
const GET_ELIGIBILITY_REQUEST_DETAILS = gql`
  query GetEligibilityRequestDetails($id: String!) {
    eligibilityRequest(where: { id: $id }) {
      id
      status
      eligible
      createdAt
      clearinghouse
      insurancePolicy {
        id
        payer {
          id
          name
        }
      }
      rawEligibilityRequestResponses {
        id
        requestedServiceTypeCode
        requestedServiceTypeDisplay
        response
        availityCoverageId
      }
      allRequestsErrored
      deduplicatedErrors {
        id
        code
        description
      }
    }
  }
`;

const EligibilityServiceTypeStatusIcon: React.FC<{
  response: RawEligibilityRequestResponse;
  finalized: boolean;
}> = ({ response, finalized }) => {
  if (!response.response || Object.keys(response.response).length === 0) {
    if (finalized) {
      return <ExclamationCircleIcon className="h-3 w-3 text-yellow-500" />;
    }
    return <Loader2 className="h-3 w-3 text-blue-500 animate-spin" />;
  }
  return <CheckCircleIcon className="h-3 w-3 text-green-500" />;
};

const getEligibilityStatus = (
  eligibilityRequest: EligibilityRequestDetails
) => {
  if (eligibilityRequest.status === "InProgress") {
    return "InProgress";
  }
  if (eligibilityRequest.status === "TimedOut") {
    return "Error";
  }
  if (eligibilityRequest.allRequestsErrored) {
    return "Error";
  }
  if (eligibilityRequest.eligible) {
    return "Active";
  }
  return "Inactive";
};

// Add new component for pending state banner
const PendingVerificationBanner: React.FC<{
  eligibilityRequestId: string;
  onDismiss: () => void;
  patientId: string;
}> = ({ eligibilityRequestId, onDismiss, patientId }) => {
  const apollo = useApolloClient();
  const { data, loading, stopPolling } = useQuery<
    GetEligibilityRequestDetails,
    GetEligibilityRequestDetailsVariables
  >(GET_ELIGIBILITY_REQUEST_DETAILS, {
    variables: { id: eligibilityRequestId },
    pollInterval: 2000,
  });

  const eligibilityRequest = data?.eligibilityRequest;
  // Stop polling when the request is no longer in progress
  React.useEffect(() => {
    if (eligibilityRequest && eligibilityRequest?.status !== "InProgress") {
      stopPolling();
      // Refetch all active queries
      apollo.refetchQueries({
        include: "active",
      });
    }
  }, [eligibilityRequest?.status, stopPolling]);

  if (!eligibilityRequest) return null;
  const hasErrors = eligibilityRequest?.deduplicatedErrors?.length > 0;

  const status = getEligibilityStatus(eligibilityRequest);

  // Determine alert variant and styles based on status
  const getAlertStyles = () => {
    if (status === "InProgress") return "bg-blue-50 border-blue-200";
    if (status === "Active") return "bg-green-50 border-green-200";
    if (status === "Error") return "bg-red-50 border-red-200";
    if (status === "Inactive") return "bg-yellow-50 border-yellow-200";
    return "bg-gray-50 border-gray-200";
  };

  const getStatusText = () => {
    if (status === "InProgress")
      return (
        <span className="text-blue-600 font-medium">
          Verifying eligibility with {eligibilityRequest.clearinghouse}...
        </span>
      );
    if (status === "Active")
      return (
        <span className="text-green-600 font-medium">Coverage Active</span>
      );
    if (status === "Error")
      return (
        <span className="text-red-600 font-medium">Verification Error</span>
      );
    if (status === "Inactive")
      return (
        <span className="text-yellow-600 font-medium">Coverage Inactive</span>
      );
    return "Unknown";
  };

  const getStatusIcon = () => {
    if (status === "InProgress") {
      return (
        <svg
          className="h-4 w-4 text-blue-500 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      );
    }
    if (status === "Active")
      return <CheckCircleIcon className="h-4 w-4 text-green-500" />;
    if (status === "Error")
      return <ExclamationIcon className="h-4 w-4 text-red-500" />;
    if (status === "Inactive")
      return <BanIcon className="h-4 w-4 text-yellow-500" />;
    return <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />;
  };

  if (loading && !data) return null;

  return (
    <Alert className={cn("mb-4 relative", getAlertStyles())}>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-none items-center space-x-3">
          {getStatusIcon()}
          <div>
            <h3 className="text-sm font-medium">{getStatusText()}</h3>
            {status === "InProgress" && (
              <p className="text-xs text-gray-600 mt-0.5">
                Contacting {eligibilityRequest.clearinghouse} to verify with{" "}
                {eligibilityRequest.insurancePolicy.payer.name}...
              </p>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex flex-wrap items-center gap-1">
            {eligibilityRequest?.rawEligibilityRequestResponses.map(
              (response) => (
                <Tooltip
                  key={response.id}
                  content={response.requestedServiceTypeDisplay}
                  trigger={
                    <div className="flex items-center space-x-1 bg-white/50 px-2 py-1 rounded text-xs">
                      <EligibilityServiceTypeStatusIcon
                        response={response}
                        finalized={status !== "InProgress"}
                      />
                      <span className="text-gray-700">
                        {response.requestedServiceTypeDisplay}
                      </span>
                    </div>
                  }
                />
              )
            )}
          </div>

          <div className="flex flex-none items-center space-x-2">
            <Button variant="outline" size="sm" asChild className="text-xs">
              <Link
                to={`/patients/${patientId}/eligibilities/${eligibilityRequestId}`}
              >
                View Details
              </Link>
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={onDismiss}
              className="text-xs"
            >
              Dismiss
            </Button>
          </div>
        </div>
      </div>

      {hasErrors && (
        <div className="mt-2 text-xs text-red-700">
          {eligibilityRequest.deduplicatedErrors.map((error) => (
            <div key={error.id}>{error.description}</div>
          ))}
        </div>
      )}
      {eligibilityRequest?.status === "TimedOut" && (
        <div className="mt-2 text-xs text-red-700">
          Eligibility request timed out. Please try again.
        </div>
      )}
    </Alert>
  );
};

const PatientInsuranceInner: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
  }>
> = ({ insurancePolicy }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = useQueryParams();

  const [openCoverageVersionHistory, setOpenCoverageVersionHistory] =
    useState(false);
  const [pendingEligibilityRequestId, setPendingEligibilityRequestId] =
    useState(queryParams.get("eligibilityRequestId"));

  const user = useUser()!;
  const analytics = useAnalytics();
  const { patientId, insurancePolicyId } = useParams();

  const [
    sendBenefitsToLoggedInUserDialogOpen,
    setSendBenefitsToLoggedInUserDialogOpen,
  ] = useState(false);
  const [inNetwork, setInNetwork] = useState(insurancePolicy.inNetwork);

  const patient = insurancePolicy.patient;
  const providerServiceConfiguration =
    patient.organization?.providerServiceConfiguration ?? [];

  const eligibilityEnabled = !!insurancePolicy.payer?.eligibilityEnabled;
  const eligibilityRequests = [...insurancePolicy.eligibilityRequests].sort(
    (a, b) => compareDesc(parseISO(a.createdAt), parseISO(b.createdAt))
  );
  const mostRecentEligibility = eligibilityRequests[0];

  const benefitsTracked =
    insurancePolicy.mostRecentCoverageBenefits.filter(
      (b) => b.networkStatus === insurancePolicy.inNetwork
    ).length > 0;
  const sendBenefitsPdfDownloadEvent = () => {
    analytics?.track("Benefits PDF Downloaded", {
      eligibilityRequestId: mostRecentEligibility?.id,
      organizationId: user.organization.id,
      organizationName: user.organization.name,
      locationId: user.activeLocation.id,
      locationName: user.activeLocation.name,
    });
  };

  const currentRenewalReset = insurancePolicy.renewalResets.find(
    (reset) => !reset.endedAt
  );

  const [showLookupDialog, setShowLookupDialog] = useState(false);

  return (
    <>
      <Layout
        header={
          <nav className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-sm md:text-base">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to={`/patients/${patientId}`}
              >
                {patient.displayName}
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to={`/patients/${patientId}/insurances/${insurancePolicyId}`}
              >
                {insurancePolicy.payer.name} - {insurancePolicy.memberId}
              </Link>
            </div>
          </nav>
        }
        content={
          <div className="flex flex-col pt-4 pb-8 gap-4">
            <PatientHeader
              patient={insurancePolicy.patient}
              endContent={
                <div className="flex gap-2">
                  <Tooltip
                    trigger={
                      <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        className="gap-x-1.5 print:hidden"
                        onClick={() => window.print()}
                      >
                        <PrinterIcon className="h-5 w-5" />
                        <div className="hidden lg:flex">Print</div>
                      </Button>
                    }
                    content="Print this page"
                  />
                  <Button
                    asChild
                    type="button"
                    variant="outline"
                    size="sm"
                    className="gap-x-1.5 print:hidden"
                  >
                    <PDFDownloadLink
                      document={
                        <BenefitsPDF
                          insurancePolicy={insurancePolicy}
                          providerServiceConfigurations={
                            providerServiceConfiguration
                          }
                        />
                      }
                      fileName={`${insurancePolicy.patient.firstName}_${
                        insurancePolicy.patient.lastName
                      }_${formatDateMMDDYYYY(
                        mostRecentEligibility?.createdAt ??
                          new Date().toISOString()
                      )}_benefits.pdf`}
                      onClick={() => sendBenefitsPdfDownloadEvent()}
                      className={cn(
                        benefitsTracked ? "" : "pointer-events-none opacity-50"
                      )}
                    >
                      <DownloadIcon className="h-5 w-5" />
                      <div className="hidden lg:flex">Download PDF</div>
                    </PDFDownloadLink>
                  </Button>
                  <SendBenefitsEmailButton
                    patientEmail={insurancePolicy.patient.email}
                    insurancePolicy={insurancePolicy}
                  />
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto flex px-2 print:hidden"
                      >
                        <DotsVerticalIcon className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      sideOffset={8}
                      align="end"
                      className="min-w-[150px]"
                    >
                      <DropdownMenuItem
                        onClick={() =>
                          setSendBenefitsToLoggedInUserDialogOpen(true)
                        }
                        className={cn(
                          "gap-4 cursor-pointer",
                          benefitsTracked
                            ? ""
                            : "pointer-events-none opacity-50"
                        )}
                      >
                        <MailIcon className="h-5 w-5" />
                        <div>Send Benefits Email to Myself</div>
                      </DropdownMenuItem>
                      <Link
                        to={`/portal/${patient.organization.id}/benefits/${insurancePolicyId}`}
                        target="_blank"
                        className={cn(
                          benefitsTracked
                            ? ""
                            : "pointer-events-none opacity-50"
                        )}
                      >
                        <DropdownMenuItem className="gap-4 cursor-pointer">
                          <ExternalLinkIcon className="h-5 w-5" />
                          <div>View Benefits Portal</div>
                        </DropdownMenuItem>
                      </Link>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              }
            />
            {currentRenewalReset && (
              <RenewalResetBanner
                patientId={patientId!}
                insurancePolicy={insurancePolicy}
                renewalReset={currentRenewalReset}
                setOpenCoverageVersionHistory={setOpenCoverageVersionHistory}
              />
            )}
            <Card>
              <InsurancePolicySummary insurancePolicy={insurancePolicy} />
            </Card>

            {pendingEligibilityRequestId && (
              <PendingVerificationBanner
                eligibilityRequestId={pendingEligibilityRequestId}
                patientId={patientId!}
                onDismiss={() => {
                  setPendingEligibilityRequestId(null);
                  // Clear out the eligibilityRequestId query params
                  if (searchParams.has("eligibilityRequestId")) {
                    searchParams.delete("eligibilityRequestId");
                    setSearchParams(searchParams);
                  }
                }}
              />
            )}

            <div className="grid grid-cols-2 gap-6">
              <Card className="col-span-2 sm:col-span-1 max-h-[12em] print:hidden">
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center">
                    <div className="font-medium">Eligibility History</div>
                    {eligibilityEnabled && (
                      <Button
                        asChild
                        variant="ghost"
                        size="sm"
                        className="gap-x-1.5 print:hidden text-sm flex items-center text-indigo-600 hover:text-indigo-700"
                      >
                        <Link
                          to={`/patients/${patientId}/eligibilities/new`}
                          state={{
                            policy: insurancePolicy.id,
                          }}
                          // className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 print:hidden"
                        >
                          <PlusIcon
                            className="h-4 w-4 text-indigo-600 hover:text-indigo-700"
                            aria-hidden="true"
                          />
                          Check Eligibility
                        </Link>
                      </Button>
                    )}
                  </div>
                  <div className="divide-y divide-gray-200 my-2 overflow-auto">
                    {eligibilityRequests.length === 0 && (
                      <div className="flex justify-center items-center h-24">
                        <div className="text-gray-400 italic">
                          No eligibility checks have been requested
                        </div>
                      </div>
                    )}
                    {eligibilityRequests.map((eligibilityRequest) => {
                      return (
                        <EligibilityHistoryRow
                          key={eligibilityRequest.id}
                          eligibilityRequest={eligibilityRequest}
                          patientId={patientId!}
                        />
                      );
                    })}
                  </div>
                </div>
              </Card>
              <Card className="col-span-2 sm:col-span-1 max-h-[12em] print:hidden">
                <CoverageVersionHistory
                  insurancePolicy={insurancePolicy}
                  open={openCoverageVersionHistory}
                  setOpen={setOpenCoverageVersionHistory}
                />
              </Card>
              <div className="col-span-2 print:col-span-2 flex flex-col gap-8">
                <Card className="h-fit">
                  <div className="flex flex-col w-full">
                    <div className="flex flex-wrap justify-between items-end gap-1 pb-2">
                      <h3 className="text-lg font-medium flex items-center gap-4">
                        Benefits
                        <CompletenessScore
                          insurancePolicy={insurancePolicy}
                          providerServiceConfigurations={
                            providerServiceConfiguration
                          }
                        />
                        <PatientInsurancePolicyNoteDialogButton
                          policy={insurancePolicy}
                        />
                        <span className="inline-flex shadow-sm rounded-md">
                          <button
                            type="button"
                            className={classNames(
                              "inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-100",
                              inNetwork ? "bg-indigo-100 text-indigo-900" : ""
                            )}
                            onClick={() => setInNetwork(true)}
                          >
                            In Network
                          </button>
                          <button
                            type="button"
                            className={classNames(
                              "inline-flex items-center px-2 py-1 rounded-r-md border-t border-r border-b border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-100",
                              inNetwork ? "" : "bg-indigo-100 text-indigo-900"
                            )}
                            onClick={() => setInNetwork(false)}
                          >
                            Out Network
                          </button>
                        </span>
                        {insurancePolicy.payer.uhcBenefitsSearchEnabled && (
                          <>
                            <div className="flex items-center justify-between">
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => setShowLookupDialog(true)}
                                className="gap-x-1.5"
                              >
                                <MagnifyingGlassIcon className="h-4 w-4" />
                                Lookup UHC Portal Benefits
                              </Button>
                            </div>
                            <LookupUHCBenefitsDialog
                              open={showLookupDialog}
                              setOpen={setShowLookupDialog}
                              insurancePolicy={insurancePolicy}
                            />
                          </>
                        )}
                      </h3>
                      <div className="flex gap-2 items-center">
                        {mostRecentEligibility && (
                          <>
                            {/* Display relative time for last verified */}
                            <div className="text-gray-600 block print:hidden">
                              Last verified{" "}
                              {formatDistanceToNow(
                                parseISO(mostRecentEligibility.createdAt),
                                { addSuffix: true }
                              )}
                            </div>
                            {/* On print, display the last date verified */}
                            <div className="text-gray-600 hidden print:block">
                              Last verified on{" "}
                              {formatDateMMDDYYYY(
                                mostRecentEligibility.createdAt
                              )}
                            </div>
                          </>
                        )}
                        <InsurancePolicyWorkflowButton
                          insurancePolicy={insurancePolicy}
                          verificationWorkflowStatuses={
                            insurancePolicy?.patient?.location
                              ?.verificationWorkflowStatuses
                          }
                        />
                        <BenefitsConfirmationButton
                          insurancePolicy={insurancePolicy}
                        />
                      </div>
                    </div>
                    <CoverageBenefitsDisplay
                      insurancePolicy={insurancePolicy}
                      providerServiceConfigurations={
                        providerServiceConfiguration
                      }
                      inNetwork={inNetwork}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        }
      />
      {sendBenefitsToLoggedInUserDialogOpen && (
        <SendBenefitsToLoggedInUserConfirmationDialog
          insurancePolicy={insurancePolicy}
          open={sendBenefitsToLoggedInUserDialogOpen}
          setOpen={setSendBenefitsToLoggedInUserDialogOpen}
        />
      )}
    </>
  );
};

export const PatientInsurance: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ patientId: string; insurancePolicyId: string }>();
  const insurancePolicyId = params.insurancePolicyId!;
  const { data, loading } = useQuery<
    GetInsurancePolicy,
    GetInsurancePolicyVariables
  >(GET_INSURANCE_POLICY, {
    variables: {
      id: insurancePolicyId,
    },
  });
  if (loading || !data) return <LoadingLayout />;

  return <PatientInsuranceInner insurancePolicy={data.insurancePolicy!} />;
};
