import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ShieldPlus, Check, ExternalLinkIcon } from "lucide-react";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";
import { Badge, Tooltip } from "../../components";
import { Button } from "../../components/ui/button";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@radix-ui/react-hover-card";
import {
  InsurancePolicySummaryFields as InsurancePolicy,
  InsurancePolicySummaryFields_additionalPlans as AdditionalPlan,
} from "../../generated/InsurancePolicySummaryFields";
import { useFeatureFlags } from "../../hooks";
import { isDefined, toDateMMDDYYYY } from "../../utils";
import { NetworkBadge } from "./networkBadge";
import { PlanTypeSelect } from "./plan-type-select";

const ACKNOWLEDGE_ADDITIONAL_PLAN = gql`
  mutation AcknowledgeAdditionalPlan($additionalPlanId: String!) {
    acknowledgeAdditionalPlan(additionalPlanId: $additionalPlanId) {
      insurancePolicy {
        id
        additionalPlans {
          id
          createdAt
          acknowledgedAt
          entityName
          insuranceType
          entityIdentifier
          planNetworkName
          eligibilityRequestId
        }
      }
    }
  }
`;

const NotPresent = () => (
  <span className="text-gray-500 italic">Not Present</span>
);

export const AdditionalPlanAcknowledgements = ({
  additionalPlans,
  patientId,
}: {
  additionalPlans: AdditionalPlan[];
  patientId: string;
}) => {
  const [acknowledgedPayers, setAcknowledgedPayers] = useState<Set<string>>(
    new Set(
      additionalPlans
        .filter((plan) => plan.acknowledgedAt)
        .map((plan) => plan.id)
    )
  );
  const [loadingId, setLoadingId] = useState<string | null>(null);
  const [acknowledgeAdditionalPlan] = useMutation(ACKNOWLEDGE_ADDITIONAL_PLAN);
  const handleButtonClick = (plan: AdditionalPlan) => {
    setLoadingId(plan.id);
    setAcknowledgedPayers((prev) => {
      const newSet = new Set(prev);
      newSet.add(plan.id);
      return newSet;
    });
    acknowledgeAdditionalPlan({
      variables: { additionalPlanId: plan.id },
      onCompleted: () => {
        toast.success("Additional plan acknowledged");
        setLoadingId(null);
      },
      onError: (error) => {
        toast.error(error.message);
        setLoadingId(null);
      },
    });
  };

  const allAcknowledged = additionalPlans.every((plan) =>
    acknowledgedPayers.has(plan.id)
  );

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <ShieldPlus
          className={`h-4 w-4 cursor-pointer ${
            allAcknowledged ? "text-gray-400" : "text-indigo-800"
          }`}
        />
      </HoverCardTrigger>
      <HoverCardContent className="z-50 max-w-md w-full bg-white rounded-md shadow-lg p-4">
        <div className="space-y-4 w-full">
          <div className="border-b pb-2 break-words w-full">
            <h3 className="text-lg text-center font-semibold w-full">
              Additional Payers Identified
            </h3>
            <p className="text-sm text-gray-500 text-center mt-1 break-words w-full whitespace-normal">
              Please review and acknowledge any additional insurance plans found
              during eligibility checks
            </p>
          </div>
          {additionalPlans.map((plan) => (
            <div key={plan.id} className="flex items-center gap-4">
              <div className="flex-1 text-sm space-y-1">
                {[
                  {
                    label: "Name",
                    text: plan.entityName,
                    className: "text-gray-700",
                  },
                  {
                    label: "Insurance Type",
                    text: plan.insuranceType,
                    className: "text-gray-500",
                  },
                  {
                    label: "Entity Identifier",
                    text: plan.entityIdentifier,
                    className: "text-gray-500",
                  },
                  {
                    label: "Plan Network Name",
                    text: plan.planNetworkName,
                    className: "text-gray-500",
                  },
                ]
                  .filter((item) => item.text)
                  .map((item, index) => (
                    <div key={index}>
                      <span className="text-gray-500">{item.label}: </span>
                      <span className={item.className}>{item.text}</span>
                    </div>
                  ))}
              </div>
              <div className="flex items-center gap-2 shrink-0">
                <button
                  className={`p-1.5 rounded-full transition-colors ${
                    acknowledgedPayers.has(plan.id)
                      ? "bg-green-100 text-green-600 cursor-default"
                      : "hover:bg-gray-100 text-gray-400 hover:text-gray-600"
                  }`}
                  onClick={() =>
                    !acknowledgedPayers.has(plan.id) && handleButtonClick(plan)
                  }
                  disabled={acknowledgedPayers.has(plan.id)}
                  title={
                    acknowledgedPayers.has(plan.id)
                      ? "Plan Acknowledged"
                      : "Acknowledge Plan"
                  }
                >
                  <Check className="h-4 w-4" />
                </button>
                <Link
                  to={`/patients/${patientId}/eligibilities/${plan.eligibilityRequestId}#otherOrAdditionalPayers`}
                  target="_blank"
                  className="p-1.5 rounded-full hover:bg-gray-100 text-gray-400 hover:text-gray-600"
                  title="View Details"
                >
                  <ExternalLinkIcon className="h-4 w-4" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

export const InsurancePolicySummary: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: Omit<InsurancePolicy, "__typename">;
    accounts?: {
      id: string;
      accountType: {
        name: string;
      } | null;
    }[];
  }>
> = ({ insurancePolicy, accounts }) => {
  const flags = useFeatureFlags();
  const eligibilityEnabled = !!insurancePolicy.payer?.eligibilityEnabled;
  const hasPlanDates =
    isDefined(insurancePolicy.effectiveDate) ||
    isDefined(insurancePolicy.renewalDate) ||
    isDefined(insurancePolicy.terminationDate);

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-2 print:flex-row justify-between">
        <div className="flex items-center flex-wrap gap-2">
          <h1 className="text-xl font-semibold flex items-center gap-2">
            {insurancePolicy.payer.name}
            {insurancePolicy.payer.tradingPartner?.tradingPartnerId && (
              <Tooltip
                trigger={
                  <Link to="/payers" className="text-gray-700">
                    ({insurancePolicy.payer.tradingPartner.tradingPartnerId})
                  </Link>
                }
                content={<>{insurancePolicy.payer.tradingPartner.name}</>}
              />
            )}
            <PlanTypeSelect insurancePolicy={insurancePolicy} />
          </h1>
          <div className="space-x-4">
            {!eligibilityEnabled && (
              <Badge
                text="Electronic Eligibility Unsupported"
                variant="warning"
              />
            )}
            {accounts
              ?.filter((account) => account.accountType)
              .map((account) => (
                <Badge variant="info" text={account.accountType!.name} />
              ))}
          </div>
        </div>
        <div className="flex items-center space-x-4 sm:items-center">
          {flags.showAdditionalPlans &&
            insurancePolicy.additionalPlans.length > 0 && (
              <AdditionalPlanAcknowledgements
                additionalPlans={insurancePolicy.additionalPlans}
                patientId={insurancePolicy.patientId}
              />
            )}
          {insurancePolicy.priority && (
            <Badge
              text={insurancePolicy.priority}
              variant="info"
              className="text-sm"
            />
          )}
          <NetworkBadge orgPayerNetworkStatus={insurancePolicy.inNetwork} />
          <Badge
            text={insurancePolicy.active ? "Active" : "Inactive"}
            variant={insurancePolicy.active ? "success" : "warning"}
          />
        </div>
      </div>
      <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-4 print:grid-cols-4 print:text-sm pt-2 print:gap-y-0">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Member ID</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.memberId}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Plan</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.plan?.name ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Insurance Type</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.plan?.type ?? <NotPresent />}
          </dd>
        </div>
        {/* <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Plan Effective Dates
          </dt>
          <dd className="mt-1 text-md text-gray-900">
            {hasPlanDates ? planDates : <NotPresent />}
          </dd>
        </div> */}
        <div className="sm:col-span-1 row-span-2">
          <dt className="text-sm font-medium text-gray-500">Policy Dates</dt>
          <dd className="mt-1 text-sm text-gray-900 flex flex-col gap-1">
            <div className="flex items-center justify-between gap-1">
              <div className="text-gray-500">Effective</div>
              <div>
                {insurancePolicy.effectiveDate ? (
                  toDateMMDDYYYY(insurancePolicy.effectiveDate)
                ) : (
                  <NotPresent />
                )}
              </div>
            </div>
            {insurancePolicy.active ? (
              <div className="flex items-center justify-between gap-1">
                <div className="text-gray-500">Renewal</div>
                <div>
                  {insurancePolicy.renewalDate ? (
                    toDateMMDDYYYY(insurancePolicy.renewalDate)
                  ) : (
                    <NotPresent />
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between gap-1">
                <div className="text-gray-500">Termination</div>
                <div>
                  {insurancePolicy.terminationDate ? (
                    toDateMMDDYYYY(insurancePolicy.terminationDate)
                  ) : (
                    <NotPresent />
                  )}
                </div>
              </div>
            )}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Relation to Subscriber
          </dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.relationToSubscriber ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Group Number</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.groupId ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Group Name</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.groupName ?? <NotPresent />}
          </dd>
        </div>
      </dl>
    </div>
  );
};
