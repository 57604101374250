import React, { useMemo, useState, useRef, useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../utils";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Badge } from "./badge";
import fuzzysort from "fuzzysort";
import { useVirtualizer } from "@tanstack/react-virtual";

interface Provider {
  id: string;
  firstName: string | null;
  lastName: string | null;
  organizationName: string | null;
  npi: string | null;
}

interface ProviderComboboxProps {
  value: Provider | null;
  providers: Provider[];
  onSelect: (value: Provider | null) => void;
  placeholder?: string;
  disabled?: boolean;
}

const normalizeScore = (score: number | null) => {
  const MIN_SCORE = -2500;
  if (score === null || score < MIN_SCORE) return 0;
  return (score - MIN_SCORE) / -MIN_SCORE;
};

const getProviderDisplayName = (provider: Provider) => {
  if (provider.organizationName) return provider.organizationName;
  return provider.firstName && provider.lastName
    ? `${provider.firstName} ${provider.lastName}`
    : "Unknown Provider";
};

export const ProviderCombobox: React.FC<ProviderComboboxProps> = ({
  value,
  providers,
  onSelect,
  placeholder = "Select provider...",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(
    value ? getProviderDisplayName(value) : ""
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const filteredItems = useMemo(() => {
    if (!search) return providers;

    const results = providers.map((provider) => {
      const displayName = getProviderDisplayName(provider);
      const nameMatch = fuzzysort.single(
        search.toLowerCase(),
        displayName.toLowerCase()
      );
      const npiMatch = fuzzysort.single(
        search.toLowerCase(),
        provider.npi?.toLowerCase() ?? ""
      );

      const score = Math.max(
        normalizeScore(nameMatch?.score ?? null),
        normalizeScore(npiMatch?.score ?? null)
      );

      return {
        provider,
        score,
        nameMatch,
        npiMatch,
      };
    });

    return results
      .filter((result) => result.score > 0)
      .sort((a, b) => b.score - a.score)
      .map((result) => result.provider);
  }, [providers, search]);

  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 56,
    overscan: 5,
  });

  useEffect(() => {
    if (open) {
      virtualizer.measure();
    }
  }, [open, virtualizer]);

  useEffect(() => {
    virtualizer.measure();
  }, [filteredItems, virtualizer]);

  const renderHighlightedText = (
    text: string,
    match: Fuzzysort.Result | null
  ) => {
    if (!match || !search) return text;

    const highlighted = fuzzysort.highlight(match, (m, i) => (
      <span key={i} className="bg-yellow-100 text-yellow-900">
        {m}
      </span>
    ));

    return highlighted || text;
  };

  const renderProviderRow = (provider: Provider) => {
    const displayName = getProviderDisplayName(provider);
    const nameMatch = search
      ? fuzzysort.single(search.toLowerCase(), displayName.toLowerCase())
      : null;
    const npiMatch = search
      ? fuzzysort.single(
          search.toLowerCase(),
          provider.npi?.toLowerCase() ?? ""
        )
      : null;

    return (
      <CommandItem
        key={provider.id}
        value={provider.id}
        onSelect={() => {
          onSelect(provider === value ? null : provider);
          setOpen(false);
        }}
        className="py-2"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col">
            <div className="font-medium">
              {renderHighlightedText(displayName, nameMatch)}
            </div>
            {provider.npi && (
              <div className="text-sm text-muted-foreground font-mono">
                {renderHighlightedText(provider.npi, npiMatch)}
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            {value?.id === provider.id && (
              <Check className="h-4 w-4 text-primary" />
            )}
          </div>
        </div>
      </CommandItem>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={disabled}
          className={cn(
            "justify-between w-full",
            value && "text-muted-foreground"
          )}
        >
          {value ? (
            <div className="flex items-center gap-2 text-left">
              <span className="truncate">{getProviderDisplayName(value)}</span>
              {value.npi && (
                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-mono text-xs"
                >
                  {value.npi}
                </Badge>
              )}
            </div>
          ) : (
            placeholder
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[400px] p-0" sideOffset={4}>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search by name or NPI..."
            value={search}
            onValueChange={(newSearch) => {
              setSearch(newSearch);
              virtualizer.scrollToIndex(0);
            }}
          />
          <CommandEmpty>No providers found.</CommandEmpty>
          <CommandGroup>
            <div
              ref={scrollContainerRef}
              className="max-h-[300px] overflow-y-auto"
            >
              <div
                style={{
                  height: `${virtualizer.getTotalSize()}px`,
                  width: "100%",
                  position: "relative",
                }}
              >
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const provider = filteredItems[virtualRow.index];
                  return (
                    <div
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      {renderProviderRow(provider)}
                    </div>
                  );
                })}
              </div>
            </div>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
