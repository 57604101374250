import React, { Fragment, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Card, SubmitButton } from "../../../components";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  CreateChargeTemplateMapping,
  CreateChargeTemplateMappingVariables,
} from "../../../generated/CreateChargeTemplateMapping";
import {
  UpdateChargeTemplateMapping,
  UpdateChargeTemplateMappingVariables,
} from "../../../generated/UpdateChargeTemplateMapping";
import { GetLocationRules_chargeTemplateMappings as ChargeTemplateMapping } from "../../../generated/GetLocationRules";
import { GetChargeTemplates_chargeTemplates as ChargeTemplate } from "../../../generated/GetChargeTemplates";
import { useUser } from "../../../user-context";
import { Button } from "../../../components/ui/button";
import { cn, isDefined } from "../../../utils";
import { NumberInput } from "@tremor/react";
import { Textarea } from "../../../components/ui/textarea";
import { useAnalytics } from "../../../analytics-context";
import { AttributeTypes, Visit } from "../../../evaluate-rule";
import { Comparison } from "../../../generated/globalTypes";
import { COMPARISONS } from "../benefit-mappings/show";
import { CHARGE_TEMPLATE_MAPPING_FIELDS } from "../../../graphql";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select as UiSelect,
  SelectItem as UiSelectItem,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  GetLocationChargeTemplateMappings,
  GetLocationChargeTemplateMappingsVariables,
} from "../../../generated/GetLocationChargeTemplateMappings";
import { Tab } from "@headlessui/react";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField } from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { AttributeInput, ChargemasterGroupCombobox } from "../rule-inputs";
import {
  GetChargeTemplateFormOptions,
  GetChargeTemplateFormOptionsVariables,
} from "../../../generated/GetChargeTemplateFormOptions";
import { useNavigate } from "react-router-dom";

export const CREATE_CHARGE_TEMPLATE_MAPPING = gql`
  ${CHARGE_TEMPLATE_MAPPING_FIELDS}
  mutation CreateChargeTemplateMapping(
    $data: ChargeTemplateMappingCreateInput!
  ) {
    createOneChargeTemplateMapping(data: $data) {
      ...ChargeTemplateMappingFields
    }
  }
`;

export const UPDATE_CHARGE_TEMPLATE_MAPPING = gql`
  ${CHARGE_TEMPLATE_MAPPING_FIELDS}
  mutation UpdateChargeTemplateMapping(
    $id: String!
    $data: ChargeTemplateMappingUpdateInput!
  ) {
    updateOneChargeTemplateMapping(where: { id: $id }, data: $data) {
      ...ChargeTemplateMappingFields
    }
  }
`;

const CHARGE_TEMPLATE_MAPPING_ATTRIBUTES: {
  label: string;
  value: AttributeTypes;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Appointment Type",
    value: "appointmentType",
  },
  {
    label: "Appointment Label",
    value: "appointmentLabelId",
  },
  {
    label: "Appointment Location",
    value: "appointmentLocationId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Provider",
    value: "providerId",
  },
  {
    label: "Provider Classification",
    value: "providerTaxonomyCodeId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
  {
    label: "Deductible",
    value: "remainingDeductible",
  },
  {
    label: "Out of Pocket",
    value: "remainingOutOfPocket",
  },
  {
    label: "Remaining Visits",
    value: "remainingVisits",
  },
  {
    label: "Appointment Duration (minutes)",
    value: "appointmentDuration",
  },
];

const BENEFIT_ATTRIBUTE_TYPES = [
  "remainingDeductible",
  "remainingOutOfPocket",
  "remainingVisits",
];

export type ChargeTemplateMappingConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (["appointmentType"].includes(attributeType)) {
    return COMPARISONS;
  }

  if (
    ["remainingDeductible", "remainingOutOfPocket", "remainingVisits"].includes(
      attributeType
    )
  ) {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "does not equal", value: Comparison.NotEquals },
      { label: "is greater than", value: Comparison.GreaterThan },
      {
        label: "is greater than or equal",
        value: Comparison.GreaterThanOrEqual,
      },
      { label: "is less than", value: Comparison.LessThan },
      { label: "is less than or equal", value: Comparison.LessThanOrEqual },
      { label: "is met", value: Comparison.IsMet },
    ];
  }
  if (attributeType === "appointmentDuration") {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "does not equal", value: Comparison.NotEquals },
      { label: "is greater than", value: Comparison.GreaterThan },
      {
        label: "is greater than or equal",
        value: Comparison.GreaterThanOrEqual,
      },
      { label: "is less than", value: Comparison.LessThan },
      { label: "is less than or equal", value: Comparison.LessThanOrEqual },
    ];
  }
  // Don't allow contains comparison for ID attributes. Only for free text attributes.
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

const GET_LOCATION_CHARGE_TEMPLATE_MAPPINGS = gql`
  query GetLocationChargeTemplateMappings($locationId: String!) {
    chargeTemplateMappings(
      where: {
        locationId: { equals: $locationId }
        pending: { equals: false }
        chargeTemplate: { is: { pending: { equals: false } } }
      }
    ) {
      id
      name
      notes
      chargeTemplate {
        id
        name
      }
      chargeTemplateMappingConditions {
        id
        attributeType
        comparison
        attribute
        providerServiceConfigurationId
      }
    }
  }
`;

const DuplicateChargeTemplateMappingDialogButton: React.FC<{
  onSelect: (chargeTemplateMapping: {
    name: string;
    chargeTemplateMappingConditions: {
      attributeType: string;
      comparison: Comparison;
      attribute: string | string[];
      providerServiceConfigurationId: string | null;
    }[];
    chargeTemplate: {
      id: string;
      name: string;
    };
  }) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationChargeTemplateMappings,
    GetLocationChargeTemplateMappingsVariables
  >(GET_LOCATION_CHARGE_TEMPLATE_MAPPINGS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [chargeTemplateMappingId, setChargeTemplateMappingId] =
    useState<string>();
  const chargeTemplateMappings = data?.chargeTemplateMappings ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate an existing Charge Template Rule?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={chargeTemplateMappingId}
                  onValueChange={setChargeTemplateMappingId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {chargeTemplateMappings.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    type="button"
                    disabled={!chargeTemplateMappingId}
                    onClick={() => {
                      const chargeTemplateMapping = chargeTemplateMappings.find(
                        (c) => c.id === chargeTemplateMappingId
                      );
                      if (chargeTemplateMapping) {
                        onSelect(chargeTemplateMapping);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

enum ChargeTemplateTab {
  Assign,
  Create,
}

const ChargeTemplateRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
      providerServiceConfigurationId: z.string().nullable().optional(),
    })
  ),
  chargeTemplateId: z.string().nullable(),
  newChargeTemplate: z
    .object({
      name: z.string().nullable(),
      charges: z.array(
        z.object({
          chargemasterGroupId: z.string(),
          units: z.string(),
        })
      ),
    })
    .nullable(),
});

const CHARGE_TEMPLATE_FORM_OPTIONS = gql`
  query GetChargeTemplateFormOptions(
    $locationId: String!
    $organizationId: String!
  ) {
    chargeTemplates(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      modifier1
      modifier2
      modifier3
      modifier4
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(where: { locationId: { equals: $locationId } }) {
      id
      name
    }
    patientLabels(where: { locationId: { equals: $locationId } }) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
    appointmentLocations(
      where: { organizationId: { equals: $organizationId } }
    ) {
      id
      name
    }
  }
`;

export const ChargeTemplateMappingForm: React.FC<{
  chargeTemplateMapping: ChargeTemplateMapping | null;
  chargeTemplates: ChargeTemplate[];
  defaultNewChargeTemplate?: {
    name: string;
    charges: { chargemasterGroupId: string; units: string }[];
  };
  children: (props: {
    conditions: ChargeTemplateMappingConditionInput[];
  }) => React.ReactElement;
}> = ({
  chargeTemplateMapping,
  chargeTemplates,
  defaultNewChargeTemplate,
  children,
}) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const optionsResult = useQuery<
    GetChargeTemplateFormOptions,
    GetChargeTemplateFormOptionsVariables
  >(CHARGE_TEMPLATE_FORM_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
      organizationId: user.organization.id,
    },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];
  const appointmentLocations = optionsResult.data?.appointmentLocations ?? [];

  const form = useForm<z.infer<typeof ChargeTemplateRuleForm>>({
    resolver: zodResolver(ChargeTemplateRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: chargeTemplateMapping?.id ?? null,
      name: chargeTemplateMapping?.name,
      notes: chargeTemplateMapping?.notes,
      conditions: chargeTemplateMapping?.chargeTemplateMappingConditions ?? [],
      chargeTemplateId: chargeTemplateMapping?.chargeTemplate?.id ?? null,
      // TODO: fill from rule params
      newChargeTemplate: defaultNewChargeTemplate ?? null,
    },
  });
  const conditionsField = useFieldArray({
    name: "conditions",
    control: form.control,
  });
  const chargeTemplateChargesField = useFieldArray({
    name: "newChargeTemplate.charges",
    control: form.control,
  });

  const ruleName = form.watch("name");

  const defaultTab = defaultNewChargeTemplate
    ? ChargeTemplateTab.Create
    : ChargeTemplateTab.Assign;
  const [tab, setTab] = useState<ChargeTemplateTab>(defaultTab);
  const newChargeTemplate = form.watch("newChargeTemplate");
  const chargeTemplateId = form.watch("chargeTemplateId");

  const [createChargeTemplateMapping, createChargeTemplateMappingResult] =
    useMutation<
      CreateChargeTemplateMapping,
      CreateChargeTemplateMappingVariables
    >(CREATE_CHARGE_TEMPLATE_MAPPING);
  const [updateChargeTemplateMapping, updateChargeTemplateMappingResult] =
    useMutation<
      UpdateChargeTemplateMapping,
      UpdateChargeTemplateMappingVariables
    >(UPDATE_CHARGE_TEMPLATE_MAPPING);

  const assignValid =
    tab === ChargeTemplateTab.Assign && isDefined(chargeTemplateId);
  const createValid =
    tab === ChargeTemplateTab.Create &&
    isDefined(newChargeTemplate) &&
    isDefined(newChargeTemplate.name) &&
    newChargeTemplate.charges.every(
      (c) => isDefined(c.chargemasterGroupId) && isDefined(c.units)
    );

  const conditions = form.watch("conditions");

  const providerServiceConfigurations =
    user.organization.providerServiceConfiguration;

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison) &&
        // If the condition is a benefit attribute, it must have a provider service configuration
        (!BENEFIT_ATTRIBUTE_TYPES.includes(c.attributeType) ||
          isDefined(c.providerServiceConfigurationId))
    ) &&
    isDefined(ruleName) &&
    (assignValid || createValid);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          // Form is disabled if not defined
          if (chargeTemplateMapping?.id) {
            const toDelete =
              chargeTemplateMapping.chargeTemplateMappingConditions.filter(
                (c) => !data.conditions.some(({ id }) => id === c.id)
              );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            updateChargeTemplateMapping({
              variables: {
                id: chargeTemplateMapping.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  chargeTemplateMappingConditions: {
                    create: toCreate.map(
                      ({
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                        providerServiceConfiguration:
                          providerServiceConfigurationId
                            ? {
                                connect: {
                                  id: providerServiceConfigurationId,
                                },
                              }
                            : undefined,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({
                        id,
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                          providerServiceConfiguration:
                            providerServiceConfigurationId
                              ? {
                                  connect: {
                                    id: providerServiceConfigurationId,
                                  },
                                }
                              : { disconnect: true },
                        },
                      })
                    ),
                  },
                  chargeTemplate: {
                    ...(tab === ChargeTemplateTab.Assign &&
                    data.chargeTemplateId
                      ? {
                          connect: {
                            id: data.chargeTemplateId,
                          },
                        }
                      : {}),
                    ...(tab === ChargeTemplateTab.Create &&
                    data.newChargeTemplate
                      ? {
                          create: {
                            name: data.newChargeTemplate.name!,
                            location: {
                              connect: {
                                id: user.activeLocation.id,
                              },
                            },
                            chargeTemplateCharges: {
                              create: data.newChargeTemplate.charges.map(
                                (c, i) => ({
                                  chargemasterGroup: {
                                    connect: {
                                      id: c.chargemasterGroupId,
                                    },
                                  },
                                  units: parseInt(c.units),
                                  priority: i,
                                })
                              ),
                            },
                          },
                        }
                      : {}),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Charge Template Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createChargeTemplateMapping({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  chargeTemplate: {
                    ...(tab === ChargeTemplateTab.Assign &&
                    data.chargeTemplateId
                      ? {
                          connect: {
                            id: data.chargeTemplateId,
                          },
                        }
                      : {}),
                    ...(tab === ChargeTemplateTab.Create &&
                    data.newChargeTemplate
                      ? {
                          create: {
                            name: data.newChargeTemplate.name!,
                            location: {
                              connect: {
                                id: user.activeLocation.id,
                              },
                            },
                            chargeTemplateCharges: {
                              create: data.newChargeTemplate.charges.map(
                                (c, i) => ({
                                  chargemasterGroup: {
                                    connect: {
                                      id: c.chargemasterGroupId,
                                    },
                                  },
                                  units: parseInt(c.units),
                                  priority: i,
                                })
                              ),
                            },
                          },
                        }
                      : {}),
                  },
                  chargeTemplateMappingConditions: {
                    create: data.conditions.map(
                      ({
                        attributeType,
                        comparison,
                        attribute,
                        providerServiceConfigurationId,
                      }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                        providerServiceConfiguration:
                          providerServiceConfigurationId
                            ? {
                                connect: {
                                  id: providerServiceConfigurationId,
                                },
                              }
                            : undefined,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping created");
                analytics?.track("Charge Template Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create mapping");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicateChargeTemplateMappingDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue("chargeTemplateId", c.chargeTemplate.id);
                conditionsField.replace(
                  c.chargeTemplateMappingConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                    providerServiceConfigurationId:
                      condition.providerServiceConfigurationId,
                  }))
                );
                setTab(ChargeTemplateTab.Assign);
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          const isBenefitAttributeType =
                            BENEFIT_ATTRIBUTE_TYPES.includes(attributeType);
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <div
                                    className={cn(
                                      "grid gap-2",
                                      isBenefitAttributeType
                                        ? "grid-cols-2"
                                        : "grid-cols-1"
                                    )}
                                  >
                                    <FormField
                                      control={form.control}
                                      name={`conditions.${index}.attributeType`}
                                      render={({ field }) => (
                                        <UiSelect
                                          onValueChange={field.onChange}
                                          defaultValue={field.value}
                                          {...form.register(
                                            `conditions.${index}.attributeType`
                                          )}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select an attribute" />
                                          </SelectTrigger>
                                          <SelectContent>
                                            <SelectGroup>
                                              {CHARGE_TEMPLATE_MAPPING_ATTRIBUTES.map(
                                                (o) => (
                                                  <UiSelectItem value={o.value}>
                                                    {o.label}
                                                  </UiSelectItem>
                                                )
                                              )}
                                            </SelectGroup>
                                          </SelectContent>
                                        </UiSelect>
                                      )}
                                    />
                                    {isBenefitAttributeType && (
                                      <FormField
                                        control={form.control}
                                        name={`conditions.${index}.providerServiceConfigurationId`}
                                        render={({ field }) => (
                                          <UiSelect
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            {...form.register(
                                              `conditions.${index}.providerServiceConfigurationId`
                                            )}
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Select a benefit" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {providerServiceConfigurations.map(
                                                  (c) => (
                                                    <UiSelectItem value={c.id}>
                                                      {c.name}
                                                    </UiSelectItem>
                                                  )
                                                )}
                                              </SelectGroup>
                                            </SelectContent>
                                          </UiSelect>
                                        )}
                                      />
                                    )}
                                  </div>

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                    appointmentLocations={appointmentLocations}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                              providerServiceConfigurationId: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="pt-4">
                      <div className="text-lg font-semibold">THEN</div>

                      <Tab.Group selectedIndex={tab} onChange={setTab}>
                        <div className="m-2">
                          <Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow border">
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  type="button"
                                  className={cn(
                                    selected
                                      ? "text-gray-900"
                                      : "text-gray-500 hover:text-gray-700",
                                    "rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm lg:text-base font-medium text-center hover:bg-gray-50 focus:z-10"
                                  )}
                                >
                                  Assign Existing Template
                                  <span
                                    aria-hidden="true"
                                    className={cn(
                                      selected
                                        ? "bg-indigo-500"
                                        : "bg-transparent",
                                      "absolute inset-x-0 bottom-0 h-1"
                                    )}
                                  />
                                </button>
                              )}
                            </Tab>
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  type="button"
                                  className={cn(
                                    selected
                                      ? "text-gray-900"
                                      : "text-gray-500 hover:text-gray-700",
                                    "rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm lg:text-base font-medium text-center hover:bg-gray-50 focus:z-10"
                                  )}
                                >
                                  Create New Template
                                  <span
                                    aria-hidden="true"
                                    className={cn(
                                      selected
                                        ? "bg-indigo-500"
                                        : "bg-transparent",
                                      "absolute inset-x-0 bottom-0 h-1"
                                    )}
                                  />
                                </button>
                              )}
                            </Tab>
                          </Tab.List>
                        </div>

                        <Tab.Panels className="p-2">
                          <Tab.Panel>
                            <div>
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Assign template
                              </label>
                              <div className="mt-2 max-w-xl flex justify-between gap-2">
                                <FormField
                                  control={form.control}
                                  name="chargeTemplateId"
                                  render={({ field }) => (
                                    <UiSelect
                                      {...form.register("chargeTemplateId")}
                                      defaultValue={field.value}
                                      onValueChange={field.onChange}
                                    >
                                      <SelectTrigger>
                                        <SelectValue placeholder="Select a template" />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          {chargeTemplates.map((template) => (
                                            <UiSelectItem
                                              key={template.id}
                                              value={template.id}
                                            >
                                              {template.name}
                                            </UiSelectItem>
                                          ))}
                                        </SelectGroup>
                                      </SelectContent>
                                    </UiSelect>
                                  )}
                                />
                              </div>
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div>
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Create new template
                              </label>
                              <div className="mt-2 max-w-3xl flex flex-col justify-between gap-2">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium leading-6 text-gray-700"
                                >
                                  Name
                                </label>
                                <h2 className="text-base leading-7">
                                  <Input
                                    placeholder="Enter a name for the charge template"
                                    {...form.register("newChargeTemplate.name")}
                                  />
                                </h2>
                                {chargeTemplateChargesField.fields.map(
                                  (charge, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="grid grid-cols-5 gap-4"
                                      >
                                        <div className="flex flex-col col-span-3">
                                          <label className="block text-sm font-medium leading-6 text-gray-700">
                                            Code
                                          </label>
                                          <div className="flex gap-1 items-center">
                                            <ChargemasterGroupCombobox
                                              chargemasterGroups={
                                                chargemasterGroups
                                              }
                                              key={form.getValues(
                                                `newChargeTemplate.charges.${i}.chargemasterGroupId`
                                              )}
                                              value={form.watch(
                                                `newChargeTemplate.charges.${i}.chargemasterGroupId`
                                              )}
                                              // value={charge.chargemasterGroupId}
                                              onSelect={(
                                                chargemasterGroupIds
                                              ) => {
                                                const chargemasterGroup =
                                                  chargemasterGroupIds[0];
                                                form.setValue(
                                                  `newChargeTemplate.charges.${i}.chargemasterGroupId`,
                                                  chargemasterGroup,
                                                  {
                                                    shouldTouch: true,
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                  }
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex flex-col">
                                          <label
                                            htmlFor={`chargeTemplateCharges.${i}.units`}
                                            className="block text-sm font-medium leading-6 text-gray-700"
                                          >
                                            Units
                                          </label>
                                          <div className="font-medium text-gray-900">
                                            <NumberInput
                                              min={1}
                                              placeholder="Number of units"
                                              {...form.register(
                                                `newChargeTemplate.charges.${i}.units`
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex justify-end gap-2 mt-6">
                                          <div className="flex gap-2">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                chargeTemplateChargesField.swap(
                                                  i,
                                                  i - 1
                                                );
                                              }}
                                              disabled={i === 0}
                                              className="disabled:cursor-not-allowed disabled:opacity-50"
                                            >
                                              <ArrowUpIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                chargeTemplateChargesField.swap(
                                                  i,
                                                  i + 1
                                                );
                                              }}
                                              disabled={
                                                i ===
                                                chargeTemplateChargesField
                                                  .fields.length -
                                                  1
                                              }
                                              className="disabled:cursor-not-allowed disabled:opacity-50"
                                            >
                                              <ArrowDownIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                                            </button>
                                          </div>

                                          <button
                                            type="button"
                                            className="text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
                                            onClick={() => {
                                              chargeTemplateChargesField.remove(
                                                i
                                              );
                                            }}
                                            disabled={
                                              chargeTemplateChargesField.fields
                                                .length === 1
                                            }
                                          >
                                            <TrashIcon className="h-5 w-5" />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="flex pt-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    chargeTemplateChargesField.append({
                                      chargemasterGroupId: null,
                                      units: 1,
                                    });
                                  }}
                                  className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                >
                                  <span aria-hidden="true">+</span> Add another
                                  charge
                                </button>
                              </div>
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        loading={
                          createChargeTemplateMappingResult.loading ||
                          updateChargeTemplateMappingResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
