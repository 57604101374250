import { gql, useMutation, useQuery } from "@apollo/client";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as z from "zod";
import { useAnalytics } from "../../../analytics-context";
import { Card, SubmitButton } from "../../../components";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Form, FormField } from "../../../components/ui/form";
import { CurrencyInput, Input } from "../../../components/ui/input";
import {
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
  Select as UiSelect,
  SelectItem as UiSelectItem,
} from "../../../components/ui/select";
import { Textarea } from "../../../components/ui/textarea";
import { Visit } from "../../../evaluate-rule";
import {
  CreateMaxAllowableRule,
  CreateMaxAllowableRuleVariables,
} from "../../../generated/CreateMaxAllowableRule";
import {
  GetLocationMaxAllowableRules,
  GetLocationMaxAllowableRulesVariables,
} from "../../../generated/GetLocationMaxAllowableRules";
import { GetMaxAllowableRule_maxAllowableRule as MaxAllowableRule } from "../../../generated/GetMaxAllowableRule";
import { Comparison } from "../../../generated/globalTypes";
import {
  UpdateMaxAllowableRule,
  UpdateMaxAllowableRuleVariables,
} from "../../../generated/UpdateMaxAllowableRule";
import { MAX_ALLOWABLE_RULE_FIELDS } from "../../../graphql";
import { useUser } from "../../../user-context";
import { isDefined, mapNullable, toCents, toDollarStr } from "../../../utils";
import { COMPARISONS } from "../benefit-mappings/show";
import { AttributeInput } from "../rule-inputs";

export const CREATE_MAX_ALLOWABLE_RULE = gql`
  ${MAX_ALLOWABLE_RULE_FIELDS}
  mutation CreateMaxAllowableRule($data: MaxAllowableRuleCreateInput!) {
    createOneMaxAllowableRule(data: $data) {
      ...MaxAllowableRuleFields
    }
  }
`;

export const UPDATE_MAX_ALLOWABLE_RULE = gql`
  ${MAX_ALLOWABLE_RULE_FIELDS}
  mutation UpdateMaxAllowableRule(
    $id: String!
    $data: MaxAllowableRuleUpdateInput!
  ) {
    updateOneMaxAllowableRule(where: { id: $id }, data: $data) {
      ...MaxAllowableRuleFields
    }
  }
`;

const MAX_ALLOWABLE_RULE_ATTRIBUTES: {
  label: string;
  value: AttributeTypes;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Appointment Type",
    value: "appointmentType",
  },
  {
    label: "Appointment Label",
    value: "appointmentLabelId",
  },
  {
    label: "Appointment Location",
    value: "appointmentLocationId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Provider",
    value: "providerId",
  },
  {
    label: "Provider Classification",
    value: "providerTaxonomyCodeId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
  {
    label: "In Network",
    value: "inNetwork",
  },
  {
    label: "Charge Code",
    value: "chargemasterGroupIds",
  },
  {
    label: "Code Group",
    value: "codeGroups",
  },
  {
    label: "Any Visit Charge Code",
    value: "anyVisitChargemasterGroupIds",
  },
];

type AttributeTypes = keyof Visit;

export type MaxAllowableRuleConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (
    ["appointmentType", "appointmentLabelId", "patientLabelId"].includes(
      attributeType
    )
  ) {
    return COMPARISONS;
  }
  if (
    ["remainingDeductible", "remainingOutOfPocket", "remainingVisits"].includes(
      attributeType
    )
  ) {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "does not equal", value: Comparison.NotEquals },
      { label: "is greater than", value: Comparison.GreaterThan },
      {
        label: "is greater than or equal",
        value: Comparison.GreaterThanOrEqual,
      },
      { label: "is less than", value: Comparison.LessThan },
      { label: "is less than or equal", value: Comparison.LessThanOrEqual },
      { label: "is met", value: Comparison.IsMet },
    ];
  }
  // Don't allow contains comparison for ID attributes. Only for free text attributes.
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

const GET_LOCATION_MAX_ALLOWABLE_RULES = gql`
  query GetLocationMaxAllowableRules($locationId: String!) {
    maxAllowableRules(where: { locationId: { equals: $locationId } }) {
      id
      name
      notes
      maxAllowable
      maxAllowableRuleConditions {
        id
        attributeType
        comparison
        attribute
      }
    }
  }
`;

const DuplicateMaxAllowableRuleDialogButton: React.FC<{
  onSelect: (maxAllowableRule: MaxAllowableRule) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationMaxAllowableRules,
    GetLocationMaxAllowableRulesVariables
  >(GET_LOCATION_MAX_ALLOWABLE_RULES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [maxAllowableRuleId, setMaxAllowableRuleId] = useState<string>();
  const maxAllowableRules = data?.maxAllowableRules ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Duplicate an existing Max Visit Allowable Rule?
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={maxAllowableRuleId}
                  onValueChange={setMaxAllowableRuleId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {maxAllowableRules.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!maxAllowableRuleId}
                    onClick={() => {
                      const maxAllowableRule = maxAllowableRules.find(
                        (c) => c.id === maxAllowableRuleId
                      );
                      if (maxAllowableRule) {
                        onSelect(maxAllowableRule);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const MAX_ALLOWABLE_RULE_FORM_OPTIONS = gql`
  query MaxAllowableRuleFormOptions(
    $locationId: String!
    $organizationId: String!
  ) {
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    patientLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
    appointmentLocations(
      where: { organizationId: { equals: $organizationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

const MaxAllowableRuleRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
    })
  ),
  maxAllowable: z.string().nullable(),
});

export const MaxAllowableRuleForm: React.FC<{
  maxAllowableRule: MaxAllowableRule | null;
  children: (props: {
    conditions: MaxAllowableRuleConditionInput[];
  }) => React.ReactElement;
}> = ({ maxAllowableRule, children }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const [option, setOption] = useState<"fixed" | "copay">("fixed");

  const optionsResult = useQuery(MAX_ALLOWABLE_RULE_FORM_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
      organizationId: user.organization.id,
    },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];
  const codeCategories = optionsResult.data?.codeCategories ?? [];
  const appointmentLocations = optionsResult.data?.appointmentLocations ?? [];

  const form = useForm<z.infer<typeof MaxAllowableRuleRuleForm>>({
    resolver: zodResolver(MaxAllowableRuleRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: maxAllowableRule?.id ?? null,
      name: maxAllowableRule?.name,
      notes: maxAllowableRule?.notes,
      conditions: maxAllowableRule?.maxAllowableRuleConditions ?? [],
      maxAllowable: mapNullable(toDollarStr)(maxAllowableRule?.maxAllowable),
    },
  });
  const conditionsField = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const ruleName = form.watch("name");
  const maxAllowable = form.watch("maxAllowable");
  const conditions = form.watch("conditions");
  const [createMaxAllowableRule, createMaxAllowableRuleResult] = useMutation<
    CreateMaxAllowableRule,
    CreateMaxAllowableRuleVariables
  >(CREATE_MAX_ALLOWABLE_RULE);
  const [updateMaxAllowableRule, updateMaxAllowableRuleResult] = useMutation<
    UpdateMaxAllowableRule,
    UpdateMaxAllowableRuleVariables
  >(UPDATE_MAX_ALLOWABLE_RULE);

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison)
    ) &&
    isDefined(maxAllowable) &&
    isDefined(ruleName);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          const maxAllowable = mapNullable((val: string) =>
            toCents(Number.parseInt(val))
          )(data.maxAllowable)!;

          // Form is disabled if not defined
          if (maxAllowableRule) {
            const toDelete = maxAllowableRule.maxAllowableRuleConditions.filter(
              (c) => !data.conditions.some(({ id }) => id === c.id)
            );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            updateMaxAllowableRule({
              variables: {
                id: maxAllowableRule.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  maxAllowableRuleConditions: {
                    create: toCreate.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({ id, attributeType, comparison, attribute }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                        },
                      })
                    ),
                  },
                  maxAllowable: { set: maxAllowable },
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Max Allowable Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createMaxAllowableRule({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  maxAllowable,
                  maxAllowableRuleConditions: {
                    create: data.conditions.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Rule created");
                analytics?.track("Max Allowable Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create rule");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicateMaxAllowableRuleDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue(
                  "maxAllowable",
                  mapNullable(toDollarStr)(c.maxAllowable)
                );
                conditionsField.replace(
                  c.maxAllowableRuleConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                  }))
                );
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.attributeType`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.attributeType`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select an attribute" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {MAX_ALLOWABLE_RULE_ATTRIBUTES.map(
                                              (o) => (
                                                <UiSelectItem value={o.value}>
                                                  {o.label}
                                                </UiSelectItem>
                                              )
                                            )}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                    codeCategories={codeCategories}
                                    appointmentLocations={appointmentLocations}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="pt-4">
                      <div className="text-lg font-semibold">THEN</div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Set Max Allowable
                        </label>
                        <div className="mt-2 max-w-xl flex flex-col gap-2">
                          <FormField
                            control={form.control}
                            name="maxAllowable"
                            render={({ field }) => (
                              <CurrencyInput
                                placeholder="Select a max allowable amount"
                                decimalsLimit={2}
                                onValueChange={(val) => {
                                  field.onChange(val ?? "");
                                }}
                                defaultValue={field.value}
                                value={field.value}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={() => {}}
                        loading={
                          createMaxAllowableRuleResult.loading ||
                          updateMaxAllowableRuleResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
