import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Button } from "./ui/button";
import { CommandIcon } from "lucide-react";
import { cn } from "../utils";
import { useFeatureFlags } from "../hooks";
import { User } from "../auth-context";

interface CommandBarContextType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create a context to manage command bar state
const CommandBarContext = createContext<CommandBarContextType | undefined>(
  undefined
);

// Hook to use the command bar context
export const useCommandBar = (): CommandBarContextType => {
  const context = useContext(CommandBarContext);
  if (!context) {
    throw new Error("useCommandBar must be used within a CommandBarProvider");
  }
  return context;
};

// Provider component to wrap app with command bar state
export const CommandBarProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const flags = useFeatureFlags();

  // Add keyboard shortcut listeners for command palette
  useEffect(() => {
    // If command bar is disabled, don't set up keyboard shortcuts
    if (!flags.commandBarEnabled) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      // Only trigger if not inside input/textarea/etc to avoid conflicts
      if (
        e.target &&
        ["INPUT", "TEXTAREA", "SELECT"].includes(
          (e.target as HTMLElement).tagName
        )
      ) {
        return;
      }

      // Capture cmd+k, ctrl+k, or / shortcuts
      if (
        ((e.metaKey || e.ctrlKey) && e.key === "k") ||
        (!e.metaKey && !e.ctrlKey && !e.altKey && e.key === "/")
      ) {
        e.preventDefault();
        setOpen((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [flags.commandBarEnabled]);

  return (
    <CommandBarContext.Provider value={{ open, setOpen }}>
      {children}
    </CommandBarContext.Provider>
  );
};

interface SidebarCommandBarProps {
  user?: User;
  contextObject?: {
    type: string;
    data: any;
  };
  collapsed: boolean;
}

export function SidebarCommandBar({
  user,
  contextObject,
  collapsed,
}: SidebarCommandBarProps) {
  const { setOpen } = useCommandBar();
  const flags = useFeatureFlags();

  // Don't render if command bar is disabled
  if (!flags.commandBarEnabled) {
    return null;
  }

  const handleClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <div>
      <Button
        variant="outline"
        className={cn(
          "w-full justify-start text-sm relative focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:outline-none text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded-md",
          collapsed && "justify-center"
        )}
        onClick={handleClick}
      >
        <CommandIcon className={cn("h-4 w-4", !collapsed && "mr-2")} />
        {!collapsed && <span>Search...</span>}
        {!collapsed && (
          <kbd className="absolute right-3 top-1/2 transform -translate-y-1/2 font-sans text-xs bg-gray-100 text-gray-500 py-0.5 px-1.5 border border-gray-300 rounded">
            ⌘K
          </kbd>
        )}
      </Button>
    </div>
  );
}
