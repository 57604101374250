import { gql, useMutation, useQuery } from "@apollo/client";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as z from "zod";
import { useAnalytics } from "../../../analytics-context";
import { Card, SubmitButton } from "../../../components";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Form, FormField } from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue,
  Select as UiSelect,
  SelectItem as UiSelectItem,
} from "../../../components/ui/select";
import { Textarea } from "../../../components/ui/textarea";
import { TradingPartnerCombobox } from "../../../components/ui/trading-partner-combobox";
import { Visit } from "../../../evaluate-rule";
import {
  CreateTradingPartnerMapping,
  CreateTradingPartnerMappingVariables,
} from "../../../generated/CreateTradingPartnerMapping";
import {
  GetLocationTradingPartnerMappings,
  GetLocationTradingPartnerMappingsVariables,
} from "../../../generated/GetLocationTradingPartnerMappings";
import { GetTradingPartnerMapping_tradingPartnerMapping as TradingPartnerMapping } from "../../../generated/GetTradingPartnerMapping";
import { GetTradingPartners_tradingPartners as TradingPartner } from "../../../generated/GetTradingPartners";
import { Comparison } from "../../../generated/globalTypes";
import {
  TradingPartnerMappingFormOptions,
  TradingPartnerMappingFormOptionsVariables,
} from "../../../generated/TradingPartnerMappingFormOptions";
import {
  UpdateTradingPartnerMapping,
  UpdateTradingPartnerMappingVariables,
} from "../../../generated/UpdateTradingPartnerMapping";
import { TRADING_PARTNER_MAPPING_FIELDS } from "../../../graphql";
import { useUser } from "../../../user-context";
import { isDefined } from "../../../utils";
import { COMPARISONS } from "../benefit-mappings/show";
import { AttributeInput } from "../rule-inputs";

export const CREATE_TRADING_PARTNER_MAPPING = gql`
  ${TRADING_PARTNER_MAPPING_FIELDS}
  mutation CreateTradingPartnerMapping(
    $data: TradingPartnerMappingCreateInput!
  ) {
    createOneTradingPartnerMapping(data: $data) {
      ...TradingPartnerMappingFields
    }
  }
`;

export const UPDATE_TRADING_PARTNER_MAPPING = gql`
  ${TRADING_PARTNER_MAPPING_FIELDS}
  mutation UpdateTradingPartnerMapping(
    $id: String!
    $data: TradingPartnerMappingUpdateInput!
  ) {
    updateOneTradingPartnerMapping(where: { id: $id }, data: $data) {
      ...TradingPartnerMappingFields
    }
  }
`;

const TRADING_PARTNER_MAPPING_ATTRIBUTES: {
  label: string;
  value: keyof Visit;
}[] = [
  {
    label: "Account Type",
    value: "accountTypeId",
  },
  {
    label: "Patient Label",
    value: "patientLabelId",
  },
  {
    label: "Payer",
    value: "payerId",
  },
  {
    label: "Group Name",
    value: "groupName",
  },
  // {
  //   label: "Plan",
  //   value: "planId",
  // },
];

export type TradingPartnerMappingConditionInput = {
  id: string | null;
  attributeType: string | null;
  comparison: Comparison | null;
  attribute: string | string[] | null;
};

const getComparisonsForAttribute = (attributeType: keyof Visit) => {
  if (
    ["appointmentType", "appointmentLabelId", "patientLabelId"].includes(
      attributeType
    )
  ) {
    return COMPARISONS;
  }
  if (attributeType === "groupName") {
    return [
      { label: "equals", value: Comparison.Equals },
      { label: "contains", value: Comparison.Contains },
    ];
  }
  // Don't allow contains comparison for ID attributes. Only for free text attributes.
  return COMPARISONS.filter((c) => c.value !== Comparison.Contains);
};

const GET_LOCATION_TRADING_PARTNER_MAPPINGS = gql`
  query GetLocationTradingPartnerMappings($locationId: String!) {
    tradingPartnerMappings(where: { locationId: { equals: $locationId } }) {
      id
      name
      notes
      tradingPartner {
        id
        name
      }
      tradingPartnerMappingConditions {
        id
        attributeType
        comparison
        attribute
      }
    }
  }
`;

const DuplicateTradingPartnerMappingDialogButton: React.FC<{
  onSelect: (tradingPartnerMapping: TradingPartnerMapping) => void;
}> = ({ onSelect }) => {
  const user = useUser()!;
  const [open, setOpen] = useState(false);
  const { data } = useQuery<
    GetLocationTradingPartnerMappings,
    GetLocationTradingPartnerMappingsVariables
  >(GET_LOCATION_TRADING_PARTNER_MAPPINGS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [tradingPartnerMappingId, setTradingPartnerMappingId] =
    useState<string>();
  const tradingPartnerMappings = data?.tradingPartnerMappings ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button type="button">Duplicate</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate an existing Trading Partner Rule?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <UiSelect
                  value={tradingPartnerMappingId}
                  onValueChange={setTradingPartnerMappingId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a rule to duplicate" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {tradingPartnerMappings.map((s) => (
                        <UiSelectItem value={s.id}>{s.name}</UiSelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </UiSelect>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!tradingPartnerMappingId}
                    onClick={() => {
                      const tradingPartnerMapping = tradingPartnerMappings.find(
                        (c) => c.id === tradingPartnerMappingId
                      );
                      if (tradingPartnerMapping) {
                        onSelect(tradingPartnerMapping);
                      }
                      setOpen(false);
                    }}
                  >
                    Duplicate
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const TRADINGPARTNER_MAPPING_FORM_OPTIONS = gql`
  query TradingPartnerMappingFormOptions($locationId: String!) {
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: { code: asc }
    ) {
      id
      code
      description
    }
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: { firstName: { sort: asc, nulls: last } }
    ) {
      id
      displayName
    }
    providerTaxonomyCodes(orderBy: { displayName: asc }) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    patientLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
    location(where: { id: $locationId }) {
      appointmentTypes
    }
  }
`;

const TradingPartnerMappingRuleForm = z.object({
  id: z.string().nullable(),
  name: z.string(),
  notes: z.string().nullable(),
  conditions: z.array(
    z.object({
      id: z.string().nullable(),
      attributeType: z.string(),
      comparison: z.string(),
      attribute: z.union([z.string(), z.array(z.string())]),
    })
  ),
  tradingPartner: z.object({
    id: z.string(),
  }),
});

export const TradingPartnerMappingForm: React.FC<{
  tradingPartnerMapping: TradingPartnerMapping | null;
  tradingPartners: TradingPartner[];
  children: (props: {
    conditions: TradingPartnerMappingConditionInput[];
  }) => React.ReactElement;
}> = ({ tradingPartnerMapping, tradingPartners, children }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const optionsResult = useQuery<
    TradingPartnerMappingFormOptions,
    TradingPartnerMappingFormOptionsVariables
  >(TRADINGPARTNER_MAPPING_FORM_OPTIONS, {
    variables: { locationId: user.activeLocation.id },
  });
  const payers = optionsResult.data?.payers ?? [];
  const accountTypes = optionsResult.data?.accountTypes ?? [];
  const providers = optionsResult.data?.providers ?? [];
  const providerTaxonomyCodes = optionsResult.data?.providerTaxonomyCodes ?? [];
  const appointmentTypes = optionsResult.data?.location?.appointmentTypes ?? [];
  const appointmentLabels = optionsResult.data?.appointmentLabels ?? [];
  const patientLabels = optionsResult.data?.patientLabels ?? [];
  const chargemasterGroups = optionsResult.data?.chargemasterGroups ?? [];

  const form = useForm<z.infer<typeof TradingPartnerMappingRuleForm>>({
    resolver: zodResolver(TradingPartnerMappingRuleForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: tradingPartnerMapping?.id ?? null,
      name: tradingPartnerMapping?.name,
      notes: tradingPartnerMapping?.notes,
      conditions: tradingPartnerMapping?.tradingPartnerMappingConditions ?? [],
      tradingPartner: tradingPartnerMapping?.tradingPartner ?? null,
    },
  });
  const conditionsField = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const ruleName = form.watch("name");
  const tradingPartner = form.watch("tradingPartner");
  const conditions = form.watch("conditions");
  const [createTradingPartnerMapping, createTradingPartnerMappingResult] =
    useMutation<
      CreateTradingPartnerMapping,
      CreateTradingPartnerMappingVariables
    >(CREATE_TRADING_PARTNER_MAPPING);
  const [updateTradingPartnerMapping, updateTradingPartnerMappingResult] =
    useMutation<
      UpdateTradingPartnerMapping,
      UpdateTradingPartnerMappingVariables
    >(UPDATE_TRADING_PARTNER_MAPPING);

  const isValid =
    conditions.length > 0 &&
    conditions.every(
      (c) =>
        isDefined(c.attribute) &&
        isDefined(c.attributeType) &&
        isDefined(c.comparison)
    ) &&
    isDefined(tradingPartner?.id) &&
    isDefined(ruleName);

  console.log({
    isValid,
    errors: form.formState.errors,
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          console.log({ data });
          // Form is disabled if not defined
          if (tradingPartnerMapping) {
            const toDelete =
              tradingPartnerMapping.tradingPartnerMappingConditions.filter(
                (c) => !data.conditions.some(({ id }) => id === c.id)
              );
            const toCreate = data.conditions.filter((c) => c.id === null);
            const toUpdate = data.conditions.filter((c) => c.id !== null);

            updateTradingPartnerMapping({
              variables: {
                id: tradingPartnerMapping.id,
                data: {
                  name: { set: data.name },
                  notes: { set: data.notes },
                  lastUpdatedBy: { connect: { id: user.id } },
                  tradingPartnerMappingConditions: {
                    create: toCreate.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                    delete: toDelete.map(({ id }) => ({
                      id: id,
                    })),
                    update: toUpdate.map(
                      ({ id, attributeType, comparison, attribute }) => ({
                        where: { id: id! },
                        data: {
                          attributeType: {
                            set: attributeType,
                          },
                          comparison: { set: comparison as Comparison },
                          attribute,
                        },
                      })
                    ),
                  },
                  tradingPartner: {
                    connect: {
                      id: tradingPartner.id,
                    },
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping updated");
                analytics?.track("Trading Partner Rule Updated", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to update mapping");
              },
            });
          } else {
            createTradingPartnerMapping({
              variables: {
                data: {
                  location: {
                    connect: { id: user.activeLocation.id },
                  },
                  name: data.name,
                  notes: data.notes,
                  createdBy: { connect: { id: user.id } },
                  lastUpdatedBy: { connect: { id: user.id } },
                  priority: 1,
                  tradingPartner: {
                    connect: {
                      id: tradingPartner.id,
                    },
                  },
                  tradingPartnerMappingConditions: {
                    create: data.conditions.map(
                      ({ attributeType, comparison, attribute }) => ({
                        attributeType,
                        comparison: comparison as Comparison,
                        attribute,
                      })
                    ),
                  },
                },
              },
              onCompleted: () => {
                toast.success("Mapping created");
                analytics?.track("Trading Partner Rule Created", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                });
                navigate("/rules");
              },
              onError: () => {
                toast.error("Failed to create mapping");
              },
            });
          }
        })}
      >
        <div className="py-8">
          <div className="-mt-12 pb-4 flex w-full justify-end">
            <DuplicateTradingPartnerMappingDialogButton
              onSelect={(c) => {
                const name = "[Duplicate] " + c.name;
                form.setValue("name", name);
                form.setValue("tradingPartnerId", c.tradingPartner.id);
                conditionsField.replace(
                  c.tradingPartnerMappingConditions.map((condition) => ({
                    id: null,
                    attributeType: condition.attributeType,
                    comparison: condition.comparison,
                    attribute: condition.attribute,
                  }))
                );
              }}
            />
          </div>

          <div className="flex flex-col gap-8">
            {/* Rule Form */}
            <div>
              <Card>
                <div className="flex flex-col gap-8 p-2 w-full">
                  <div>
                    <div className="flex flex-col gap-4 max-w-xl">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Rule Name
                        </label>
                        <Input {...form.register("name")} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Notes
                        </label>
                        <Textarea {...form.register("notes")} />
                      </div>
                    </div>

                    <div className="border-y border-gray-100 my-4"></div>

                    <div className="text-lg font-semibold">IF</div>

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Rule Conditions
                      </label>
                      <div className="gap-4">
                        {conditionsField.fields.map((condition, index) => {
                          const attributeType = form.watch(
                            `conditions.${index}.attributeType`
                          );
                          return (
                            <div key={condition.id} className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <div className="grid grid-cols-3 gap-2 flex-grow">
                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.attributeType`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.attributeType`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select an attribute" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {TRADING_PARTNER_MAPPING_ATTRIBUTES.map(
                                              (o) => (
                                                <UiSelectItem value={o.value}>
                                                  {o.label}
                                                </UiSelectItem>
                                              )
                                            )}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <FormField
                                    control={form.control}
                                    name={`conditions.${index}.comparison`}
                                    render={({ field }) => (
                                      <UiSelect
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        {...form.register(
                                          `conditions.${index}.comparison`
                                        )}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select a comparison" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectGroup>
                                            {getComparisonsForAttribute(
                                              form.watch(
                                                `conditions.${index}.attributeType`
                                              ) as keyof Visit
                                            ).map((o) => (
                                              <UiSelectItem value={o.value}>
                                                {o.label}
                                              </UiSelectItem>
                                            ))}
                                          </SelectGroup>
                                        </SelectContent>
                                      </UiSelect>
                                    )}
                                  />

                                  <AttributeInput
                                    attributeType={attributeType as keyof Visit}
                                    value={form.watch(
                                      `conditions.${index}.attribute`
                                    )}
                                    comparison={
                                      form.watch(
                                        `conditions.${index}.comparison`
                                      ) as Comparison
                                    }
                                    onChange={(value) => {
                                      form.setValue(
                                        `conditions.${index}.attribute`,
                                        value
                                      );
                                    }}
                                    payers={payers}
                                    accountTypes={accountTypes}
                                    providers={providers}
                                    providerTaxonomyCodes={
                                      providerTaxonomyCodes
                                    }
                                    appointmentTypes={appointmentTypes}
                                    appointmentLabels={appointmentLabels}
                                    patientLabels={patientLabels}
                                    chargemasterGroups={chargemasterGroups}
                                  />
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="p-1 rounded-full"
                                  onClick={() => {
                                    conditionsField.remove(index);
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500" />
                                </Button>
                              </div>
                              {index !== conditionsField.fields.length - 1 && (
                                <div className="flex justify-center py-2 font-medium">
                                  And
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center gap-4 mt-2">
                        <Button
                          variant="default"
                          type="button"
                          className="rounded-lg bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            conditionsField.append({
                              id: null,
                              attributeType: null,
                              comparison: null,
                              attribute: null,
                            });
                          }}
                        >
                          New Condition
                          <PlusIcon className="h-5 w-5 ml-2" />
                        </Button>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="pt-4">
                      <div className="text-lg font-semibold">THEN</div>

                      <div>
                        <div className="mt-2 max-w-xl flex justify-between gap-2">
                          <FormField
                            control={form.control}
                            name="tradingPartner"
                            render={({ field }) => (
                              <div className="space-y-2">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Assign Trading Partner
                                </label>
                                <TradingPartnerCombobox
                                  tradingPartners={tradingPartners}
                                  value={field.value}
                                  onSelect={field.onChange}
                                  className="w-full"
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="border-y border-gray-100 mt-4"></div>

                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <SubmitButton
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                        loading={
                          createTradingPartnerMappingResult.loading ||
                          updateTradingPartnerMappingResult.loading
                        }
                        disabled={!isValid}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {children && children({ conditions })}
          </div>
        </div>
      </form>
    </Form>
  );
};
