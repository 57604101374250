import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../user-context";
import { Card, Button } from "../../components";
import {
  GetLocationRules,
  GetLocationRulesVariables,
} from "../../generated/GetLocationRules";
import { BarChart, Title } from "@tremor/react";
import {
  BENEFIT_MAPPING_FIELDS,
  CHARGE_TEMPLATE_MAPPING_FIELDS,
  DEPOSIT_MAPPING_FIELDS,
  FEE_SCHEDULE_MAPPING_FIELDS,
  MAX_ALLOWABLE_RULE_FIELDS,
  PRE_VISIT_MAPPING_FIELDS,
  TRADING_PARTNER_MAPPING_FIELDS,
} from "../../graphql";
import { HorizontalPadding, Layout } from "../layout";
import { GetBenefitAssignmentRuleStats } from "../../generated/GetBenefitAssignmentRuleStats";
import { HashLink } from "react-router-hash-link";
import { ChargeTemplateRulesList } from "./charge-templates";
import { BenefitMappings } from "./benefit-mappings";
import { FeeScheduleRulesList } from "./fee-schedules";
import { Link, matchPath, useLocation } from "react-router-dom";
import { cn } from "../../utils";
import { useFeatureFlags } from "../../hooks";
import { DepositRulesList } from "./deposits";
import { PreVisitRulesList } from "./pre-visits";
import { TradingPartnerRulesList } from "./trading-partners";
import { MaxAllowableRulesList } from "./max-allowables";

export const GET_BENEFIT_ASSIGNMENT_RULE_STATS = gql`
  query GetBenefitAssignmentRuleStats {
    benefitAssignmentRuleStats {
      period
      Scheduled
      Assigned
    }
  }
`;

const RulePerfomanceChart: React.FC = () => {
  const { loading, data } = useQuery<GetBenefitAssignmentRuleStats>(
    GET_BENEFIT_ASSIGNMENT_RULE_STATS
  );

  if (loading || !data) {
    // if (true) {
    return (
      <Card>
        <div className="flex flex-col w-full">
          <Title>Benefit Assignment Rule Performance</Title>
          <div className="flex gap-4 mt-4 h-80">
            {Array.from({ length: 8 }).map((_, i) => (
              <div
                key={i}
                className="h-full w-full animate-pulse rounded-md bg-slate-100"
              />
            ))}
          </div>
        </div>
      </Card>
    );
  }

  const stats = (data?.benefitAssignmentRuleStats ?? []).map((s) => ({
    ...s,
    Unassigned: s.Scheduled - s.Assigned,
  }));

  return (
    <Card>
      <div className="flex flex-col w-full">
        <Title>Benefit Assignment Rule Performance</Title>
        <BarChart
          className="mt-4 h-80"
          data={stats}
          index="period"
          categories={["Assigned", "Unassigned"]}
          colors={["emerald", "slate"]}
          stack={true}
        />
      </div>
    </Card>
  );
};

const GET_LOCATION_RULES = gql`
  ${BENEFIT_MAPPING_FIELDS}
  ${CHARGE_TEMPLATE_MAPPING_FIELDS}
  ${FEE_SCHEDULE_MAPPING_FIELDS}
  ${DEPOSIT_MAPPING_FIELDS}
  ${PRE_VISIT_MAPPING_FIELDS}
  ${TRADING_PARTNER_MAPPING_FIELDS}
  ${MAX_ALLOWABLE_RULE_FIELDS}
  query GetLocationRules($locationId: String!, $pending: Boolean!) {
    benefitMappings(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...BenefitMappingFields
    }
    chargeTemplateMappings(
      where: {
        locationId: { equals: $locationId }
        pending: { equals: $pending }
        chargeTemplate: { is: { pending: { equals: $pending } } }
      }
      orderBy: { updatedAt: desc }
    ) {
      ...ChargeTemplateMappingFields
    }
    feeScheduleMappings(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...FeeScheduleMappingFields
    }
    depositMappings(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...DepositMappingFields
    }
    preVisitMappings(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...PreVisitMappingFields
    }
    tradingPartnerMappings(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...TradingPartnerMappingFields
    }
    maxAllowableRules(
      where: { locationId: { equals: $locationId } }
      orderBy: { updatedAt: desc }
    ) {
      ...MaxAllowableRuleFields
    }
  }
`;

export const RulesHeader: React.FC<
  React.PropsWithChildren<{
    setIsPending: (isPending: boolean) => void;
    isPending: boolean;
  }>
> = ({ setIsPending, isPending }) => {
  const flags = useFeatureFlags();
  const location = useLocation();
  const user = useUser();
  const togglePending = () => {
    setIsPending(!isPending);
  };
  return (
    <div className="flex justify-between gap-4">
      <h1 className="text-2xl font-semibold text-gray-900">Rules</h1>
      <nav className="order-last flex items-end w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7 text-gray-700">
        <Link
          to="/rules"
          className={cn(
            matchPath("/rules", location.pathname) && "text-indigo-500"
          )}
        >
          Rules List
        </Link>
        {flags.estimatesEnabled && (
          <Link
            to="/rules/explorer"
            className={cn(
              matchPath("/rules/explorer", location.pathname) &&
                "text-indigo-500"
            )}
          >
            Explorer
          </Link>
        )}
        {user?.isPledgeUser && (
          <Button onClick={togglePending}>
            Toggle Pending {isPending ? "Off" : "On"}
          </Button>
        )}
      </nav>
    </div>
  );
};

export const RulesPage: React.FC = () => {
  const flags = useFeatureFlags();
  const user = useUser()!;
  const [isPending, setIsPending] = useState(false);
  const { data } = useQuery<GetLocationRules, GetLocationRulesVariables>(
    GET_LOCATION_RULES,
    {
      variables: {
        locationId: user.activeLocation.id,
        pending: isPending,
      },
    }
  );

  const benefitMappings = data?.benefitMappings ?? [];
  const chargeTemplateMappings = data?.chargeTemplateMappings ?? [];
  const feeScheduleMappings = data?.feeScheduleMappings ?? [];
  const depositMappings = data?.depositMappings ?? [];
  const preVisitMappings = data?.preVisitMappings ?? [];
  const tradingPartnerMappings = data?.tradingPartnerMappings ?? [];
  const maxAllowableRules = data?.maxAllowableRules ?? [];
  const tableOfContents = [
    {
      id: "benefit-mappings",
      label: "Benefit Mappings",
      count: benefitMappings.length,
    },
    ...(flags.estimatesEnabled
      ? [
          {
            id: "charge-templates",
            label: "Charge Templates",
            count: chargeTemplateMappings.length,
          },
          {
            id: "fee-schedules",
            label: "Fee Schedules",
            count: feeScheduleMappings.length,
          },
          {
            id: "deposits",
            label: "Deposits",
            count: depositMappings.length,
          },
          {
            id: "pre-visits",
            label: "Pre-Visit Reminders",
            count: preVisitMappings.length,
          },
        ]
      : []),
    {
      id: "trading-partners",
      label: "Trading Partners",
      count: tradingPartnerMappings.length,
    },
    {
      id: "max-allowables",
      label: "Max Visit Allowables",
      count: maxAllowableRules.length,
    },
  ];
  return (
    <Layout
      header={
        <HorizontalPadding>
          <RulesHeader isPending={isPending} setIsPending={setIsPending} />
        </HorizontalPadding>
      }
      content={
        <HorizontalPadding>
          <div className="relative flex flex-1 max-h-[95vh] overflow-hidden gap-4 print:overflow-visible print:max-h-none">
            <aside className="relative w-48 hidden overflow-y-auto sm:flex-shrink-0 sm:flex sm:flex-col print:hidden">
              <nav className="space-y-1 flex flex-col pt-4">
                {tableOfContents.map((item) => (
                  <HashLink
                    key={item.id}
                    smooth
                    to={`#${item.id}`}
                    // @ts-ignore This is a real prop
                    activeClassName="bg-gray-100 text-gray-900"
                    className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 py-1 px-4 flex justify-between text-sm rounded-md"
                  >
                    <span className="truncate">{item.label}</span>
                    <span className="ml-1 text-xs text-gray-500">
                      {item.count}
                    </span>
                  </HashLink>
                ))}
              </nav>
            </aside>
            <div className="py-4 overflow-y-auto w-full">
              <div className="flex flex-col gap-8">
                {/* <RulePerfomanceChart /> */}
                <BenefitMappings benefitMappings={benefitMappings} />
                {flags.estimatesEnabled && (
                  <>
                    <ChargeTemplateRulesList
                      chargeTemplateMappings={chargeTemplateMappings}
                    />
                    <FeeScheduleRulesList
                      feeScheduleMappings={feeScheduleMappings}
                    />
                    <DepositRulesList depositMappings={depositMappings} />
                    <PreVisitRulesList preVisitMappings={preVisitMappings} />
                    <TradingPartnerRulesList
                      tradingPartnerMappings={tradingPartnerMappings}
                    />
                    <MaxAllowableRulesList
                      maxAllowableRules={maxAllowableRules}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </HorizontalPadding>
      }
    />
  );
};
