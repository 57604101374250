import { gql, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import React, { useEffect, useMemo, useState } from "react";

import fuzzysort from "fuzzysort";
import { AlertCircle, ChevronRight, RefreshCw } from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../components/ui/collapsible";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { ProviderCombobox } from "../../../components/ui/provider-combobox";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { Skeleton } from "../../../components/ui/skeleton";
import { useDashboardResizable } from "../../../dashboard-resizable";
import { GetInsurancePolicy_insurancePolicy as InsurancePolicy } from "../../../generated/GetInsurancePolicy";
import {
  GetProviders,
  GetProvidersVariables,
} from "../../../generated/GetProviders";
import { cn, isDefined } from "../../../utils";
import { GET_PROVIDERS } from "../../providers";
import {
  GetUHCBenefitLanguage,
  GetUHCBenefitLanguageVariables,
} from "../../../generated/GetUHCBenefitLanguage";
import {
  GetUHCBenefitCategories,
  GetUHCBenefitCategoriesVariables,
} from "../../../generated/GetUHCBenefitCategories";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";
import { Card } from "../../../components";

const GET_UHC_BENEFIT_CATEGORIES = gql`
  query GetUHCBenefitCategories(
    $insurancePolicyId: String!
    $providerId: String!
  ) {
    getUHCBenefitCategories(
      insurancePolicyId: $insurancePolicyId
      providerId: $providerId
    ) {
      transactionId
      benefits {
        categoryName
        children {
          categoryName
          benefitId
        }
      }
    }
  }
`;

const GET_UHC_BENEFIT_LANGUAGE = gql`
  query GetUHCBenefitLanguage($benefitCode: String!, $transactionId: String!) {
    getUHCBenefitLanguage(
      benefitCode: $benefitCode
      transactionId: $transactionId
    ) {
      benefitName
      benefitDetails
      benefitNetworkSection {
        costs
        networkStatus
      }
    }
  }
`;

const ErrorDisplay: React.FC<{
  error: any;
  onRetry: () => void;
  title: string;
  retrying?: boolean;
}> = ({ error, onRetry, title, retrying = false }) => {
  // Extract UHC API specific error details if available
  const errorDetails = error.graphQLErrors?.[0]?.extensions?.exception;
  const isUHCError = errorDetails?.name === "UHCApiError";

  let errorMessage = error.message;
  if (isUHCError && errorDetails.body?.message) {
    errorMessage = errorDetails.body.message;
  }

  return (
    <Alert variant="destructive" className="mb-4">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription className="mt-2">
        <p className="mb-2">{errorMessage}</p>
        {isUHCError && errorDetails.code && (
          <p className="text-sm text-gray-200 mb-2">
            Error Code: {errorDetails.code}
          </p>
        )}
        <Button variant="outline" size="sm" onClick={onRetry} className="gap-2">
          <RefreshCw className={cn("h-4 w-4", retrying && "animate-spin")} />
          Retry
        </Button>
      </AlertDescription>
    </Alert>
  );
};

export const UHCBenefitsPanel: React.FC<{
  insurancePolicy: InsurancePolicy;
  providerId: string;
}> = ({ insurancePolicy, providerId }) => {
  const [search, setSearch] = useState("");

  const { data, loading, error, refetch } = useQuery<
    GetUHCBenefitCategories,
    GetUHCBenefitCategoriesVariables
  >(GET_UHC_BENEFIT_CATEGORIES, {
    variables: {
      insurancePolicyId: insurancePolicy.id,
      providerId,
    },
  });
  const transactionId = data?.getUHCBenefitCategories?.transactionId;

  const normalizeScore = (score: number | null) => {
    const MIN_SCORE = -2500;
    if (score === null || score < MIN_SCORE) return 0;
    return (score - MIN_SCORE) / -MIN_SCORE;
  };

  const renderHighlightedText = (
    text: string,
    match: Fuzzysort.Result | null
  ) => {
    if (!match || !search) return text;

    const highlighted = fuzzysort.highlight(match, (m, i) => (
      <span key={i} className="bg-yellow-100 text-yellow-900">
        {m}
      </span>
    ));

    return highlighted || text;
  };

  const filteredBenefits = useMemo(() => {
    if (!data?.getUHCBenefitCategories?.benefits) return [];
    if (!search) return data.getUHCBenefitCategories.benefits;

    const searchLower = search.toLowerCase();
    return data.getUHCBenefitCategories.benefits
      .map((category) => {
        // Score the category name
        const categoryMatch = fuzzysort.single(
          searchLower,
          category.categoryName.toLowerCase()
        );
        const categoryScore = normalizeScore(categoryMatch?.score ?? null);

        // Score and filter children
        const matchedChildren = category.children
          .map((child) => {
            const childMatch = fuzzysort.single(
              searchLower,
              child.categoryName.toLowerCase()
            );
            const childScore = normalizeScore(childMatch?.score ?? null);

            return {
              child,
              match: childMatch,
              score: childScore,
            };
          })
          .filter((result) => result.score > 0);

        // If category matches or any children match, include this category
        if (categoryScore > 0 || matchedChildren.length > 0) {
          return {
            ...category,
            match: categoryMatch,
            score: categoryScore,
            children: matchedChildren.map((m) => ({
              ...m.child,
              match: m.match,
            })),
          };
        }
        return null;
      })
      .filter(isDefined)
      .sort((a, b) => b.score - a.score);
  }, [data, search]);

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b">
        <Input
          placeholder="Search benefits..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full"
        />
      </div>
      <ScrollArea className="flex-1">
        <div className="p-4">
          {error ? (
            <ErrorDisplay
              error={error}
              onRetry={() => refetch()}
              title="Error Loading Benefits"
              retrying={loading}
            />
          ) : loading ? (
            <div className="space-y-6">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="space-y-4">
                  <Skeleton className="h-6 w-48" />
                  <div className="pl-4 space-y-3">
                    {[...Array(3)].map((_, j) => (
                      <Skeleton key={j} className="h-10 w-full" />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-6 pb-8">
              {filteredBenefits.map((category) => (
                <div key={category.categoryName}>
                  <h3 className="font-medium text-lg mb-3 text-gray-900">
                    {renderHighlightedText(
                      category.categoryName,
                      category.match
                    )}
                    <span className="text-sm text-gray-500 ml-2">
                      ({category.children.length})
                    </span>
                  </h3>
                  <div className="space-y-2">
                    {category.children.map((child) => (
                      <div key={child.benefitId}>
                        <Collapsible>
                          <CollapsibleTrigger
                            className={cn(
                              "w-full text-left px-4 py-3 rounded-lg border transition-colors",
                              "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary/20",
                              "data-[state=open]:bg-gray-50 data-[state=open]:border-gray-300 data-[state=closed]:border-transparent"
                            )}
                          >
                            <div className="flex items-center justify-between">
                              <span className="font-medium text-blue-900">
                                {renderHighlightedText(
                                  child.categoryName,
                                  child.match
                                )}
                              </span>
                              <ChevronRight
                                className={cn(
                                  "h-5 w-5 text-gray-400 transition-transform",
                                  "data-[state=open]:rotate-90"
                                )}
                              />
                            </div>
                          </CollapsibleTrigger>
                          <CollapsibleContent>
                            <div className="p-2">
                              <Card>
                                {transactionId && (
                                  <BenefitLanguageDisplay
                                    benefitId={child.benefitId}
                                    transactionId={transactionId}
                                  />
                                )}
                              </Card>
                            </div>
                          </CollapsibleContent>
                        </Collapsible>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

const BenefitLanguageDisplay: React.FC<{
  benefitId: string;
  transactionId: string;
}> = ({ benefitId, transactionId }) => {
  const { data, loading, error, refetch } = useQuery<
    GetUHCBenefitLanguage,
    GetUHCBenefitLanguageVariables
  >(GET_UHC_BENEFIT_LANGUAGE, {
    variables: {
      benefitCode: benefitId,
      transactionId,
    },
    skip: !benefitId || !transactionId,
  });

  const languageBenefits = data?.getUHCBenefitLanguage ?? [];

  return (
    <div>
      {error ? (
        <ErrorDisplay
          error={error}
          onRetry={() => refetch()}
          title="Error Loading Benefit Details"
          retrying={loading}
        />
      ) : loading ? (
        <div className="space-y-4">
          <Skeleton className="h-6 w-3/4" />
          <Skeleton className="h-24 w-full" />
          <div className="space-y-3">
            <Skeleton className="h-16 w-full" />
            <Skeleton className="h-16 w-full" />
          </div>
        </div>
      ) : (
        languageBenefits.map((language, idx) => (
          <div key={idx} className="space-y-4">
            <h4 className="text-base font-semibold">{language.benefitName}</h4>
            {language.benefitDetails && (
              <div
                className="prose max-w-none text-sm text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: language.benefitDetails,
                }}
              />
            )}
            <div className="space-y-3">
              {language.benefitNetworkSection.map((section, i) => (
                <div key={i} className="rounded-lg border border-gray-200 p-3">
                  <div className="font-medium text-sm text-gray-900 mb-2">
                    {section.networkStatus.join(", ")}
                  </div>
                  <div
                    className="prose max-w-none text-sm"
                    dangerouslySetInnerHTML={{
                      __html: section.costs,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export const LookupUHCBenefitsDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  insurancePolicy: InsurancePolicy;
}> = ({ open, setOpen, insurancePolicy }) => {
  const [selectedProvider, setSelectedProvider] = useState<{
    id: string;
    firstName: string | null;
    lastName: string | null;
    organizationName: string | null;
    npi: string | null;
  } | null>(null);

  const { setPanelChild, collapse } = useDashboardResizable();

  const { data: providersData } = useQuery<GetProviders, GetProvidersVariables>(
    GET_PROVIDERS,
    {
      variables: {
        locationId: insurancePolicy.patient.location.id,
        organizationId: insurancePolicy.patient.organization.id,
      },
    }
  );
  const providers = providersData?.providers ?? [];

  // Default to first provider
  useEffect(() => {
    if (providers.length > 0) {
      setSelectedProvider(providers[0]);
    }
  }, [providers]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Lookup UHC Portal Benefits</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div>
            <Label>Select Provider</Label>
            <div className="mt-2">
              <ProviderCombobox
                value={selectedProvider}
                providers={providers}
                onSelect={setSelectedProvider}
                placeholder="Search providers..."
              />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedProvider) {
                  setPanelChild(
                    <UHCBenefitsSidePanel
                      insurancePolicy={insurancePolicy}
                      providerId={selectedProvider.id}
                      onClose={collapse}
                    />
                  );
                  setOpen(false);
                }
              }}
              disabled={!selectedProvider}
            >
              View Benefits
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const UHCBenefitsSidePanel: React.FC<{
  insurancePolicy: InsurancePolicy;
  providerId: string;
  onClose: () => void;
}> = ({ insurancePolicy, providerId, onClose }) => {
  return (
    <div className="h-screen flex flex-col px-4 bg-white border-l border-gray-300">
      <div className="flex justify-between items-center pt-4">
        <div>
          <h2 className="text-xl font-medium">UHC Portal Detailed Benefits</h2>
          <div className="mt-2 text-sm text-gray-600">
            <div>Patient: {insurancePolicy.patient.displayName}</div>
            <div>Member ID: {insurancePolicy.memberId}</div>
          </div>
        </div>
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={onClose}
        >
          <span className="sr-only">Close side panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <UHCBenefitsPanel
        insurancePolicy={insurancePolicy}
        providerId={providerId}
      />
    </div>
  );
};
