import { gql, useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../user-context";
import { DataTable } from "./table";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../components/ui/table-helpers/data-table-column-header";
import {
  cn,
  formatRelativeDay,
  formatUSD,
  isDefined,
  mapNullable,
  toCents,
  toDollarStr,
} from "../../utils";
import { format, max, parseISO, subYears } from "date-fns";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Button } from "../../components/ui/button";
import { CurrencyInput, Input } from "../../components/ui/input";
import { v4 as uuidV4 } from "uuid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  UpsertScheduledServiceFee,
  UpsertScheduledServiceFeeVariables,
} from "../../generated/UpsertScheduledServiceFee";
import {
  GetChargemasterGroupsAndFeeSchedules,
  GetChargemasterGroupsAndFeeSchedulesVariables,
  GetChargemasterGroupsAndFeeSchedules_getChargemasterGroupsAndFeeSchedules_chargemasterGroups as ChargemasterGroup,
  GetChargemasterGroupsAndFeeSchedules_getChargemasterGroupsAndFeeSchedules_feeSchedules as FeeSchedule,
  GetChargemasterGroupsAndFeeSchedules_getChargemasterGroupsAndFeeSchedules_chargemasterGroups_scheduledServiceFees as ScheduledServiceFee,
} from "../../generated/GetChargemasterGroupsAndFeeSchedules";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
  FormDescription,
} from "../../components/ui/form";
import { OvalSpinner } from "../../components/loading";
import { IntegrationType } from "../../generated/globalTypes";
import {
  UpsertChargemasterGroup,
  UpsertChargemasterGroupVariables,
} from "../../generated/UpsertChargemasterGroup";
import { Switch } from "../../components/ui/switch";
import { Tooltip } from "../../components";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { HorizontalPadding } from "../layout";

export type ChargemasterRow = {
  id: string;
  archived: "Active" | "Stale" | "Archived";
  lastUsedAt: Date | null;
  code: string;
  amount: number;
  cashPay: "Yes" | "No";
  description: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  // feeSchedules: {
  //   id: string;
  //   name: string;
  //   allowedAmount: number;
  // }[];
  chargemasterGroup: ChargemasterGroup;
};

export const GET_CHARGEMASTER_GROUPS = gql`
  query GetChargemasterGroupsAndFeeSchedules(
    $locationId: String!
    $pending: Boolean!
    $includeArchived: Boolean!
    $lastUsedAfter: DateTime
  ) {
    getChargemasterGroupsAndFeeSchedules(
      locationId: $locationId
      pending: $pending
      includeArchived: $includeArchived
      lastUsedAfter: $lastUsedAfter
    ) {
      chargemasterGroups {
        id
        updatedAt
        archivedAt
        amount
        code
        modifier1
        modifier2
        modifier3
        modifier4
        description
        cashPay
        lastUsedAt
        scheduledServiceFees {
          id
          allowedAmount
          feeSchedule {
            id
            name
          }
        }
      }
      feeSchedules {
        id
        name
      }
    }
  }
`;

const UPSERT_SCHEDULED_SERVICE_FEE = gql`
  mutation UpsertScheduledServiceFee(
    $id: String!
    $create: ScheduledServiceFeeCreateInput!
    $update: ScheduledServiceFeeUpdateInput!
  ) {
    upsertOneScheduledServiceFee(
      where: { id: $id }
      create: $create
      update: $update
    ) {
      id
      allowedAmount
      feeSchedule {
        id
        name
      }
      chargemasterGroup {
        id
      }
    }
  }
`;

const FeeScheduleFeeEditDialog: React.FC<{
  scheduledServiceFee: ScheduledServiceFee | null;
  feeSchedule: FeeSchedule;
  chargemasterGroup: ChargemasterGroup;
}> = ({ scheduledServiceFee, feeSchedule, chargemasterGroup }) => {
  const [open, setOpen] = useState(false);
  const [fee, setFee] = useState<string | null>(
    mapNullable(toDollarStr)(scheduledServiceFee?.allowedAmount)
  );
  const [upsertScheduledServiceFee, upsertScheduledServiceFeeResult] =
    useMutation<UpsertScheduledServiceFee, UpsertScheduledServiceFeeVariables>(
      UPSERT_SCHEDULED_SERVICE_FEE
    );

  const id = scheduledServiceFee?.id ?? uuidV4();

  const allowedAmount = parseFloat(fee ?? 0);
  const isValid = !isNaN(allowedAmount);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="w-full text-right truncate h-full px-2">
        {mapNullable(formatUSD)(scheduledServiceFee?.allowedAmount) ?? (
          <>&nbsp;</>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Edit allowed amount for {chargemasterGroup.code} for the{" "}
            {feeSchedule.name} Fee Schedule?
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <CurrencyInput
                  decimalsLimit={2}
                  onValueChange={(val) => {
                    setFee(val);
                  }}
                  defaultValue={fee}
                  // value={fee}
                />
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={
                      !isValid || upsertScheduledServiceFeeResult.loading
                    }
                    onClick={() => {
                      const allowedAmountCents = toCents(allowedAmount);
                      upsertScheduledServiceFee({
                        variables: {
                          id,
                          create: {
                            allowedAmount: allowedAmountCents,
                            chargemasterGroup: {
                              connect: {
                                id: chargemasterGroup.id,
                              },
                            },
                            feeSchedule: {
                              connect: {
                                id: feeSchedule.id,
                              },
                            },
                          },
                          update: {
                            allowedAmount: { set: allowedAmountCents },
                          },
                        },
                        refetchQueries: [GET_CHARGEMASTER_GROUPS],
                        onCompleted: () => {
                          toast.success("Fee schedule updated");
                          setOpen(false);
                        },
                        onError: () => {
                          toast.error("Failed to update fee schedule");
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const UPSERT_CHARGEMASTER_GROUPS = gql`
  mutation UpsertChargemasterGroups(
    $params: [UpsertChargemasterGroupsInput!]!
  ) {
    upsertChargemasterGroups(params: $params) {
      id
    }
  }
`;

const ImportChargemastersForm = z.object({
  chargemasters: z.array(
    z.object({
      id: z.string().nullable(),
      code: z.string(),
      modifier1: z.string(),
      modifier2: z.string(),
      modifier3: z.string(),
      modifier4: z.string(),
      description: z.string(),
      cashPay: z.boolean(),
      price: z.string(),
    })
  ),
});

const ChargemasterFormRow: React.FC<{ idx: number }> = ({ idx }) => {
  const form = useFormContext<z.infer<typeof ImportChargemastersForm>>();
  const formPrice = form.getValues(`chargemasters.${idx}.price`);
  const [price, setPrice] = useState<string>(formPrice);

  const codeDirty = form.getFieldState(`chargemasters.${idx}.code`).isDirty;
  const cashPayDirty = form.getFieldState(
    `chargemasters.${idx}.cashPay`
  ).isDirty;
  const descriptionDirty = form.getFieldState(
    `chargemasters.${idx}.description`
  ).isDirty;
  const modifier1Dirty = form.getFieldState(
    `chargemasters.${idx}.modifier1`
  ).isDirty;
  const modifier2Dirty = form.getFieldState(
    `chargemasters.${idx}.modifier2`
  ).isDirty;
  const modifier3Dirty = form.getFieldState(
    `chargemasters.${idx}.modifier3`
  ).isDirty;
  const modifier4Dirty = form.getFieldState(
    `chargemasters.${idx}.modifier4`
  ).isDirty;
  const priceDirty = form.getFieldState(`chargemasters.${idx}.price`).isDirty;

  return (
    <tr>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.code`}
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    codeDirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.description`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    descriptionDirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.modifier1`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    modifier1Dirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.modifier2`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    modifier2Dirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.modifier3`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    modifier3Dirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.modifier4`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className={cn(
                    modifier4Dirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.cashPay`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className={cn(cashPayDirty && "ring-2 ring-amber-500")}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="p-1">
        <FormField
          control={form.control}
          name={`chargemasters.${idx}.price`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CurrencyInput
                  decimalsLimit={2}
                  onValueChange={(val) => {
                    setPrice(val);
                    if (val) {
                      form.setValue(`chargemasters.${idx}.price`, val);
                    }
                  }}
                  defaultValue={price}
                  {...field}
                  value={price}
                  className={cn(
                    priceDirty && "ring-2 ring-inset ring-amber-500"
                  )}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
    </tr>
  );
};

const IMPORT_EXTERNAL_CHARGEMASTER = gql`
  mutation ImportExternalChargemaster($integrationId: String!) {
    importExternalChargemaster(integrationId: $integrationId) {
      chargemasters {
        chargemasterGroupId
        code
        description
        modifier1
        modifier2
        modifier3
        modifier4
        price
      }
      errors {
        message
      }
    }
  }
`;

export const ImportChargemasterDialog: React.FC<{
  chargemasterGroups: ChargemasterGroup[];
}> = ({ chargemasterGroups }) => {
  const user = useUser()!;
  // TODO: Filter for only integrations that support chargemasters
  const integrations = user.activeLocation.integrations.filter((integration) =>
    [IntegrationType.Chirotouch, IntegrationType.Elation].includes(
      integration.type
    )
  );
  const [open, setOpen] = useState(false);
  const [upsertChargemasterGroups, upsertChargemasterGroupsResult] =
    useMutation(UPSERT_CHARGEMASTER_GROUPS);

  const [importExternalChargemaster, importExternalChargemasterResult] =
    useMutation(IMPORT_EXTERNAL_CHARGEMASTER);

  const form = useForm<z.infer<typeof ImportChargemastersForm>>({
    resolver: zodResolver(ImportChargemastersForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      chargemasters: chargemasterGroups
        .map((cg) => ({
          id: cg.id,
          code: cg.code,
          modifier1: cg.modifier1,
          modifier2: cg.modifier2,
          modifier3: cg.modifier3,
          modifier4: cg.modifier4,
          description: cg.description ?? "",
          cashPay: cg.cashPay,
          price: toDollarStr(cg.amount),
        }))
        .sort((a, b) => a.code.localeCompare(b.code)),
    },
  });

  const chargemasterGroupFields = useFieldArray({
    control: form.control,
    name: "chargemasters",
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="w-full text-right truncate">
        <Button variant="default" size="sm" className="h-8">
          Bulk Edit
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <DialogTitle className="flex justify-between items-center py-2">
            <div>Bulk Edit Chargemaster</div>
            <div>
              {integrations.map((integration) => (
                <Button
                  key={integration.id}
                  size="sm"
                  disabled={importExternalChargemasterResult.loading}
                  onClick={() => {
                    importExternalChargemaster({
                      variables: {
                        integrationId: integration.id,
                      },
                      onCompleted: (data) => {
                        const chargemasters =
                          data.importExternalChargemaster.chargemasters?.map(
                            (c) => ({
                              id: c.chargemasterGroupId ?? null,
                              code: c.code,
                              modifier1: c.modifier1 ?? "",
                              modifier2: c.modifier2 ?? "",
                              modifier3: c.modifier3 ?? "",
                              modifier4: c.modifier4 ?? "",
                              description: c.description ?? "",
                              cashPay: false,
                              price: toDollarStr(c.price),
                            })
                          ) ?? [];

                        // Load imported chargemasters in same order to avoid dirtying just from reordering
                        const currentValues =
                          form.getValues("chargemasters") ?? [];
                        const updated = currentValues.map((row) => {
                          const imported = chargemasters.find(
                            (c) => c.id && c.id === row.id
                          );
                          return imported ?? row;
                        });
                        const newlyImported = chargemasters.filter((c) =>
                          currentValues.every(
                            (row) => !row.id || c.id !== row.id
                          )
                        );
                        const orderedChargemasters = [
                          ...updated,
                          ...newlyImported,
                        ];

                        chargemasterGroupFields.replace(orderedChargemasters);
                      },
                    });
                  }}
                >
                  {importExternalChargemasterResult.loading ? (
                    <OvalSpinner />
                  ) : (
                    <>Load Chargemaster from {integration.name}</>
                  )}
                </Button>
              ))}
            </div>
          </DialogTitle>
          <DialogDescription>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  upsertChargemasterGroups({
                    variables: {
                      params: data.chargemasters.map((chargemaster) => ({
                        id: chargemaster.id,
                        code: chargemaster.code,
                        modifier1: chargemaster.modifier1,
                        modifier2: chargemaster.modifier2,
                        modifier3: chargemaster.modifier3,
                        modifier4: chargemaster.modifier4,
                        description: chargemaster.description,
                        cashPay: chargemaster.cashPay,
                        amount: toCents(parseFloat(chargemaster.price)),
                      })),
                    },
                    refetchQueries: [GET_CHARGEMASTER_GROUPS],
                    onCompleted: () => {
                      toast.success("Chargemaster updated");
                      setOpen(false);
                    },
                    onError: () => {
                      toast.error("Failed to update chargemaster");
                    },
                  });
                })}
              >
                <div className="max-h-[80vh] overflow-auto px-2 pb-2 border rounded-md">
                  <table className="relative">
                    <thead className="sticky top-0">
                      <tr>
                        <th className="pt-2 p-1 bg-white">Code</th>
                        <th className="pt-2 p-1 bg-white">Description</th>
                        <th className="pt-2 p-1 bg-white">Modifier 1</th>
                        <th className="pt-2 p-1 bg-white">Modifier 2</th>
                        <th className="pt-2 p-1 bg-white">Modifier 3</th>
                        <th className="pt-2 p-1 bg-white">Modifier 4</th>
                        <th className="pt-2 p-1 bg-white">Cash Only</th>
                        <th className="pt-2 p-1 bg-white">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chargemasterGroupFields.fields.map((field, index) => {
                        return (
                          <ChargemasterFormRow key={field.id} idx={index} />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end gap-2 pt-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <div>
                    <Button disabled={upsertChargemasterGroupsResult.loading}>
                      {upsertChargemasterGroupsResult.loading ? (
                        <OvalSpinner />
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const UPSERT_CHARGEMASTER_GROUP = gql`
  mutation UpsertChargemasterGroup(
    $id: String
    $code: String!
    $description: String!
    $modifier1: String!
    $modifier2: String!
    $modifier3: String!
    $modifier4: String!
    $cashPay: Boolean!
    $amount: Int!
  ) {
    upsertChargemasterGroup(
      id: $id
      code: $code
      description: $description
      modifier1: $modifier1
      modifier2: $modifier2
      modifier3: $modifier3
      modifier4: $modifier4
      cashPay: $cashPay
      amount: $amount
    ) {
      id
    }
  }
`;

const NewChargemasterForm = z.object({
  code: z.string(),
  modifier1: z.string().optional(),
  modifier2: z.string().optional(),
  modifier3: z.string().optional(),
  modifier4: z.string().optional(),
  description: z.string().optional(),
  cashPay: z.boolean(),
  amount: z.string(),
});

export const NewChargemasterDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [upsertChargemasterGroup, upsertChargemasterGroupResult] = useMutation<
    UpsertChargemasterGroup,
    UpsertChargemasterGroupVariables
  >(UPSERT_CHARGEMASTER_GROUP);

  const form = useForm<z.infer<typeof NewChargemasterForm>>({
    resolver: zodResolver(NewChargemasterForm),
    reValidateMode: "onSubmit",
    defaultValues: {
      code: undefined,
      modifier1: undefined,
      modifier2: undefined,
      modifier3: undefined,
      modifier4: undefined,
      description: undefined,
      cashPay: false,
      amount: undefined,
    },
  });

  const [price, setPrice] = useState<string>("0");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="w-full text-right">
        <Button variant="default" size="sm" className="h-8 truncate">
          New Chargemaster
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create new Chargemaster</DialogTitle>
          <DialogDescription>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  const amount = toCents(parseFloat(data.amount));
                  upsertChargemasterGroup({
                    variables: {
                      id: null,
                      code: data.code,
                      modifier1: data.modifier1 ?? "",
                      modifier2: data.modifier2 ?? "",
                      modifier3: data.modifier3 ?? "",
                      modifier4: data.modifier4 ?? "",
                      description: data.description ?? "",
                      cashPay: data.cashPay,
                      amount,
                    },
                    refetchQueries: [GET_CHARGEMASTER_GROUPS],
                    onCompleted: () => {
                      toast.success("Chargemaster created");
                      setOpen(false);
                    },
                    onError: () => {
                      toast.error("Failed to create chargemaster");
                    },
                  });
                })}
              >
                <div className="flex flex-col gap-1 pt-2">
                  <FormField
                    control={form.control}
                    name="code"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Code</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex gap-2">
                    <FormField
                      control={form.control}
                      name="modifier1"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Modifier 1</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="modifier2"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Modifier 2</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="modifier3"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Modifier 3</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="modifier4"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Modifier 4</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="amount"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Price</FormLabel>
                          <FormControl>
                            <CurrencyInput
                              decimalsLimit={2}
                              onValueChange={(val) => {
                                setPrice(val);
                                if (val) {
                                  form.setValue("amount", val);
                                }
                              }}
                              defaultValue={price}
                              {...field}
                              value={price}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="cashPay"
                      render={({ field }) => (
                        <FormItem>
                          <div className="pt-1">
                            <FormLabel className="flex items-center gap-1">
                              Cash Pay
                              <Tooltip
                                content={
                                  <>Don't apply insurance towards this charge</>
                                }
                                trigger={
                                  <InformationCircleIcon className="w-4 h-4 text-gray-400" />
                                }
                              />
                            </FormLabel>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  {/* <div>
                    <CurrencyInput
                      decimalsLimit={2}
                      onValueChange={(val) => {
                        setFee(val);
                      }}
                      defaultValue={fee}
                      {...form.register("amount")}
                      // value={fee}
                    />
                  </div> */}
                  <div className="flex justify-end gap-2 pt-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <div>
                      <Button
                      // disabled={!isValid}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const ChargemasterGrid: React.FC = () => {
  const user = useUser()!;
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [includeArchived, setIncludeArchived] = useState(false);

  const staleBefore = format(subYears(new Date(), 1), "yyyy-MM-dd");
  const [lastUsedAfter, setLastUsedAfter] = useState<string | null>(
    () => staleBefore
  );

  const { data, loading, error } = useQuery<
    GetChargemasterGroupsAndFeeSchedules,
    GetChargemasterGroupsAndFeeSchedulesVariables
  >(GET_CHARGEMASTER_GROUPS, {
    variables: {
      locationId: user.activeLocation.id,
      pending: isPending,
      includeArchived,
      lastUsedAfter,
    },
    fetchPolicy: "cache-first",
  });

  const togglePending = () => {
    setIsPending(!isPending);
  };

  const rows =
    data?.getChargemasterGroupsAndFeeSchedules?.chargemasterGroups ?? [];

  const defaultColumns: ColumnDef<ChargemasterRow>[] = [
    {
      id: "lastUsedAt",
      accessorKey: "lastUsedAt",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Last Used" />
      ),
      cell: ({ row }) => (
        <div>{mapNullable(formatRelativeDay)(row.original.lastUsedAt)}</div>
      ),
    },
    {
      id: "archived",
      accessorKey: "archived",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Archived" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.original.archived);
      },
    },
    {
      id: "code",
      accessorKey: "code",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
    },
    {
      id: "description",
      accessorKey: "description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Description" />
      ),
    },
    {
      id: "modifier1",
      accessorKey: "modifier1",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Modifier 1" />
      ),
    },
    {
      id: "modifier2",
      accessorKey: "modifier2",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Modifier 2" />
      ),
    },
    {
      id: "cashPay",
      accessorKey: "cashPay",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Cash Only" />
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.original.cashPay);
      },
    },
    {
      id: "amount",
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Cash Price" />
      ),
      cell: ({ row }) => {
        return (
          <div className="text-right">{formatUSD(row.original.amount)}</div>
        );
      },
    },
  ];

  const feeSchedules =
    data?.getChargemasterGroupsAndFeeSchedules?.feeSchedules ?? [];

  const columns: ColumnDef<ChargemasterRow>[] = [
    ...defaultColumns,
    ...feeSchedules.map(
      (feeSchedule): ColumnDef<ChargemasterRow> => ({
        id: feeSchedule.name,
        accessorKey: feeSchedule.name,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={feeSchedule.name}
            className="text-nowrap"
          />
        ),
        cell: ({ row }) => {
          const chargemasterGroup = row.original.chargemasterGroup;
          const scheduledServiceFee =
            chargemasterGroup.scheduledServiceFees?.find(
              (fee) => fee.feeSchedule.id === feeSchedule.id
            ) ?? null;
          return (
            <div
              className="h-9"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FeeScheduleFeeEditDialog
                scheduledServiceFee={scheduledServiceFee}
                feeSchedule={feeSchedule}
                chargemasterGroup={chargemasterGroup}
              />
            </div>
          );
        },
        className: "ring-inset hover:ring-1 hover:ring-indigo-500 p-0",
      })
    ),
  ];

  const tableData: ChargemasterRow[] = rows.map((row) => {
    const chargemasterGroup = row;
    const scheduledFees: Record<string, number> =
      row.scheduledServiceFees?.reduce((acc, fee) => {
        return {
          ...acc,
          [fee.feeSchedule.name]: fee.allowedAmount,
        };
      }, {}) ?? {};

    let status: "Active" | "Stale" | "Archived";
    if (row.archivedAt) {
      status = "Archived";
    } else if (!row.lastUsedAt || row.lastUsedAt < staleBefore) {
      status = "Stale";
    } else {
      status = "Active";
    }

    return {
      id: row.id,
      chargemasterGroup,
      archived: status,
      lastUsedAt: row.lastUsedAt ? parseISO(row.lastUsedAt) : null,
      code: row.code,
      amount: row.amount,
      cashPay: row.cashPay ? "Yes" : "No",
      description: row.description ?? "",
      modifier1: row.modifier1,
      modifier2: row.modifier2,
      modifier3: row.modifier3,
      modifier4: row.modifier4,
      ...scheduledFees,
    };
  });

  return (
    <div className="flex flex-col gap-8">
      {user.isPledgeUser && (
        <HorizontalPadding>
          <div className="flex justify-end w-full">
            <Button onClick={togglePending} size="sm">
              Toggle Pending {isPending ? "Off" : "On"}
            </Button>
          </div>
        </HorizontalPadding>
      )}
      <DataTable
        data={tableData}
        columns={columns}
        loading={loading}
        defaultColumnFilters={[
          {
            id: "archived",
            value: ["Active"],
          },
        ]}
        onRowClick={(row) => navigate(`/chargemaster/${row.id}`)}
        isPending={isPending}
        onArchiveFilterChange={(filterValue) => {
          const includesArchived = filterValue.includes("Archived");
          const includesStale = filterValue.includes("Stale");

          setIncludeArchived(includesArchived);
          setLastUsedAfter(
            includesStale ? null : format(subYears(new Date(), 1), "yyyy-MM-dd")
          );
        }}
      />
    </div>
  );
};
