import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Dialog,
  Disclosure,
  Listbox,
  Menu,
  RadioGroup,
  Switch,
  Tab,
  Transition,
} from "@headlessui/react";
import * as Sentry from "@sentry/react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { format, isSameDay, isAfter, parseISO } from "date-fns";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { Textarea } from "../../components/ui/textarea";
import { Label } from "../../components/ui/label";
import {
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DuplicateIcon,
  ExclamationCircleIcon as ExclamationCircleIconOutline,
  ExclamationIcon,
  ExternalLinkIcon,
  IdentificationIcon,
  InformationCircleIcon,
  LinkIcon,
  MailIcon,
  PencilAltIcon,
  PencilIcon,
  PrinterIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/outline";
import { ColumnFiltersState } from "@tanstack/react-table";
import { MessageCircleOffIcon, MonitorOffIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as z from "zod";
import { useAnalytics } from "../../analytics-context";
import { Location } from "../../auth-context";
import {
  Badge,
  Card,
  IntegrationStatus,
  Modal,
  SubmitButton,
  Tooltip,
  Button,
} from "../../components";
import { CardComponent } from "../../components/card-icons";
import { DollarInput } from "../../components/input";
import { OvalSpinner } from "../../components/loading";
import { Checkbox } from "../../components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../components/ui/form";
import {
  ChargePatientPaymentMethod,
  ChargePatientPaymentMethodVariables,
} from "../../generated/ChargePatientPaymentMethod";
import {
  GetPatient_patient_accounts as Account,
  GetPatient,
  GetPatientVariables,
  GetPatient_patient_integrationLinks as IntegrationLink,
  GetPatient_patient as IPatient,
  GetPatient_patient_paymentMethods as PaymentMethod,
  GetPatient_patient_notes as INote,
} from "../../generated/GetPatient";
import {
  GetPatientUnpaidBills_getPatientUnpaidBills as UnpaidBill,
  GetPatientUnpaidBills_getPatientUnpaidBills_charges as BillCharge,
  GetPatientUnpaidBills,
  GetPatientUnpaidBillsVariables,
} from "../../generated/GetPatientUnpaidBills";
import {
  GetPossibleMatches,
  GetPossibleMatchesVariables,
} from "../../generated/GetPossibleMatches";
import {
  LedgerGranularityLevel,
  ValidationStatus,
  NoteType,
} from "../../generated/globalTypes";
import {
  PauseReminderWorkflow,
  PauseReminderWorkflowVariables,
} from "../../generated/PauseReminderWorkflow";
import {
  ResumeReminderWorkflow,
  ResumeReminderWorkflowVariables,
} from "../../generated/ResumeReminderWorkflow";
import {
  SavePaymentMethodFromSetupIntent,
  SavePaymentMethodFromSetupIntentVariables,
} from "../../generated/SavePaymentMethodFromSetupIntent";
import {
  SendLinkPaymentRequest,
  SendLinkPaymentRequestVariables,
} from "../../generated/SendLinkPaymentRequest";
import {
  SendPrintPaymentRequest,
  SendPrintPaymentRequestVariables,
} from "../../generated/SendPrintPaymentRequest";
import {
  SetAutoPayEnrollment,
  SetAutoPayEnrollmentVariables,
} from "../../generated/SetAutoPayEnrollment";
import {
  StartReminderWorkflow,
  StartReminderWorkflowVariables,
} from "../../generated/StartReminderWorkflow";
import {
  UpdatePatientCommunication,
  UpdatePatientCommunicationVariables,
} from "../../generated/UpdatePatientCommunication";
import {
  UpdatePatientMaxAutopayLimit,
  UpdatePatientMaxAutopayLimitVariables,
} from "../../generated/UpdatePatientMaxAutopayLimit";
import {
  UpdatePatientWorkflowSettings,
  UpdatePatientWorkflowSettingsVariables,
} from "../../generated/UpdatePatientWorkflowSettings";
import { UpdateNote, UpdateNoteVariables } from "../../generated/UpdateNote";
import { CreateNote, CreateNoteVariables } from "../../generated/CreateNote";
import {
  BILL_CHARGE_FIELDS,
  VISIT_COLLECTION_REQUEST_FIELDS,
} from "../../graphql";
import { useFeatureFlags } from "../../hooks";
import { useStripeContext } from "../../stripe";
import { useUser } from "../../user-context";
import {
  billStateDisplay,
  classNames,
  cn,
  copyTextToClipboard,
  formatDateMMDDYYYY,
  formatUSD,
  isDefined,
  mapNullable,
  toCents,
  toDate,
  toDateMMDDYYYY,
  toDollars,
  toUtcDate,
} from "../../utils";
import { getBillIndicator } from "../appointments";
import { Layout } from "../layout";
import { AutoEstimateIndicator } from "../shared/Tooltips";
import { FeedTimeline } from "./activity-feed";
import { PatientLedger } from "./ledger";
import {
  PAUSE_REMINDER_WORKFLOW,
  RESUME_REMINDER_WORKFLOW,
  START_REMINDER_WORKFLOW,
} from "./patient-header";
import {
  LinkPatientDialogButton,
  NewLinkPatientDialogButton,
  UnlinkPatientDialog,
  UnlinkPatientDialogButton,
} from "./patient-link-dialog";
import {
  ExternalPaymentMethodDisplay,
  PaymentMethodDisplay,
  paymentMethodDisplay,
} from "./payment-method-display";
import { Eligibility, GET_PATIENT, GET_PATIENT_READY_BILLS } from "./show";
import { PatientVisitList } from "./visits";
import { CREATE_NOTE, UPDATE_NOTE } from "../appointments/table/columns";

const UPDATE_PATIENT_WORKFLOW_SETTINGS = gql`
  mutation UpdatePatientWorkflowSettings(
    $id: String!
    $data: PatientUpdateInput!
  ) {
    updateOnePatient(where: { id: $id }, data: $data) {
      id
      estimationPausedAt
      preVisitReminderPausedAt
      timeOfServiceAutoChargePausedAt
      estimateCommunicationEnrolled
    }
  }
`;

const MainSection: React.FC<
  React.PropsWithChildren<{
    patient: IPatient;
  }>
> = ({ patient }) => {
  const flags = useFeatureFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab")?.toLowerCase();

  const billingFeatureEnabledAndChargesSupported =
    flags.chargesSupported &&
    (flags.postVisitBillingEnabled || flags.tosCollectionEnabled);
  // If billing is enabled, default to Activity tab else default to Eligibility tab
  const defaultTab =
    flags.postVisitBillingEnabled || flags.tosCollectionEnabled
      ? flags.chargesSupported
        ? 0
        : 1
      : 3;
  // TODO: enable the Estimates tab when we're confident
  const tabs = [
    {
      id: "activity",
      name: "Activity",
      disabled: !billingFeatureEnabledAndChargesSupported,
    },
    {
      id: "visits",
      name: "Visits",
      disabled: !(flags.postVisitBillingEnabled || flags.tosCollectionEnabled),
    },
    {
      id: "ledger",
      name: "Ledger",
      disabled: !billingFeatureEnabledAndChargesSupported,
    },
    {
      id: "insurance",
      name: "Insurance Policies",
      disabled: !flags.benefitsProductEnabled,
    },
    ...(flags.showNotes
      ? [
          {
            id: "notes",
            name: "Notes",
          },
        ]
      : []),
  ];

  // Determine the initial tab index based on the URL parameter or default
  const getInitialTabIndex = () => {
    if (!tabParam) return defaultTab;

    // Normalize tab names for comparison (lowercase, handle spaces)
    const normalizedTabParam = tabParam.trim().toLowerCase();

    // Find the tab index based on the normalized names
    const tabIndex = tabs.findIndex((tab) => {
      return tab.id === normalizedTabParam && !tab.disabled;
    });

    return tabIndex !== -1 ? tabIndex : defaultTab;
  };

  const handleTabChange = (index: number) => {
    // Preserve existing query parameters while updating the tab
    const newParams = new URLSearchParams(location.search);

    // Set the tab parameter to the selected tab's name
    // If the tab name has spaces, ensure it's properly handled in the URL
    newParams.set("tab", tabs[index].id);

    // Update the URL without triggering a full page reload
    navigate(`${location.pathname}?${newParams.toString()}`, { replace: true });
  };

  return (
    <Card className="max-h-[90vh] overflow-auto">
      <div className="w-full">
        <Tab.Group
          defaultIndex={getInitialTabIndex()}
          onChange={handleTabChange}
        >
          <div className="border-b border-gray-300">
            <div className="-mb-px flex space-x-16">
              <Tab.List className="space-x-4">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.name}
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        tab.disabled ? "cursor-not-allowed" : "",
                        "whitespace-nowrap pb-1 px-2 border-b-2 font-medium text-sm -mb-px"
                      )
                    }
                    disabled={tab.disabled}
                  >
                    {tab.name}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <Tab.Panels>
            <div className="pt-4 pb-2">
              {/* <Tab.Panel>
                <PatientSummaryTab patient={patient} />
              </Tab.Panel> */}
              <Tab.Panel>
                <div className="pb-2">
                  <h2 className="text-xl pb-2">Recent Activity</h2>
                  <FeedTimeline patient={patient} />
                </div>
              </Tab.Panel>
              {/* <Tab.Panel>
                <EstimatesTab patient={patient} />
              </Tab.Panel> */}
              <Tab.Panel>
                <PatientVisitList patientId={patient.id} />
              </Tab.Panel>
              <Tab.Panel>
                {/* <Ledger patient={patient} /> */}
                <PatientLedger patientId={patient.id} />
              </Tab.Panel>
              {/* <Tab.Panel>
                <PledgePayments patient={patient} />
              </Tab.Panel> */}
              <Tab.Panel>
                <Eligibility patient={patient} />
              </Tab.Panel>
              {flags.showNotes && (
                <Tab.Panel>
                  <PatientNotes patient={patient} />
                </Tab.Panel>
              )}
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Card>
  );
};

export const CREATE_SETUP_INTENT = gql`
  mutation CreateSetupIntent($patientId: String!) {
    createSetupIntent(patientId: $patientId) {
      clientSecret
    }
  }
`;

export const SAVE_PAYMENT_METHOD_FROM_SETUP_INTENT = gql`
  mutation SavePaymentMethodFromSetupIntent(
    $patientId: String!
    $stripeSetupIntentId: String!
    $autopayEnrolled: Boolean
  ) {
    savePaymentMethodFromSetupIntent(
      patientId: $patientId
      stripeSetupIntentId: $stripeSetupIntentId
      autopayEnrolled: $autopayEnrolled
    ) {
      paymentMethod {
        id
        default
        cardBrand
        walletType
        lastFour
        expirationMonth
        expirationYear
        funding
      }
      errors {
        message
      }
    }
  }
`;

const SavePaymentMethodSchema = z.object({
  stripeSetupIntentId: z.string().optional(),
  financialPolicyConsent: z.boolean().optional(),
});

const SetupForm: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    setOpen: (open: boolean) => void;
    onSuccess: () => Promise<void>;
    financialPolicyConsent?: ValidationStatus;
    financialPolicyInputLabel?: React.ReactNode;
  }>
> = ({
  patientId,
  setOpen,
  onSuccess,
  financialPolicyConsent = ValidationStatus.Hidden,
  financialPolicyInputLabel,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [
    savePaymentMethodFromSetupIntent,
    savePaymentMethodFromSetupIntentResult,
  ] = useMutation<
    SavePaymentMethodFromSetupIntent,
    SavePaymentMethodFromSetupIntentVariables
  >(SAVE_PAYMENT_METHOD_FROM_SETUP_INTENT);

  const form = useForm<z.infer<typeof SavePaymentMethodSchema>>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (
    data: z.infer<typeof SavePaymentMethodSchema>
  ) => {
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // Only redirect if the payment method requires a redirect
      redirect: "if_required",
      confirmParams: {
        // TODO: handle the redirect?
        return_url: window.location.href,
      },
    });

    if (error) {
      const errorMessage = error.message ?? "An unexpected error occured.";
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(errorMessage);
      Sentry.captureException(error);
      setLoading(false);
    } else {
      // If the setupIntent status is succeeded, attempt to save the payment
      // method and load the imported payment method immediately.
      if (setupIntent?.status === "succeeded") {
        savePaymentMethodFromSetupIntent({
          variables: {
            patientId,
            stripeSetupIntentId: setupIntent.id,
            autopayEnrolled: data.financialPolicyConsent,
          },
          onCompleted: async (data) => {
            if (data.savePaymentMethodFromSetupIntent.errors.length > 0) {
              for (const error of data.savePaymentMethodFromSetupIntent
                .errors) {
                Sentry.captureMessage(error.message);
              }
            }
            await onSuccess();
            setLoading(false);
            setSuccess(true);
          },
          onError: async (error) => {
            Sentry.captureException(error);
            await onSuccess();
            setLoading(false);
            setSuccess(true);
          },
        });
      } else {
        await onSuccess();
        setLoading(false);
        setSuccess(true);
      }
    }
  };

  return success ? (
    <AddPaymentMethodSuccessConfirmation setOpen={setOpen} />
  ) : (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <PaymentElement
          options={{
            terms: {
              card: "never",
            },
          }}
        />

        {financialPolicyConsent !== ValidationStatus.Hidden && (
          <FormField
            control={form.control}
            name="financialPolicyConsent"
            rules={{
              required: financialPolicyConsent === "Required",
              validate: (value) => {
                // If required, the value must be true
                if (financialPolicyConsent === "Required") {
                  return value === true;
                }
                return true;
              },
            }}
            render={({ field }) => (
              <FormItem className="items-top flex space-x-2 space-y-0 pt-2">
                <FormControl>
                  <Checkbox
                    className="h-5 w-5"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="grid gap-1.5 leading-none">
                  {financialPolicyInputLabel}
                </div>
              </FormItem>
            )}
          />
        )}
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          {/* <SubmitButton className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"> */}
          <SubmitButton loading={loading}>Submit</SubmitButton>
        </div>
        {/* Show any error or success messages */}
        {errorMessage && (
          <div
            id="payment-message"
            className="text-center text-xl pt-4 text-red-600"
          >
            {errorMessage}
          </div>
        )}
      </form>
    </Form>
  );
};

const AddPaymentMethodSuccessConfirmation: React.FC<
  React.PropsWithChildren<{
    setOpen: (open: boolean) => void;
  }>
> = ({ setOpen }) => {
  return (
    <div>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Payment method added!
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export const AddPaymentMethodDialog: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccessfulSetup: () => Promise<void>;
    financialPolicyConsent?: ValidationStatus;
    financialPolicyInputLabel?: React.ReactNode;
  }>
> = ({
  patientId,
  open,
  setOpen,
  onSuccessfulSetup,
  financialPolicyConsent,
  financialPolicyInputLabel,
}) => {
  const stripePromise = useStripeContext();
  const [clientSecret, setClientSecret] = useState("");
  const [createSetupIntent, result] = useMutation(CREATE_SETUP_INTENT);
  const [stripeLoading, setStripeLoading] = useState(true);
  const analytics = useAnalytics();

  useEffect(() => {
    // Create SetupIntent as soon as the page loads
    createSetupIntent({
      variables: { patientId },
      onCompleted: (data) => {
        setClientSecret(data.createSetupIntent.clientSecret);
        setStripeLoading(false);
      },
    });
  }, [patientId]);

  const appearance = {
    theme: "stripe" as "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {stripeLoading ? (
        <div className="flex min-h-[12em]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-700 h-8 w-8" />
          </div>
        </div>
      ) : (
        clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <SetupForm
              patientId={patientId}
              setOpen={setOpen}
              onSuccess={onSuccessfulSetup}
              financialPolicyConsent={financialPolicyConsent}
              financialPolicyInputLabel={financialPolicyInputLabel}
            />
          </Elements>
        )
      )}
    </Modal>
  );
};

export const GET_PATIENT_UNPAID_BILLS = gql`
  ${BILL_CHARGE_FIELDS}
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  query GetPatientUnpaidBills($id: String!) {
    # Get the unpaid bills
    getPatientUnpaidBills(patientId: $id) {
      ...BillChargeFields
      collectionMode
      charges {
        id
        customCode
        transaction {
          id
          description
        }
        patientBalance
      }
      toCollect {
        collectionMode
        estimate {
          id
        }
        visitCollectionRequest {
          id
        }
        patientResponsibility
        patientPaid
        patientBalance
      }
    }
  }
`;

type Allocation = {
  chargeId: string | null;
  billId: string | null;
  amount: number;
};

const billToPaymentTargets = (
  bill: UnpaidBill,
  ledgerGranularityLevel: LedgerGranularityLevel
): PaymentTarget[] => {
  // If the ledger granularity level is "Charge" and the bill is a "Bill" collection mode,
  // then allow applying payments to the charges on the bill.
  if (
    ledgerGranularityLevel === "Charge" &&
    bill.toCollect.collectionMode === "Bill"
  ) {
    return bill.charges;
  } else {
    return [bill];
  }
};

const allocatePaymentToBills = (
  bills: UnpaidBill[],
  amount: number,
  referenceDate: Date,
  ledgerGranularityLevel: LedgerGranularityLevel
): {
  unallocated: number;
  allocations: Allocation[];
  todaysBills: UnpaidBill[];
  pastReadyBills: UnpaidBill[];
  pastUnreadyBills: UnpaidBill[];
  futureBills: UnpaidBill[];
  ledgerGranularityLevel: LedgerGranularityLevel;
} => {
  const { todaysBills, pastBills, futureBills } = bills.reduce(
    (
      acc: {
        todaysBills: UnpaidBill[];
        pastBills: UnpaidBill[];
        futureBills: UnpaidBill[];
      },
      bill
    ) => {
      if (isSameDay(referenceDate, toUtcDate(bill.dateOfService))) {
        return { ...acc, todaysBills: [...acc.todaysBills, bill] };
      }
      if (isAfter(toUtcDate(bill.dateOfService), referenceDate)) {
        return { ...acc, futureBills: [...acc.futureBills, bill] };
      }
      return { ...acc, pastBills: [...acc.pastBills, bill] };
    },
    { todaysBills: [], pastBills: [], futureBills: [] }
  );
  const { pastReadyBills, pastUnreadyBills } = pastBills.reduce(
    (
      acc: { pastReadyBills: UnpaidBill[]; pastUnreadyBills: UnpaidBill[] },
      bill
    ) => {
      if (bill.status === "Ready") {
        return { ...acc, pastReadyBills: [...acc.pastReadyBills, bill] };
      }
      return { ...acc, pastUnreadyBills: [...acc.pastUnreadyBills, bill] };
    },
    { pastReadyBills: [], pastUnreadyBills: [] }
  );

  const orderedPaymentTargets = [
    ...todaysBills.flatMap((b) =>
      billToPaymentTargets(b, ledgerGranularityLevel)
    ),
    ...pastReadyBills.flatMap((b) =>
      billToPaymentTargets(b, ledgerGranularityLevel)
    ),
    ...pastUnreadyBills.flatMap((b) =>
      billToPaymentTargets(b, ledgerGranularityLevel)
    ),
    ...futureBills.flatMap((b) =>
      billToPaymentTargets(b, ledgerGranularityLevel)
    ),
  ];

  let allocations: Allocation[] = [];
  let amountToAllocate = amount;
  for (const target of orderedPaymentTargets) {
    if (amountToAllocate <= 0) {
      if (target.__typename === "Bill") {
        allocations.push({
          chargeId: null,
          billId: target.id,
          amount: 0,
        });
      } else {
        allocations.push({
          chargeId: target.id,
          billId: null,
          amount: 0,
        });
      }
    } else {
      const maxCollectable =
        target.__typename === "Bill"
          ? target.toCollect.patientBalance
          : -(target.patientBalance ?? 0);

      const amountToAllocateToCharge = Math.min(
        amountToAllocate,
        maxCollectable
      );
      if (target.__typename === "Bill") {
        allocations.push({
          chargeId: null,
          billId: target.id,
          amount: amountToAllocateToCharge,
        });
      } else {
        allocations.push({
          chargeId: target.id,
          billId: null,
          amount: amountToAllocateToCharge,
        });
      }
      amountToAllocate -= amountToAllocateToCharge;
    }
  }

  return {
    allocations,
    unallocated: amountToAllocate,
    todaysBills,
    pastReadyBills,
    pastUnreadyBills,
    futureBills,
    ledgerGranularityLevel,
  };
};

const BillAllocationDollarInput: React.FC<
  React.PropsWithChildren<{
    value: number;
    onUpdate: (newPaymentAmount: number) => void;
    maxCollectable: number;
  }>
> = ({ value, onUpdate, maxCollectable }) => {
  const [internalValue, setInternalValue] = useState(value / 100);
  useEffect(() => {
    setInternalValue(value / 100);
  }, [value]);

  return (
    <div className="flex rounded-l-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <DollarInput
          containerClassName="relative rounded-l-md border w-[80px]"
          className="rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 border-gray-300"
          placeholder="0.00"
          decimalsLimit={2}
          min={0}
          max={maxCollectable / 100}
          step={0.01}
          value={internalValue}
          onValueChange={(amount) => {
            const newPaymentAmount = mapNullable(toCents)(amount as any) ?? 0;
            setInternalValue(amount as any);
            if (newPaymentAmount !== null) {
              onUpdate(newPaymentAmount);
            }
          }}
        />
      </div>
      <button
        type="button"
        onClick={() => {
          setInternalValue(maxCollectable / 100);
          onUpdate(maxCollectable);
        }}
        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md p-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-gray-50"
      >
        of {formatUSD(maxCollectable)}
      </button>
    </div>
  );
};

const AllocationDollarInput: React.FC<
  React.PropsWithChildren<{
    // charge: Charge;
    target: PaymentTarget;
    allocation: Allocation;
    updateAllocation: (target: PaymentTarget, newPaymentAmount: number) => void;
    maxCollectable: number;
  }>
> = ({ target, allocation, updateAllocation, maxCollectable }) => {
  const [internalValue, setInternalValue] = useState(allocation.amount / 100);
  useEffect(() => {
    setInternalValue(allocation.amount / 100);
  }, [allocation.amount]);

  return (
    <div className="flex rounded-l-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <DollarInput
          containerClassName="relative rounded-l-md border w-[80px]"
          className="rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 border-gray-300"
          placeholder="0.00"
          decimalsLimit={2}
          min={0}
          max={maxCollectable}
          disabled={maxCollectable === 0}
          step={0.01}
          value={internalValue}
          onValueChange={(amount) => {
            const newPaymentAmount = mapNullable(toCents)(amount as any) ?? 0;
            setInternalValue(amount as any);
            if (newPaymentAmount !== null) {
              // validatePaymentAmount(newPaymentAmount);
              updateAllocation(target, newPaymentAmount);
            }
          }}
        />
      </div>
      <button
        type="button"
        onClick={() => {
          setInternalValue(maxCollectable / 100);
          updateAllocation(target, maxCollectable);
        }}
        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md p-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-gray-50"
      >
        of {formatUSD(maxCollectable)}
      </button>
    </div>
  );
};

const BillListPaymentAllocationRow: React.FC<
  React.PropsWithChildren<{
    bill: UnpaidBill;
    idx: number;
    listLength: number;
    allocations: Allocation[];
    updateAllocation: (target: PaymentTarget, amount: number) => void;
    updateAllocations: (
      updates: { target: PaymentTarget; amount: number }[]
    ) => void;
    ledgerGranularityLevel: LedgerGranularityLevel;
  }>
> = ({
  bill,
  idx,
  listLength,
  allocations,
  updateAllocation,
  updateAllocations,
  ledgerGranularityLevel,
}) => {
  const indicator = getBillIndicator(bill);

  const charges = bill.charges.map((c) => ({
    ...c,
    patientBalance: -c.patientBalance,
  }));

  if (
    ledgerGranularityLevel === "Bill" ||
    bill.toCollect.collectionMode !== "Bill"
  ) {
    const allocation = allocations.find((a) => a.billId === bill.id)!;
    const selected = allocation.amount > 0;
    return (
      <div key={bill.id}>
        <div>
          <div
            className={classNames(
              selected
                ? "z-10 border-indigo-200 bg-indigo-50"
                : "border-gray-200",
              idx === 0 ? "rounded-tl-md rounded-tr-md border-t" : "",
              idx === listLength - 1 ? "rounded-bl-md rounded-br-md" : "",
              "relative flex cursor-pointer border-l border-r border-b px-4"
            )}
          >
            <div className="flex justify-between py-2 text-lg w-full">
              <div className="text-xs flex items-center gap-2 truncate">
                {toDateMMDDYYYY(bill.dateOfService)} with{" "}
                {bill?.primaryProvider?.displayName}
                {bill.account?.accountType?.name && (
                  <span className="text-gray-600">
                    ({bill.account?.accountType?.name})
                  </span>
                )}
                {indicator}
                {billStateDisplay(bill)}
              </div>
              <div className="text-xs">
                <AllocationDollarInput
                  target={bill}
                  allocation={allocation}
                  updateAllocation={updateAllocation}
                  maxCollectable={bill.toCollect.patientBalance}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const allocatedToBill = allocations.reduce(
    (sum, a) =>
      bill.charges.some((c) => a.chargeId === c.id) ? sum + a.amount : sum,
    0
  );
  const billsSelected = allocatedToBill > 0;
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div
            className={classNames(
              billsSelected
                ? "z-10 border-indigo-200 bg-indigo-50"
                : "border-gray-200",
              idx === 0 ? "rounded-tl-md rounded-tr-md border-t" : "",
              idx === listLength - 1 ? "rounded-bl-md rounded-br-md" : "",
              "relative flex cursor-pointer border-l border-r border-b px-4"
            )}
          >
            <div className="w-full">
              <div className="flex justify-between py-2 text-lg w-full">
                <div className="text-xs flex items-center gap-1 truncate">
                  {toDateMMDDYYYY(bill.dateOfService)} with{" "}
                  {bill?.primaryProvider?.displayName}
                  {bill.account?.accountType?.name && (
                    <span className="text-gray-600">
                      ({bill.account?.accountType?.name})
                    </span>
                  )}
                  {indicator}
                  {billStateDisplay(bill)}
                </div>
                <div className="flex items-center gap-1 text-xs">
                  <BillAllocationDollarInput
                    value={allocatedToBill}
                    onUpdate={(newPaymentAmount) => {
                      let amountToAllocate = newPaymentAmount;
                      let updates = [];
                      for (const charge of charges) {
                        const amountToAllocateToCharge = Math.min(
                          amountToAllocate,
                          charge.patientBalance!
                        );
                        amountToAllocate =
                          amountToAllocate - amountToAllocateToCharge;
                        updates.push({
                          target: charge,
                          amount: amountToAllocateToCharge,
                        });
                      }
                      updateAllocations(updates);
                    }}
                    maxCollectable={bill.toCollect.patientBalance}
                  />
                  <Disclosure.Button className="hover:bg-gray-200 rounded-md">
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-gray-500`}
                    />
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel>
            {charges.map((charge, chgIdx) => {
              const allocation = allocations.find(
                (a) => a.chargeId === charge.id
              )!;
              const selected = allocation.amount > 0;
              return (
                <div>
                  <div
                    key={charge.id}
                    className={classNames(
                      selected
                        ? "z-10 border-indigo-200 bg-indigo-50"
                        : "border-gray-200",
                      "relative flex cursor-pointer border-l border-r border-b py-1 px-4 pl-10 text-xs"
                    )}
                  >
                    <div className="flex justify-between items-center w-full">
                      <div className="p-1">{charge.customCode}</div>
                      <div className="p-1 truncate whitespace-nowrap">
                        {charge.transaction.description}
                      </div>
                      <div>
                        <AllocationDollarInput
                          target={charge}
                          allocation={allocation}
                          updateAllocation={updateAllocation}
                          maxCollectable={charge.patientBalance}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const PaymentAllocationDisclosure: React.FC<
  React.PropsWithChildren<{
    unpaidBills: UnpaidBill[];
    allocatableAmount: number;
    setPaymentAmountError: (error: string | null) => void;
    setParentAllocations: (allocations: Allocation[]) => void;
    referenceDate: Date;
    ledgerGranularityLevel: LedgerGranularityLevel;
  }>
> = ({
  unpaidBills,
  allocatableAmount,
  setPaymentAmountError,
  setParentAllocations,
  referenceDate,
  ledgerGranularityLevel,
}) => {
  const {
    unallocated: defaultUnallocated,
    allocations: defaultAllocations,
    todaysBills,
    pastReadyBills,
    pastUnreadyBills,
    futureBills,
  } = allocatePaymentToBills(
    unpaidBills,
    allocatableAmount,
    referenceDate,
    ledgerGranularityLevel
  );
  const [allocations, setAllocations] =
    useState<Allocation[]>(defaultAllocations);
  const [unallocatedAmount, setUnallocatedAmount] =
    useState<number>(defaultUnallocated);

  useEffect(() => {
    const updated = allocatePaymentToBills(
      unpaidBills,
      allocatableAmount,
      referenceDate,
      ledgerGranularityLevel
    );
    setAllocations(updated.allocations);
    setUnallocatedAmount(updated.unallocated);
  }, [allocatableAmount]);

  const updateAllocation = (target: PaymentTarget, amount: number) => {
    const updatedAllocations = allocations.map((a) => {
      if (target.__typename === "Bill" && a.billId === target.id) {
        return {
          billId: target.id,
          chargeId: null,
          amount,
        };
      } else if (target.__typename === "Charge" && a.chargeId === target.id) {
        return {
          billId: null,
          chargeId: target.id,
          amount,
        };
      }
      return a;

      // // Update the allocation amount for the charge
      // if (a.chargeId === chargeId) {
      //   return { chargeId, billId: null, amount };
      // }
      // return a;
    });
    const newAllocatedAmount = updatedAllocations.reduce(
      (acc, a) => acc + a.amount,
      0
    );
    const updatedUnallocated = allocatableAmount - newAllocatedAmount;
    setAllocations(updatedAllocations);
    setUnallocatedAmount(updatedUnallocated);
  };
  const updateAllocations = (
    updates: { target: PaymentTarget; amount: number }[]
  ) => {
    const updatedAllocations = allocations.map((a) => {
      const update = updates.find(
        (u) =>
          (u.target.__typename === "Bill" && a.billId === u.target.id) ||
          (u.target.__typename === "Charge" && a.chargeId === u.target.id)
      )!;
      if (!update) return a;
      const { target, amount } = update;
      if (target.__typename === "Bill" && a.billId === target.id) {
        return {
          billId: target.id,
          chargeId: null,
          amount,
        };
      } else if (target.__typename === "Charge" && a.chargeId === target.id) {
        return {
          billId: null,
          chargeId: target.id,
          amount,
        };
      }
      return a;
    });
    const updatedUnallocated =
      updatedAllocations.reduce((acc, a) => acc - a.amount, 0) +
      allocatableAmount;
    setAllocations(updatedAllocations);
    setUnallocatedAmount(updatedUnallocated);
  };
  const paymentFullyAllocated = unallocatedAmount === 0;

  useEffect(() => {
    // If not fully allocated, show error
    if (allocatableAmount > 100) {
      setPaymentAmountError(
        paymentFullyAllocated ? null : "Payment is not fully applied."
      );
    }
  }, [paymentFullyAllocated]);
  useEffect(() => {
    setParentAllocations(allocations);
  }, [allocations]);

  return (
    <>
      <div className="flex flex-col">
        <div className="rounded-md bg-blue-50 px-2 py-3">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-xs text-blue-700">
                Allocate the amount paid towards specific bills and charges to
                run payment.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-1 mt-2 text-xs text-gray-600">
          <ShieldCheckIcon
            className={classNames(
              "h-4",
              paymentFullyAllocated ? "text-green-500" : "text-red-500"
            )}
          />
          {paymentFullyAllocated ? (
            <>Full {formatUSD(allocatableAmount)} applied!</>
          ) : unallocatedAmount > 0 ? (
            <>
              Payment is not fully applied. {formatUSD(unallocatedAmount)} is
              still unapplied to a charge.
            </>
          ) : (
            <>
              Payment is over applied. Applied amounts exceed the payment amount
              by {formatUSD(-unallocatedAmount)}.
            </>
          )}
        </div>
      </div>

      <div>
        <div className="flex flex-col max-h-[60vh] overflow-y-auto">
          {todaysBills.length !== 0 && (
            <>
              <div className="py-2 text-base flex items-center gap-2">
                Today's Visits
              </div>
            </>
          )}
          {todaysBills.map((bill, idx) => {
            return (
              <BillListPaymentAllocationRow
                bill={bill}
                idx={idx}
                listLength={todaysBills.length}
                allocations={allocations}
                updateAllocation={updateAllocation}
                updateAllocations={updateAllocations}
                ledgerGranularityLevel={ledgerGranularityLevel}
              />
            );
          })}
          {pastReadyBills.length !== 0 && (
            <>
              <div className="py-2 text-base flex items-center gap-2">
                Ready Bills
              </div>
            </>
          )}
          {pastReadyBills.map((bill, idx) => {
            return (
              <BillListPaymentAllocationRow
                bill={bill}
                idx={idx}
                listLength={pastReadyBills.length}
                allocations={allocations}
                updateAllocation={updateAllocation}
                updateAllocations={updateAllocations}
                ledgerGranularityLevel={ledgerGranularityLevel}
              />
            );
          })}
          {pastUnreadyBills.length !== 0 && (
            <>
              <div className="py-2 text-base flex items-center gap-2">
                Other Past Bills
              </div>
            </>
          )}
          {pastUnreadyBills.map((bill, idx) => {
            return (
              <BillListPaymentAllocationRow
                bill={bill}
                idx={idx}
                listLength={pastUnreadyBills.length}
                allocations={allocations}
                updateAllocation={updateAllocation}
                updateAllocations={updateAllocations}
                ledgerGranularityLevel={ledgerGranularityLevel}
              />
            );
          })}
          {futureBills.length !== 0 && (
            <>
              <div className="py-2 text-base flex items-center gap-2">
                Future Bills
              </div>
            </>
          )}
          {futureBills.map((bill, idx) => {
            return (
              <BillListPaymentAllocationRow
                bill={bill}
                idx={idx}
                listLength={futureBills.length}
                allocations={allocations}
                updateAllocation={updateAllocation}
                updateAllocations={updateAllocations}
                ledgerGranularityLevel={ledgerGranularityLevel}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const CHARGE_PATIENT_PAYMENT_METHOD = gql`
  mutation ChargePatientPaymentMethod(
    $paymentMethodId: String!
    $allocations: [ChargePatientPaymentAllocationInput!]!
  ) {
    chargePatientPaymentMethod(
      paymentMethodId: $paymentMethodId
      allocations: $allocations
    ) {
      clientSecret
      paymentIntent {
        id
        status
        lastPaymentError
      }
      errors {
        message
      }
    }
  }
`;

const PaymentMethodSelectionSection: React.FC<
  React.PropsWithChildren<{
    patient: CreatePaymentDialogPatient;
    paymentMethod: PaymentMethod;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  }>
> = ({ patient, paymentMethod, setPaymentMethod }) => {
  return (
    <div>
      <RadioGroup value={paymentMethod} onChange={setPaymentMethod}>
        <RadioGroup.Label className="text-base font-medium text-gray-900">
          Select a payment method
        </RadioGroup.Label>

        <div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {patient.paymentMethods.map((paymentMethod) => (
            <RadioGroup.Option
              key={paymentMethod.id}
              value={paymentMethod}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                  "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="font-medium">
                          <div className="flex items-center gap-1">
                            <CardComponent
                              cardBrand={paymentMethod.cardBrand}
                              type={paymentMethod.type}
                              className="h-4"
                            />
                            <span>{paymentMethodDisplay(paymentMethod)}</span>
                            {paymentMethod.default && (
                              <div>
                                <Badge variant="info" text="Default" />
                              </div>
                            )}
                          </div>
                        </div>
                        {paymentMethod.type !== "link" && (
                          <div className="text-gray-600">
                            Expires {paymentMethod.expirationMonth}/
                            {paymentMethod.expirationYear}
                          </div>
                        )}
                      </div>
                    </span>
                  </span>
                  <div>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-indigo-600"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-500" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

type CreatePaymentDialogPatient = Pick<
  IPatient,
  "id" | "paymentMethods" | "patientBalance"
>;

type PaymentTarget = UnpaidBill | BillCharge;

const BillListBoxRow: React.FC<
  React.PropsWithChildren<{
    bill: UnpaidBill;
    idx: number;
    listLength: number;
    selectedPaymentTargets: PaymentTarget[];
    setSelectedPaymentTargets: (paymentTargets: PaymentTarget[]) => void;
    ledgerGranularityLevel: LedgerGranularityLevel;
  }>
> = ({
  bill,
  idx,
  listLength,
  selectedPaymentTargets,
  setSelectedPaymentTargets,
  ledgerGranularityLevel,
}) => {
  const allChargesSelected = bill.charges.every((charge) =>
    selectedPaymentTargets.some((c) => c.id === charge.id)
  );
  const indicator = getBillIndicator(bill);

  const charges = bill.charges.map((c) => ({
    ...c,
    patientBalance: -c.patientBalance,
  }));

  if (
    ledgerGranularityLevel === "Bill" ||
    bill.toCollect.collectionMode !== "Bill"
  ) {
    return (
      <div key={bill.id}>
        <Listbox.Option value={bill} as="div">
          {({ active, selected }) => (
            <>
              <div
                className={classNames(
                  selected
                    ? "z-10 border-indigo-200 bg-indigo-50"
                    : "border-gray-200",
                  idx === 0 ? "rounded-tl-md rounded-tr-md border-t" : "",
                  idx === listLength - 1 ? "rounded-bl-md rounded-br-md" : "",
                  "relative flex cursor-pointer border-l border-r border-b px-4"
                )}
              >
                <div className="px-2 py-4">
                  <input
                    type="checkbox"
                    checked={selected}
                    className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    readOnly
                  />
                </div>
                <div className="flex justify-between py-2 text-lg w-full">
                  <div className="text-sm flex items-center gap-2 truncate">
                    {toDateMMDDYYYY(bill.dateOfService)} with{" "}
                    {bill?.primaryProvider?.displayName}
                    {bill.account?.accountType?.name && (
                      <span className="text-gray-600">
                        ({bill.account?.accountType?.name})
                      </span>
                    )}
                    {indicator}
                    {billStateDisplay(bill)}
                  </div>
                  <div className="flex gap-1 items-center">
                    {(bill.toCollect.collectionMode === "Deposit" ||
                      bill.toCollect.collectionMode === "Estimate") && (
                      <AutoEstimateIndicator />
                    )}
                    <div className="font-semibold text-md">
                      {formatUSD(bill.toCollect.patientBalance)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Listbox.Option>
      </div>
    );
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div
            className={classNames(
              allChargesSelected
                ? "z-10 border-indigo-200 bg-indigo-50"
                : "border-gray-200",
              idx === 0 ? "rounded-tl-md rounded-tr-md border-t" : "",
              idx === listLength - 1 ? "rounded-bl-md rounded-br-md" : "",
              "relative flex cursor-pointer border-l border-r border-b px-4"
            )}
          >
            <div className="px-2 py-4">
              <input
                type="checkbox"
                checked={allChargesSelected}
                readOnly
                onClick={() => {
                  if (allChargesSelected) {
                    const nonBillTargets = selectedPaymentTargets.filter(
                      (t) => {
                        if (t.__typename === "Bill") {
                          return t.id !== bill.id;
                        } else {
                          return t.billId !== bill.id;
                        }
                      }
                    );
                    setSelectedPaymentTargets(nonBillTargets);
                  } else {
                    const newTargets = [
                      ...selectedPaymentTargets,
                      ...(bill.toCollect.collectionMode !== "Bill"
                        ? [bill]
                        : charges),
                    ];
                    setSelectedPaymentTargets(newTargets);
                  }
                }}
                className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="flex justify-between w-full">
              <div className="flex justify-between py-2 text-lg w-full">
                <div className="text-sm flex items-center gap-2 truncate">
                  {toDateMMDDYYYY(bill.dateOfService)} with{" "}
                  {bill?.primaryProvider?.displayName}
                  {bill.account?.accountType?.name && (
                    <span className="text-gray-600">
                      ({bill.account?.accountType?.name})
                    </span>
                  )}
                  {indicator}
                  {billStateDisplay(bill)}
                </div>
              </div>
              <div className="flex gap-1 items-center">
                {(bill.toCollect.collectionMode === "Deposit" ||
                  bill.toCollect.collectionMode === "Estimate") && (
                  <AutoEstimateIndicator />
                )}
                <div className="font-medium">
                  {formatUSD(bill.toCollect.patientBalance)}
                </div>
                <Disclosure.Button className="hover:bg-gray-200 rounded-md">
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-gray-500`}
                  />
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel>
            {charges.map((charge, chgIdx) => {
              return (
                <Listbox.Option key={charge.id} value={charge} as={Fragment}>
                  {({ active, selected }) => (
                    <div
                      key={charge.id}
                      className={classNames(
                        selected
                          ? "z-10 border-indigo-200 bg-indigo-50"
                          : "border-gray-200",
                        "relative flex cursor-pointer border-l border-r border-b px-4 pl-10 text-sm"
                      )}
                    >
                      <div className="px-2 py-4">
                        <input
                          type="checkbox"
                          readOnly
                          checked={selected}
                          className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>

                      <div className="flex justify-between items-center w-full">
                        <div className="p-1">{charge.customCode}</div>
                        <div className="p-1 truncate whitespace-nowrap">
                          {charge.transaction.description}
                        </div>
                        <div className="p-1 text-right">
                          {formatUSD(charge.patientBalance)}
                        </div>
                      </div>
                    </div>
                  )}
                </Listbox.Option>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const SelectBillsToPay: React.FC<
  React.PropsWithChildren<{
    paymentAmount: number;
    paymentAmountError: string | null;
    patient: CreatePaymentDialogPatient;
    paymentMethod: PaymentMethod;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    selectedPaymentTargets: PaymentTarget[];
    setSelectedPaymentTargets: React.Dispatch<
      React.SetStateAction<PaymentTarget[]>
    >;
    bills: UnpaidBill[];
    todaysBills: UnpaidBill[];
    pastReadyBills: UnpaidBill[];
    pastUnreadyBills: UnpaidBill[];
    futureBills: UnpaidBill[];
    selectAllBills: (bills: UnpaidBill[], includeFuture?: boolean) => void;
    allBillsSelected: boolean;
    allTodayBillsSelected: boolean;
    allPastReadyBillsSelected: boolean;
    allPastUnreadyBillsSelected: boolean;
    allFutureBillsSelected: boolean;
    ledgerGranularityLevel: LedgerGranularityLevel;
  }>
> = ({
  paymentAmount,
  paymentAmountError,
  patient,
  paymentMethod,
  setPaymentMethod,
  selectedPaymentTargets,
  setSelectedPaymentTargets,
  bills,
  todaysBills,
  pastReadyBills,
  pastUnreadyBills,
  futureBills,
  selectAllBills,
  allBillsSelected,
  allTodayBillsSelected,
  allPastReadyBillsSelected,
  allPastUnreadyBillsSelected,
  allFutureBillsSelected,
  ledgerGranularityLevel,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label htmlFor="price" className="text-base font-medium text-gray-900">
          Payment Amount
        </label>
        <div className="text-lg font-semibold flex gap-1 items-center">
          {formatUSD(paymentAmount)}

          {paymentAmountError && (
            <Tooltip
              content={paymentAmountError}
              trigger={
                <ExclamationCircleIconOutline
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              }
            />
          )}
        </div>
      </div>

      <PaymentMethodSelectionSection
        patient={patient}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
      <div>
        <Listbox
          value={selectedPaymentTargets}
          onChange={setSelectedPaymentTargets}
          by="id"
          multiple
        >
          <div className="flex flex-col max-h-[60vh] overflow-y-auto">
            <Listbox.Label className="py-2 font-medium flex items-center gap-2">
              <input
                type="checkbox"
                className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={() => {
                  selectAllBills(bills, false); // Don't include future bills by default
                }}
                checked={allBillsSelected}
              />
              Select Bills to Pay
              {futureBills.length !== 0 && (
                <button
                  type="button"
                  className="ml-2 text-sm text-indigo-600 hover:text-indigo-500"
                  onClick={() => selectAllBills(bills, true)} // Include future bills when explicitly clicked
                >
                  {allBillsSelected && allFutureBillsSelected
                    ? "Deselect"
                    : "Select"}{" "}
                  All (including future)
                </button>
              )}
            </Listbox.Label>

            {todaysBills.length !== 0 && (
              <>
                <Listbox.Label className="py-2 text-base flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => {
                      selectAllBills(todaysBills);
                    }}
                    checked={allTodayBillsSelected}
                  />
                  Today's Visits
                </Listbox.Label>

                {todaysBills.map((bill, idx) => (
                  <BillListBoxRow
                    key={bill.id}
                    bill={bill}
                    idx={idx}
                    listLength={todaysBills.length}
                    selectedPaymentTargets={selectedPaymentTargets}
                    setSelectedPaymentTargets={setSelectedPaymentTargets}
                    ledgerGranularityLevel={ledgerGranularityLevel}
                  />
                ))}
              </>
            )}

            {pastReadyBills.length !== 0 && (
              <>
                <Listbox.Label className="py-2 text-base flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => {
                      selectAllBills(pastReadyBills);
                    }}
                    checked={allPastReadyBillsSelected}
                  />
                  Ready Bills
                </Listbox.Label>

                {pastReadyBills.map((bill, idx) => (
                  <BillListBoxRow
                    key={bill.id}
                    bill={bill}
                    idx={idx}
                    listLength={pastReadyBills.length}
                    selectedPaymentTargets={selectedPaymentTargets}
                    setSelectedPaymentTargets={setSelectedPaymentTargets}
                    ledgerGranularityLevel={ledgerGranularityLevel}
                  />
                ))}
              </>
            )}

            {pastUnreadyBills.length !== 0 && (
              <>
                <Listbox.Label className="py-2 text-base flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => {
                      selectAllBills(pastUnreadyBills);
                    }}
                    checked={allPastUnreadyBillsSelected}
                  />
                  Other Past Bills
                </Listbox.Label>

                {pastUnreadyBills.map((bill, idx) => (
                  <BillListBoxRow
                    key={bill.id}
                    bill={bill}
                    idx={idx}
                    listLength={pastUnreadyBills.length}
                    selectedPaymentTargets={selectedPaymentTargets}
                    setSelectedPaymentTargets={setSelectedPaymentTargets}
                    ledgerGranularityLevel={ledgerGranularityLevel}
                  />
                ))}
              </>
            )}

            {futureBills.length !== 0 && (
              <>
                <Listbox.Label className="py-2 text-base flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 flex items-center justify-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => {
                      selectAllBills(futureBills, true);
                    }}
                    checked={allFutureBillsSelected}
                  />
                  Future Bills
                </Listbox.Label>

                {futureBills.map((bill, idx) => (
                  <BillListBoxRow
                    key={bill.id}
                    bill={bill}
                    idx={idx}
                    listLength={futureBills.length}
                    selectedPaymentTargets={selectedPaymentTargets}
                    setSelectedPaymentTargets={setSelectedPaymentTargets}
                    ledgerGranularityLevel={ledgerGranularityLevel}
                  />
                ))}
              </>
            )}
          </div>
        </Listbox>
      </div>
    </div>
  );
};

const EnterPaymentAmount: React.FC<
  React.PropsWithChildren<{
    paymentAmount: number;
    setPaymentAmount: React.Dispatch<React.SetStateAction<number>>;
    paymentAmountError: string | null;
    patient: CreatePaymentDialogPatient;
    paymentMethod: PaymentMethod;
    setPaymentAmountError: (error: string | null) => void;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    bills: UnpaidBill[];
    referenceDate: Date;
    validatePaymentAmount: (amount: number) => boolean;
    setParentAllocations: React.Dispatch<React.SetStateAction<Allocation[]>>;
    ledgerGranularityLevel: LedgerGranularityLevel;
  }>
> = ({
  referenceDate,
  paymentAmount,
  setPaymentAmount,
  paymentAmountError,
  setPaymentAmountError,
  patient,
  paymentMethod,
  setPaymentMethod,
  bills,
  validatePaymentAmount,
  setParentAllocations,
  ledgerGranularityLevel,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label htmlFor="price" className="text-base font-medium text-gray-900">
          Payment Amount
        </label>
        <DollarInput
          containerClassName="relative rounded-md border max-w-[10em]"
          className="rounded-md text-xl font-medium focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 border-gray-300"
          placeholder="0.00"
          decimalsLimit={2}
          step={0.01}
          min={0}
          max={-patient.patientBalance}
          error={!!paymentAmountError}
          errorMessage={paymentAmountError ?? undefined}
          onValueChange={(amount) => {
            const newPaymentAmount = mapNullable(toCents)(amount as any) ?? 0;
            validatePaymentAmount(newPaymentAmount);
            setPaymentAmount(newPaymentAmount);
          }}
        />
      </div>
      <PaymentMethodSelectionSection
        patient={patient}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
      <div>
        <PaymentAllocationDisclosure
          unpaidBills={bills}
          allocatableAmount={paymentAmount}
          setPaymentAmountError={setPaymentAmountError}
          setParentAllocations={setParentAllocations}
          referenceDate={referenceDate}
          ledgerGranularityLevel={ledgerGranularityLevel}
        />
      </div>
    </div>
  );
};

const checkAllBillsAreSelected = (
  bills: UnpaidBill[],
  selectedPaymentTargets: PaymentTarget[],
  ledgerGranularityLevel: LedgerGranularityLevel
) => {
  return bills.every((bill) => {
    // Just check if the bill is selected if the ledger granularity level is Bill
    // or the bill collection mode is not Bill
    if (
      ledgerGranularityLevel === "Bill" ||
      bill.toCollect.collectionMode !== "Bill"
    ) {
      return selectedPaymentTargets.some((t) => {
        if (t.__typename === "Bill") {
          return t.id === bill.id;
        }
        return t.billId === bill.id;
      });
    }
    // Check if the bill and all the charges are selected
    return (
      selectedPaymentTargets.some((t) => {
        if (t.__typename === "Bill") {
          return t.id === bill.id;
        }
        return t.billId === bill.id;
      }) &&
      bill.charges.every((c) =>
        selectedPaymentTargets.some((t) => t.id === c.id)
      )
    );
  });
};

const GET_PAYMENT_DIALOG_PATIENT = gql`
  query GetPaymentDialogPatient($id: String!) {
    patient(where: { id: $id }) {
      id
      patientBalance
      paymentMethods(where: { detatchedAt: null }) {
        id
        default
        cardBrand
        expirationMonth
        expirationYear
        lastFour
        type
      }
    }
  }
`;

// TODO: This sucks but don't want to spend much time here
export const CreatePaymentDialogWithFetch: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    referenceDate?: Date;
  }>
> = ({ patientId, open, setOpen, referenceDate = new Date() }) => {
  const { data, loading } = useQuery(GET_PAYMENT_DIALOG_PATIENT, {
    variables: { id: patientId },
  });

  if (loading) {
    return (
      <Modal open={open} setOpen={() => {}}>
        <div className="flex justify-center items-center">
          <OvalSpinner className="h-6 w-6" />
        </div>
      </Modal>
    );
  }
  const patient = data?.patient;
  if (!patient) return null;
  return (
    <CreatePaymentDialog
      patient={patient}
      open={open}
      setOpen={setOpen}
      referenceDate={referenceDate}
    />
  );
};

export const CreatePaymentDialog: React.FC<
  React.PropsWithChildren<{
    patient: CreatePaymentDialogPatient;
    open: boolean;
    setOpen: (open: boolean) => void;
    referenceDate?: Date;
  }>
> = ({ patient, open, setOpen, referenceDate = new Date() }) => {
  const user = useUser()!;
  const ledgerGranularityLevel = user.activeLocation.ledgerGranularityLevel;
  const apollo = useApolloClient();
  const { data, loading } = useQuery<
    GetPatientUnpaidBills,
    GetPatientUnpaidBillsVariables
  >(GET_PATIENT_UNPAID_BILLS, {
    variables: { id: patient.id },
  });
  const [selectedPaymentTargets, setSelectedPaymentTargets] = useState<
    PaymentTarget[]
  >([]);
  const [chargePatientPaymentMethod, result] = useMutation<
    ChargePatientPaymentMethod,
    ChargePatientPaymentMethodVariables
  >(CHARGE_PATIENT_PAYMENT_METHOD);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successfulPayment, setSuccessfulPayment] = useState<boolean>(false);
  const [paymentConfirmationScreen, setPaymentConfirmationScreen] =
    useState<boolean>(false);
  const defaultPaymentMethod =
    patient.paymentMethods.find((pm) => pm.default)! ??
    patient.paymentMethods.at(0);
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethod>(defaultPaymentMethod);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentAmountError, setPaymentAmountError] = useState<string | null>(
    "Payment amount must be greater than $1"
  );
  const [allocations, setParentAllocations] = useState<Allocation[]>([]);
  const tabs = ["Select Bills to Pay", "Enter Payment Amount"];

  const bills = (data?.getPatientUnpaidBills ?? []).filter(
    (b) => b.toCollect.patientBalance > 0
  );
  // The total amount of money that the patient owes for unpaid ready bills and estimates
  const balanceAvailableToPay = bills.reduce(
    (sum, bill) => sum + bill.toCollect.patientBalance,
    0
  );

  // Set the allocations based on the selected bills
  useEffect(() => {
    const newAllocations = selectedPaymentTargets.map((target) => {
      if (target.__typename === "Bill") {
        return {
          billId: target.id,
          chargeId: null,
          amount: target.toCollect.patientBalance,
        };
      } else {
        return {
          billId: null,
          chargeId: target.id,
          amount: target.patientBalance!,
        };
      }
    });
    setParentAllocations(newAllocations);

    const allocatedAmount = newAllocations.reduce(
      (acc, alloc) => acc + alloc.amount,
      0
    );
    setPaymentAmount(allocatedAmount);
    validatePaymentAmount(allocatedAmount);
  }, [selectedPaymentTargets]);

  const validatePaymentAmount = (amount: number) => {
    // If payment amount is greater than patient balance, it's not valid
    if (amount > balanceAvailableToPay) {
      setPaymentAmountError("Payment amount cannot exceed patient balance");
      return false;
    }
    // If payment amount is $1 or less, it's not valid
    if (amount <= 100) {
      setPaymentAmountError("Payment amount must be greater than $1");
      return false;
    }
    setPaymentAmountError(null);
    return true;
  };

  const submit = () => {
    setSubmitting(true);
    const positiveAllocations = allocations.filter((a) => a.amount > 0);
    chargePatientPaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id,
        allocations: positiveAllocations,
      },
      onCompleted: async ({ chargePatientPaymentMethod }) => {
        if (chargePatientPaymentMethod.errors.length === 0) {
          // Refetch active queries
          await new Promise((r) => setTimeout(r, 3000));
          await apollo.refetchQueries({
            include: "active",
          });
          toast.success("Payment successfully created!");
          setSubmitting(false);
          setSuccessfulPayment(true);
        } else {
          setSubmitting(false);
        }
      },
      onError: (error) => {
        console.log(error);
        setSubmitting(false);
        toast.error("Error creating payment");
      },
    });
  };

  const { todaysBills, pastBills, futureBills } = bills.reduce(
    (
      acc: {
        todaysBills: UnpaidBill[];
        pastBills: UnpaidBill[];
        futureBills: UnpaidBill[];
      },
      bill
    ) => {
      if (isSameDay(referenceDate, toUtcDate(bill.dateOfService))) {
        return { ...acc, todaysBills: [...acc.todaysBills, bill] };
      }
      if (isAfter(toUtcDate(bill.dateOfService), referenceDate)) {
        return { ...acc, futureBills: [...acc.futureBills, bill] };
      }
      return { ...acc, pastBills: [...acc.pastBills, bill] };
    },
    { todaysBills: [], pastBills: [], futureBills: [] }
  );
  const { pastReadyBills, pastUnreadyBills } = pastBills.reduce(
    (
      acc: { pastReadyBills: UnpaidBill[]; pastUnreadyBills: UnpaidBill[] },
      bill
    ) => {
      if (bill.status === "Ready") {
        return { ...acc, pastReadyBills: [...acc.pastReadyBills, bill] };
      }
      return { ...acc, pastUnreadyBills: [...acc.pastUnreadyBills, bill] };
    },
    { pastReadyBills: [], pastUnreadyBills: [] }
  );

  const allTodayBillsSelected = checkAllBillsAreSelected(
    todaysBills,
    selectedPaymentTargets,
    ledgerGranularityLevel
  );
  const allPastReadyBillsSelected = checkAllBillsAreSelected(
    pastReadyBills,
    selectedPaymentTargets,
    ledgerGranularityLevel
  );
  const allPastUnreadyBillsSelected = checkAllBillsAreSelected(
    pastUnreadyBills,
    selectedPaymentTargets,
    ledgerGranularityLevel
  );
  const allBillsSelected =
    allTodayBillsSelected &&
    allPastReadyBillsSelected &&
    allPastUnreadyBillsSelected;

  const allFutureBillsSelected = checkAllBillsAreSelected(
    futureBills,
    selectedPaymentTargets,
    ledgerGranularityLevel
  );

  /**
   * Toggle select all for set of bills
   */
  const selectAllBills = (
    bills: UnpaidBill[],
    includeFuture: boolean = false
  ) => {
    // Get the bills we want to consider based on includeFuture flag
    const billsToConsider = includeFuture
      ? bills
      : bills.filter(
          (bill) => !isAfter(toUtcDate(bill.dateOfService), referenceDate)
        );

    const allBillsSelected = billsToConsider.every((bill) =>
      selectedPaymentTargets.some((t) => {
        if (t.__typename === "Bill") return t.id === bill.id;
        return t.billId === bill.id;
      })
    );

    if (allBillsSelected) {
      // Remove all charges and bills from the selected payment targets for the bills we're considering
      const newTargets = selectedPaymentTargets.filter((t) => {
        if (t.__typename === "Bill") {
          return !billsToConsider.some((bill) => t.id === bill.id);
        } else {
          return !billsToConsider.some((bill) => t.billId === bill.id);
        }
      });
      setSelectedPaymentTargets(newTargets);
    } else {
      // Add all bills and charges to the selected payment targets
      let newTargets: PaymentTarget[] = [...selectedPaymentTargets]; // Start with existing targets

      for (const bill of billsToConsider) {
        // If bill already selected, skip to avoid selecting again
        if (
          !selectedPaymentTargets.some(
            (t) =>
              (t.__typename === "Bill" && t.id === bill.id) ||
              (t.__typename === "Charge" && t.billId === bill.id)
          )
        ) {
          const charges = bill.charges.map((c) => ({
            ...c,
            patientBalance: -c.patientBalance,
          }));

          let billTargets: PaymentTarget[] = [];
          // If ledger granularity level is Bill or the bill collection mode is not Bill add bill targets
          if (
            ledgerGranularityLevel === LedgerGranularityLevel.Bill ||
            bill.toCollect.collectionMode !== "Bill"
          ) {
            billTargets = [bill];
          } else {
            // Else add charge targets
            billTargets = charges;
          }
          newTargets = [...newTargets, ...billTargets];
        }
      }

      setSelectedPaymentTargets(newTargets);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      {successfulPayment ? (
        <PaymentSuccessConfirmation setOpen={setOpen} amount={paymentAmount} />
      ) : paymentConfirmationScreen ? (
        <div className="flex flex-col">
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Confirm Payment
            </Dialog.Title>
          </div>
          <p className="flex justify-center items-center gap-1 py-4">
            Charge {formatUSD(paymentAmount)} to{" "}
            <div className="flex items-center gap-1">
              <CardComponent
                cardBrand={paymentMethod.cardBrand}
                className="h-4"
              />
              <span>{paymentMethodDisplay(paymentMethod)}</span>
            </div>
            ?
          </p>
          {!!result.data?.chargePatientPaymentMethod.errors.length && (
            <p className="text-red-600 text-sm mt-2 text-center">
              {result.data?.chargePatientPaymentMethod.errors.map((e) => (
                <div key={e.message}>{e.message}</div>
              ))}
            </p>
          )}
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setPaymentConfirmationScreen(false)}
            >
              Back
            </button>
            <SubmitButton
              type="button"
              loading={submitting}
              disabled={submitting || !!paymentAmountError}
              onClick={() => submit()}
            >
              {result.data?.chargePatientPaymentMethod.errors.length
                ? "Retry Payment"
                : "Run Payment"}
            </SubmitButton>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            {loading ? (
              <div className="w-full py-4 flex justify-center items-center">
                <OvalSpinner className="h-6 w-6" />
              </div>
            ) : (
              <div>
                <Tab.Group
                  onChange={() => {
                    // Reset state when tab changes
                    setSelectedPaymentTargets([]);
                    setParentAllocations([]);
                    setPaymentAmount(0);
                  }}
                >
                  <Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow border">
                    {tabs.map((tab, tabIdx) => (
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button
                            type="button"
                            key={tab}
                            className={classNames(
                              selected
                                ? "text-gray-900"
                                : "text-gray-500 hover:text-gray-700",
                              tabIdx === 0 ? "rounded-l-lg" : "",
                              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                              "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                            )}
                          >
                            <span>{tab}</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                selected ? "bg-indigo-500" : "bg-transparent",
                                "absolute inset-x-0 bottom-0 h-0.5"
                              )}
                            />
                          </button>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels>
                    <div className="pt-4">
                      <Tab.Panel>
                        <SelectBillsToPay
                          paymentAmount={paymentAmount}
                          paymentAmountError={paymentAmountError}
                          paymentMethod={paymentMethod}
                          setPaymentMethod={setPaymentMethod}
                          patient={patient}
                          selectAllBills={selectAllBills}
                          selectedPaymentTargets={selectedPaymentTargets}
                          setSelectedPaymentTargets={setSelectedPaymentTargets}
                          bills={bills}
                          todaysBills={todaysBills}
                          pastReadyBills={pastReadyBills}
                          pastUnreadyBills={pastUnreadyBills}
                          futureBills={futureBills}
                          allBillsSelected={allBillsSelected}
                          allTodayBillsSelected={allTodayBillsSelected}
                          allPastReadyBillsSelected={allPastReadyBillsSelected}
                          allPastUnreadyBillsSelected={
                            allPastUnreadyBillsSelected
                          }
                          allFutureBillsSelected={allFutureBillsSelected}
                          ledgerGranularityLevel={ledgerGranularityLevel}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <EnterPaymentAmount
                          referenceDate={referenceDate}
                          paymentAmount={paymentAmount}
                          setPaymentAmount={setPaymentAmount}
                          paymentAmountError={paymentAmountError}
                          setPaymentAmountError={setPaymentAmountError}
                          paymentMethod={paymentMethod}
                          setPaymentMethod={setPaymentMethod}
                          patient={patient}
                          bills={bills}
                          setParentAllocations={setParentAllocations}
                          validatePaymentAmount={validatePaymentAmount}
                          ledgerGranularityLevel={ledgerGranularityLevel}
                        />
                      </Tab.Panel>
                    </div>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            )}
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
              disabled={submitting || !!paymentAmountError}
              onClick={() => {
                setPaymentConfirmationScreen(true);
              }}
            >
              Confirm
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const PaymentSuccessConfirmation: React.FC<
  React.PropsWithChildren<{
    setOpen: (open: boolean) => void;
    amount: number;
  }>
> = ({ setOpen, amount }) => {
  return (
    <div>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {formatUSD(amount)} Payment successful
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Go back to dashboard
        </button>
      </div>
    </div>
  );
};

export const SET_AUTO_PAY_ENROLLMENT = gql`
  mutation SetAutoPayEnrollment(
    $patientId: String!
    $enabled: Boolean!
    $sendNotification: Boolean!
  ) {
    setAutoPayEnrollment(
      id: $patientId
      enabled: $enabled
      sendNotification: $sendNotification
    ) {
      id
      enrolledInAutopay
      financialPolicyConsentRequired
      maxAutopayLimit
    }
  }
`;

const EditAutopayDialog: React.FC<
  React.PropsWithChildren<{
    patient: IPatient;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ patient, open, setOpen }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [enabled, setEnabled] = useState(patient.enrolledInAutopay);
  const [notify, setNotify] = useState(false);
  const [setAutoPayEnrollment, setAutoPayEnrollmentResult] = useMutation<
    SetAutoPayEnrollment,
    SetAutoPayEnrollmentVariables
  >(SET_AUTO_PAY_ENROLLMENT);

  // If the user is potentially enabling autopay
  const isEnabling = !patient.enrolledInAutopay;

  const onSubmit = async (data: any) => {
    setAutoPayEnrollment({
      variables: {
        patientId: patient.id,
        enabled,
        sendNotification: notify,
      },
      onCompleted: () => {
        toast.success(`Autopay ${enabled ? "enabled" : "disabled"}`);
        analytics?.track(enabled ? "Autopay enabled" : "Autopay disabled", {
          patientId: patient.id,
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
        });
        setOpen(false);
      },
      onError: () => {
        toast.error("Error updating autopay");
      },
      refetchQueries: [GET_PATIENT],
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Autopay Settings
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <Switch.Group
                        as="div"
                        className="flex items-center justify-between w-full"
                      >
                        <div className="flex flex-grow gap-1">
                          <span>
                            <Switch.Label
                              as="span"
                              className="text-md text-gray-700 flex items-center"
                              passive
                            >
                              Autopay enabled
                            </Switch.Label>
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={classNames(
                              enabled ? "bg-indigo-600" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                enabled ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                      </Switch.Group>
                      {isEnabling && (
                        <div className="flex pt-2">
                          <div className="h-5">
                            <input
                              id="notify"
                              name="notify"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              defaultChecked={notify}
                              onChange={(e) => {
                                setNotify(e.target.checked);
                              }}
                            />
                          </div>
                          <div className="ml-2 text-sm font-light">
                            <label htmlFor="notify" className="text-gray-700">
                              Notify patient about enrollment in Autopay.
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={setAutoPayEnrollmentResult.loading}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const UPDATE_PATIENT_MAX_AUTOPAY_LIMIT = gql`
  mutation UpdatePatientMaxAutopayLimit($id: String!, $maxAutopayLimit: Int) {
    updatePatientMaxAutopayLimit(id: $id, maxAutopayLimit: $maxAutopayLimit) {
      id
      maxAutopayLimit
    }
  }
`;

export const EditMaxAutopayLimitDialog: React.FC<
  React.PropsWithChildren<{
    patient: Pick<IPatient, "id" | "maxAutopayLimit">;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ patient, open, setOpen }) => {
  const [maxAutopayLimit, setMaxAutopayLimit] = useState(
    patient.maxAutopayLimit
  );
  const [updatePatientMaxAutopayLimit, updatePatientMaxAutopayLimitResult] =
    useMutation<
      UpdatePatientMaxAutopayLimit,
      UpdatePatientMaxAutopayLimitVariables
    >(UPDATE_PATIENT_MAX_AUTOPAY_LIMIT);

  const onSubmit = async (data: any) => {
    updatePatientMaxAutopayLimit({
      variables: {
        id: patient.id,
        maxAutopayLimit,
      },
      onCompleted: () => {
        toast.success("Updated Max Autopay Limit");
        setOpen(false);
      },
      onError: () => {
        toast.error("Error updating Max Autopay Limit");
      },
    });
  };

  const valid = !isDefined(maxAutopayLimit) || maxAutopayLimit >= 100;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Autopay Settings
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <DollarInput
                        containerClassName="relative rounded-md border"
                        className="rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 sm:text-sm border-gray-300"
                        placeholder="0.00"
                        decimalsLimit={2}
                        step={0.01}
                        min={1}
                        defaultValue={
                          maxAutopayLimit
                            ? toDollars(maxAutopayLimit)
                            : undefined
                        }
                        onValueChange={(amount) => {
                          const cents = mapNullable(toCents)(amount as any);
                          setMaxAutopayLimit(cents);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updatePatientMaxAutopayLimitResult.loading}
                    disabled={!valid}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const UPDATE_PATIENT_COMMUNICATION = gql`
  mutation UpdatePatientCommunication(
    $id: String!
    $data: PatientUpdateInput!
  ) {
    updateOnePatient(where: { id: $id }, data: $data) {
      emailCommunicationEnabled
      smsCommunicationEnabled
    }
  }
`;

const EditContactSettings: React.FC<
  React.PropsWithChildren<{
    patient: IPatient;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ patient, open, setOpen }) => {
  const patientPreferenceEmail = patient.communicationPreferences?.find(
    (elem) => elem === "EMAIL"
  );
  const patientPreferenceSms = patient.communicationPreferences?.find(
    (elem) => elem === "TEXT"
  );
  const [emailCommunicationEnabled, setEmailCommunicationEnabled] = useState(
    patient.emailCommunicationEnabled
  );
  const [smsCommunicationEnabled, setSmsCommunicationEnabled] = useState(
    patient.smsCommunicationEnabled
  );
  const [overrideEnabled, setOverrideEnabled] = useState(
    isDefined(emailCommunicationEnabled) || isDefined(smsCommunicationEnabled)
  );

  const [updatePatientCommunication, updatePatientCommunicationResult] =
    useMutation<
      UpdatePatientCommunication,
      UpdatePatientCommunicationVariables
    >(UPDATE_PATIENT_COMMUNICATION, {
      onCompleted: (data) => {
        setEmailCommunicationEnabled(
          data.updateOnePatient?.emailCommunicationEnabled ?? null
        );
        setSmsCommunicationEnabled(
          data.updateOnePatient?.smsCommunicationEnabled ?? null
        );
        toast.success("Updated communication settings");
      },
      onError: (error) => {
        toast.error("Failed to update communication settings");
      },
      refetchQueries: [GET_PATIENT],
    });

  const enableOverride = async (enabled: boolean) => {
    if (enabled) {
      await updatePatientCommunication({
        variables: {
          id: patient.id,
          data: {
            emailCommunicationEnabled: {
              set: isDefined(patientPreferenceEmail)
                ? Boolean(patientPreferenceEmail)
                : true,
            },
            smsCommunicationEnabled: {
              set: isDefined(patientPreferenceSms)
                ? Boolean(patientPreferenceSms)
                : true,
            },
          },
        },
      });
      setOverrideEnabled(true);
    } else {
      await updatePatientCommunication({
        variables: {
          id: patient.id,
          data: {
            emailCommunicationEnabled: { set: null },
            smsCommunicationEnabled: { set: null },
          },
        },
      });
      setOverrideEnabled(false);
    }
  };

  const onChangeEmailCommunication = async () => {
    await updatePatientCommunication({
      variables: {
        id: patient.id,
        data: {
          emailCommunicationEnabled: { set: !emailCommunicationEnabled },
        },
      },
    });
  };

  const onChangeSmsCommunication = async () => {
    await updatePatientCommunication({
      variables: {
        id: patient.id,
        data: {
          smsCommunicationEnabled: { set: !smsCommunicationEnabled },
        },
      },
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mb-6"
                    >
                      Edit Communication Settings
                    </Dialog.Title>
                    <div className="flex flex-col mt-2">
                      <Switch.Group
                        as="div"
                        className="flex items-center justify-between w-full"
                      >
                        <div className="flex flex-grow">
                          <span>
                            <Switch.Label
                              as="span"
                              className="text-md text-gray-700 flex items-center"
                              passive
                            >
                              Override patient preferences
                            </Switch.Label>
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <Switch
                            checked={overrideEnabled}
                            onChange={enableOverride}
                            className={classNames(
                              overrideEnabled ? "bg-indigo-600" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                overrideEnabled
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                      </Switch.Group>
                      <Transition
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-200 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                        show={overrideEnabled}
                      >
                        <div className="mt-2 flex gap-4">
                          <div className="flex flex-col gap-2 mt-2 items-center">
                            <Switch.Group
                              as="div"
                              className="w-full text-center"
                            >
                              <div className="flex flex-col items-center gap-1">
                                <Switch.Label
                                  as="span"
                                  className="text-md text-gray-700"
                                >
                                  Patient email communication enabled
                                </Switch.Label>
                                <Switch
                                  checked={Boolean(emailCommunicationEnabled)}
                                  onChange={onChangeEmailCommunication}
                                  className={classNames(
                                    emailCommunicationEnabled
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  )}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      emailCommunicationEnabled
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </div>
                            </Switch.Group>
                          </div>

                          <div className="flex flex-col gap-2 mt-2 items-center">
                            <Switch.Group
                              as="div"
                              className="w-full text-center"
                            >
                              <div className="flex flex-col items-center gap-1">
                                <Switch.Label
                                  as="span"
                                  className="text-md text-gray-700"
                                >
                                  Patient SMS communication enabled
                                </Switch.Label>
                                <Switch
                                  checked={
                                    !Boolean(patient.twilioOptOut) &&
                                    Boolean(smsCommunicationEnabled)
                                  }
                                  onChange={onChangeSmsCommunication}
                                  disabled={Boolean(patient.twilioOptOut)}
                                  className={classNames(
                                    !patient.twilioOptOut &&
                                      smsCommunicationEnabled
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    patient.twilioOptOut
                                      ? "disabled:cursor-not-allowed"
                                      : "cursor-pointer",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  )}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      !patient.twilioOptOut &&
                                        smsCommunicationEnabled
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </div>
                            </Switch.Group>
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="flex flex-col gap-2 mt-1 items-center w-1/2">
                            <div className="mt-2 text-sm inline-flex items-center">
                              {patient.emailInvalid ? (
                                <span className="text-red-600">
                                  Patient's email is not valid
                                </span>
                              ) : (
                                <span className="text-yellow-600">
                                  {isDefined(patientPreferenceEmail) ||
                                  isDefined(patientPreferenceSms)
                                    ? `Patient marked email as a ${
                                        patientPreferenceEmail
                                          ? "preferred"
                                          : "non-preferred"
                                      } method of communication`
                                    : "Patient has not set a preference for email communication"}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col gap-2 mt-1 items-center w-1/2">
                            <div className="mt-2 text-sm inline-flex items-center">
                              {patient.twilioOptOut ? (
                                <span className="text-red-600">
                                  Patient has opted out of SMS communication
                                </span>
                              ) : (
                                <span className="text-yellow-600">
                                  {isDefined(patientPreferenceSms) ||
                                  isDefined(patientPreferenceEmail)
                                    ? `Patient marked SMS as a ${
                                        patientPreferenceSms
                                          ? "preferred"
                                          : "non-preferred"
                                      } method of communication`
                                    : "Patient has not set a preference for SMS communication"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const autopayEnabledForPatientAndLocation = ({
  patient,
  location,
}: {
  patient: Pick<IPatient, "paymentMethods" | "enrolledInAutopay"> & {
    accounts: Pick<Account, "externalPaymentMethods">[];
  };
  location: Pick<Location, "externalAutopayEnabled">;
}) => {
  const hasSavedPaymentMethod = patient.paymentMethods.length > 0;
  const hasExternalPaymentMethod = patient.accounts.some(
    (a) => a.externalPaymentMethods.length > 0
  );
  // Disable the switch if there are no saved payment methods and it's not
  // enabled OR if the patient's location has external autopay enabled and the
  // patient has an external payment method.
  const pledgeAutopayAllowed = hasSavedPaymentMethod;
  const externalAutopayAllowed =
    location.externalAutopayEnabled && hasExternalPaymentMethod;
  const disabled =
    !patient.enrolledInAutopay &&
    !pledgeAutopayAllowed &&
    !externalAutopayAllowed;

  return !disabled;
};

const AutopaySummary: React.FC<
  React.PropsWithChildren<{ patient: IPatient }>
> = ({ patient }) => {
  const user = useUser()!;
  const [autopayDialogOpen, setAutopayDialogOpen] = useState(false);
  const [maxAutopayLimitDialogOpen, setMaxAutopayLimitDialogOpen] =
    useState(false);
  const disabled = !autopayEnabledForPatientAndLocation({
    patient,
    location: user.activeLocation,
  });
  const nextAutoPayRequest = patient.nextAutopayPaymentRequest?.at(0);
  const maxAutopayLimitEnabled = user.activeLocation.maxAutopayLimitEnabled;

  return (
    <div className="w-full">
      <div className="flex flex-grow gap-2 justify-between">
        <span className="text-md text-gray-700 flex items-center gap-2">
          Autopay
          <Tooltip
            content={
              <>
                {disabled ? "Autopay requires a saved payment method." : "Edit"}
              </>
            }
            trigger={
              <button
                type="button"
                className="relative inline-flex items-center rounded-md border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setAutopayDialogOpen(true)}
                disabled={disabled}
              >
                <span className="sr-only">Edit</span>
                <PencilIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            }
          />
        </span>
        <div>
          {patient.enrolledInAutopay ? (
            <span className="text-green-500">On</span>
          ) : (
            <span className="text-gray-500">Off</span>
          )}
        </div>
      </div>
      {maxAutopayLimitEnabled && patient.enrolledInAutopay && (
        <div className="flex flex-grow gap-1 justify-between text-md text-gray-700">
          <div className="flex gap-1 items-center">
            Max Autopay Limit
            <Tooltip
              content={<>Edit</>}
              trigger={
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 hover:bg-gray-50"
                  onClick={() => setMaxAutopayLimitDialogOpen(true)}
                  disabled={disabled}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              }
            />
          </div>
          <div>
            {isDefined(patient.maxAutopayLimit)
              ? formatUSD(patient.maxAutopayLimit)
              : "None"}
          </div>
        </div>
      )}
      {nextAutoPayRequest && nextAutoPayRequest.scheduledAt && (
        <div className="flex flex-grow gap-1 justify-between text-md text-gray-700">
          <div className="flex gap-1 items-center">
            Next Autopay Scheduled
            {nextAutoPayRequest.paymentRequestBatchId && (
              <Tooltip
                content={<>View Autopay Request Batch</>}
                trigger={
                  <Link
                    to={`/billing/batches/${nextAutoPayRequest.paymentRequestBatchId}`}
                    className="relative inline-flex items-center rounded-md border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">View Batch</span>
                    <LinkIcon className="h-4 w-4" aria-hidden="true" />
                  </Link>
                }
              />
            )}
          </div>
          <div>
            {nextAutoPayRequest.amount && (
              <>{formatUSD(nextAutoPayRequest.amount)} on </>
            )}
            {formatDateMMDDYYYY(nextAutoPayRequest.scheduledAt)}
          </div>
        </div>
      )}
      {autopayDialogOpen && (
        <EditAutopayDialog
          patient={patient}
          open={autopayDialogOpen}
          setOpen={setAutopayDialogOpen}
        />
      )}
      {maxAutopayLimitDialogOpen && (
        <EditMaxAutopayLimitDialog
          patient={patient}
          open={maxAutopayLimitDialogOpen}
          setOpen={setMaxAutopayLimitDialogOpen}
        />
      )}
    </div>
  );
};

const SEND_LINK_PAYMENT_REQUEST = gql`
  mutation SendLinkPaymentRequest(
    $patientId: String!
    $enrollInReminders: Boolean!
  ) {
    sendLinkPaymentRequest(
      patientId: $patientId
      enrollInReminders: $enrollInReminders
    ) {
      success
      patientId
    }
  }
`;

const SEND_PRINT_PAYMENT_REQUEST = gql`
  mutation SendPrintPaymentRequest($patientId: String!, $patientEmail: String) {
    printStatement(patientId: $patientId, patientEmail: $patientEmail) {
      success
      url
    }
  }
`;

const NavbarActions: React.FC<
  React.PropsWithChildren<{ patient: IPatient }>
> = ({ patient }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const analytics = useAnalytics();
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [generatingStatement, setGeneratingStatement] = useState(false);
  const [sent, setSent] = useState(false);
  const cancelButtonRef = useRef(null);
  const [reminders, setReminders] = useState(
    user?.organization?.automatedRemindersEnabled ?? false
  );

  const { data, loading } = useQuery(GET_PATIENT_READY_BILLS, {
    variables: { id: patient.id },
  });

  const [sendLinkPaymentRequest, sendLinkPaymentRequestResult] = useMutation<
    SendLinkPaymentRequest,
    SendLinkPaymentRequestVariables
  >(SEND_LINK_PAYMENT_REQUEST, {
    onCompleted: (data) => {
      if (data.sendLinkPaymentRequest.success) {
        toast.success("Payment link sent successfully");
        setSending(false);
        setSent(true);
      } else {
        toast.error("Failed to send payment link");
        setSending(false);
      }
    },
    onError: (error) => {
      toast.error("Failed to send payment link");
      setSending(false);
    },
    refetchQueries: [GET_PATIENT],
  });

  const [sendPrintPaymentRequest, sendPrintPaymentRequestResult] = useMutation<
    SendPrintPaymentRequest,
    SendPrintPaymentRequestVariables
  >(SEND_PRINT_PAYMENT_REQUEST, {
    onCompleted: (data) => {
      if (data.printStatement.success && data.printStatement.url) {
        const a = document.createElement("a");
        a.href = data.printStatement.url;
        a.click();
        analytics?.track("Paper Statement PDF Downloaded", {
          patientId: patient.id,
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
          page: "PatientPage",
        });
        toast.success("Your paper statement has downloaded!");
      } else {
        toast.error("Your statement request failed, please try again.");
      }
      setGeneratingStatement(false);
    },
    onError: (error) => {
      toast.error("Your statement request failed, please try again.");
      Sentry.captureException(error);
      setGeneratingStatement(false);
    },
  });

  const toggleReminders: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setReminders(event.currentTarget.checked);
  };

  const copyPaymentPortalLink = () => {
    copyTextToClipboard(
      `${window.location.protocol}//${window.location.host}/portal/${patient.organizationId}/${patient.id}`
    )
      .then(() => {
        toast.success("Payment portal link copied!");
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast.success("Payment portal link failed to copy");
      });
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        <div>
          <Link
            to={`/patients/${patient.id}/edit`}
            className="flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilAltIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Edit
          </Link>
        </div>
        {flags.postVisitBillingEnabled && (
          <div>
            <span className="relative inline-flex shadow-sm rounded-md">
              <button
                type="button"
                onClick={() => setOpen(true)}
                // TODO: Add tooltip expaining why this is disabled
                disabled={!patient.shareable}
                className="inline-flex items-center px-3 py-2 h-full border border-transparent shadow-sm text-sm font-medium rounded-l-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300"
              >
                <MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Send payment link
              </button>
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-transparent border-l-gray-50 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:z-10 focus:outline-none">
                  <span className="sr-only">Open options</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 -mr-1 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/portal/${patient.organizationId}/${patient.id}`}
                            target="_blank"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full text-center px-4 py-2 text-sm"
                            )}
                          >
                            <div className="flex items-center justify-center gap-2">
                              View patient billing portal
                              <ExternalLinkIcon className="h-4 w-4" />
                            </div>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full text-center px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={copyPaymentPortalLink}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <span>Copy link to patient billing portal</span>
                              <DuplicateIcon className="h-4 w-4" />
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !loading &&
                          data.bills.length === 0 &&
                          !generatingStatement
                        }
                      >
                        {({ active, disabled }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full text-center px-4 py-2 text-sm",
                              disabled
                                ? "cursor-not-allowed opacity-50"
                                : "cursor-pointer"
                            )}
                            onClick={() => {
                              setGeneratingStatement(true);
                              toast.promise(
                                sendPrintPaymentRequest({
                                  variables: {
                                    patientId: patient.id,
                                    patientEmail: patient.email,
                                  },
                                }),
                                {
                                  pending: "Generating statement request...",
                                }
                              );
                            }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              {disabled
                                ? "No bills ready to print"
                                : "Generate Statement PDF"}
                              <PrinterIcon className="h-4 w-4" />
                            </div>
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span>
          </div>
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="transform opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  {sent ? (
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 pt-4"
                      >
                        Payment link sent!
                      </Dialog.Title>
                      <div className="mt-5 sm:mt-6 flex">
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={() => {
                            setOpen(false);
                            setSent(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Send {patient.displayName} a payment link?
                          </Dialog.Title>
                          <div className="mt-2">
                            {loading ? (
                              "loading"
                            ) : data.bills.length === 0 ? (
                              <div className="flex justify-center">
                                <div className="flex-shrink-0">
                                  <ExclamationIcon
                                    className="h-5 w-5 text-yellow-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="text-sm text-yellow-700">
                                    This patient has no bills ready to pay!
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <p className="text-sm text-gray-500">
                                Send a link to pay {data.bills.length} ready
                                bills.
                              </p>
                            )}
                          </div>
                        </div>
                        {user?.organization?.automatedRemindersEnabled && (
                          <div className="relative flex items-start pl-2 pt-4">
                            <div className="flex h-5 items-center">
                              <input
                                id="reminders"
                                name="reminders"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={toggleReminders}
                                defaultChecked={reminders}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="reminders"
                                className="font-medium text-gray-700"
                              >
                                Enroll Patients in Reminders
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <SubmitButton
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                          onClick={() => {
                            setSending(true);
                            sendLinkPaymentRequest({
                              variables: {
                                patientId: patient.id,
                                enrollInReminders: reminders,
                              },
                            });
                          }}
                          loading={sending}
                        >
                          Send
                        </SubmitButton>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const GET_POSSIBLE_MATCHES = gql`
  query GetPossibleMatches($id: String!) {
    getPossibleMatches(id: $id) {
      patient {
        id
        displayName
        firstName
        lastName
        dateOfBirth
        cellPhone
        email
        address1
        address2
        city
        state
        postalCode
        country
        accounts {
          id
          accountType {
            id
            name
          }
        }
        location {
          id
          name
        }
      }
      similarity
    }
  }
`;

const LinkedAccountsCard: React.FC<{ patient: IPatient }> = ({ patient }) => {
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState(false);
  const { data, loading } = useQuery<
    GetPossibleMatches,
    GetPossibleMatchesVariables
  >(GET_POSSIBLE_MATCHES, {
    variables: { id: patient.id },
  });
  const matches = data?.getPossibleMatches ?? [];

  const linkedAccounts = patient.organizationPatientGroup?.patients ?? [];
  const hasLinkedAccounts = linkedAccounts.length > 0;

  return (
    <>
      <Card>
        <div className="flex flex-col divide-y gap-2 w-full">
          <div className="flex justify-between items-center">
            <h1 className="truncate text-lg font-medium text-gray-900">
              Linked Accounts
            </h1>
            <div className="flex items-center gap-4">
              <NewLinkPatientDialogButton patient={patient} />
              {hasLinkedAccounts && (
                <button
                  className="font-medium text-red-600 hover:text-red-500"
                  onClick={() => {
                    setUnlinkDialogOpen(true);
                  }}
                >
                  Unlink
                </button>
              )}
            </div>
          </div>
          {matches.length > 0 && (
            <div
              className="border-l-4 border-yellow-400 bg-yellow-50 p-4"
              style={{ borderTop: 0 }}
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <ShieldExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-sm w-full">
                  <p className="text-sm text-yellow-700">
                    Possible duplicate accounts found.
                  </p>
                  <ul className="list-disc list-inside flex flex-col gap-1">
                    {matches.map((match) => (
                      <li
                        key={match.patient.id}
                        className="flex justify-between flex-wrap"
                      >
                        <div>
                          {match.patient.displayName}{" "}
                          <span className="text-gray-500">
                            (DOB:{" "}
                            {match.patient.dateOfBirth
                              ? format(
                                  parseISO(toDate(match.patient.dateOfBirth)),
                                  "MM/dd/yyyy"
                                )
                              : "N/A"}
                            )
                          </span>{" "}
                          in {match.patient.location.name}
                        </div>
                        <LinkPatientDialogButton
                          patient={patient}
                          matchingPatient={match.patient}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {!hasLinkedAccounts && (
            <div className="flex items-center py-2">
              <div className="text-gray-500">No linked accounts found.</div>
            </div>
          )}
          {patient.organizationPatientGroup?.patients?.map((p) => (
            <div key={p.id} className="flex justify-between pt-2">
              <div className="flex gap-2 items-center">
                {p.displayName} ({p.location.name})
                <Link to={`/patients/${p.id}`} target="_blank">
                  <ExternalLinkIcon className="h-4 w-4 text-gray-500 hover:text-gray-400" />
                </Link>
              </div>
              <div className="flex items-center gap-2">
                <div>{formatUSD(-p.patientReadyBalance)}</div>
                <UnlinkPatientDialogButton
                  patient={p}
                  remainingPatients={[
                    patient,
                    ...(patient.organizationPatientGroup?.patients?.filter(
                      (ogp) => p.id !== ogp.id
                    ) ?? []),
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
      {unlinkDialogOpen && (
        <UnlinkPatientDialog
          patient={patient}
          remainingPatients={patient.organizationPatientGroup?.patients ?? []}
          open={unlinkDialogOpen}
          setOpen={setUnlinkDialogOpen}
        />
      )}
    </>
  );
};

export const PatientSummary: React.FC<{
  patient: Pick<IPatient, "id" | "displayName" | "dateOfBirth" | "birthSex"> & {
    integrationLinks?: Pick<
      IntegrationLink,
      | "id"
      | "integrationLinkSyncs"
      | "integration"
      | "lastCheckedAt"
      | "createdAt"
    >[];
    accounts?: {
      accountNumber?: string;
      accountType: {
        name: string;
      } | null;
    }[];
    patientLabelings?: {
      id: string;
      patientLabel: {
        id: string;
        name: string;
      };
    }[];
  };
}> = ({ patient }) => {
  const accountsWithNames = (patient?.accounts ?? [])
    .map((a) => ({
      name: a.accountType?.name,
      accountNumber: a.accountNumber,
    }))
    .filter((a) => isDefined(a.name));
  const externalReferenceNumbers = (patient?.accounts ?? [])
    .map((a) => a.accountNumber)
    .filter(isDefined);
  return (
    <div>
      <div className="flex items-center gap-2">
        <h1 className="truncate text-xl font-bold text-gray-900">
          {patient.displayName}
        </h1>
        {patient.integrationLinks && patient.integrationLinks.length > 0 && (
          <IntegrationStatus
            patientId={patient.id}
            integrationLinks={patient.integrationLinks}
            refetchQueries={[GET_PATIENT]}
          />
        )}
      </div>

      <div className="flex space-x-4 items-center pt-1">
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">DOB</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.dateOfBirth
              ? format(parseISO(toDate(patient.dateOfBirth)), "MM/dd/yyyy")
              : "N/A"}
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">Sex</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.birthSex ?? "N/A"}
          </span>
        </div>
      </div>
      {accountsWithNames.length === 0 && externalReferenceNumbers.length > 0 && (
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">
            External Account Number(s)
          </span>{" "}
          <span className="text-sm text-gray-900">
            {externalReferenceNumbers.join(", ")}
          </span>
        </div>
      )}
      {accountsWithNames.length > 0 && (
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">Accounts</span>{" "}
          <span className="text-sm text-gray-900 flex gap-1">
            {accountsWithNames.map((a) => (
              <Badge
                key={a.name}
                variant="info"
                text={`${a.name} (${a.accountNumber})`}
              />
            ))}
          </span>
        </div>
      )}

      {patient.patientLabelings && patient.patientLabelings.length > 0 && (
        <div className="flex flex-wrap items-center space-x-1 mt-2">
          <span className="text-sm text-gray-900 flex gap-1">
            {patient.patientLabelings.map((labeling) => (
              <Badge
                key={labeling.id}
                variant="info"
                text={labeling.patientLabel.name}
              />
            ))}
          </span>
        </div>
      )}
    </div>
  );
};

const WorkflowSettings: React.FC<{ patient: IPatient }> = ({ patient }) => {
  const flags = useFeatureFlags();
  const [editWorkflowSettings, setEditWorkflowSettings] = useState(false);

  return (
    <Card>
      <div className="flex flex-col divide-y gap-2 w-full">
        <div className="flex justify-between items-center">
          <h1 className="truncate text-lg font-medium text-gray-900">
            Workflow Settings
          </h1>
          <Tooltip
            content={<>Edit</>}
            trigger={
              <button
                type="button"
                className="relative inline-flex items-center rounded-md border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => setEditWorkflowSettings(true)}
              >
                <span className="sr-only">Edit</span>
                <PencilIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            }
          />
        </div>

        {(flags.automatedEstimatesEnabled ||
          flags.preVisitReminderEnabled ||
          flags.automatedTimeOfServiceChargingEnabled) && (
          <div className="pt-2">
            <h2 className="text-md font-semibold">Visit Workflow</h2>
            <div className="flex flex-col divide-y gap-2">
              {flags.automatedEstimatesEnabled && (
                <div className="flex justify-between pt-2">
                  <span className="flex items-center gap-1 text-gray-700">
                    Automated Estimates
                    <Tooltip
                      trigger={<InformationCircleIcon className="h-4 w-4" />}
                      content={
                        <div className="max-w-md">
                          Automated estimates will be created ahead of their
                          scheduled appointments and re-estimated when charges
                          are entered.
                        </div>
                      }
                    />
                  </span>
                  <span>
                    {patient.estimationPausedAt ? (
                      <span className="text-gray-500">Off</span>
                    ) : (
                      <span className="text-green-500">On</span>
                    )}
                  </span>
                </div>
              )}
              {flags.preVisitReminderEnabled && (
                <>
                  <div className="flex justify-between pt-2">
                    <span className="flex items-center gap-1 text-gray-700">
                      Automated Pre-visit Reminders
                      <Tooltip
                        trigger={<InformationCircleIcon className="h-4 w-4" />}
                        content={
                          <div className="max-w-md">
                            Notifications will be sent to this patient to remind
                            them to provide any missing required information. If
                            all required information is provided, they will not
                            receive additional reminders after their first visit
                            unless they opt-in for Estimate Notifications.
                          </div>
                        }
                      />
                    </span>
                    <span>
                      {patient.preVisitReminderPausedAt ? (
                        <span className="text-gray-500">Off</span>
                      ) : (
                        <span className="text-green-500">On</span>
                      )}
                    </span>
                  </div>
                  {!isDefined(patient.preVisitReminderPausedAt) &&
                    flags.estimatesEnabled && (
                      <div className="flex justify-between pt-2">
                        <span className="flex items-center gap-1 text-gray-700">
                          Estimate Notifications
                          <Tooltip
                            trigger={
                              <InformationCircleIcon className="h-4 w-4" />
                            }
                            content={
                              <div className="max-w-md">
                                Notifications will be sent to patients with
                                their estimated cost ahead of each appointment
                                when the estimate is present.
                              </div>
                            }
                          />
                        </span>
                        <span>
                          {patient.estimateCommunicationEnrolled ? (
                            <span className="text-green-500">On</span>
                          ) : (
                            <span className="text-gray-500">Off</span>
                          )}
                        </span>
                      </div>
                    )}
                  {flags.automatedTimeOfServiceChargingEnabled && (
                    <div className="flex justify-between pt-2">
                      <span className="flex items-center gap-1 text-gray-700">
                        Payment collection
                        <Tooltip
                          trigger={
                            <InformationCircleIcon className="h-4 w-4" />
                          }
                          content={
                            <div className="max-w-md">
                              Automatically charge the patient after their visit
                              is finalized based on their estimated cost. Their
                              card will be charged if enrolled in Autopay,
                              otherwise they will be sent a payment link.
                            </div>
                          }
                        />
                      </span>
                      <span>
                        {patient.timeOfServiceAutoChargePausedAt ? (
                          <span className="text-gray-500">Off</span>
                        ) : (
                          <span className="text-green-500">On</span>
                        )}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {flags.postVisitBillingEnabled && (
          <div className="pt-2">
            <h2 className="text-md font-semibold">Statement Workflow</h2>
            <div className="flex flex-col divide-y gap-2">
              <div className="flex justify-between pt-2">
                <span className="flex items-center gap-1">
                  Automated Statement Reminders
                  <Tooltip
                    trigger={<InformationCircleIcon className="h-4 w-4" />}
                    content={
                      <div className="max-w-md">
                        Notifications will be automatically sent to patients for
                        their unpaid ready bills based on the location's
                        reminder sequence settings.
                      </div>
                    }
                  />
                </span>
                <span>
                  {isDefined(patient.reminderWorkflow) &&
                  !isDefined(patient.reminderWorkflow?.pausedAt) ? (
                    <span className="text-green-500">On</span>
                  ) : (
                    <span className="text-gray-500">Off</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        {editWorkflowSettings && (
          <EditWorkflowSettings
            patient={patient}
            open={editWorkflowSettings}
            setOpen={setEditWorkflowSettings}
          />
        )}
      </div>
    </Card>
  );
};

const EditWorkflowSettings: React.FC<{
  patient: IPatient;
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ patient, open, setOpen }) => {
  const flags = useFeatureFlags();
  const reminderWorkflowId = patient.reminderWorkflow?.id;

  const [updatePatientWorkflowSettings, updatePatientWorkflowSettingsResult] =
    useMutation<
      UpdatePatientWorkflowSettings,
      UpdatePatientWorkflowSettingsVariables
    >(UPDATE_PATIENT_WORKFLOW_SETTINGS, {
      onCompleted: () => {
        toast.success("Workflow settings updated successfully");
      },
      onError: () => {
        toast.error("Failed to update workflow settings");
      },
    });
  const [pauseReminderWorkflow, pauseReminderWorkflowResult] = useMutation<
    PauseReminderWorkflow,
    PauseReminderWorkflowVariables
  >(PAUSE_REMINDER_WORKFLOW, {
    onCompleted: () => {
      toast.success("Workflow settings updated successfully");
    },
  });
  const [resumeReminderWorkflow, resumeReminderWorkflowResult] = useMutation<
    ResumeReminderWorkflow,
    ResumeReminderWorkflowVariables
  >(RESUME_REMINDER_WORKFLOW, {
    onCompleted: () => {
      toast.success("Workflow settings updated successfully");
    },
  });
  const [startReminderWorkflow, startReminderWorkflowResult] = useMutation<
    StartReminderWorkflow,
    StartReminderWorkflowVariables
  >(START_REMINDER_WORKFLOW, {
    onCompleted: () => {
      toast.success("Workflow settings updated successfully");
    },
  });

  const [automatedEstimates, setAutomatedEstimates] = useState(
    !isDefined(patient.estimationPausedAt)
  );
  const [preVisitReminders, setPreVisitReminders] = useState(
    !isDefined(patient.preVisitReminderPausedAt)
  );
  const [estimateNotifications, setEstimateNotifications] = useState(
    patient.estimateCommunicationEnrolled
  );
  const [autoCharging, setAutoCharging] = useState(
    !isDefined(patient.timeOfServiceAutoChargePausedAt)
  );
  const [automatedStatementReminders, setAutomatedStatementReminders] =
    useState(
      isDefined(patient.reminderWorkflow) &&
        !isDefined(patient.reminderWorkflow?.pausedAt)
    );

  const onChangeStatementReminders = async (enabled: boolean) => {
    if (enabled) {
      if (reminderWorkflowId) {
        await resumeReminderWorkflow({
          variables: { patientId: patient.id },
          onCompleted: () => {
            setAutomatedStatementReminders(true);
            toast.success("Updated statement reminders settings");
          },
          onError: () => {
            toast.error("Failed to update statement reminders settings");
          },
        });
      } else {
        await startReminderWorkflow({
          variables: { patientId: patient.id },
          onCompleted: () => {
            setAutomatedStatementReminders(true);
            toast.success("Updated statement reminders settings");
          },
          onError: () => {
            toast.error("Failed to update statement reminders settings");
          },
        });
      }
    } else {
      await pauseReminderWorkflow({
        variables: { patientId: patient.id },
        onCompleted: () => {
          setAutomatedStatementReminders(false);
          toast.success("Updated statement reminders settings");
        },
        onError: () => {
          toast.error("Failed to update statement reminders settings");
        },
      });
    }
  };

  const onChangeAutomatedEstimates = async (enabled: boolean) => {
    await updatePatientWorkflowSettings({
      variables: {
        id: patient.id,
        data: {
          estimationPausedAt: {
            set: enabled ? null : new Date().toISOString(),
          },
        },
      },
      onCompleted: (data) => {
        setAutomatedEstimates(
          !isDefined(data.updateOnePatient?.estimationPausedAt)
        );
        toast.success("Updated automated estimates settings");
      },
      onError: () => {
        toast.error("Failed to update automated estimates settings");
      },
    });
  };

  const onChangePreVisitReminder = async (enabled: boolean) => {
    await updatePatientWorkflowSettings({
      variables: {
        id: patient.id,
        data: {
          preVisitReminderPausedAt: {
            set: enabled ? null : new Date().toISOString(),
          },
        },
      },
      onCompleted: (data) => {
        setPreVisitReminders(
          !isDefined(data.updateOnePatient?.preVisitReminderPausedAt)
        );
        toast.success("Updated pre-visit reminder settings");
      },
      onError: () => {
        toast.error("Failed to update pre-visit reminder settings");
      },
    });
  };

  const onChangeEstimateNotifications = async (enabled: boolean) => {
    await updatePatientWorkflowSettings({
      variables: {
        id: patient.id,
        data: {
          estimateCommunicationEnrolled: {
            set: enabled,
          },
        },
      },
      onCompleted: (data) => {
        setEstimateNotifications(
          data.updateOnePatient?.estimateCommunicationEnrolled === true
        );
        toast.success("Updated estimate notifications settings");
      },
      onError: () => {
        toast.error("Failed to update estimate notifications settings");
      },
    });
  };

  const onChangeAutoCharging = async (enabled: boolean) => {
    await updatePatientWorkflowSettings({
      variables: {
        id: patient.id,
        data: {
          timeOfServiceAutoChargePausedAt: {
            set: enabled ? null : new Date().toISOString(),
          },
        },
      },
      onCompleted: (data) => {
        setAutoCharging(
          !isDefined(data.updateOnePatient?.timeOfServiceAutoChargePausedAt)
        );
        toast.success("Updated payment collection settings");
      },
      onError: () => {
        toast.error("Failed to update payment collection settings");
      },
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Workflow Settings
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="space-y-4">
                        {(flags.automatedEstimatesEnabled ||
                          flags.preVisitReminderEnabled ||
                          flags.automatedTimeOfServiceChargingEnabled) && (
                          <>
                            <h2 className="text-md font-semibold text-left">
                              Visit Workflow
                            </h2>
                            {flags.automatedEstimatesEnabled && (
                              <div className="flex items-center justify-between">
                                <span className="flex items-center gap-1">
                                  Automated estimates
                                  <Tooltip
                                    trigger={
                                      <InformationCircleIcon className="h-4 w-4" />
                                    }
                                    content={
                                      <div className="max-w-md">
                                        Automated estimates will be created
                                        ahead of their scheduled appointments
                                        and re-estimated when charges are
                                        entered.
                                      </div>
                                    }
                                  />
                                </span>
                                <Switch
                                  checked={automatedEstimates}
                                  onChange={onChangeAutomatedEstimates}
                                  className={classNames(
                                    automatedEstimates
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  )}
                                  disabled={
                                    updatePatientWorkflowSettingsResult.loading
                                  }
                                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      automatedEstimates
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </div>
                            )}
                            {flags.preVisitReminderEnabled && (
                              <>
                                <div className="flex items-center justify-between">
                                  <span className="flex items-center gap-1">
                                    Pre-visit reminders
                                    <Tooltip
                                      trigger={
                                        <InformationCircleIcon className="h-4 w-4" />
                                      }
                                      content={
                                        <div className="max-w-md">
                                          Notifications will be sent to this
                                          patient to remind them to provide any
                                          required missing information.
                                        </div>
                                      }
                                    />
                                  </span>
                                  <Switch
                                    checked={preVisitReminders}
                                    onChange={onChangePreVisitReminder}
                                    className={classNames(
                                      preVisitReminders
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    )}
                                    disabled={
                                      updatePatientWorkflowSettingsResult.loading
                                    }
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        preVisitReminders
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </div>
                                {!isDefined(patient.preVisitReminderPausedAt) &&
                                  flags.estimatesEnabled && (
                                    <div className="flex items-center justify-between">
                                      <span className="flex items-center gap-1">
                                        Estimate notifications
                                        <Tooltip
                                          trigger={
                                            <InformationCircleIcon className="h-4 w-4" />
                                          }
                                          content={
                                            <div className="max-w-md">
                                              Notifications will be sent to
                                              patients with their estimated cost
                                              ahead of each appointment when the
                                              estimate is present.
                                            </div>
                                          }
                                        />
                                      </span>
                                      <Switch
                                        checked={estimateNotifications}
                                        onChange={onChangeEstimateNotifications}
                                        className={classNames(
                                          estimateNotifications
                                            ? "bg-indigo-600"
                                            : "bg-gray-200",
                                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        )}
                                        disabled={
                                          updatePatientWorkflowSettingsResult.loading
                                        }
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            estimateNotifications
                                              ? "translate-x-5"
                                              : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  )}
                                {flags.automatedTimeOfServiceChargingEnabled && (
                                  <div className="flex items-center justify-between">
                                    <span className="flex items-center gap-1">
                                      Payment collection
                                      <Tooltip
                                        trigger={
                                          <InformationCircleIcon className="h-4 w-4" />
                                        }
                                        content={
                                          <div className="max-w-md">
                                            Automatically charge the patient
                                            after their visit is finalized based
                                            on their estimated cost. Their card
                                            will be charged if enrolled in
                                            Autopay, otherwise they will be sent
                                            a payment link.
                                          </div>
                                        }
                                      />
                                    </span>
                                    <Switch
                                      checked={autoCharging}
                                      onChange={onChangeAutoCharging}
                                      className={classNames(
                                        autoCharging
                                          ? "bg-indigo-600"
                                          : "bg-gray-200",
                                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                      )}
                                      disabled={
                                        updatePatientWorkflowSettingsResult.loading
                                      }
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          autoCharging
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        )}
                                      />
                                    </Switch>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {flags.postVisitBillingEnabled && (
                          <>
                            <h2 className="text-md font-semibold text-left">
                              Statement Workflow
                            </h2>
                            <div className="flex items-center justify-between">
                              <span className="flex items-center gap-1">
                                Automated statement reminders
                                <Tooltip
                                  trigger={
                                    <InformationCircleIcon className="h-4 w-4" />
                                  }
                                  content={
                                    <div className="max-w-md">
                                      Notifications will be automatically sent
                                      to patients for their unpaid ready bills
                                      based on the location's reminder sequence
                                      settings.
                                    </div>
                                  }
                                />
                              </span>
                              <Switch
                                checked={automatedStatementReminders}
                                onChange={onChangeStatementReminders}
                                className={classNames(
                                  automatedStatementReminders
                                    ? "bg-indigo-600"
                                    : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                )}
                                disabled={
                                  resumeReminderWorkflowResult.loading ||
                                  pauseReminderWorkflowResult.loading ||
                                  startReminderWorkflowResult.loading
                                }
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    automatedStatementReminders
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="w-full mt-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const Profile: React.FC<
  React.PropsWithChildren<{ patient: IPatient }>
> = ({ patient }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const apollo = useApolloClient();
  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] =
    useState(false);
  const [createPaymentDialogOpen, setCreatePaymentDialogOpen] = useState(false);
  const [showAllPolicies, setShowAllPolicies] = useState(false);
  const [editContactSettings, setEditContactSettings] = useState(false);
  const [defaultColumnFilters, setDefaultColumnFilters] =
    useState<ColumnFiltersState>();
  // Disable process payment button if no payment methods or patient has no balance
  const paymentProcessingDisabled =
    patient.paymentMethods.length === 0 ||
    patient.patientCollectableBalance <= 0;
  // After setup is successful, refetch the patient query to get the updated payment methods
  const onSuccessfulSetup = async () => {
    await apollo.refetchQueries({ include: [GET_PATIENT] });
  };

  const hasSavedPaymentMethods = patient.paymentMethods.length > 0;
  const hasExternalPaymentMethods = patient.accounts.some(
    (a) => a.externalPaymentMethods.length > 0
  );
  const hasAnyPaymentMethods =
    hasSavedPaymentMethods || hasExternalPaymentMethods;

  const activePolicies = patient.insurancePolicies.filter((ip) => ip.active);
  const showingPolicies = showAllPolicies
    ? patient.insurancePolicies
    : activePolicies;

  const patientPreferenceEmail = patient.communicationPreferences?.find(
    (elem) => elem === "EMAIL"
  );

  const patientPreferenceSms = patient.communicationPreferences?.find(
    (elem) => elem === "TEXT"
  );

  // Transform patient data for PatientSummary component
  const patientSummaryData = {
    id: patient.id,
    displayName: patient.displayName,
    dateOfBirth: patient.dateOfBirth,
    birthSex: patient.birthSex,
    integrationLinks: patient.integrationLinks,
    accounts: patient.accounts.map((account) => ({
      accountNumber: account.accountNumber ?? undefined,
      accountType: account.accountType,
    })),
    patientLabelings: patient.patientLabelings,
  };

  return (
    <>
      <Layout
        header={
          <nav className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-sm md:text-base">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to={`/patients/${patient.id}`}
              >
                {patient.displayName}
              </Link>
            </div>
            <div>
              <NavbarActions patient={patient} />
            </div>
          </nav>
        }
        content={
          <main className="flex flex-col sm:grid sm:grid-cols-3 gap-4 pt-4">
            <aside className="sm:col-span-1 flex-col space-y-2">
              <Card>
                <div className="flex flex-col divide-y gap-2 w-full py-2">
                  <PatientSummary patient={patientSummaryData} />

                  {flags.chargesSupported &&
                    (flags.postVisitBillingEnabled ||
                      flags.tosCollectionEnabled) && (
                      <>
                        <div
                          className={cn(
                            "grid gap-1 divide-y sm:divide-y-0 sm:divide-x py-4",
                            flags.chargesSupported
                              ? "grid-cols-1 sm:grid-cols-2"
                              : "grid-cols-1"
                          )}
                        >
                          <div className="col-span-1 flex flex-col pt-2 justify-center gap-1 text-center">
                            <div className="flex flex-grow flex-col justify-center items-center">
                              <dd className="order-1 text-2xl font-extrabold flex flex-col">
                                <span>
                                  {isDefined(patient.newPatientBalance) ? (
                                    <span>
                                      {formatUSD(
                                        patient.newPatientBalance -
                                          patient.totalCredits
                                      )}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}{" "}
                                </span>
                                <span className="text-xs text-yellow-500">
                                  {patient.totalCredits !== 0
                                    ? "(" +
                                      formatUSD(patient.totalCredits) +
                                      " credit applied)"
                                    : ""}
                                </span>
                              </dd>
                              <dt className="flex items-center gap-1 order-2 text-sm font-medium text-gray-500">
                                Total Patient Balance
                                <Tooltip
                                  trigger={
                                    <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                                  }
                                  content={
                                    <div className="max-w-md text-center">
                                      This is the sum of the patient balance of
                                      unarchived bills and estimates.
                                    </div>
                                  }
                                />
                              </dt>
                            </div>
                          </div>
                          {flags.chargesSupported && (
                            <div className="col-span-1 grid-cols-1 md:grid-cols-2 divide-y-0 md:divide-y md:divide-x-0">
                              <div className="col-span-1 flex flex-col md:h-auto justify-center items-center p-1">
                                <dd className="order-1 text-lg font-semibold">
                                  {isDefined(
                                    patient.patientEstimatesBalance
                                  ) ? (
                                    <span>
                                      {formatUSD(
                                        patient.patientEstimatesBalance
                                      )}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}{" "}
                                </dd>
                                <dt className="flex items-center gap-1 order-2 text-xs font-medium text-gray-500">
                                  Pending{" "}
                                  <Tooltip
                                    trigger={
                                      <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                                    }
                                    content={
                                      <div className="max-w-md text-center">
                                        The balance remaining on pending bills
                                        including estimates.
                                      </div>
                                    }
                                  />
                                </dt>
                              </div>
                              <div className="col-span-1 flex flex-col md:h-auto justify-center items-center p-1">
                                <dd className="order-1 text-lg font-semibold">
                                  {isDefined(patient.patientInReviewBalance) ? (
                                    <span>
                                      {formatUSD(
                                        -patient.patientInReviewBalance
                                      )}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}{" "}
                                </dd>
                                <dt className="flex items-center gap-1 order-2 text-xs font-medium text-gray-500">
                                  In Review{" "}
                                  <Tooltip
                                    trigger={
                                      <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                                    }
                                    content={
                                      <div className="max-w-md text-center">
                                        The balance of bills that have been
                                        finalized but not yet marked as ready to
                                        be paid.
                                      </div>
                                    }
                                  />
                                </dt>
                              </div>
                              <div className="col-span-1 flex flex-col md:h-auto justify-center items-center p-1">
                                <dd className="order-1 text-lg font-semibold">
                                  {isDefined(patient.patientReadyBalance) ? (
                                    <span>
                                      {formatUSD(-patient.patientReadyBalance)}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}{" "}
                                </dd>
                                <dt className="flex items-center gap-1 order-2 text-xs font-medium text-gray-500">
                                  Ready{" "}
                                  <Tooltip
                                    trigger={
                                      <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                                    }
                                    content={
                                      <div className="max-w-md text-center">
                                        This is the amount that patients see and
                                        can make payments towards in the patient
                                        portal.
                                      </div>
                                    }
                                  />
                                </dt>
                              </div>
                            </div>
                          )}
                        </div>

                        {(flags.postVisitBillingEnabled ||
                          flags.tosCollectionEnabled) && (
                          <div className="flex justify-between items-center pt-2 text-sm ">
                            <label className="text-gray-700 items-center flex gap-2">
                              {user.externalLedgerName} Balance
                              <Tooltip
                                trigger={
                                  <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                                }
                                content={
                                  <div className="max-w-md text-center">
                                    This is the sum of all transactions in the
                                    ledger as imported from the external system.
                                  </div>
                                }
                              />
                            </label>
                            {formatUSD(-patient.ledgerBalance)}
                          </div>
                        )}
                        {flags.postVisitBillingEnabled && (
                          <div className="relative flex items-center gap-1 pt-2">
                            {/* <AutoPayToggle patient={patient} /> */}
                            <AutopaySummary patient={patient} />
                          </div>
                        )}

                        <div className="pt-3">
                          <button
                            className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
                            disabled={
                              !flags.stripeAccountActive ||
                              paymentProcessingDisabled
                            }
                            onClick={() => setCreatePaymentDialogOpen(true)}
                          >
                            Process Payment
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </Card>

              <Card>
                <div className="flex flex-col divide-y gap-2 w-full">
                  <div className="flex justify-between items-center">
                    <h1 className="truncate text-lg font-medium text-gray-900">
                      Contact Settings
                    </h1>
                    <Tooltip
                      content={<>Edit</>}
                      trigger={
                        <button
                          type="button"
                          className="relative inline-flex items-center rounded-md border-gray-300 bg-white p-1 text-sm font-medium text-gray-500 hover:bg-gray-50"
                          onClick={() => setEditContactSettings(true)}
                        >
                          <span className="sr-only">Edit</span>
                          <PencilIcon className="h-4 w-4" aria-hidden="true" />
                        </button>
                      }
                    />
                  </div>

                  <div className="flex justify-between pt-2">
                    <div className="flex items-center">
                      <h3 className="text-md text-gray-700">Email</h3>
                      {patient.emailInvalid && patient.email && (
                        <Tooltip
                          content={"Patient's email is invalid"}
                          trigger={
                            <ExclamationCircleIconOutline
                              className={`h-5 w-5 text-orange-500 ml-1`}
                              aria-hidden="true"
                            />
                          }
                        />
                      )}
                    </div>
                    <div className="flex items-center">
                      {((isDefined(patient.emailCommunicationEnabled) &&
                        !patient.emailCommunicationEnabled) ||
                        (!isDefined(patient.emailCommunicationEnabled) &&
                          patientPreferenceSms &&
                          !patientPreferenceEmail)) &&
                        patient.email && (
                          <Tooltip
                            content={"Email Communication Disabled"}
                            trigger={
                              <div className="flex items-center gap-1">
                                <MonitorOffIcon className="h-4 w-4 text-grey-300 mr-2" />
                              </div>
                            }
                          />
                        )}
                      {patient.email}
                    </div>
                  </div>

                  <div className="flex justify-between pt-2">
                    <div className="flex items-center">
                      <h3 className="text-md text-gray-700">Phone number</h3>
                      {patient.twilioOptOut && patient.cellPhone && (
                        <Tooltip
                          content={"Patient has opted out of SMS"}
                          trigger={
                            <ExclamationCircleIconOutline
                              className={`h-5 w-5 text-orange-500 ml-1`}
                              aria-hidden="true"
                            />
                          }
                        />
                      )}
                    </div>
                    <div className="flex items-center">
                      {((isDefined(patient.smsCommunicationEnabled) &&
                        !patient.smsCommunicationEnabled) ||
                        (!isDefined(patient.smsCommunicationEnabled) &&
                          patientPreferenceEmail &&
                          !patientPreferenceSms) ||
                        patient.twilioOptOut) &&
                        patient.cellPhone && (
                          <Tooltip
                            content={"SMS Communication Disabled"}
                            trigger={
                              <div className="flex items-center gap-1">
                                <MessageCircleOffIcon className="h-4 w-4 text-grey-300 mr-2" />
                              </div>
                            }
                          />
                        )}
                      {patient.cellPhone}
                    </div>
                  </div>

                  {editContactSettings && (
                    <EditContactSettings
                      patient={patient}
                      open={editContactSettings}
                      setOpen={setEditContactSettings}
                    />
                  )}
                </div>
              </Card>

              {(flags.estimatesEnabled ||
                flags.estimatesEnabled ||
                flags.postVisitBillingEnabled) && (
                <WorkflowSettings patient={patient} />
              )}

              {flags.stripeAccountActive &&
                (flags.postVisitBillingEnabled ||
                  flags.tosCollectionEnabled) && (
                  <Card>
                    <div className="flex flex-col divide-y gap-2 w-full">
                      <div className="flex justify-between">
                        <h1 className="truncate text-lg font-medium text-gray-900">
                          Saved Payment Methods
                        </h1>
                        <button
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={() => setAddPaymentMethodDialogOpen(true)}
                        >
                          Add
                        </button>
                      </div>
                      {!hasAnyPaymentMethods ? (
                        <div className="pt-2">No saved Payment methods</div>
                      ) : hasSavedPaymentMethods ? (
                        // TODO: Handle non-card payment methods
                        patient.paymentMethods.map((paymentMethod) => (
                          <PaymentMethodDisplay
                            paymentMethod={paymentMethod}
                            refetchQueries={[GET_PATIENT]}
                            lastPaymentError={
                              paymentMethod.paymentIntents.at(0)
                                ?.lastPaymentError ?? undefined
                            }
                          />
                        ))
                      ) : (
                        patient.accounts
                          .flatMap((account) =>
                            account.externalPaymentMethods.map(
                              (externalPaymentMethod) => ({
                                account,
                                externalPaymentMethod,
                              })
                            )
                          )
                          .map(({ account, externalPaymentMethod }) => (
                            <ExternalPaymentMethodDisplay
                              paymentMethod={externalPaymentMethod}
                              account={account}
                              showExternalBadge={true}
                            />
                          ))
                      )}
                    </div>
                  </Card>
                )}

              <Card>
                <div className="flex flex-col divide-y gap-2 w-full">
                  <div className="flex justify-between items-center">
                    <h1 className="truncate text-lg font-medium text-gray-900">
                      Insurance Policies
                    </h1>
                    {patient.insurancePolicies.length ===
                    activePolicies.length ? null : showAllPolicies ? (
                      <button
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        onClick={() => setShowAllPolicies(false)}
                      >
                        Hide Inactive (
                        {patient.insurancePolicies.length -
                          activePolicies.length}
                        )
                      </button>
                    ) : (
                      <button
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        onClick={() => setShowAllPolicies(true)}
                      >
                        Show Inactive (
                        {patient.insurancePolicies.length -
                          activePolicies.length}
                        )
                      </button>
                    )}
                  </div>
                  {showingPolicies.map((insurancePolicy) => {
                    const hasPlanDates =
                      isDefined(insurancePolicy.effectiveDate) ||
                      isDefined(insurancePolicy.renewalDate) ||
                      isDefined(insurancePolicy.terminationDate);
                    const planDates = [
                      insurancePolicy.effectiveDate
                        ? toDateMMDDYYYY(insurancePolicy.effectiveDate)
                        : "NA",
                      insurancePolicy.active
                        ? insurancePolicy.renewalDate
                          ? toDateMMDDYYYY(insurancePolicy.renewalDate)
                          : "NA"
                        : insurancePolicy.terminationDate
                        ? toDateMMDDYYYY(insurancePolicy.terminationDate)
                        : "NA",
                    ].join("-");
                    return (
                      <div
                        className="flex flex-col pt-2"
                        key={insurancePolicy.id}
                      >
                        <div className="pt-2 flex justify-between gap-2">
                          <div className="font-medium flex gap-1 flex-wrap">
                            {insurancePolicy.payer.name}
                            {insurancePolicy.accountCoverages
                              .map((c) => c.account.accountType?.name)
                              .filter(isDefined)
                              .map((acctName) => (
                                <Badge variant="info" text={acctName} />
                              ))}
                          </div>
                          <div className="flex flex-wrap gap-1 justify-end h-6">
                            {insurancePolicy.priority && (
                              <Badge
                                text={insurancePolicy.priority}
                                variant="info"
                              />
                            )}
                            <Badge
                              text={
                                insurancePolicy.active ? "Active" : "Inactive"
                              }
                              variant={
                                insurancePolicy.active ? "success" : "warning"
                              }
                            />
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <div className="text-gray-600 flex items-center gap-1">
                            <IdentificationIcon className="h-4" />
                            {insurancePolicy.memberId}
                          </div>
                          {hasPlanDates && (
                            <div className="text-gray-600 flex items-center gap-1">
                              <CalendarIcon className="h-4" />
                              {planDates}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>

              {flags.patientAccountLinkingEnabled && (
                <LinkedAccountsCard patient={patient} />
              )}
            </aside>

            <section className="sm:col-span-2">
              <MainSection patient={patient} />
            </section>
          </main>
        }
      />
      {addPaymentMethodDialogOpen && (
        <AddPaymentMethodDialog
          open={addPaymentMethodDialogOpen}
          setOpen={setAddPaymentMethodDialogOpen}
          patientId={patient.id}
          onSuccessfulSetup={onSuccessfulSetup}
        />
      )}
      {createPaymentDialogOpen && (
        <CreatePaymentDialog
          open={createPaymentDialogOpen}
          setOpen={setCreatePaymentDialogOpen}
          patient={patient}
        />
      )}
    </>
  );
};

const PatientNotes: React.FC<
  React.PropsWithChildren<{ patient: IPatient }>
> = ({ patient }) => {
  const [existingPatientNote, setExistingPatientNote] = useState<
    INote | undefined
  >(
    patient.notes
      .filter((n) => n.type === "Patient")
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .at(0)
  );
  const [patientNotes, setPatientNotes] = useState(
    existingPatientNote?.content || ""
  );
  const [createNote, createNoteResult] = useMutation<
    CreateNote,
    CreateNoteVariables
  >(CREATE_NOTE);
  const [updateNote, updateNoteResult] = useMutation<
    UpdateNote,
    UpdateNoteVariables
  >(UPDATE_NOTE);
  const MAX_CHARS = 500;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (existingPatientNote) {
        await updateNote({
          variables: {
            id: existingPatientNote.id,
            data: {
              content: { set: patientNotes },
              updatedAt: { set: new Date() },
            },
          },
        });
      } else {
        if (patientNotes.length > 0) {
          const result = await createNote({
            variables: {
              data: {
                content: patientNotes,
                patient: {
                  connect: {
                    id: patient.id,
                  },
                },
                type: NoteType.Patient,
              },
            },
          });
          if (result.data?.createOneNote) {
            setExistingPatientNote(result.data.createOneNote);
          }
        }
      }
      toast.success("Notes saved");
    } catch (e) {
      toast.error("Failed to save notes");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
      <div className="space-y-2">
        <div className="flex items-center gap-1">
          <Label htmlFor="patient-notes">Notes for Patient</Label>
          <Tooltip
            trigger={<InformationCircleIcon className="h-4 w-4" />}
            content={
              <div className="max-w-md">
                Add notes that will apply to all appointments for this specific
                patient
              </div>
            }
          />
        </div>
        <Textarea
          id="patient-notes"
          placeholder="Enter notes for the patient..."
          value={patientNotes}
          onChange={(e) => setPatientNotes(e.target.value.slice(0, MAX_CHARS))}
          className="min-h-[100px]"
        />
        <p className="text-sm text-muted-foreground text-right">
          {patientNotes.length}/{MAX_CHARS}
        </p>
      </div>
      <Button
        type="submit"
        className="w-1/4 bg-indigo-600 text-white rounded-lg py-2 font-semibold pr-5 pl-5"
      >
        Save Notes
      </Button>
    </form>
  );
};

export const Patient: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data, loading } = useQuery<GetPatient, GetPatientVariables>(
    GET_PATIENT,
    {
      variables: { id: patientId! },
    }
  );
  return loading ? <>Loading...</> : <Profile patient={data!.patient!} />;
};
