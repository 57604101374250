import {
  BriefcaseIcon,
  CalendarIcon,
  CheckCircleIcon,
  ExternalLinkIcon,
  MinusIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ColumnDef } from "@tanstack/react-table";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { Badge, Tooltip } from "../../components";
import { Circle } from "../../components/icons";
import { Checkbox } from "../../components/ui/checkbox";
import { DataTableColumnHeader } from "../../components/ui/table-helpers/data-table-column-header";
import {
  GetVerificationWorkflowPolicies_verificationWorkflowPolicies_mostRecentEligibilityRequest as EligibilityRequest,
  GetVerificationWorkflowPolicies_verificationWorkflowPolicies_mostRecentBenefitsCommunication as MostRecentBenefitsCommunication,
  GetVerificationWorkflowPolicies_verificationWorkflowPolicies_verificationWorkflowStatus as VerificationWorkflowStatus,
  GetVerificationWorkflowPolicies_verificationWorkflowPolicies_nextAppointment as WorklistAppointment,
  GetVerificationWorkflowPolicies_verificationWorkflowPolicies as WorklistPolicy,
} from "../../generated/GetVerificationWorkflowPolicies";
import {
  InsurancePolicyVerificationStatus,
  VerificationWorkflowReason,
} from "../../generated/globalTypes";
import { useFeatureFlags } from "../../hooks";
import { isDefined } from "../../utils";
import { InsurancePolicyPriorityBadge } from "../appointments";
import { EligibilityStatusCell } from "../estimations/columns";
import { AdditionalPlanAcknowledgements } from "../patients/insurance-policy-summary";
import { ServiceBenefitHoverCard } from "../patients/insurances/show";
import { VerificationWorkflowStatusIndicator } from "./list";
import { gql, useLazyQuery } from "@apollo/client";
import { VERIFICATION_WORKFLOW_POLICY_FIELDS } from "../../graphql";
import { useEligibilityStatusPolling } from "../../hooks/use-eligibility-status-polling";
export type VerificationWorklistRow = {
  id: string;
  date: Date;
  // The name of the verification workflow status
  status: string;
  verificationWorkflowStatus: VerificationWorkflowStatus;
  lastVerificationWorkflowReason: VerificationWorkflowReason | null;
  patientName: string;
  memberId: string;
  payerName: string;
  policy: WorklistPolicy;
  eligibilityStatus: InsurancePolicyVerificationStatus;
  mostRecentEligibility: EligibilityRequest | null;
  nextAppointmentStart: Date | null;
  nextAppointment: WorklistAppointment;
  completeness: number | null;
  lastExportedAt: Date | null;
  mostRecentBenefitsCommunication: MostRecentBenefitsCommunication | null;
};

export const PatientCell: React.FC<{
  patientId: string;
  patientName: string;
  accounts: {
    accountType: { name: string | null } | null;
  }[];
}> = ({ patientId, patientName, accounts }) => {
  const accountsDisplay = accounts
    .map((account) => account.accountType?.name)
    .filter(isDefined)
    .join(", ");
  return (
    <div className="flex flex-col">
      <Link
        to={`/patients/${patientId}`}
        className="text-sm text-indigo-500 hover:text-indigo-400"
      >
        {patientName}
      </Link>
      <div className="flex items-center gap-1">
        {accountsDisplay && (
          <div className="flex items-center gap-1 text-xs">
            <BriefcaseIcon className="h-4" />
            <span className="text-ellipsis truncate max-w-[12em]">
              {accountsDisplay}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const AppointmentCell: React.FC<{
  appointment: Pick<
    WorklistAppointment,
    "start" | "end" | "provider" | "appointmentType" | "appointmentLabelings"
  > | null;
}> = ({ appointment }) => {
  return (
    <div>
      <div className="text-sm text-gray-900">
        {appointment ? (
          <div className="flex flex-col text-sm text-gray-900 truncate">
            <div>
              {format(parseISO(appointment.start), "MM/dd/yyyy h:mm aa")}
              {isDefined(appointment.end) &&
                `- ${format(parseISO(appointment.end), "h:mm aa")}`}
            </div>
            <div className="flex items-center gap-1">
              {appointment.provider && (
                <div className="flex items-center gap-1 text-xs">
                  <UserIcon className="h-4" />
                  <span className="text-ellipsis truncate max-w-[10em]">
                    {appointment.provider?.displayName}
                  </span>
                </div>
              )}
              {appointment.appointmentLabelings.length > 0 && (
                <div className="flex items-center gap-1 text-xs">
                  <CalendarIcon className="h-4" />
                  <span className="text-ellipsis truncate max-w-[10em]">
                    {appointment.appointmentLabelings
                      .map((labeling) => labeling.appointmentLabel.name)
                      .join(", ")}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-sm text-gray-700 italic">
            No upcoming appointments
          </div>
        )}
      </div>
    </div>
  );
};

export const MemberIdCell: React.FC<{
  patientId: string;
  insurancePolicy: Pick<WorklistPolicy, "id" | "memberId" | "priority">;
}> = ({ patientId, insurancePolicy }) => {
  if (!insurancePolicy.memberId) {
    return (
      <div className="flex justify-center">
        <MinusIcon className="h-4 w-4 text-gray-300" />
      </div>
    );
  }
  return (
    <div className="flex items-center gap-1">
      {insurancePolicy.priority && (
        <InsurancePolicyPriorityBadge priority={insurancePolicy.priority} />
      )}
      <Link
        to={`../patients/${patientId}/insurances/${insurancePolicy.id}`}
        className="text-indigo-500 hover:text-indigo-400"
      >
        {insurancePolicy.memberId}
      </Link>
    </div>
  );
};

export const CompletenessCellRow: React.FC<{
  insurancePolicy: WorklistPolicy;
}> = ({ insurancePolicy }) => {
  const coverageBenefits = insurancePolicy.mostRecentCoverageBenefits;
  if (coverageBenefits.length === 0) {
    return (
      <div className="flex justify-center">
        <MinusIcon className="h-4 w-4 text-gray-300" />
      </div>
    );
  }
  const requested = coverageBenefits.length;
  const nonEmpty = coverageBenefits.filter((s) => !s.empty).length;
  const complete = requested == nonEmpty;
  return (
    <div>
      <div className="text-sm text-gray-900 flex justify-center items-center gap-2">
        {complete && <CheckCircleIcon className="h-5 w-5 text-green-500" />}
        {!complete && (
          <div className="h-5 w-5 flex items-center justify-center">
            <Circle
              percentage={100 * (nonEmpty / requested)}
              stroke={"#eab308"}
            />
          </div>
        )}
        <div>
          {nonEmpty} of {requested}
        </div>
      </div>
    </div>
  );
};

export const InsurancePolicyVerificationStatusBadge: React.FC<{
  status: InsurancePolicyVerificationStatus;
}> = ({ status }) => {
  if (status === InsurancePolicyVerificationStatus.Active) {
    return <Badge text="Active" variant="success" />;
  }
  if (status === InsurancePolicyVerificationStatus.Inactive) {
    return <Badge text="Inactive" variant="warning" />;
  }
  if (status === InsurancePolicyVerificationStatus.Unverified) {
    return <Badge text="Unverified" variant="info" />;
  }
  if (status === InsurancePolicyVerificationStatus.Error) {
    return <Badge text="Error" variant="error" />;
  }
  if (status === InsurancePolicyVerificationStatus.NeedsReverification) {
    return <Badge text="Needs Reverification" variant="warning" />;
  }
  if (status === InsurancePolicyVerificationStatus.InProgress) {
    return <Badge text="In Progress" variant="info" />;
  }
};

export const PolicyStatusCellRow: React.FC<{
  insurancePolicy: Pick<
    WorklistPolicy,
    "id" | "insurancePolicyVerificationStatus" | "unverifiedReason"
  > &
    Partial<Pick<WorklistPolicy, "mostRecentEligibilityRequest">>;
  patientId: string;
}> = ({ insurancePolicy, patientId }) => {
  const mostRecentEligibility =
    insurancePolicy.mostRecentEligibilityRequest ?? null;
  // Poll the eligibility request status
  const {} = useEligibilityStatusPolling(mostRecentEligibility);

  switch (insurancePolicy.insurancePolicyVerificationStatus) {
    case InsurancePolicyVerificationStatus.Active:
      return (
        <div key={insurancePolicy.id} className="flex gap-2 items-center">
          <InsurancePolicyVerificationStatusBadge
            status={insurancePolicy.insurancePolicyVerificationStatus}
          />
          {mostRecentEligibility && (
            <Link
              to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" />
            </Link>
          )}
        </div>
      );
    case InsurancePolicyVerificationStatus.NeedsReverification:
      return (
        <div key={insurancePolicy.id} className="flex gap-2 items-center">
          <InsurancePolicyVerificationStatusBadge
            status={insurancePolicy.insurancePolicyVerificationStatus}
          />
          {mostRecentEligibility && (
            <Link
              to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" />
            </Link>
          )}
        </div>
      );
    case InsurancePolicyVerificationStatus.Inactive:
      return (
        <div key={insurancePolicy.id} className="flex gap-2 items-center">
          <InsurancePolicyVerificationStatusBadge
            status={insurancePolicy.insurancePolicyVerificationStatus}
          />
          {mostRecentEligibility && (
            <Link
              to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" />
            </Link>
          )}
        </div>
      );
    case InsurancePolicyVerificationStatus.Unverified:
      return (
        <div key={insurancePolicy.id}>
          {insurancePolicy.unverifiedReason ? (
            <Tooltip
              content={<>{insurancePolicy.unverifiedReason}</>}
              trigger={
                <InsurancePolicyVerificationStatusBadge status="Unverified" />
              }
            />
          ) : (
            <Badge text="Unverified" variant="info" />
          )}
        </div>
      );
    case InsurancePolicyVerificationStatus.InProgress:
      return (
        <div key={insurancePolicy.id} className="flex gap-2 items-center">
          <InsurancePolicyVerificationStatusBadge
            status={insurancePolicy.insurancePolicyVerificationStatus}
          />
          {mostRecentEligibility && (
            <Link
              to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" />
            </Link>
          )}
        </div>
      );
    case InsurancePolicyVerificationStatus.Error:
      return (
        <div key={insurancePolicy.id} className="flex gap-2 items-center">
          <InsurancePolicyVerificationStatusBadge
            status={insurancePolicy.insurancePolicyVerificationStatus}
          />
          {mostRecentEligibility && (
            <Link
              to={`/patients/${patientId}/eligibilities/${mostRecentEligibility.id}`}
              className="text-gray-400 hover:text-gray-300"
              target="_blank"
            >
              <ExternalLinkIcon className="h-4 w-4" />
            </Link>
          )}
        </div>
      );
  }
};

const LastVerifiedCellRow: React.FC<{
  insurancePolicy: WorklistPolicy;
}> = ({ insurancePolicy }) => {
  const mostRecentEligibility = insurancePolicy.mostRecentEligibilityRequest;
  let lastVerified = mostRecentEligibility?.createdAt
    ? parseISO(mostRecentEligibility.createdAt)
    : null;
  return (
    <div className="text-sm text-gray-900">
      {lastVerified ? (
        <Tooltip
          content={<>{format(lastVerified, "MM/dd/yyyy hh:mm a")}</>}
          trigger={
            <>
              {formatDistanceToNow(lastVerified, {
                addSuffix: true,
              })}
            </>
          }
        />
      ) : (
        <div className="flex items-center gap-1">
          <MinusIcon className="h-4 w-4 text-gray-300" />
        </div>
      )}
    </div>
  );
};

const ServicesCellRow: React.FC<{
  patientId: string;
  insurancePolicy: WorklistPolicy;
}> = ({ patientId, insurancePolicy }) => {
  const coverageBenefits = insurancePolicy.mostRecentCoverageBenefits;
  if (coverageBenefits.length === 0) {
    return (
      <div key={insurancePolicy.id} className="flex justify-center">
        <MinusIcon className="h-4 w-4 text-gray-300" />
      </div>
    );
  }
  return (
    <div className="text-sm text-gray-900 flex gap-1">
      {[...coverageBenefits]
        .sort(
          (a, b) =>
            (a.providerServiceConfiguration.priorityOrder ?? Infinity) -
            (b.providerServiceConfiguration.priorityOrder ?? Infinity)
        )
        .map((cb) => {
          if (cb.empty) {
            return (
              <ServiceBenefitHoverCard
                key={cb.id}
                trigger={
                  <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/10">
                    {cb.providerServiceConfiguration.shortName ??
                      cb.providerServiceConfiguration.name}
                  </span>
                }
                coverageBenefit={cb}
                patientId={patientId}
                providerServiceConfiguration={cb.providerServiceConfiguration}
                insurancePolicy={insurancePolicy}
                inNetwork={cb.networkStatus}
                hoverEnabled={false}
              />
            );
          }
          if (cb.nonCovered) {
            return (
              <ServiceBenefitHoverCard
                key={cb.id}
                trigger={
                  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {cb.providerServiceConfiguration.shortName ??
                      cb.providerServiceConfiguration.name}
                  </span>
                }
                coverageBenefit={cb}
                patientId={patientId}
                providerServiceConfiguration={cb.providerServiceConfiguration}
                insurancePolicy={insurancePolicy}
                inNetwork={cb.networkStatus}
                hoverEnabled={false}
              />
            );
          }

          return (
            <ServiceBenefitHoverCard
              key={cb.id}
              trigger={
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {cb.providerServiceConfiguration.shortName ??
                    cb.providerServiceConfiguration.name}
                </span>
              }
              coverageBenefit={cb}
              patientId={patientId}
              providerServiceConfiguration={cb.providerServiceConfiguration}
              insurancePolicy={insurancePolicy}
              inNetwork={cb.networkStatus}
              hoverEnabled={false}
            />
          );
        })}
    </div>
  );
};

const LastExportedAtRow: React.FC<{
  lastExportedAt: Date | null;
}> = ({ lastExportedAt }) => {
  return (
    <div className="text-sm text-gray-900">
      {lastExportedAt ? (
        <Tooltip
          content={<>{format(lastExportedAt, "MM/dd/yyyy hh:mm a")}</>}
          trigger={
            <>
              {formatDistanceToNow(lastExportedAt, {
                addSuffix: true,
              })}
            </>
          }
        />
      ) : (
        <div className="flex items-center gap-1">
          <MinusIcon className="h-4 w-4 text-gray-300" />
        </div>
      )}
    </div>
  );
};

const LastBenefitsEmailRow: React.FC<{
  mostRecentBenefitsCommunication: MostRecentBenefitsCommunication | null;
}> = ({ mostRecentBenefitsCommunication }) => {
  return (
    <div className="text-sm text-gray-900">
      {mostRecentBenefitsCommunication?.sentAt ? (
        <Tooltip
          content={
            <>
              {format(
                new Date(mostRecentBenefitsCommunication.sentAt),
                "MMMM do, yyyy hh:mm a"
              )}
            </>
          }
          trigger={
            <>
              {formatDistanceToNow(
                new Date(mostRecentBenefitsCommunication.sentAt),
                {
                  addSuffix: true,
                }
              )}
            </>
          }
        />
      ) : (
        <div className="flex items-center gap-1">
          <MinusIcon className="h-4 w-4 text-gray-300" />
        </div>
      )}
    </div>
  );
};

const VerificationWorkfloReasonDisplay: React.FC<{
  reason: VerificationWorkflowReason;
}> = ({ reason }) => {
  if (reason === VerificationWorkflowReason.NewPolicy) {
    return <div className="text-sm text-gray-900">New Policy</div>;
  }
  if (reason === VerificationWorkflowReason.Reverification) {
    return <div className="text-sm text-gray-900">Reverification</div>;
  }
  if (reason === VerificationWorkflowReason.ManuallyMoved) {
    return <div className="text-sm text-gray-900">Manually Moved</div>;
  }

  return <div className="text-sm text-gray-900">{reason}</div>;
};

const GET_VERIFICATION_WORKFLOW_POLICY = gql`
  ${VERIFICATION_WORKFLOW_POLICY_FIELDS}
  query GetVerificationWorkflowPolicy($id: String!) {
    insurancePolicy(where: { id: $id }) {
      ...VerificationWorkflowPolicyFields
    }
  }
`;

export const columns: ColumnDef<VerificationWorklistRow>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "date",
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({ row }) => {
      return (
        <Tooltip
          content={<>{format(row.original.date, "MM/dd/yyyy hh:mm a")}</>}
          trigger={
            <>
              {formatDistanceToNow(row.original.date, {
                addSuffix: true,
              })}
            </>
          }
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.patientName);
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const policy = row.original.policy;
      return (
        <div className="flex gap-1 items-center">
          <VerificationWorkflowStatusIndicator
            status={policy.verificationWorkflowStatus}
          />
          {policy.verificationWorkflowStatus.name}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "lastVerificationWorkflowReason",
    accessorKey: "lastVerificationWorkflowReason",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Reason" />
    ),
    cell: ({ cell }) => (
      <VerificationWorkfloReasonDisplay
        reason={cell.row.original.lastVerificationWorkflowReason}
      />
    ),
    filterFn: (row, id, value) => {
      const status = row.original.lastVerificationWorkflowReason;
      return value.includes(status);
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row }) => {
      const policy = row.original.policy;
      const patient = policy.patient;
      return (
        <PatientCell
          patientId={patient.id}
          patientName={row.original.patientName}
          accounts={policy.accounts}
        />
      );
    },
    filterFn: (row, id, value) => {
      return row.original.policy.accounts.some((account) =>
        value.includes(account.accountType?.name ?? "")
      );
    },
  },
  {
    accessorKey: "memberId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Member ID" />
    ),
    cell: ({ row }) => {
      const patient = row.original.policy.patient;
      return (
        <MemberIdCell
          patientId={patient.id}
          insurancePolicy={row.original.policy}
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "payerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payer" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "nextAppointmentStart",
    accessorKey: "nextAppointmentStart",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Next Appointment" />
    ),
    cell: ({ row }) => {
      const appointment = row.original.nextAppointment;
      if (!appointment)
        return (
          <div className="flex justify-center">
            <MinusIcon className="h-4 w-4 text-gray-300" />
          </div>
        );

      return <AppointmentCell appointment={appointment} />;
    },
  },
  {
    accessorKey: "eligibilityStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Eligibility Status" />
    ),
    cell: ({ row }) => {
      const flags = useFeatureFlags();
      const policy = row.original.policy;
      const appointment = row.original.nextAppointment;
      const [getVerificationWorkflowPolicy, { loading }] = useLazyQuery(
        GET_VERIFICATION_WORKFLOW_POLICY
      );
      return (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <EligibilityStatusCell
              patientId={policy.patient.id}
              insurancePolicy={policy}
              appointment={appointment ?? null}
              onComplete={() => {
                getVerificationWorkflowPolicy({
                  variables: { id: policy.id },
                });
              }}
            />
            {flags.showAdditionalPlans && policy.additionalPlans.length > 0 && (
              <AdditionalPlanAcknowledgements
                additionalPlans={policy.additionalPlans}
                patientId={policy.patient.id}
              />
            )}
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "lastVerified",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Verified" />
    ),
    cell: ({ row }) => {
      const policy = row.original.policy;
      return <LastVerifiedCellRow insurancePolicy={policy} />;
    },
  },
  {
    accessorKey: "completeness",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Completness" />
    ),
    cell: ({ row }) => {
      const policy = row.original.policy;
      return <CompletenessCellRow insurancePolicy={policy} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "services",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Services" />
    ),
    cell: ({ row }) => {
      const policy = row.original.policy;
      return (
        <ServicesCellRow
          patientId={policy.patient.id}
          insurancePolicy={policy}
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: false,
  },
  {
    accessorKey: "lastExportedAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Exported" />
    ),
    cell: ({ row }) => {
      return <LastExportedAtRow lastExportedAt={row.original.lastExportedAt} />;
    },
  },
  {
    accessorKey: "mostRecentBenefitsCommunication",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Benefits Email" />
    ),
    cell: ({ row }) => {
      return (
        <LastBenefitsEmailRow
          mostRecentBenefitsCommunication={
            row.original.mostRecentBenefitsCommunication
          }
        />
      );
    },
  },
  {
    accessorKey: "benefitsEmailSent",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Benefits Email Sent" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm text-gray-900">
          {row.original.mostRecentBenefitsCommunication?.sentAt ? "Yes" : "No"}
        </div>
      );
    },
    accessorFn: (row) => {
      return row.mostRecentBenefitsCommunication?.sentAt ? "Yes" : "No";
    },
  },
];
